import axios from 'axios';
import { api } from './apiService';

export interface UserData {
  blocked: boolean;
  confirmed: boolean;
  email?: string;
  username: string;
  id?: number;
  name?: string;
  rights: string[];
}

export interface UserDataUpdate {
  email?: string | null;
  username: string;
  name: string;
  password?: string;
  rights: string[];
}

interface LoginResponse {
  jwt: string;
  user: UserData;
}

export let login = (email: string, password: string): Promise<LoginResponse> => {
  return new Promise((resolve, reject) => {
    email = email.toLowerCase();
    axios.post(api + '/api/auth/local', {identifier: email, password: password})
    .then(res => {
      let {blocked, confirmed, email, username, rights} = res.data.user;
      resolve({jwt: res.data.jwt, user: {blocked, confirmed, email, username, rights}});
    })
    .catch(err => {
      reject({});
    })
  })
}

export let logout = (): void => {
  window.localStorage.removeItem('ksm_erp_jwt');
  window.location.replace('/');
}

// no register available
// export let register = (email: string, password: string, username: string): Promise<LoginResponse> => {
//   return new Promise((resolve, reject) => {
//     axios.post(api + '/api/auth/register', {identifier: email, password: password, username: username})
//     .then(res => {
//       let {blocked, confirmed, email, username} = res.data.user;
//       let role = res.data.user.role;
//       resolve({jwt: res.data.jwt, user: {blocked, confirmed, email, username, role}});
//     })
//     .catch(err => {
//       reject({});
//     })
//   })
// }

export let isAuthenticated = (): Promise<UserData> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt) {
      reject({});
    } else {
      axios.get(api + '/api/users/me', {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        resolve({
          blocked: res.data.blocked,
          confirmed: res.data.confirmed,
          email: res.data.email,
          username: res.data.username,
          rights: res.data.rights || []
        });
      })
      .catch(err => {
        reject({});
      })
    }
  })
}

export let fetchUsers = (): Promise<{users: UserData[]}> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt) {
      reject({});
    } else {
      axios.get(api + '/api/users?populate=role&sort[0]=id', {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        resolve({
          users: res.data && res.data.length >= 1 ? res.data : []
        })
      })
      .catch(err => {
        reject({})
      })
    }
  })
}

export let updateUser = (id: number, data: UserDataUpdate): Promise<string> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt || !id) {
      reject({});
    } else {
      axios.put(api + '/api/users/' + id, data, {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        resolve('success')
      })
      .catch(err => {
        reject({})
      })
    }
  })
}

export let createUser = (data: UserDataUpdate): Promise<string> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt) {
      reject({});
    } else {
      data.password = '12345678';
      axios.post(api + '/api/users/', data, {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        resolve('success')
      })
      .catch(err => {
        reject(err.response.data.error.message)
      })
    }
  })
}

export let deleteUser = (id: number): Promise<string> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt) {
      reject({});
    } else {
      axios.delete(api + '/api/users/' + id, {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        resolve('')
      })
      .catch(err => {
        reject(err.response.data.error.message)
      })
    }
  })
}