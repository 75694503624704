import Sidebar from '../components/Sidebar/Sidebar';
import TopNav from '../components/TopNav/TopNav';
import WarehouseCalendar from '../components/Warehouse/WarehouseCalendar/WarehouseCalendar';

interface WarehouseInventoryRouteProps {
  type: string;
}

export default function WarehouseInventoryRoute(props: WarehouseInventoryRouteProps) {
  return (
    <main className='Warehouse container'>
      <Sidebar />
      <TopNav />
      <WarehouseCalendar dashboard={false} type={props.type} />
    </main>
  )
}