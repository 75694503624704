import { Navigate } from 'react-router-dom';
import ContentContainer from '../components/ContentContainer/ContentContainer';
import Sidebar from '../components/Sidebar/Sidebar';

export default function System() {
  return (
    <main className='System container'>
      <Sidebar />
      <ContentContainer />
      <Navigate to="/system/einstellungen" />
    </main>
  )
}