import { Close, QrCode2 } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableHead, TableRow, Modal, Box, Button, TextField, InputAdornment, MenuItem, Tooltip, Tabs, Tab } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Batch, createBatch, createBatchData, emptyBatch, fetchBatches, GoodToUse, StoreToStore, StoreToUse } from '../../../services/batchesService';
import { fetchProductionSettings, fetchWarehouseData, WarehouseSpot } from '../../../services/configService';
import { fetchInvetory, Good } from '../../../services/inventoryService';
import { resetNotifications } from '../../../services/notiStoreService';
import { convertGoodsCategory, convertOrderAttributes, localNumber, toLocalISOTime } from '../../../services/stringConverter';
import { fetchOrders, OrderData } from '../../../services/warehouseEntriesService';
import { NotiStore } from '../../../stores/notifications';
import { rpetColors } from '../../../utils/defaults';
import './ProductionBatchesForm.sass';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'scroll',
  overflowX: 'hidden'
};

interface ProductionBatchesFormProps {
  open: boolean;
  activeBatch: number;
  closeModal: () => void;
}
interface ProductionBatchesFormState {
  batches: Batch[];
  goods: Good[];
  orders: {id: number, attributes: OrderData}[];
  stores: WarehouseSpot[];
  storesToStore: StoreToStore[];
  storesToUse: StoreToUse[];
  showPop: boolean;
  activeBatch: number;
  activeBatchIsDraft: boolean;
  showStoragePop: boolean;
  storagePopMode: string;
  popMode: string;
  productionTime: number;
  rpetInput: number;
  minInput: number;
  //modal data
  creator: string;
  input: number;
  type: string;
  output: number;
  color: string;
  foodSafe: string;
  material: string;
  duration: number;
  labInterval: number;
  batchChangeBagsCount: number;
  batchChangeStore: string;
  start: string;
  end: string;
  shifts: {start: string; end: string;}[];
  tab: number;
}

const defaultState = {
  batches: [],
  goods: [],
  stores: [],
  orders: [],
  storesToStore: [],
  storesToUse: [],
  showPop: false,
  showStoragePop: false,
  storagePopMode: '',
  popMode: 'create',
  activeBatch: -1,
  activeBatchIsDraft: false,
  productionTime: 0,
  rpetInput: 0,
  minInput: 0,
  creator: '',
  input: 0,
  output: 0,
  type: '',
  color: '',
  foodSafe: '',
  material: '',
  duration: 0,
  labInterval: 0,
  batchChangeBagsCount: 0,
  batchChangeStore: '',
  start: '',
  end: '',
  shifts: [],
  tab: 0,
}

class ProductionBatchesForm extends React.Component<ProductionBatchesFormProps, ProductionBatchesFormState> {
  state: ProductionBatchesFormState = {...defaultState}

  componentDidMount() {
    resetNotifications()
    this.fetchData()
  }

  componentDidUpdate(prevProps: ProductionBatchesFormProps) {
    if (prevProps.activeBatch !== this.props.activeBatch && this.props.activeBatch >= 0 && this.props.open) {
      this.startEdit(this.props.activeBatch)
    }
  }

  fetchData = (): void => {
    NotiStore.setState({loading: true});

    let batches = fetchBatches();
    let goods = fetchInvetory('filters[status]=stored');
    let stores = fetchWarehouseData();
    let productionSettings = fetchProductionSettings();
    let orders = fetchOrders('filters[isDraft][$eq]=false&filters[isCancelled][$eq]=false&filters[isFulfilled]=true');

    Promise.all([batches, goods, stores, productionSettings, orders])
    .then(res => {
      let {shiftsFirstEnd, shiftsFirstStart, shiftsSecondEnd, shiftsSecondStart, shiftsThirdEnd, shiftsThirdStart} = res[3].data
      let shifts: {start: string; end: string}[] = [];

      if (shiftsFirstEnd && shiftsFirstStart && shiftsSecondEnd && shiftsSecondStart && shiftsThirdEnd && shiftsThirdStart) {
        shifts = [
          {start: shiftsFirstStart.toString(), end: shiftsFirstEnd.toString()},
          {start: shiftsSecondStart.toString(), end: shiftsSecondEnd.toString()},
          {start: shiftsThirdStart.toString(), end: shiftsThirdEnd.toString()},
        ]
      }

      if (res[2].spots && res[2].spots.length >= 1) res[2].spots.forEach(store => {
        let storeGoods: Good[] = []

        if (store.attributes.goods && store.attributes.goods.data) store.attributes.goods.data.forEach(good => {
          if (good.attributes.status === 'stored') storeGoods.push(good)
        })

        store.attributes.goods = {data: storeGoods}
      })

      this.setState({
        batches: res[0].data,
        goods: res[1].data,
        stores: res[2].spots,
        productionTime: res[3].data.productionTime,
        rpetInput: res[3].data.rpetInput,
        orders: res[4].data || [],
        shifts
      }, this.updateMinInput)
    })
    .catch(err => {
      NotiStore.setState({error: true, errorMessage: 'Fehler beim Abrufen der Daten aufgetreten.'})
    })
    .finally(() => {
      NotiStore.setState({loading: false})
    })
  }

  onClosePop = (): void => {
    this.setState(prevState => ({
      ...defaultState,
      batches: prevState.batches,
      stores: prevState.stores,
      goods: prevState.goods,
    }))

    this.fetchData()
    this.props.closeModal()
  }

  updateType = (e: React.ChangeEvent<HTMLInputElement>): void => {
      let value: string = e.target.value;

      this.setState({
        type: value
      })
  }

  updateOutput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      let value: number = Number(e.target.value);

      this.setState({
        output: Number(value)
      }, () => {
        this.updateEndTime()
        this.updateMinInput()
      })

    } catch {}
  }

  updateEndTime = (): void => {
    if (this.state.output && this.state.productionTime) {
      let duration: number = Math.ceil(this.state.productionTime * this.state.output / 1000);
      
      let start: Date;
      if (this.state.start) {
        start = new Date(this.state.start)
        if (start) {
          let end: Date = new Date(start.getTime() + duration * 60000)

          this.setState({
            duration,
            end: toLocalISOTime(end)
          })
        }
      } else {
        this.setState({duration})
      }
    }
  }

  updateDateTime = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let {name, value} = e.target;

    try {
      if (name === 'start') this.setState({start: value}, this.updateEndTime)
    } catch {}
  }

  updateMinInput = (): void => {
    if (this.state.rpetInput && this.state.output) {
      let minInput = Math.ceil(this.state.output / 1000 * this.state.rpetInput)
      this.setState({minInput})
    }
  }

  addStoreToUse = (index: number, ids: number[] = [], title: string = ''): void => {
    resetNotifications();

    let {storagePopMode} = this.state;

    if (storagePopMode === 'goods' && ids && ids.length >= 1 && title) {

      // prevent double items
      let double: number[] = []
      this.state.storesToUse.forEach(store => {
        store.goods.forEach(good => {
          if (ids.includes(good.id) && !double.includes(good.id)) double.push(good.id)
        })
      })

      if (double.length >= 1) double.forEach(d => {
        let index = ids.indexOf(d)
        if (index > -1) ids.splice(index, 1)
      })
      // prevent double items end

      if (ids.length >= 1) {
        this.setState(prevState => ({storesToUse: [...prevState.storesToUse, {
          id: -1, 
          input: 0, 
          storageName: title,
          goods: this.state.goods.filter(good => ids.includes(good.id)) || []
        }], showStoragePop: false}))
      } else {
        NotiStore.setState({error: true, errorMessage: 'Alle Produkte befinden sich bereits in der Liste.'});
      }
    } else if (index > -1 && this.state.stores.length >= 1 && typeof this.state.stores[index] !== 'undefined') {
      if (storagePopMode === 'goods') {
        if (this.state.storesToUse.filter((store: StoreToUse) => store.id === this.state.stores[index].id).length < 1) {
          this.setState(prevState => ({storesToUse: [...prevState.storesToUse, {
            id: prevState.stores[index].id, 
            input: 0, 
            storageName: prevState.goods[index].attributes.warehouse_spot.data.attributes.name,
            goods: this.state.goods.filter(good => good.attributes.quantity && good.attributes.quantity > 0 && good.attributes.warehouse_spot.data && good.attributes.warehouse_spot.data.id === prevState.stores[index].id) || []
          }], showStoragePop: false}))
        } else {
          NotiStore.setState({error: true, errorMessage: 'Lager ist in der Liste bereits vorhanden.'});
        }
      } else if (storagePopMode === 'stores') {
        if (this.state.storesToStore.filter((store: StoreToStore) => store.id === this.state.stores[index].id).length < 1) {
          this.setState(prevState => ({storesToStore: [...prevState.storesToStore, {...this.state.stores[index], input: !prevState.storesToStore || prevState.storesToStore.length === 0 ? this.state.output : 0, }], showStoragePop: false}))
        } else {
          NotiStore.setState({error: true, errorMessage: 'Lager ist in der Liste bereits vorhanden.'});
        }
      }
    }
  }

  deleteGoodToUse = (index: number): void => {
    if (index > -1 && typeof this.state.storesToUse[index] !== 'undefined') {
      let {storesToUse} = this.state;
      storesToUse.splice(index, 1);
      this.setState({storesToUse})
    }
  }

  deleteStoreToStore = (index: number): void => {
    if (index > -1 && typeof this.state.storesToStore[index] !== 'undefined') {
      let {storesToStore} = this.state;
      storesToStore.splice(index, 1);
      this.setState({storesToStore})
    }
  }

  updateInputQuantity = (e: React.ChangeEvent<HTMLInputElement>, storeI: number, posI: number): void => {
    let {value} = e.target;

    if (typeof this.state.storesToUse[storeI] !== 'undefined' && typeof this.state.storesToUse[storeI].goods[posI] !== 'undefined') {
      try {
        let {storesToUse} = this.state;

        storesToUse[storeI].goods[posI].inputQuantity = Number(value);

        let input: number = 0;
        let totalInput: number = 0;

        storesToUse[storeI].goods.forEach((good) => {
          input += good.inputQuantity ? good.inputQuantity : 0
        })

        storesToUse[storeI].input = input;

        storesToUse.forEach(store => {
          totalInput += store.input ? store.input : 0
        })

        this.setState({storesToUse, input: totalInput})
      } catch {}
    }
  }

  updateStorageInput = (e: React.ChangeEvent<HTMLInputElement>, i: number): void => {
    let {value} = e.target;

    if (typeof this.state.storesToStore[i] !== 'undefined') {
      try {
        let {storesToStore} = this.state;

        storesToStore[i].input = Number(value);

        this.setState({storesToStore})
      } catch {}
    }
  }

  submitForm = (mode: string = '', isUpdate: boolean = false): void => {
    resetNotifications();
    NotiStore.setState({loading: true});

    let {storesToUse, storesToStore, start, labInterval, batchChangeBagsCount, batchChangeStore, output, color, foodSafe, material, type} = this.state;

    if (mode !== 'draft' && (!output || output < 0)) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib die zu erzeugende Menge an.'});
    } else if (mode !== 'draft' && (!type)) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib die Chargenbezeichnung an.'});
    } else if (mode !== 'draft' && (!color)) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib die Farbe des Endprodukts an.'});
    } else if (mode !== 'draft' && (!foodSafe)) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib an, ob das Endprodukt lebensmittelecht ist.'});
    } else if (mode !== 'draft' && (!material)) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib das Material des Endprodukts an.'});
    } else if (mode !== 'draft' && (!start)) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib an, wann die Produktion startet.'});
    } else if (mode !== 'draft' && (!storesToUse || storesToUse.length < 1)) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte wähle die Waren, die verarbeitet werden sollen, aus.'});
    } else if (mode !== 'draft' && (storesToUse.map(store => store.goods.filter(good => !(good.inputQuantity || good.inputQuantity === 0)))[0].length >= 1)) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib die zu verarbeitende Menge aller ausgewählten Lager & Waren an.'});
    } else if (mode !== 'draft' && (!storesToStore ||storesToStore.length < 1)) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib an, wo das Endprodukt gelagert werden soll.'});
    } else if (mode !== 'draft' && (storesToStore.filter(store => !store.input).length >= 1)) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib an, wie viel von dem Endprodukt am jeweiligen Lagerplatz gelagert werden soll.'});
    } else if (mode !== 'draft' && (storesToStore.filter(store => store.input > store.attributes.capacity).length >= 1)) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Die Lagerkapazität von mind. einem der Lagerplätze ist überschritten.'});
    } else if (mode !== 'draft' && (!(batchChangeBagsCount || batchChangeBagsCount === 0))) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib an, wie viele Bigbags als Chargenwechsel gekennzeichnet werden müssen.'});
    } else if (mode !== 'draft' && (batchChangeBagsCount && !(batchChangeStore))) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib an, wo die Chargenwechsel-Bigbags gelagert werden sollen.'});
    } else if (mode !== 'draft' && (!(labInterval || labInterval === 0))) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib an, in welchem Interval Laborproben entnommen werden sollen.'});
    } else if (mode !== 'draft' && (storesToStore.reduce((partialSum, store) => partialSum + store.input, 0) !== output)) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Die Menge der gelagerten Waren muss ' + output.toLocaleString('de-DE') + ' kg ergeben.'});
    } else {
      // TODO: handle form
      let data: createBatchData = {
        storesToUse, 
        storesToStore, 
        start, 
        color,
        foodSafe,
        material,
        labInterval: labInterval ? labInterval : 0, 
        batchChangeBagsCount: batchChangeBagsCount ? batchChangeBagsCount : 0, 
        batchChangeStore, 
        type,
        output: output ? output : 0,
        isDraft: mode === 'draft' ? true : false,
        id: this.state.activeBatch >= 0 ? this.state.activeBatch : undefined,
      }

      createBatch(data, isUpdate)
      .then(() => {
        this.setState(prevState => ({
          ...defaultState,
          batches: prevState.batches,
          stores: prevState.stores,
          goods: prevState.goods,
        }))
        NotiStore.setState({loading: false, success: true, successMessage: this.state.popMode === 'edit' ? 'Charge erfolgreich aktualisiert.' : 'Charge erfolgreich erstellt.'});
        this.fetchData();
        this.props.closeModal();
      })
      .catch(err => {
        NotiStore.setState({loading: false, error: true, errorMessage: err.message || 'FEHLER'})
      })
    }
  }

  startEdit = (i: number, mode: string = 'edit'): void => {
    try {
      let matches: Batch[] = this.state.batches.filter(b => b.id === i);

      if (matches.length >= 1) {

        let batch: Batch = matches[0];
  
        let storesToStore: StoreToStore[] = [];
        let storesToUse: StoreToUse[] = [];
  
        if (batch.attributes.storesToStore && Array.isArray(batch.attributes.storesToStore.data) && batch.attributes.storesToStore.data.length >= 1) batch.attributes.storesToStore.data.forEach(store => {
          let data: StoreToStore = {...store, input: 0};
  
          let match = batch.attributes.storesToStoreData.filter(data => data.id === store.id)
          
          if (match.length === 1) data.input = match[0].input;
  
          storesToStore.push(data)
        })

        let goods: GoodToUse[] = []

        if (Array.isArray(batch.attributes.goodsToUse.data) && batch.attributes.goodsToUse.data.length >= 1) batch.attributes.goodsToUse.data.forEach(good => {
          let goodToUse: GoodToUse = {
            ...good,
            inputQuantity: batch.attributes.goodsToUseData.filter(data => data.id === good.id).length === 1 ? (batch.attributes.goodsToUseData.filter(data => data.id === good.id)[0].inputQuantity || 0) : 0,
            inputDone: batch.attributes.goodsToUseData.filter(data => data.id === good.id).length === 1 ? (batch.attributes.goodsToUseData.filter(data => data.id === good.id)[0].inputDone || 0) : 0
          }

          goods.push(goodToUse)
        })
        
        if (goods.length >= 1) {
          let data: StoreToUse = {
            id: -1,
            storageName: 'Bereits eingeplant',
            input: 0,
            goods: goods || []
          }
  
          storesToUse.push(data)
        }

        let total = 0;
        if (storesToUse.length >= 1) storesToUse.forEach(s => s.goods.forEach(g => total += g.inputQuantity ? g.inputQuantity : 0));
  
        this.setState({
          popMode: batch.attributes.status === 'planned' ? mode : 'view',
          showPop: true,
          activeBatch: batch.id >= 0  ? batch.id : -1,
          activeBatchIsDraft: batch.attributes.isDraft ? true : false,
          storesToUse,
          storesToStore,
          creator: batch.attributes.creator && batch.attributes.creator.data && batch.attributes.creator.data.attributes.name ? batch.attributes.creator.data.attributes.name : '',
          input: total,
          type: batch.attributes.type || '',
          output: batch.attributes.output || 0,
          color: batch.attributes.color || '',
          foodSafe: batch.attributes.foodSafe || '',
          material: batch.attributes.material || '',
          labInterval: batch.attributes.labInterval || 0,
          batchChangeBagsCount: batch.attributes.batchChangeBagsCount || 0,
          batchChangeStore: batch.attributes.batchChangeStore && batch.attributes.batchChangeStore.data && batch.attributes.batchChangeStore.data.id ? batch.attributes.batchChangeStore.data.id.toString() : '',
          start: batch.attributes.start ? toLocalISOTime(new Date(batch.attributes.start)) : '',
        }, () => {
          this.updateEndTime()
          this.updateMinInput()
        })
      }
    } catch(e) {
      NotiStore.setState({error: true, errorMessage: 'Beim Laden der Daten ist ein Fehler aufgetreten.'});
    }
  }

  getActualDuration = (batch: Batch): string => {
    let {startedAt, finishedAt} = batch.attributes;

    if (startedAt && finishedAt) {
      try {
        let duration = Math.ceil((new Date(finishedAt).getTime() - new Date(startedAt).getTime()) / 60000)

        return `${Math.floor(duration / 60)} h ${duration % 60} min`
      } catch {
        return 'nicht verfügbar'
      }
    }

    return 'nicht verfügbar'
  }

  useCompleteStorage = (i: number): void => {
    let {storesToUse} = this.state
    let totalInput: number = 0;

    if (typeof storesToUse[i] !== 'undefined' && storesToUse[i].goods && storesToUse[i].goods.length >= 1) {
      storesToUse[i].goods.forEach(good => {
        good.inputQuantity = good.attributes.quantity - this.goodAssignedToOtherBatches(good) < 0 ? 0 : good.attributes.quantity - this.goodAssignedToOtherBatches(good)
        totalInput += good.attributes.quantity - this.goodAssignedToOtherBatches(good)
      }) 

      this.setState({storesToUse, input: totalInput})
    }
  }

  goodAssignedToOtherBatches = (good: Good): number => {
    let result = 0
    let {activeBatch} = this.state

    if (good.attributes.batches && good.attributes.batches.data && good.attributes.batches.data.length >= 1) good.attributes.batches.data.forEach(batch => {
      if (batch.id !== activeBatch && batch.attributes.status !== 'finished') {
        if (batch.attributes.goodsToUseData && batch.attributes.goodsToUseData.length >= 1) batch.attributes.goodsToUseData.forEach(data => {
          if (data.id === good.id) result += data.inputQuantity
        })
      }
    })

    return result
  }

  getTotalInputDone = (): number => {
    let result = 0

    if (this.state.activeBatch >= 0) {
      let batch = this.state.batches.filter(b => b.id === this.state.activeBatch)[0]

      if (batch && batch.attributes.goodsToUseData && batch.attributes.goodsToUseData.length >= 1) batch.attributes.goodsToUseData.forEach(data => {
        if (data.inputDone) result += data.inputDone
      })
    }

    return result
  }

  render() {
    let matches = this.state.batches.filter(b => b.id === this.state.activeBatch)
    let activeBatch: Batch = emptyBatch;

    if (matches.length === 1) activeBatch = matches[0]

    return (
      <div className="ProductionBatchesForm" data-testid="ProductionBatchesForm">
        <Modal
          open={this.props.open}
          onClose={this.onClosePop}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="batch-pop"
        >
          <>
            <span className='modal-close pointer' onClick={this.onClosePop}><Close /></span>
            <Box sx={modalStyle}>
              <span className='modal-close pointer' onClick={this.onClosePop}><Close /></span>
              <h2>{this.state.popMode === 'view' || this.state.popMode === 'edit' ? `Charge ${this.state.batches.filter(b => b.id === this.state.activeBatch)[0].attributes.customId}` : 'Neue Charge erstellen'}</h2>
              <h3>Grunddaten</h3>
              <div className={"flex spacing-1 of-3"}>
                <TextField
                  value={'Produkt'}
                  helperText="Kategorie"
                  disabled
                  size='small'
                  sx={{marginBottom: '15px'}}
                />
                <TextField
                  value={this.state.type}
                  helperText="Bezeichnung"
                  size='small'
                  error={this.state.type ? false :true}
                  sx={{marginBottom: '15px'}}
                  onChange={this.updateType}
                  disabled={this.state.popMode === 'view'}
                />
                {this.state.creator && (
                  <TextField
                    value={this.state.creator}
                    helperText="Erstellt von"
                    disabled
                    error={this.state.creator ? false :true}
                    size='small'
                    sx={{marginBottom: '15px'}}
                  />
                )}
                <TextField
                  value={this.state.output > 0 ? this.state.output : ''}
                  type='number'
                  onChange={this.updateOutput}
                  helperText='Menge Endprodukt'
                  size='small'
                  error={this.state.output ? false :true }
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kg</InputAdornment>
                  }}
                  disabled={this.state.popMode === 'view'}
                  sx={{marginBottom: '15px'}}
                />
                {this.state.popMode !== 'view' ? (
                  <TextField
                    value={this.state.color}
                    helperText="Farbe"
                    size='small'
                    select
                    error={this.state.color ? false : true }
                    sx={{marginBottom: '15px'}}
                    onChange={e => this.setState({color: e.target.value})}
                  >
                    {rpetColors.map(color => <MenuItem key={color} value={color}>{convertOrderAttributes(color)}</MenuItem>)}
                  </TextField>
                ) : (
                  <TextField
                    value={convertOrderAttributes(this.state.color)}
                    error={this.state.color ? false : true }
                    helperText="Farbe"
                    size='small'
                    sx={{marginBottom: '15px'}}
                    disabled
                  />
                )}
                {this.state.popMode !== 'view' ? (
                  <TextField
                    value={this.state.foodSafe}
                    error={this.state.foodSafe ? false : true }
                    helperText="Lebensmittelecht"
                    size='small'
                    select
                    sx={{marginBottom: '15px'}}
                    onChange={e => this.setState({foodSafe: e.target.value})}
                  >
                    <MenuItem value='yes'>Ja</MenuItem>
                    <MenuItem value='no'>Nein</MenuItem>
                  </TextField>
                ) : (
                  <TextField
                    value={convertOrderAttributes(this.state.foodSafe)}
                    helperText="Lebensmittelecht"
                    error={this.state.foodSafe ? false : true }
                    size='small'
                    sx={{marginBottom: '15px'}}
                    disabled
                  />
                )}
                {this.state.popMode !== 'view' ? (
                  <TextField
                    value={this.state.material}
                    helperText="Material"
                    size='small'
                    select
                    error={this.state.material ? false : true }
                    sx={{marginBottom: '15px'}}
                    onChange={e => this.setState({material: e.target.value})}
                  >
                    <MenuItem value='tray'>Tray</MenuItem>
                    <MenuItem value='bottle'>Bottle</MenuItem>
                    <MenuItem value='bottleAndTray'>Bottle + Tray</MenuItem>
                  </TextField>
                ) : (
                  <TextField
                    value={convertOrderAttributes(this.state.material)}
                    helperText="Lebensmittelecht"
                    error={this.state.material ? false : true }
                    size='small'
                    sx={{marginBottom: '15px'}}
                    disabled
                  />
                )}
              </div>
              <div style={{position: 'relative'}}>
                <h3>Details</h3>
                {this.state.shifts.length === 3 && (
                  <Tooltip title={
                    <div>
                      1. Schicht: {this.state.shifts[0].start} - {this.state.shifts[0].end}<br />
                      2. Schicht: {this.state.shifts[1].start} - {this.state.shifts[1].end}<br />
                      3. Schicht: {this.state.shifts[2].start} - {this.state.shifts[2].end}<br />
                    </div>
                  }>
                    <span className='help' style={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}}>Schichtzeiten</span>
                  </Tooltip>
                )}
              </div>
              <div className={"flex spacing-1 of-3"}>
                <TextField
                  name="start"
                  helperText={`${this.state.popMode === 'view' ? 'Geplanter ' : ''}Start`}
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={this.state.popMode ? false : true }
                  value={this.state.start}
                  onChange={this.updateDateTime}
                  size="small"
                  disabled={this.state.popMode === 'view'}
                />
                <TextField
                  helperText={`${this.state.popMode === 'view' ? 'Geplante ' : ''}Dauer`}
                  value={`${Math.floor(this.state.duration / 60)} h ${this.state.duration % 60} min`}
                  disabled
                  error={this.state.popMode ? false : true }
                  size="small"
                />
                <TextField
                  name="end"
                  helperText={`${this.state.popMode === 'view' ? 'Geplantes ' : ''}Ende`}
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={this.state.popMode ? false : true }
                  value={this.state.end}
                  disabled
                  size="small"
                />
              </div>
              {this.state.popMode === 'view' && activeBatch.attributes.startedAt && (
                <div className={"flex spacing-1 of-3"} style={{marginTop: 15}}>
                  <TextField
                    helperText={`Tatsächlicher Start`}
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={toLocalISOTime(new Date(activeBatch.attributes.startedAt)) ? false : true }
                    value={toLocalISOTime(new Date(activeBatch.attributes.startedAt))}
                    size="small"
                    disabled
                  />
                  <TextField
                    helperText={`Tatsächliche Dauer`}
                    value={this.getActualDuration(activeBatch)}
                    disabled
                    size="small"
                  />
                  {activeBatch.attributes.finishedAt ? (
                    <TextField
                      helperText={`Tatsächliches Ende`}
                      type="datetime-local"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={toLocalISOTime(new Date(activeBatch.attributes.finishedAt))}
                      disabled
                      size="small"
                    />
                  ) : (
                    <TextField
                      helperText={`Tatsächliches Ende`}
                      value={'nicht vorhanden'}
                      disabled
                      size="small"
                    />
                  )}
                </div>
              )}

              <hr />

              <h3>{activeBatch.attributes.status === 'finished' ? 'Verarbeitete Ware' : 'Zu verarbeitende Ware'}</h3>
              <div className={`flex ${this.state.popMode === 'view' ? 'of-5' : 'of-4'} spacing-1`} style={{marginTop: 10}}>
                <TextField
                  value={localNumber(this.state.minInput) + ' kg'}
                  helperText='min. zu verarbeitende Ware'
                  disabled
                  size='small'
                />
                <div></div>
                <div></div>
                <TextField
                  value={this.state.input >= 0 ? (localNumber(this.state.input) + ' kg') : '0 kg'}
                  disabled
                  helperText='geplanter Wareneinsatz'
                />
                {this.state.popMode === 'view' && <TextField
                  value={`${localNumber(this.getTotalInputDone() || 0)} kg`}
                  disabled
                  helperText='tatsächl. Wareneinsatz'
                />}
              </div>
              <div>
                  {this.state.storesToUse.map((store: StoreToUse, i: number) => (
                    <div key={`gtu-${i}`} style={{marginTop: 20}}>
                      {this.state.popMode !== 'view' && (
                        <div className="header-bar" style={{marginBottom: 0}}>
                          <div>
                            <strong>{store.storageName}</strong> {this.state.popMode !== 'view' &&  <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => this.deleteGoodToUse(i)}>löschen</span>}
                          </div>
                          <div>
                            <Button variant='contained' size='small' onClick={() => this.useCompleteStorage(i)}>Gesamte Gruppe verarbeiten</Button>
                          </div>
                        </div>
                      )}
                      {store.goods.length >= 1 && store.goods.map((good: GoodToUse, l: number) => (
                        <>
                          {((this.state.popMode === 'view' && (good.inputQuantity || good.inputDone)) || this.state.popMode !== 'view') && (
                            <div key={'good' + good.id + l} className={`flex ${this.state.popMode === 'view' ? 'of-5' : 'of-4'} spacing-1`} style={{marginTop: 10}}>
                              <TextField
                                value={good.attributes.customId ? good.attributes.customId : 'FEHLER'}
                                helperText='ID'
                                disabled
                                size='small'
                              />
                              <TextField
                                value={good.attributes.category ? convertGoodsCategory(good.attributes.category) : 'FEHLER'}
                                helperText='Kategorie'
                                disabled
                                size='small'
                              />
                              <TextField
                                value={good.attributes.typeIntern ? good.attributes.typeIntern : 'FEHLER'}
                                helperText='Bezeichnung'
                                disabled
                                size='small'
                              />
                              <TextField
                                value={this.state.popMode === 'view' ? (localNumber(good.inputQuantity || 0) + ' kg') : good.inputQuantity}
                                helperText={this.state.popMode !== 'view' ? (
                                  <div>
                                    eingeplante Menge<br />
                                    übrig = {good.inputQuantity ? localNumber(good.attributes.quantity - good.inputQuantity - this.goodAssignedToOtherBatches(good)) : localNumber(good.attributes.quantity - this.goodAssignedToOtherBatches(good))} kg<br />
                                    andere Chargen = {localNumber(this.goodAssignedToOtherBatches(good))} kg<br />
                                    ursp. verfügbar = {localNumber(good.attributes.quantity)} kg</div>
                                ) : (
                                  <div>eingeplante Menge</div>
                                )}
                                type={this.state.popMode === 'view' ? 'text' : 'number'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateInputQuantity(e, i, l)}
                                InputProps={this.state.popMode !== 'view' ? {
                                  endAdornment: <InputAdornment position="end">kg</InputAdornment>
                                } : {}}
                                size='small'
                                disabled={this.state.popMode === 'view'}
                              />
                              {this.state.popMode === 'view' ? (
                                <TextField
                                  disabled
                                  helperText='eingesetzte Menge'
                                  value={`${localNumber(good.inputDone || 0)} kg`}
                                />
                              ) : null}
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  ))}
              </div>
              {this.state.popMode !== 'view' && (
                <div onClick={() => this.setState({showStoragePop: true, storagePopMode: 'goods'})}>
                  <strong style={{cursor: 'pointer', marginTop: 20, display: 'block'}}><Button variant='contained' size='small'>+ Ware hinzufügen</Button></strong>
                </div>
              )}

              <hr />

              <h3>Chargenwechsel</h3>
              <div>
                <div className="flex align-center space-between">
                  <div style={{marginRight: 30}}>Wie viele Bigbags müssen als Chargenwechsel gekennzeichnet werden?</div>
                  <div style={{width: 200}}>
                    <TextField
                      type='number'
                      value={this.state.batchChangeBagsCount}
                      onChange={(e) => this.setState({batchChangeBagsCount: Number(e.target.value)})}
                      className="text-center"
                      fullWidth
                      size='small'
                      disabled={this.state.popMode === 'view'}
                    />
                  </div>
                </div>
                {this.state.batchChangeBagsCount ? (<div className="flex align-center space-between" style={{marginTop: 10}}>
                  <div style={{marginRight: 30}}>Lagerplatz für Bigbags mit Chargenwechsel</div>
                  <div style={{width: 200}}>
                    <TextField
                      select
                      size='small'
                      fullWidth
                      value={this.state.batchChangeStore}
                      onChange={(e) => this.setState({batchChangeStore: e.target.value})}
                      className="text-center"
                      disabled={this.state.popMode === 'view'}
                    >
                      {this.state.stores.map((store, i) => (
                        <MenuItem key={'store' + store.id + i} value={store.id}>{store.attributes.name}</MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>) : null}
              </div>

              <hr />

              <h3>Lagerplatz der produzierten Ware</h3>
              
              <div>
                {this.state.storesToStore.map((store, i) => (
                  <React.Fragment>
                    {this.state.popMode !== 'view' && (
                      <div style={{marginTop: 15}}>
                        <strong>Lagerplatz {i+1}: {store.attributes.name}</strong> {this.state.popMode !== 'view' && <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => this.deleteStoreToStore(i)}>löschen</span>}
                      </div>
                    )}
                  
                    <div key={'storetostore'+i} style={{marginTop: 10}} className={`flex ${this.state.popMode === 'view' ? 'of-2' : 'of-4'} spacing-1`}>
                      <TextField
                        size='small'
                        fullWidth
                        value={store.attributes.name}
                        helperText='Name'
                        disabled
                      />
                      {this.state.popMode !== 'view' && (
                        <TextField
                          size='small'
                          fullWidth
                          value={store.attributes.capacity}
                          helperText='Kapazität'
                          disabled
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>
                          }}
                        />
                      )}
                      <TextField
                        size='small'
                        fullWidth
                        value={store.input}
                        helperText={this.state.popMode !== 'view' ? 'Menge' : 'Geplante Menge'}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">kg</InputAdornment>
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateStorageInput(e, i)}
                        disabled={this.state.popMode === 'view'}
                      />
                      {this.state.popMode !== 'view' && (
                        <TextField
                          size='small'
                          fullWidth
                          value={store.attributes.capacity - store.input}
                          helperText='Differenz'
                          InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>
                          }}
                          disabled
                        />
                      )}
                    </div>
                  </React.Fragment>
                ))}
              </div>

              {this.state.popMode !== 'view' && (
                <div onClick={() => this.setState({showStoragePop: true, storagePopMode: 'stores'})}>
                  <strong style={{cursor: 'pointer', marginTop: 20, display: 'block'}}><Button variant='contained' color='primary'>+ Lagerfläche hinzufügen</Button></strong>
                </div>
              )}

              <hr />

              <h3>Laborproben</h3>
              <div className="flex align-center space-between">
                <div style={{marginRight: 30}}>Wie oft soll eine Laborprobe entnommen werden?</div>
                <div style={{width: 200}}>
                  <TextField
                    type='number'
                    value={this.state.labInterval}
                    onChange={(e) => this.setState({labInterval: Number(e.target.value)})}
                    className="text-center"
                    fullWidth
                    helperText='Interval'
                    size='small'
                    disabled={this.state.popMode === 'view'}
                  />
                </div>
              </div>

              {this.state.popMode !== 'view' && (
                <div className='text-right' style={{marginTop: 30}}>
                  {this.state.popMode === 'edit' && this.state.activeBatchIsDraft && <Button variant='contained' color='secondary' style={{marginRight: 10}} onClick={() => this.submitForm('draft', true)}>Entwurf aktualisieren</Button>}
                  {this.state.popMode === 'edit' && <Button variant='contained' onClick={() => this.submitForm('', true)}>Charge {this.state.activeBatchIsDraft ? 'erstellen' : 'aktualisieren'}</Button>}
                  {this.state.popMode === 'create' && <Button variant='contained' color='secondary' style={{marginRight: 10}} onClick={() => this.submitForm('draft')}>Als Entwurf erstellen</Button>}
                  {this.state.popMode === 'create' && <Button variant='contained' onClick={() => this.submitForm()}>Charge erstellen</Button>}
                </div>
              )}

              {this.state.popMode === 'view' && (
                <React.Fragment>
                  <hr />
                  <h3>Verwendete Betriebsmittel</h3>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Bezeichnung</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.batches.filter(b => b.id === this.state.activeBatch)[0].attributes.utilitiesUsed.data.length >= 1 && this.state.batches.filter(b => b.id === this.state.activeBatch)[0].attributes.utilitiesUsed.data.map((util, i) => (
                        <TableRow key={`util-${i}`}>
                          <TableCell><Link to={`/lager/inventar?id=${util.id}`}>{util.attributes.customId}</Link></TableCell>
                          <TableCell>{util.attributes.typeIntern}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {this.state.batches.filter(b => b.id === this.state.activeBatch)[0].attributes.utilitiesUsed.data.length < 1 && <p>Keine Betriebsmittel genutzt.</p>}
                  
                  <hr />
                  <h3>Produzierte Produkte</h3>
                  {activeBatch.attributes.finishedProducts.data.length >= 1 ? (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Kategorie</TableCell>
                          <TableCell>Bezeichnung</TableCell>
                          <TableCell>Menge</TableCell>
                          <TableCell>Lagerplatz</TableCell>
                          <TableCell>&nbsp;</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {activeBatch.attributes.finishedProducts.data.map((good: Good, i: number) => (
                          <TableRow key={'goodProduced-' + i}>
                            <TableCell>{good.attributes.customId}</TableCell>
                            <TableCell>{convertGoodsCategory(good.attributes.category)}</TableCell>
                            <TableCell>{good.attributes.typeIntern}</TableCell>
                            <TableCell>{localNumber(good.attributes.origQuantity)} {good.attributes.unit}</TableCell>
                            <TableCell>{good.attributes.warehouse_spot && good.attributes.warehouse_spot.data ? good.attributes.warehouse_spot.data.attributes.name : '???'}</TableCell>
                            <TableCell align='right'><a href={good.attributes.label.data.attributes.url || '/'} target='_blank' rel='noreferrer'><Tooltip title='Label ansehen'><QrCode2 className='action primary' /></Tooltip></a></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : <p>Keine Produkte produziert.</p>}
                </React.Fragment>
              )}
            </Box>
          </>
        </Modal>

        <Modal
          open={this.state.showStoragePop}
          onClose={() => this.setState({showStoragePop: false, storagePopMode: ''})}
        >
          <>
            <span className='modal-close pointer' onClick={() => this.setState({showStoragePop: false, storagePopMode: ''})}><Close /></span>
            <Box sx={{...modalStyle, maxWidth: 800}} className='batches-select-storages-pop'>
              <span className='modal-close pointer' onClick={() => this.setState({showStoragePop: false, storagePopMode: ''})}><Close /></span>
              {this.state.storagePopMode === 'goods' ? (
              <Box sx={{marginBottom: '30px'}}>
                <Tabs
                  value={this.state.tab}
                  onChange={(e, val) => this.setState({tab: val})}
                  variant="scrollable"
                  scrollButtons={false}
                  allowScrollButtonsMobile={false}
                >
                  <Tab label="Lagerplätze" sx={{width: '50%', maxWidth: 'none'}} />
                  <Tab label="Bestellungen" sx={{width: '50%', maxWidth: 'none'}} />
                </Tabs>
              </Box>) : <h2>Lagerplätze</h2>}

              <div className="windowed">
                <Table>
                  <TableHead>
                    {this.state.storagePopMode === 'goods' && (
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Positionen</TableCell>
                        <TableCell>Waren IDs</TableCell>
                        <TableCell>Menge</TableCell>
                      </TableRow>
                    )}
                    {this.state.storagePopMode === 'stores' && (
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Waren IDs</TableCell>
                        <TableCell>Gesamtkapazität</TableCell>
                        <TableCell>verfügbar</TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {this.state.storagePopMode === 'stores' || (this.state.storagePopMode === 'goods' && this.state.tab === 0) ? this.state.stores.map((store: WarehouseSpot, i: number) => ((this.state.storagePopMode === 'goods' && store.attributes.goods && store.attributes.goods.data && store.attributes.goods.data.length >= 1) || this.state.storagePopMode === 'stores') && (
                      <TableRow key={'good'+ store.id} sx={{cursor: 'pointer'}} onClick={() => this.addStoreToUse(i, store.attributes.goods?.data && store.attributes.goods?.data.length >= 1 ? store.attributes.goods?.data.map(good => good.id) : [], `Lagerplatz: ${store.attributes.name}`)}>
                        <TableCell>{store.attributes.name}</TableCell>
                        {this.state.storagePopMode === 'goods' && <TableCell>{store.attributes.goods?.data.length} Position(en)</TableCell>}
                        <TableCell>{store.attributes.goods?.data && store.attributes.goods?.data.length >= 1 ? (
                          <React.Fragment>
                            {store.attributes.goods?.data.sort((a,b) => a.attributes.customId > b.attributes.customId ? -1 : 1).map((good, j) => (
                              <React.Fragment key={good.id+j}>{good.attributes.customId || '???'}<br /></React.Fragment>
                            ))}
                          </React.Fragment>
                        ) : 'leer'}</TableCell>
                        {this.state.storagePopMode === 'goods' && (
                          <TableCell>{store.attributes.goods?.data && store.attributes.goods?.data.length >= 1 ? (
                            <React.Fragment>
                              {store.attributes.goods?.data.map((good, j) => (
                                <React.Fragment key={'amount'+good.id+j}>{(good.attributes.quantity || good.attributes.quantity === 0) && good.attributes.unit ? `${localNumber(good.attributes.quantity - this.goodAssignedToOtherBatches(good))} von ${localNumber(good.attributes.quantity)} ${good.attributes.unit} übrig` : 'FEHLER'}<br /></React.Fragment>
                              ))}
                            </React.Fragment>
                          ) : 'leer'}</TableCell>
                        )}
                        {this.state.storagePopMode === 'stores' && <TableCell>{localNumber(store.attributes.totalCapacity)} kg</TableCell>}
                        {this.state.storagePopMode === 'stores' && <TableCell>{localNumber(store.attributes.capacity)} kg</TableCell>}
                      </TableRow>
                    )) : this.state.orders.map((order, i) => (
                      <TableRow className='pointer' key={`order-${order.id}`}  onClick={() => this.addStoreToUse(i, order.attributes.goods && order.attributes.goods.data && order.attributes.goods.data.length >= 1 ? order.attributes.goods.data.map(good => good.id) : [], order.attributes.customId ? `Bestellung: ${order.attributes.customId}` : 'Bestellung')}>
                        <TableCell>{order.attributes.customId || '???'}</TableCell>
                        <TableCell>{`${order.attributes.goods && order.attributes.goods.data && order.attributes.goods.data.length >= 1 ? order.attributes.goods.data.length : 0} Position(en)`}</TableCell>
                        <TableCell>
                          {order.attributes.goods && order.attributes.goods.data && order.attributes.goods.data.length >= 1 ? order.attributes.goods.data.map((good, i) => (
                            <React.Fragment key={good.attributes.customId}><span>{good.attributes.customId || '???'}</span><br /></React.Fragment>
                          )) : ''}
                        </TableCell>
                        <TableCell>
                          {order.attributes.goods && order.attributes.goods.data && order.attributes.goods.data.length >= 1 ? order.attributes.goods.data.map((good, i) => (
                            <React.Fragment key={'amount'+good.attributes.customId}>{good.attributes.quantity && good.attributes.unit ? `${localNumber(good.attributes.quantity - this.goodAssignedToOtherBatches(good))} von ${localNumber(good.attributes.quantity)} ${good.attributes.unit} übrig` : 'FEHLER'}<br /></React.Fragment>
                          )) : ''}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Box>
          </>
        </Modal>
      </div>
    )
  }
}

export default ProductionBatchesForm;
