import React, { FC } from 'react';
import './LogoutButton.sass';
import {UserStore} from '../../../stores/user';
import powerIcon from '../../../assets/icons/power-off.png';

interface LogoutButtonProps {}

const LogoutButton: FC<LogoutButtonProps> = () => {
  const logout = UserStore((state:any) => state.logout);
  return <div className='LogoutButton' onClick={logout}><img src={powerIcon} alt="Logout" /></div>
}

export default LogoutButton;
