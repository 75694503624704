import axios from "axios";
import { api } from "./apiService";
import { getJWT, jwtRes } from "./jwtService";
import { Report } from "./laboratoryService";

export interface DashboardDataRes {
  success: boolean;
  message: string;
  data: {
    rpetStored: number;
    rpetProducedTotal: number;
    plannedEntryQuantity: number;
    plannedProductionQuantity: number;
    plannedExitQuantity: number;
    ordersOpen: number;
    ordersNeedPlate: number;
    ordersNeedStore: number;
    exitOrdersOpen: number;
    exitOrdersNeedPlate: number;
    exitOrdersNeedGoods: number;
    inventoryData: {
      [key: string]: number;
    };
    inventoryValueData: {
      [key: string]: number;
    };
    reportsOpen: number;
    reportsDone: number;
    reportsArr: Report[];
  };
}

export let fetchDashboardData = (): Promise<DashboardDataRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: {},
      })
      
      //do stuff here
      axios.get(api + '/api/dashboard-data', {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        
        resolve({
          success: true,
          message: '',
          data: res.data
        })
      })
      .catch(err => {
        let message = ''

        try {
          if (err.response.data.error.message) message = err.response.data.error.message
        } catch {}

        reject({
          success: false,
          message: message ? message : 'Fehler beim Abrufen der Dashboard-Daten',
          data: {},
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: {},
      })
    })
  });
}