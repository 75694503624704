export let rpetColors = [
  "blue",
  "brown",
  "transparent",
  "clear-and-light-blue",
  "green",
  "light-blue",
  "colored",
]

export let foodSafeOptions = [
  "yes",
  "no",
]

export let materials = [
  "tray",
  "bottle",
  "bottleAndTray",
]

export let units = [
  "kg",
  "t",
  "l",
  "stk",
]

export let userRights = [
  // Prozesse
  { label: 'Prozess Wareneingang', value: 'process::entry' },
  { label: 'Prozess Produktion', value: 'process::production' },
  { label: 'Prozess Warenausgang', value: 'process::exit' },
  // Bereiche
  { label: 'Inventar', value: 'inventory' },
  { label: 'Inventar - CSV Export', value: 'inventory::csv' },
  { label: 'Lager', value: 'warehouse' },
  { label: 'Lager - Produkte anlegen', value: 'warehouse::create' },
  { label: 'Lager - Produkte löschen', value: 'warehouse::delete' },
  { label: 'Wareneingang', value: 'entry' },
  { label: 'Wareneingang - Preis ändern', value: 'entry::price-update' },
  { label: 'Warenausgang', value: 'exit' },
  { label: 'Labor', value: 'lab' },
  { label: 'Produktion', value: 'batches' },
  { label: 'System - Emails', value: 'system::emails' },
  { label: 'System - Lager', value: 'system::warehouse' },
  { label: 'System - Labor', value: 'system::lab' },
  { label: 'System - Produktion', value: 'system::production' },
  { label: 'System - Benutzer', value: 'system::users' },
  { label: 'System - Definitionen', value: 'system::definitions' },
  // Preise
  { label: 'Wareneingangspreise', value: 'price::entry' },
  { label: 'Warenausgangspreise', value: 'price::exit' },
  
  { label: 'WE-Preise Rohstoff', value: 'price::entry.r' },
  { label: 'WE-Preise rPet', value: 'price::entry.rpet' },
  { label: 'WE-Preise Handel', value: 'price::entry.hw' },
  { label: 'WE-Preise Handel (ZL)', value: 'price::entry.zl' },
  { label: 'WE-Preise Divers', value: 'price::entry.d' },
  { label: 'WE-Preise Betriebsmittel', value: 'price::entry.bm' },
  { label: 'WE-Preise Nebenstrom', value: 'price::entry.nm' },
  
  { label: 'WA-Preise Rohstoff', value: 'price::exit.r' },
  { label: 'WA-Preise rPet', value: 'price::exit.rpet' },
  { label: 'WA-Preise Handel', value: 'price::exit.hw' },
  { label: 'WA-Preise Handel (ZL)', value: 'price::exit.zl' },
  { label: 'WA-Preise Divers', value: 'price::exit.d' },
  { label: 'WA-Preise Betriebsmittel', value: 'price::exit.bm' },
  { label: 'WA-Preise Nebenstrom', value: 'price::exit.nm' },
]

export let bypasses = [
  'Metall',
  'PO',
  'Kleinteile',
]

export let packagings = [
  'bale',
  'bigbag',
  'divers',
]

export let statuses = [
  'stored',
  'sold',
  'used',
  'util_in_use',
  'util_used',
  'util_broken',
]

export let productCategories = [
  'rpet',
  'bm',
  'd',
  'hw',
  'zl',
  'nm',
  'r',
  'cw',
]
export let InvetorySort = [

  {
    Label:"category",
    value:"category:desc"
  },
  {
    Label:"description",
    value:"typeIntern:desc"
  },
  {
    Label:"amount",
    value:"quantity:desc"
  },
  {
    Label:"value",
    value:"baseValue:desc"
  },
  {
    Label:"value incl.",
    value:"baseValueWithDelivery:desc"
  },
  {
    Label:"material",
    value:"material:desc"
  },
  {
    Label:"income",
    value:"createdAt:desc"
  }
]


export let IncommingSort = [
  {
    Label:"---select----",
    value:"select"
  },
  {
    Label:"ID",
    value:"customId:desc"
  },
  {
    Label:"supplier",
    value:"supplier:desc"
  }
]

export let WareneingangSort = [
  {
    Label:"---Sort----",
    value:"createdAt:desc"
  },
  {
    Label:"Kennzeichen",
    value:"category:desc"
  },
  {
    Label:"description",
    value:"typeIntern:desc"
  },
  {
    Label:"amount",
    value:"quantity:desc"
  },
  {
    Label:"value",
    value:"baseValue:desc"
  },
  {
    Label:"value incl.",
    value:"baseValueWithDelivery:desc"
  },
  {
    Label:"material",
    value:"material:desc"
  },
  {
    Label:"income",
    value:"createdAt:desc"
  }
]