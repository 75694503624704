import Sidebar from '../components/Sidebar/Sidebar';
import TopNav from '../components/TopNav/TopNav';
import DashComponent from '../components/Dashboard/Dashboard';

export default function Dashboard() {
  return (
    <main className='Dashboard container'>
      <Sidebar />
      <TopNav />
      <DashComponent />
    </main>
  )
}