export interface jwtRes {
  jwt: string
}

export let getJWT = (): Promise<jwtRes> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt) reject('')
    else resolve({jwt: jwt});
  })
}

// getJWT() boilerplate
// return new Promise((resolve, reject) => {
//   getJWT()
//   .then((jwtRes: jwtRes) => {
//     if (!jwtRes.jwt) reject({
//       success: false,
//       message: 'no jwt found'
//     })
    
//     //do stuff here
    
//   })
//   .catch((err) => {
//     reject({
//       success: false,
//       message: 'JWT error'
//     })
//   })
// });