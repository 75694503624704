import Sidebar from '../components/Sidebar/Sidebar';
import TopNav from '../components/TopNav/TopNav';
import Inventory from '../components/Warehouse/Inventory/Inventory';

export default function WarehouseInventoryRoute() {
  return (
    <main className='Warehouse container'>
      <Sidebar />
      <TopNav />
      <Inventory />
    </main>
  )
}