import React from 'react';
import './SystemDefinitions.sass';

interface SystemDefinitionsProps {}

interface SystemDefinitionsState {}

class SystemDefinitions extends React.Component<SystemDefinitionsProps, SystemDefinitionsState> {
  render() {
    return (
      <div className="SystemDefinitions content" data-testid="SystemDefinitions">
        <h1>Definitionen</h1>
        <div className="definitions-table">
          <div className="row break">
            <div className="left">Bezeichnung</div>
            <div className="right">Kurzbeschreibung, um was handelt es sich genau?</div>
          </div>
          <div className="row strong">
            <div className="left">Kategorie</div>
            <div className="right">zu welchen Bereich gehört es?</div>
          </div>
          <div className="row">
            <div className="left">Handel</div><div className="right">wird nur zwischen gelagert und weiterverkauft</div>
          </div>
          <div className="row">
            <div className="left">Produkt</div><div className="right">wird hier produziert</div>
          </div>
          <div className="row">
            <div className="left">Rohstoff</div><div className="right">Wareneingang für die Produktion</div>
          </div>
          <div className="row">
            <div className="left">Betriebsmittel</div><div className="right">alles was benötigt wird damit die Waschanlage/Sortierung funktioniert</div>
          </div>
          <div className="row">
            <div className="left">Nebenstrom</div><div className="right">alles was bei der Produktion anfällt, was nicht rPET ist</div>
          </div>
          <div className="row break">
            <div className="left">Divers</div><div className="right">alles andere</div>
          </div>
          <div className="row strong">
            <div className="left">ID Ware</div><div className="right">alle IDs führen zu der Detailansicht bzw. zum PDF der jeweiligen Auswahl</div>
          </div>
          <div className="row">
            <div className="left">rPET-JJJ-0000x</div><div className="right">produziertes Produkt </div>
          </div>
          <div className="row">
            <div className="left">BM-JJJ-0000x</div><div className="right">Betriebsmittel</div>
          </div>
          <div className="row">
            <div className="left">CNR-JJJ-0000x</div><div className="right">Charge</div>
          </div>
          <div className="row">
            <div className="left">CW-JJJ-0000x</div><div className="right">Chargenwechsel Bigbag</div>
          </div>
          <div className="row">
            <div className="left">D-JJJ-0000x</div><div className="right">Divers</div>
          </div>
          <div className="row">
            <div className="left">HW-JJJ-0000x</div><div className="right">Handelsware, kommt nicht im Lager an</div>
          </div>
          <div className="row">
            <div className="left">ZL-JJJ-0000x</div><div className="right">Handelsware, welche bei uns im Lager zwischengelagert wird</div>
          </div>
          <div className="row">
            <div className="left">NM-JJJ-0000x</div><div className="right">Produkte aus dem Nebenstrom</div>
          </div>
          <div className="row break">
            <div className="left">R-JJJ-0000x</div><div className="right">Rohstoff bzw. Ware, welche verarbeitet wird</div>
          </div>
          <div className="row strong">
            <div className="left">ID Dokument</div><div className="right"></div>
          </div>
          <div className="row">
            <div className="left">A-JJJ-0000x</div><div className="right">Laborbericht</div>
          </div>
          <div className="row">
            <div className="left">AB-JJJ-0000x</div><div className="right">Auftragsbestätigung (Warenausgang)</div>
          </div>
          <div className="row">
            <div className="left">LS-JJJ-0000x</div><div className="right">Lieferschein</div>
          </div>
          <div className="row">
            <div className="left">PL-JJJ-0000x</div><div className="right">Packliste</div>
          </div>
          <div className="row break">
            <div className="left">B-JJJ-0000x</div><div className="right">Bestellung (Wareineingang) </div>
          </div>
          <div className="row strong">
            <div className="left">ID Lagerplätze</div><div className="right"></div>
          </div>
          <div className="row">
            <div className="left">L1 ... Lx</div><div className="right">Lagerplatz 1 ... Lagerplatz x</div>
          </div>
          <div className="row">
            <div className="left">BM</div><div className="right">Betriebsmittel</div>
          </div>
          <div className="row">
            <div className="left">E/W</div><div className="right">Ersatzteil/Werkstatt</div>
          </div>
          <div className="row">
            <div className="left">E</div><div className="right">Entsorgung</div>
          </div>
          <div className="row">
            <div className="left">D</div><div className="right">Divers</div>
          </div>

        </div>
      </div>
    )
  }
};

export default SystemDefinitions;
