import { DeleteForever, Save } from '@mui/icons-material';
import { Button, Checkbox, FormControl, FormHelperText, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { addWarehouseSpot, fetchWarehouseData, FetchWarehouseDataResponse, FetchWarehousePlanResponse, deleteWarehouseSpot, updateWarehouseSpot, WarehouseSpot, fetchWarehousePlan, addWarehousePlan, deleteWarehousePlan } from '../../../services/configService';
import { NotiStore } from '../../../stores/notifications';
import { bypasses } from '../../../utils/defaults';
import './WarehouseSettings.sass';

interface WarehouseSettingsProps {
  handleError: (type: string, message: string) => void;
  handleLoading: (status: boolean) => void;
  handleSuccess: (status: boolean) => void;
  clearNotifications: () => void;
}

interface WarehouseSettingsState {
  spots: WarehouseSpot[];
  newSpotId: number | null;
  newSpotName: string;
  newSpotDescription: string;
  newSpotCapacity: number | null;
  addSpotActive: boolean;
  error: string;
  errorType: string;
  loading: boolean;
  warehousePlanUrl: string;
}

class WarehouseSettings extends React.Component<WarehouseSettingsProps, WarehouseSettingsState> {
  state: WarehouseSettingsState = {
    spots: [],
    newSpotId: null,
    newSpotName: '',
    newSpotDescription: '',
    newSpotCapacity: null,
    addSpotActive: false,
    error: '',
    errorType: '',
    loading: true,
    warehousePlanUrl: ''
  }

  fileInput: HTMLInputElement | null = null;

  componentDidMount() {
    window.history.replaceState(null, "KSM Recycling ERP", "/system/einstellungen?seite=lager");
    this.fetchData();
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let key: string = e.target.name;
    if (Object.keys(this.state).includes(key)) this.setState({[key]: e.target.value} as any);
  }

  handleChangeSpot = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let id: number = Number(e.target.name.split('-')[1]);
    let fieldName: any = e.target.name.split('-')[0];
    let value: string = e.target.value;

    // 1. Make a shallow copy of the items
    let items = [...this.state.spots];

    let index = items.findIndex(el => el.id === id);
    
    if (index > -1) {
      // 2. Make a shallow copy of the item you want to mutate
      let item = {...items[index]};
      // 3. Replace the property you're intested in
      if (fieldName === 'name') item.attributes.name = value;
      if (fieldName === 'description') item.attributes.description = value;
      if (fieldName === 'totalCapacity') item.attributes.totalCapacity = Number(value);
      item.isUpdated = true;
      // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
      items[index] = item;
      // 5. Set the state to our new copy
      this.setState({spots: items});
    }
  }

  handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target?.files && e.target.files[0]) {
      this.props.handleLoading(true);

      addWarehousePlan(e.target.files[0])
      .then(() => this.props.handleSuccess(true))
      .catch(() => this.props.handleError('Es gab einen Fehler beim Hochladen des Lagerplans.', 'addWarehousePlan'))
      .finally(() => this.fetchData());
    }
  }

  handleDeleteWarehousePlan = (): void => {
    deleteWarehousePlan()
      .then(() => {this.setState({warehousePlanUrl: ''});this.props.handleSuccess(true)})
      .catch(() => this.props.handleError('Es gab einen Fehler beim Löschen des Lagerplans.', 'deleteWarehousePlan'))
  }

  fetchData = async () => {
    this.props.handleLoading(true);

    await fetchWarehouseData()
    .then((res: FetchWarehouseDataResponse) => {
      if (!res.success) return this.props.handleError('Es gab einen Fehler beim Abrufen der Lager-Daten.', 'fetchWarehouseData');

      if (res.spots) {
        res.spots.forEach(spot => {
          if (!spot.attributes.bypasses) spot.attributes.bypasses = []
        })
        this.setState({spots: res.spots});
      }
    })
    .catch(() => {
      this.props.handleError('Es gab einen Fehler beim Abrufen der Lager-Daten.', 'fetchWarehouseData');
    })

    await fetchWarehousePlan()
    .then((res: FetchWarehousePlanResponse) => {
      if (!res.success) return this.props.handleError('Es wurde kein Lagerplan gefunden.', 'fetchWarehousePlan');

      if (res.url) this.setState({warehousePlanUrl: res.url});
    })
    .catch(() => {
      this.props.handleError('Es wurde kein Lagerplan gefunden.', 'fetchWarehousePlan');
    })

    this.props.handleLoading(false)
  }

  handleAddSpot = (): void => {
    this.props.clearNotifications();

    this.props.handleLoading(true);

    if (this.state.newSpotName && this.state.newSpotCapacity) {
      addWarehouseSpot({
        attributes: {
          name: this.state.newSpotName,
          description: this.state.newSpotDescription,
          totalCapacity: this.state.newSpotCapacity,
        }
      })
      .then(() => {
        this.props.handleSuccess(true);
        this.setState({
          newSpotId: null,
          newSpotName: '',
          newSpotDescription: '',
          newSpotCapacity: null,
          addSpotActive: false,
        })
      })
      .catch((err) => {
        this.props.handleError(err.message ? err.message : 'Es gab einen Fehler beim Erstellen des neuen Lagerplatzes.', 'addWarehouseSpot');
      })
      .finally(() => {
        this.fetchData();
      })
    } else {
      this.props.handleLoading(false);
      this.props.handleError('Bitte fülle alle Felder aus.', 'addWarehouseSpot');
    }
  }

  handleUpdateSpot = (id: number): void => {
    NotiStore.setState({loading: true})
    let spot = this.state.spots.find(el => el.id === id);

    if (spot?.isUpdated) {
      let spotData = {
        name: spot.attributes.name,
        totalCapacity: spot.attributes.totalCapacity,
        description: spot.attributes.description,
        bypasses: spot.attributes.bypasses || []
      }

      updateWarehouseSpot(id, spotData)
      .then(() => {
        NotiStore.setState({loading: false, success: true, successMessage: 'Lagerplätze erfolgreich aktualisiert.'})
      })
      .catch((err) => {
        if (err.message && err.message === 'This attribute must be unique') err.message = 'ID & Name eines Lagers darf nicht doppelt vorkommen.'
        NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Es gab einen Fehler beim Aktualisieren des Lagerplatzes.'});
      })
      .finally(() => {
        this.fetchData();
      })
    }
  }

  handleDeleteSpot = (id: number): void => {
    deleteWarehouseSpot(id)
    .then(() => {
      this.props.handleSuccess(true);
    })
    .catch(() => {
      this.props.handleError('Es gab einen Fehler beim Löschen des Lagerplatzes.', 'deleteWarehouseSpot');
    })
    .finally(() => {
      this.fetchData();
    })
  }

  handleBypassChange = (e: SelectChangeEvent<string[]>): void => {
    let {spots} = this.state

    spots.filter(spot => spot.id.toString() === e.target.name).forEach(spot => {
      spot.attributes.bypasses = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value;
      spot.isUpdated = true;
    })

    this.setState({spots})
  }

  render() {
    return (
      <div className="WarehouseSettings" data-testid="WarehouseSettings">
        <div className='spots'>
          <h2>Lagerplatz &amp; Kapazität</h2>
          <div className="my_table">
            {this.state.spots.map((item, index) => (
              <div className='my_row' key={`warehouseSpot-${index}`}>
                {/* <div className="my_column">
                  <FormControl className='input'>
                    <InputLabel htmlFor={`spotId-${item.id}`}>ID</InputLabel>
                    <OutlinedInput id={`spotId-${item.id}`} value={item.id} name={`spotId-${item.id}`} label="ID" type="number"></OutlinedInput>
                  </FormControl>
                </div> */}
                <div className="my_column">
                  <FormControl className='input'>
                    <OutlinedInput id={`name-${item.id}`} value={item.attributes.name} name={`name-${item.id}`} type="text" onChange={this.handleChangeSpot}></OutlinedInput>
                    <FormHelperText>Lager-ID</FormHelperText>
                  </FormControl>
                </div>
                <div className="my_column">
                  <FormControl className='input'>
                    <OutlinedInput id={`description-${item.id}`} value={item.attributes.description} name={`description-${item.id}`} type="text" onChange={this.handleChangeSpot}></OutlinedInput>
                    <FormHelperText>Bezeichnung</FormHelperText>
                  </FormControl>
                </div>
                <div className="my_column">
                  <FormControl className='input'>
                    <OutlinedInput id={`totalCapacity-${item.id}`} value={item.attributes.totalCapacity} name={`totalCapacity-${item.id}`} type="number" endAdornment={<InputAdornment position="end">kg</InputAdornment>} onChange={this.handleChangeSpot}></OutlinedInput>
                    <FormHelperText>Gesamtkapazität</FormHelperText>
                  </FormControl>
                </div>
                <div className="my_column">
                  <FormControl className='input'>
                    <OutlinedInput disabled id={`capacity-${item.id}`} value={item.attributes.capacity} name={`capacity-${item.id}`} type="number" endAdornment={<InputAdornment position="end">kg</InputAdornment>}></OutlinedInput>
                    <FormHelperText>Kapazität aktuell</FormHelperText>
                  </FormControl>
                </div>
                <div>
                  <FormControl sx={{ m: 0, width: 300 }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={item.attributes.bypasses || []}
                      onChange={this.handleBypassChange}
                      renderValue={(selected) => selected.join(', ')}
                      input={<OutlinedInput />}
                      name={item.id.toString()}
                    >
                      {bypasses.map(b => (
                        <MenuItem key={item.id+b} value={b}>
                          <Checkbox checked={item.attributes.bypasses && item.attributes.bypasses.includes(b)} />
                          <ListItemText primary={b} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Nebenströme</FormHelperText>
                  </FormControl>
                </div>
                <div className='my_column button' style={{paddingTop: 18}}>
                  <Save style={{marginRight: 10}} className={'action-icon save updated-' + item.isUpdated} color="success" onClick={() => this.handleUpdateSpot(item.id)} />
                  <DeleteForever className={'action-icon'} color="error" onClick={() => {if (window.confirm("Lagerplatz sicher löschen?")) this.handleDeleteSpot(item.id)}} />
                </div>
              </div>
            ))}
            <div className="my_row">
              {this.state.addSpotActive ? (
                <div>
                  <h3 style={{width: '100%'}}>Neuer Lagerplatz</h3>
                  <div className="flex of-4 spacing-1">
                    <div className="">
                      <FormControl className='input'>
                        <InputLabel htmlFor="newSpotName">ID</InputLabel>
                        <OutlinedInput id='newSpotName' value={this.state.newSpotName || ''} name="newSpotName" label="ID" type="text" onChange={this.handleChange}></OutlinedInput>
                      </FormControl>
                    </div>
                    <div className="">
                      <FormControl className='input'>
                        <InputLabel htmlFor="newSpotDescription">Bezeichnung</InputLabel>
                        <OutlinedInput id='newSpotDescription' value={this.state.newSpotDescription || ''} name="newSpotDescription" label="Bezeichnung" type="text" onChange={this.handleChange}></OutlinedInput>
                      </FormControl>
                    </div>
                    <div className="">
                      <FormControl className='input'>
                        <InputLabel htmlFor="newSpotCapacity">Kapazität</InputLabel>
                        <OutlinedInput id='newSpotCapacity' value={this.state.newSpotCapacity || ''} name="newSpotCapacity" label="Kapazität" type="number" endAdornment={<InputAdornment position="end">kg</InputAdornment>} onChange={this.handleChange}></OutlinedInput>
                      </FormControl>
                    </div>
                    <div className=''>
                      <Button variant='contained' fullWidth sx={{height: '56px'}} onClick={this.handleAddSpot}>Speichern</Button>
                    </div>
                  </div>
                </div>
              ) : <div onClick={() => this.setState({addSpotActive: true})}><strong style={{cursor: 'pointer', marginTop: 10, display: 'block'}}><span className='plus-btn'>+</span>Lagerplatz hinzufügen</strong></div>}
            </div>
          </div>
        </div>
        <div className='plan'>
          <h2>Lagerplatz - Plan</h2>
          <div className='show-pdf'>
            {this.state.warehousePlanUrl ? 
              // TODO: dynamic URL for anchor tag
              <a href={this.state.warehousePlanUrl} target="_blank" rel='noreferrer' style={{width: '100%'}}>
                <Button variant="contained" sx={{width: '100%'}}>hinterlegtes PDF ansehen</Button>
              </a>
            :
              <Button disabled variant="contained" sx={{width: '100%'}}>hinterlegtes PDF ansehen</Button>
            }
            <div className='delete' onClick={this.handleDeleteWarehousePlan}>löschen</div>
          </div>
          <label htmlFor="warehousePlan"><strong style={{cursor: 'pointer'}}><span className='plus-btn'>+</span>neuen Plan hinterlegen</strong></label>
          <input accept='.pdf' style={{display: 'none'}} type="file" name="warehousePlan" id="warehousePlan" onChange={this.handleFileUpload} ref={(input) => { this.fileInput = input }}  />
        </div>
      </div>
    )
  }
};

export default WarehouseSettings;
