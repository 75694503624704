import { MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip as MuiTooltip } from '@mui/material';
import React from 'react';
import { fetchDashboardData } from '../../services/dashboardService';
import { Good } from '../../services/inventoryService';
import { resetNotifications } from '../../services/notiStoreService';
import { convertGoodsCategory, localCurrency, localNumber, localNumberInTons } from '../../services/stringConverter';
import { OrderData } from '../../services/warehouseEntriesService';
import { ExitOrderData } from '../../services/warehouseExitService';
import { NotiStore } from '../../stores/notifications';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import './Dashboard.sass';
import { Report } from '../../services/laboratoryService';
import { InfoOutlined } from '@mui/icons-material';
import { withRouter } from '../utils/withRouter/withRouter';
import { Link } from 'react-router-dom';
// import WarehouseCalendar from '../Warehouse/WarehouseCalendar/WarehouseCalendar';


// const alwaysShowTooltip = { 
//   id: 'alwaysShowTooltip', 
//   afterDraw(chart: any, args: any, options: any) { 
//     const { ctx } = chart; 
//     ctx.save(); 

//     chart.data.datasets.forEach((dataset: any, i: any) => { 
//       chart.getDatasetMeta(i).data.forEach((datapoint: any, index: any) => { 
//         const {x, y} = datapoint.tooltipPosition();
        
//         const text = chart.data.labels[index] + ': ' + chart.data.datasets[i].data[index]; 
//         const textWidth = ctx.measureText(text).width; 
//         ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
//         ctx.fillRect(x - ((textWidth + 10) / 2), y - 25, textWidth + 10, 20); 

//         ctx.beginPath(); 
//         ctx.moveTo(x, y); 
//         ctx.lineTo(x - 5, y - 5); 
//         ctx.lineTo(x + 5, y - 5); 
//         ctx.fill(); 
//         ctx.restore(); 

//         ctx.font = '15px Arial';
//         ctx.fillStyle = 'white';
//         ctx.fillText(text, x - (textWidth / 2), y - 10);
//         ctx.restore();
//       })
//     })
//   }
// }

// add always show tooltip if fixed tooltips are wanted
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);

const widgetStyles = {
  padding: '15px 20px',
  marginTop: '20px',
  flex: 1
}

interface DashboardProps {
  rights: string[];
}
interface DashboardState {
  orders: {
    id: number;
    attributes: OrderData;
  }[];
  exitOrders: {
    id: number;
    attributes: ExitOrderData;
  }[];
  goods: Good[];
  rpetStored: number;
  rpetProducedTotal: number;
  plannedEntryQuantity: number;
  plannedProductionQuantity: number;
  plannedExitQuantity: number;
  ordersOpen: number;
  ordersNeedPlate: number;
  ordersNeedStore: number;
  exitOrdersOpen: number;
  exitOrdersNeedPlate: number;
  exitOrdersNeedGoods: number;
  inventoryData: {
    [key: string]: number;
  };
  inventoryValueData: {
    [key: string]: number;
  };
  reportsOpen: number;
  reportsDone: number;
  reports: Report[];
  activeReportA: number;
  activeReportB: number;
}

const defaultState: DashboardState = {
  orders: [],
  exitOrders: [],
  goods: [],
  rpetStored: 0,
  rpetProducedTotal: 0,
  plannedEntryQuantity: 0,
  plannedProductionQuantity: 0,
  plannedExitQuantity: 0,
  ordersOpen: 0,
  ordersNeedPlate: 0,
  ordersNeedStore: 0,
  exitOrdersOpen: 0,
  exitOrdersNeedPlate: 0,
  exitOrdersNeedGoods: 0,
  inventoryData: {},
  inventoryValueData: {},
  reportsOpen: 0,
  reportsDone: 0,
  reports: [],
  activeReportA: 0,
  activeReportB: 0,
}

class Dashboard extends React.Component<DashboardProps> {
  state = {...defaultState}

  componentDidMount() {
    resetNotifications()
    this.fetchData()
  }

  fetchData = (): void => {
    NotiStore.setState({loading: true});

    let data = fetchDashboardData();

    Promise.all([data])
    .then(res => {
      this.setState(prevState => ({
        ...prevState,
        rpetStored: res[0].data.rpetStored || 0,
        rpetProducedTotal: res[0].data.rpetProducedTotal || 0,
        plannedEntryQuantity: res[0].data.plannedEntryQuantity || 0,
        plannedProductionQuantity: res[0].data.plannedProductionQuantity || 0,
        plannedExitQuantity: res[0].data.plannedExitQuantity || 0,
        ordersOpen: res[0].data.ordersOpen || 0,
        ordersNeedPlate: res[0].data.ordersNeedPlate || 0,
        ordersNeedStore: res[0].data.ordersNeedStore || 0,
        exitOrdersOpen: res[0].data.exitOrdersOpen || 0,
        exitOrdersNeedPlate: res[0].data.exitOrdersNeedPlate || 0,
        exitOrdersNeedGoods: res[0].data.exitOrdersNeedGoods || 0,
        inventoryData: res[0].data.inventoryData || {},
        inventoryValueData: res[0].data.inventoryValueData || {},
        reportsOpen: res[0].data.reportsOpen || 0,
        reportsDone: res[0].data.reportsDone || 0,
        reports: res[0].data.reportsArr || [],
        activeReportA: res[0].data.reportsArr.length >= 1 ? res[0].data.reportsArr[0].id : defaultState.activeReportA,
        activeReportB: res[0].data.reportsArr.length >= 2 ? res[0].data.reportsArr[1].id : defaultState.activeReportB,
      }))

      NotiStore.setState({loading: false})
    })
    .catch(err => {
      NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Fehler beim Abrufen der Daten.'})
    })
  }

  getInventoryDataTotal = (): string => {
    let total = 0
    let {inventoryData} = this.state

    Object.keys(inventoryData).forEach(key => total += inventoryData[key])

    return localNumber(total)
  }

  getInventoryDataTotalValue = (): string => {
    let total = 0
    let {inventoryValueData} = this.state

    Object.keys(inventoryValueData).forEach(key => {
      total += inventoryValueData[key]
    })

    return localCurrency(total)
  }

  getReportData = (id: number): {
    pa: string;
    po: string;
    rubber: string;
    pvc: string;
    yellowFlakes: string;
    glue: string;
    multilayerFlakes: string;
    metal: string;
    other: string;
    flakes: string;
    labels: string;
    customId: string;
    flakesSix: string;
    flakesTen: string;
    flakesTwo: string;
    flakesOne: string;
    flakesSubOne: string;
    flakesFour: string;
    flakesEight: string;
    flakesTwelve: string;
    flakesFourteen: string;
    phValue: string;
    bulkDensity: string;
    moisture: string;
  } => {
    let data = {
      pa: '-',
      po: '-',
      rubber: '-',
      pvc: '-',
      yellowFlakes: '-',
      glue: '-',
      multilayerFlakes: '-',
      metal: '-',
      other: '-',
      flakes: '-',
      labels: '-',
      customId: '',
      flakesSix: '',
      flakesTen: '',
      flakesTwo: '',
      flakesFour: '',
      flakesEight: '',
      flakesTwelve: '',
      flakesOne: '',
      flakesSubOne: '',
      flakesFourteen: '',
      phValue: '-',
      bulkDensity: '-',
      moisture: '-',
    }

    if (id) {
      let matches = this.state.reports.filter(rep => rep.id === id)

      if (matches.length === 1) {
        data.customId = matches[0].attributes.customId

        let {testData} = matches[0].attributes

        if (testData.length >= 1) {
          let test = testData[0]

          if (test.pa && !isNaN(Number(test.pa))) data.pa = localNumber(test.pa)
          if (test.po && !isNaN(Number(test.po))) data.po = localNumber(test.po)
          if (test.rubber && !isNaN(Number(test.rubber))) data.rubber = localNumber(test.rubber)
          if (test.pvc && !isNaN(Number(test.pvc))) data.pvc = localNumber(test.pvc)
          if (test.yellowFlakes && !isNaN(Number(test.yellowFlakes))) data.yellowFlakes = localNumber(test.yellowFlakes)
          if (test.glue && !isNaN(Number(test.glue))) data.glue = localNumber(test.glue)
          if (test.multilayerFlakes && !isNaN(Number(test.multilayerFlakes))) data.multilayerFlakes = localNumber(test.multilayerFlakes)
          if (test.metal && !isNaN(Number(test.metal))) data.metal = localNumber(test.metal)
          if (test.other && !isNaN(Number(test.other))) data.other = localNumber(test.other)
          if (test.flakes && !isNaN(Number(test.flakes))) data.flakes = localNumber(test.flakes)
          if (test.labels && !isNaN(Number(test.labels))) data.labels = localNumber(test.labels)
          if (test.flakesSix && !isNaN(Number(test.flakesSix))) data.flakesSix = localNumber(test.flakesSix)
          if (test.flakesTen && !isNaN(Number(test.flakesTen))) data.flakesTen = localNumber(test.flakesTen)
          if (test.flakesTwo && !isNaN(Number(test.flakesTwo))) data.flakesTwo = localNumber(test.flakesTwo)
          if (test.flakesFour && !isNaN(Number(test.flakesFour))) data.flakesFour = localNumber(test.flakesFour)
          if (test.phValue && !isNaN(Number(test.phValue))) data.phValue = localNumber(test.phValue)
          if (test.flakesEight && !isNaN(Number(test.flakesEight))) data.flakesEight = localNumber(test.flakesEight)
          if (test.flakesTwelve && !isNaN(Number(test.flakesTwelve))) data.flakesTwelve = localNumber(test.flakesTwelve)
          if (test.flakesOne && !isNaN(Number(test.flakesOne))) data.flakesOne = localNumber(test.flakesOne)
          if (test.flakesSubOne && !isNaN(Number(test.flakesSubOne))) data.flakesSubOne = localNumber(test.flakesSubOne)
          if (test.flakesFourteen && !isNaN(Number(test.flakesFourteen))) data.flakesFourteen = localNumber(test.flakesFourteen)
          if (test.phValue && !isNaN(Number(test.phValue))) data.phValue = localNumber(test.phValue);
          if (test.moisture && !isNaN(Number(test.moisture))) data.moisture = localNumber(test.moisture);
          if (test.bulkDensity && !isNaN(Number(test.bulkDensity))) data.bulkDensity = localNumber(test.bulkDensity);
        }
      }
    }

    return data
  }

  getReportDifference = (): {
    pa: string[];
    po: string[];
    rubber: string[];
    pvc: string[];
    yellowFlakes: string[];
    glue: string[];
    multilayerFlakes: string[];
    metal: string[];
    other: string[];
    flakes: string[];
    labels: string[];
    phValue: string[];
    bulkDensity: string[];
    moisture: string[];
  } => {
    let data = {
      pa: ['n/a', ''],
      po: ['n/a', ''],
      rubber: ['n/a', ''],
      pvc: ['n/a', ''],
      yellowFlakes: ['n/a', ''],
      glue: ['n/a', ''],
      multilayerFlakes: ['n/a', ''],
      metal: ['n/a', ''],
      other: ['n/a', ''],
      flakes: ['n/a', ''],
      labels: ['n/a', ''],
      phValue: ['n/a', ''],
      bulkDensity: ['n/a', ''],
      moisture: ['n/a', ''],
    }

    if (this.state.activeReportA && this.state.activeReportB) {
      let matchesA = this.state.reports.filter(rep => rep.id === this.state.activeReportA);
      let matchesB = this.state.reports.filter(rep => rep.id === this.state.activeReportB);

      if (matchesA.length === 1 && matchesB.length === 1) {
        let testDataA = matchesA[0].attributes.testData;
        let testDataB = matchesB[0].attributes.testData;

        if (testDataA.length >= 1 && testDataB.length >= 1) {
          let testA: any = testDataA[0];
          let testB: any = testDataB[0];

          if (testA && testB) {
            if ((testA.pa || testA.pa === 0) && (testB.pa || testB.pa === 0)) {
              data.pa[0] = localNumber(testA.pa - testB.pa)
              if ((testA.pa - testB.pa) < 0) data.pa[1] = 'green'
              if ((testA.pa - testB.pa) > 0) data.pa[1] = 'red'
            }
            if ((testA.po || testA.po === 0) && (testB.po || testB.po === 0)) {
              data.po[0] = localNumber(testA.po - testB.po)
              if ((testA.po - testB.po) < 0) data.po[1] = 'green'
              if ((testA.po - testB.po) > 0) data.po[1] = 'red'
            }
            if ((testA.rubber || testA.rubber === 0) && (testB.rubber || testB.rubber === 0)) {
              data.rubber[0] = localNumber(testA.rubber - testB.rubber)
              if ((testA.rubber - testB.rubber) < 0) data.rubber[1] = 'green'
              if ((testA.rubber - testB.rubber) > 0) data.rubber[1] = 'red'
            }
            if ((testA.pvc || testA.pvc === 0) && (testB.pvc || testB.pvc === 0)) {
              data.pvc[0] = localNumber(testA.pvc - testB.pvc)
              if ((testA.pvc - testB.pvc) < 0) data.pvc[1] = 'green'
              if ((testA.pvc - testB.pvc) > 0) data.pvc[1] = 'red'
            }
            if ((testA.yellowFlakes || testA.yellowFlakes === 0) && (testB.yellowFlakes || testB.yellowFlakes === 0)) {
              data.yellowFlakes[0] = localNumber(testA.yellowFlakes - testB.yellowFlakes)
              if ((testA.yellowFlakes - testB.yellowFlakes) < 0) data.yellowFlakes[1] = 'green'
              if ((testA.yellowFlakes - testB.yellowFlakes) > 0) data.yellowFlakes[1] = 'red'
            }
            if ((testA.glue || testA.glue === 0) && (testB.glue || testB.glue === 0)) {
              data.glue[0] = localNumber(testA.glue - testB.glue)
              if ((testA.glue - testB.glue) < 0) data.glue[1] = 'green'
              if ((testA.glue - testB.glue) > 0) data.glue[1] = 'red'
            }
            if ((testA.multilayerFlakes || testA.multilayerFlakes === 0) && (testB.multilayerFlakes || testB.multilayerFlakes === 0)) {
              data.multilayerFlakes[0] = localNumber(testA.multilayerFlakes - testB.multilayerFlakes)
              if ((testA.multilayerFlakes - testB.multilayerFlakes) < 0) data.multilayerFlakes[1] = 'green'
              if ((testA.multilayerFlakes - testB.multilayerFlakes) > 0) data.multilayerFlakes[1] = 'red'
            }
            if ((testA.metal || testA.metal === 0) && (testB.metal || testB.metal === 0)) {
              data.metal[0] = localNumber(testA.metal - testB.metal)
              if ((testA.metal - testB.metal) < 0) data.metal[1] = 'green'
              if ((testA.metal - testB.metal) > 0) data.metal[1] = 'red'
            }
            if ((testA.other || testA.other === 0) && (testB.other || testB.other === 0)) {
              data.other[0] = localNumber(testA.other - testB.other)
              if ((testA.other - testB.other) < 0) data.other[1] = 'green'
              if ((testA.other - testB.other) > 0) data.other[1] = 'red'
            }
            if ((testA.flakes || testA.flakes === 0) && (testB.flakes || testB.flakes === 0)) {
              data.flakes[0] = localNumber(testA.flakes - testB.flakes)
              if ((testA.flakes - testB.flakes) < 0) data.flakes[1] = 'green'
              if ((testA.flakes - testB.flakes) > 0) data.flakes[1] = 'red'
            }
            if ((testA.labels || testA.labels === 0) && (testB.labels || testB.labels === 0)) {
              data.labels[0] = localNumber(testA.labels - testB.labels)
              if ((testA.labels - testB.labels) < 0) data.labels[1] = 'green'
              if ((testA.labels - testB.labels) > 0) data.labels[1] = 'red'
            }
            if ((testA.moisture || testA.moisture === 0) && (testB.moisture || testB.moisture === 0)) {
              data.moisture[0] = localNumber(testA.moisture - testB.moisture)
              if ((testA.moisture - testB.moisture) < 0) data.moisture[1] = 'green'
              if ((testA.moisture - testB.moisture) > 0) data.moisture[1] = 'red'
            }
            if ((testA.phValue || testA.phValue === 0) && (testB.phValue || testB.phValue === 0)) {
              data.phValue[0] = localNumber(testA.phValue - testB.phValue)
              if ((testA.phValue - testB.phValue) < 0) data.phValue[1] = 'green'
              if ((testA.phValue - testB.phValue) > 0) data.phValue[1] = 'red'
            }
            if ((testA.bulkDensity || testA.bulkDensity === 0) && (testB.bulkDensity || testB.bulkDensity === 0)) {
              data.bulkDensity[0] = localNumber(testA.bulkDensity - testB.bulkDensity)
              if ((testA.bulkDensity - testB.bulkDensity) < 0) data.bulkDensity[1] = 'green'
              if ((testA.bulkDensity - testB.bulkDensity) > 0) data.bulkDensity[1] = 'red'
            }
          }
        }

      }
    }

    Object.keys(data).forEach((key: string) => {
      // @ts-ignore
      if (isNaN(Number(data[key][0]))) data[key][0] = '-'
    })

    return data
  }

  updateReportsSort = (): void => {
    let {activeReportA, activeReportB} = this.state

    if (activeReportA && activeReportB) {
      if (activeReportB > activeReportA) this.setState({activeReportA: activeReportB, activeReportB: activeReportA})
    }
  }

  render() {
    const pieInventoryData = {
      labels: Object.keys(this.state.inventoryData).map(key => convertGoodsCategory(key)),
      datasets: [
        {
          data: Object.keys(this.state.inventoryData).map(key => this.state.inventoryData[key]),
          backgroundColor: [
            'rgba(75, 192, 192, 0.2)', //green
            'rgba(54, 162, 235, 0.2)', //blue
            'rgba(255, 99, 132, 0.2)', //red
            'rgba(255, 206, 86, 0.2)', //yellow
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(75, 192, 192, 1)', //green
            'rgba(54, 162, 235, 1)', //blue
            'rgba(255, 99, 132, 1)', //red
            'rgba(255, 206, 86, 1)', //yellow
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 2,
        },
      ],
    };

    const pieInventoryDataValue = {
      labels: Object.keys(this.state.inventoryData).map(key => convertGoodsCategory(key)),
      datasets: [
        {
          data: Object.keys(this.state.inventoryValueData).map(key => this.state.inventoryValueData[key]),
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 2,
        },
      ],
    };

    const pieOptions = {
      plugins: {
        tooltip: {
          callbacks: {
            label: (context: any) => {
              let label = "";
              if (context.formattedValue && context.label) {
                label =  `${context.label}: ${context.formattedValue} kg`
              }
              return label;
            }
          }
        },
        
        legend: {
          labels: {
            usePointStyle: true,
            generateLabels(chart: any) {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                const {labels: {pointStyle}} = chart.legend.options;
    
                return data.labels.map((label: any, i: any) => {
                  const meta = chart.getDatasetMeta(0);
                  const style = meta.controller.getStyle(i);
                  
                  return {
                    text: label + ' - ' + localNumber(chart.data.datasets[0].data[i] || 0) + ' kg',
                    fillStyle: style.backgroundColor,
                    strokeStyle: style.borderColor,
                    lineWidth: style.borderWidth,
                    pointStyle: pointStyle,
                    hidden: !chart.getDataVisibility(i),
    
                    index: i
                  };
                });
              }
              return [];
            }
          }
        }
      }
    }

    const pieOptionsValue = {
      plugins: {
        tooltip: {
          callbacks: {
            label: (context: any) => {
              let label = "";
              if (context.formattedValue && context.label) {
                label =  `${context.label}: ${context.formattedValue} €`
              }
              return label;
            }
          }
        },

        legend: {
          labels: {
            usePointStyle: true,
            generateLabels(chart: any) {
              const data = chart.data;
              if (data.labels.length && data.datasets.length) {
                const {labels: {pointStyle}} = chart.legend.options;
    
                return data.labels.map((label: any, i: any) => {
                  const meta = chart.getDatasetMeta(0);
                  const style = meta.controller.getStyle(i);
                  
                  return {
                    text: label + ' - ' + localCurrency(chart.data.datasets[0].data[i] || 0) + ' €',
                    fillStyle: style.backgroundColor,
                    strokeStyle: style.borderColor,
                    lineWidth: style.borderWidth,
                    pointStyle: pointStyle,
                    hidden: !chart.getDataVisibility(i),
    
                    index: i
                  };
                });
              }
              return [];
            }
          }
        }
      }
    }

    const lineChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'right' as const,
        }
      },
    };

    let lineChartData = {
      labels: ['< 1mm', '> 1mm', '> 2mm', '> 4mm', '> 6mm', '> 8mm', '> 10mm', '> 12mm', '14mm'],
      datasets: [
        {
          label: this.getReportData(this.state.activeReportA).customId || '',
          data: this.state.activeReportA ? [
            this.getReportData(this.state.activeReportA).flakesSubOne,
            this.getReportData(this.state.activeReportA).flakesOne,
            this.getReportData(this.state.activeReportA).flakesTwo,
            this.getReportData(this.state.activeReportA).flakesFour,
            this.getReportData(this.state.activeReportA).flakesSix,
            this.getReportData(this.state.activeReportA).flakesEight,
            this.getReportData(this.state.activeReportA).flakesTen,
            this.getReportData(this.state.activeReportA).flakesTwelve,
            this.getReportData(this.state.activeReportA).flakesFourteen,
          ] : [],
          borderColor: 'black',
          backgroundColor: 'black',
        },
        {
          label: this.getReportData(this.state.activeReportB).customId || '',
          data: this.state.activeReportB ? [
            this.getReportData(this.state.activeReportB).flakesSubOne,
            this.getReportData(this.state.activeReportB).flakesOne,
            this.getReportData(this.state.activeReportB).flakesTwo,
            this.getReportData(this.state.activeReportB).flakesFour,
            this.getReportData(this.state.activeReportB).flakesSix,
            this.getReportData(this.state.activeReportB).flakesEight,
            this.getReportData(this.state.activeReportB).flakesTen,
            this.getReportData(this.state.activeReportB).flakesTwelve,
            this.getReportData(this.state.activeReportB).flakesFourteen,
          ] : [],
          borderColor: 'darkgreen',
          backgroundColor: 'darkgreen',
        },
      ],
    };

    return (
      <div className="Dashboard content" data-testid="Dashboard">
        <h1>Overview</h1>
        <div className="flex spacing-1">
          <div className="fourty flex">
            <Paper sx={widgetStyles}>
              <p className='header'>Produced goods</p>
              <div className="flex of-2 spacing-1">
                <TextField
                  disabled
                  value={localNumberInTons(this.state.rpetStored) + ' t'}
                  size='small'
                  helperText='on stock'
                  className='text-center'
                />
                <TextField
                  disabled
                  value={localNumberInTons(this.state.rpetProducedTotal) + ' t'}
                  size='small'
                  helperText='in total'
                  className='text-center'
                />
              </div>
            </Paper>
          </div>
          <div className="sixty flex">
            <Paper sx={widgetStyles}>
              <p className='header'>Planned goods</p>
              <div className="flex of-3 spacing-1">
                <TextField
                  disabled
                  value={localNumberInTons(this.state.plannedEntryQuantity) + ' t'}
                  size='small'
                  helperText='Incoming'
                  className='text-center'
                />
                <TextField
                  disabled
                  value={localNumberInTons(this.state.plannedProductionQuantity) + ' t'}
                  size='small'
                  helperText='Production'
                  className='text-center'
                />
                <TextField
                  disabled
                  value={localNumberInTons(this.state.plannedExitQuantity) + ' t'}
                  size='small'
                  helperText='Outgoing'
                  className='text-center'
                />
              </div>
            </Paper>
          </div>
        </div>

        {/* <div className="flex spacing-1">
          <div className="fourty flex">
            <Paper sx={widgetStyles}>
            <WarehouseCalendar type={''} dashboard={true}/>
            </Paper>
          </div>
          <div className="sixty flex">
            <Paper sx={widgetStyles}>
            <WarehouseCalendar type={''} dashboard={true}/>
            </Paper>
          </div>
        </div> */}


        {(this.props.rights.includes('entry') || this.props.rights.includes('exit')) && <div className="flex of-2 spacing-1" style={{marginTop: 30}}>
          {this.props.rights.includes('entry') && <div className='flex flex-column'>
            <h1>Goods incoming Order</h1>
            <Paper sx={widgetStyles}>
              <p>Incoming</p>
              <div className="flex of-3 spacing-1">
                <TextField
                  disabled
                  value={this.state.ordersOpen}
                  size='small'
                  helperText='open'
                  className='text-center'
                />
                <TextField
                  disabled
                  value={this.state.ordersNeedPlate}
                  size='small'
                  helperText='truck nr needed'
                  className='text-center'
                />
                <TextField
                  disabled
                  value={this.state.ordersNeedStore}
                  size='small'
                  helperText='storage place needed'
                  className='text-center'
                />
              </div>
            </Paper>
          </div>}
          {this.props.rights.includes('exit') && <div className='flex flex-column'>
            <h1>Goods outgoing</h1>
            <Paper sx={widgetStyles}>
              <p>Outgoing</p>
              <div className="flex of-3 spacing-1">
                <TextField
                  disabled
                  value={this.state.exitOrdersOpen}
                  size='small'
                  helperText='open'
                  className='text-center'
                />
                <TextField
                  disabled
                  value={this.state.exitOrdersNeedPlate}
                  size='small'
                  helperText='truck nr needed'
                  className='text-center'
                />
                <TextField
                  disabled
                  value={this.state.exitOrdersNeedGoods}
                  size='small'
                  helperText='goods needed'
                  className='text-center'
                />
              </div>
            </Paper>
          </div>}
        </div>}
        {this.props.rights.includes('warehouse') && <div className="flex of-2 spacing-1" style={{marginTop: 30}}>
          <div className='flex flex-column'>
            <h1>Warehouse</h1>
            <Paper sx={widgetStyles}>
              <div className="flex" style={{marginBottom: 20}}>
                <div className="sixty"><p style={{margin: 0}}>Goods on stock in {Object.keys(this.state.inventoryData).length} categories:</p></div>
                <div className="fourty text-right"><strong>{this.getInventoryDataTotal()} kg</strong></div>
              </div>
              <div style={{maxWidth: 400, margin: '0 auto 20px'}}>
                <Pie data={pieInventoryData} options={pieOptions} />
              </div>
            </Paper>
          </div>
          <div className='flex flex-column'>
            <h1>Inventory</h1>
            <Paper sx={widgetStyles}>
              <div className="flex" style={{marginBottom: 20}}>
                <div className="sixty"><p style={{margin: 0}}>Asset value:</p></div>
                <div className="fourty text-right"><strong>{this.getInventoryDataTotalValue()} €</strong></div>
              </div>
              <div style={{maxWidth: 400, margin: '0 auto 20px'}}>
                <Pie data={pieInventoryDataValue} options={pieOptionsValue} />
              </div>
            </Paper>
          </div>
        </div>}
        {this.props.rights.includes('lab') && <div style={{marginTop: 30}}>
          <div>
            <h1>Labor</h1>
            <div className="flex of-2 spacing-1">
              <div className='flex'>
                <Paper sx={widgetStyles}>
                  <p className="header">Analysen</p>
                  <div className="flex of-3 spacing-1">
                    <TextField
                      disabled
                      value={this.state.reportsOpen}
                      size='small'
                      helperText='offen'
                      className='text-center'
                    />
                    <TextField
                      disabled
                      value={this.state.reportsDone}
                      size='small'
                      helperText='durchgeführt'
                      className='text-center'
                    />
                    <TextField
                      disabled
                      value={this.state.reportsOpen + this.state.reportsDone}
                      size='small'
                      helperText='in Summe'
                      className='text-center'
                    />
                  </div>
                </Paper>
              </div>
              <div className='flex'>
                <Paper sx={widgetStyles}>
                  <p className="header flex align-center">
                    Berichte vergleichen
                    <MuiTooltip title='Bericht 2 muss älter sein als Bericht 1'>
                      <InfoOutlined sx={{width: '17px', marginLeft: '5px'}} />
                    </MuiTooltip>
                  </p>
                  <div className="flex of-2 spacing-1">
                    <TextField
                      size='small'
                      helperText='Bericht 1'
                      select
                      value={this.state.activeReportA}
                      onChange={(e) => this.setState({activeReportA: e.target.value}, this.updateReportsSort)}
                    >
                      {this.state.reports.length >= 1 && this.state.reports.map((report, i) => (
                        <MenuItem key={`reportA-${i}`} value={report.id}>{report.attributes.customId}</MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      size='small'
                      helperText='Bericht 2'
                      select
                      value={this.state.activeReportB}
                      onChange={(e) => this.setState({activeReportB: e.target.value}, this.updateReportsSort)}
                    >
                      {this.state.reports.length >= 1 && this.state.reports.map((report, i) => (
                        <MenuItem key={`reportB-${i}`} value={report.id}>{report.attributes.customId}</MenuItem>
                      ))}
                    </TextField>
                  </div>
                </Paper>
              </div>
            </div>
            <div className="flex of-2 spacing-1">
              <div className='flex'>
                <Paper sx={widgetStyles} className='flex flex-column'>
                  <p className='header'>Flakegröße im Vergleich</p>
                  <div className="flex" style={{flex: 1, alignItems: 'center'}}>
                    <Line options={lineChartOptions} data={lineChartData} />
                  </div>
                </Paper>
              </div>
              <div className='flex'>
                <Paper sx={widgetStyles}>
                  <p className='header'>erweiterte Prüfung - Wertvergleich</p>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center'>&nbsp;</TableCell>
                          <TableCell align='center'>PH-Wert</TableCell>
                          <TableCell align='center'>Feuchte (%)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <>
                          {[this.state.activeReportA, this.state.activeReportB].map((id, i) => id ? (
                            <TableRow key={`reprowext-${i}`}>
                              <TableCell align='center'><Link to={`/labor?id=${id}`}>{this.getReportData(id).customId}</Link></TableCell>
                              <TableCell align='center'>{this.getReportData(id).phValue}</TableCell>
                              <TableCell align='center'>{this.getReportData(id).moisture}</TableCell>
                            </TableRow>
                          ) : null)}
                          {this.state.activeReportA && this.state.activeReportB ? (
                            <TableRow>
                              <TableCell align='center'>&nbsp;</TableCell>
                              <TableCell align='center'><span className={this.getReportDifference().phValue[1]}>{this.getReportDifference().phValue[0]}</span></TableCell>
                              <TableCell align='center'><span className={this.getReportDifference().moisture[1]}>{this.getReportDifference().moisture[0]}</span></TableCell>
                            </TableRow>
                          ) : null}
                        </>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </div>
            <div>
              <Paper sx={widgetStyles}>
                <p className="header">Standartprüfung - Wertvergleich</p>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center'>&nbsp;</TableCell>
                        <TableCell align='center'>farbige<br />Flakes</TableCell>
                        <TableCell align='center'>Gelbe Flakes</TableCell>
                        <TableCell align='center'>PO</TableCell>
                        <TableCell align='center'>Labels</TableCell>
                        <TableCell align='center'>PA, PS</TableCell>
                        <TableCell align='center'>Kleber (Flakes inkl.)</TableCell>
                        <TableCell align='center'>Gummi</TableCell>
                        <TableCell align='center'>Multilayer Flakes</TableCell>
                        <TableCell align='center'>Folie</TableCell>
                        <TableCell align='center'>Metall</TableCell>
                        <TableCell align='center'>PVC</TableCell>
                        <TableCell align='center'>Sonstige</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[this.state.activeReportA, this.state.activeReportB].map((id, i) => id ? (
                        <TableRow key={`reprow-${i}`}>
                          <TableCell align='center'><Link to={`/labor?id=${id}`}>{this.getReportData(id).customId}</Link></TableCell>
                          <TableCell align='center'>{this.getReportData(id).flakes}</TableCell>
                          <TableCell align='center'>{this.getReportData(id).yellowFlakes}</TableCell>
                          <TableCell align='center'>{this.getReportData(id).po}</TableCell>
                          <TableCell align='center'>{this.getReportData(id).labels}</TableCell>
                          <TableCell align='center'>{this.getReportData(id).pa}</TableCell>
                          <TableCell align='center'>{this.getReportData(id).glue}</TableCell>
                          <TableCell align='center'>{this.getReportData(id).rubber}</TableCell>
                          <TableCell align='center'>{this.getReportData(id).multilayerFlakes}</TableCell>
                          <TableCell align='center'>{this.getReportData(id).metal}</TableCell>
                          <TableCell align='center'>{this.getReportData(id).pvc}</TableCell>
                          <TableCell align='center'>{this.getReportData(id).other}</TableCell>
                        </TableRow>
                      ) : null)}
                      {this.state.activeReportA && this.state.activeReportB ? (
                        <TableRow>
                          <TableCell align='center'>&nbsp;</TableCell>
                          <TableCell align='center'><span className={this.getReportDifference().flakes[1]}>{this.getReportDifference().flakes[0]}</span></TableCell>
                          <TableCell align='center'><span className={this.getReportDifference().yellowFlakes[1]}>{this.getReportDifference().yellowFlakes[0]}</span></TableCell>
                          <TableCell align='center'><span className={this.getReportDifference().po[1]}>{this.getReportDifference().po[0]}</span></TableCell>
                          <TableCell align='center'><span className={this.getReportDifference().labels[1]}>{this.getReportDifference().labels[0]}</span></TableCell>
                          <TableCell align='center'><span className={this.getReportDifference().pa[1]}>{this.getReportDifference().pa[0]}</span></TableCell>
                          <TableCell align='center'><span className={this.getReportDifference().glue[1]}>{this.getReportDifference().glue[0]}</span></TableCell>
                          <TableCell align='center'><span className={this.getReportDifference().rubber[1]}>{this.getReportDifference().rubber[0]}</span></TableCell>
                          <TableCell align='center'><span className={this.getReportDifference().multilayerFlakes[1]}>{this.getReportDifference().multilayerFlakes[0]}</span></TableCell>
                          <TableCell align='center'><span className={this.getReportDifference().metal[1]}>{this.getReportDifference().metal[0]}</span></TableCell>
                          <TableCell align='center'><span className={this.getReportDifference().pvc[1]}>{this.getReportDifference().pvc[0]}</span></TableCell>
                          <TableCell align='center'><span className={this.getReportDifference().other[1]}>{this.getReportDifference().other[0]}</span></TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

export default withRouter(Dashboard);
