import { Button, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import './ProductionPlan.sass';
import { NotiStore } from '../../../stores/notifications';
import { resetNotifications } from '../../../services/notiStoreService';
import { Batch, fetchBatches } from '../../../services/batchesService';
import { localNumber } from '../../../services/stringConverter';
import { InfoOutlined } from '@mui/icons-material';
import { fetchInvetory, Good } from '../../../services/inventoryService';
import { fetchOrders, OrderData } from '../../../services/warehouseEntriesService';
import ProductionBatchesForm from '../ProductionBatchesForm/ProductionBatchesForm';

// calendar german language: require('moment/locale/de.js')

interface ProductionPlanProps {}
interface ProductionPlanState {
  events: Event[];
  batches: Batch[];
  calendarDate: Date;
  calendarView: string;
  start: string;
  end: string;
  outputForView: number;
  inputForView: number;
  outputForFuture: number;
  unplannedEntryGoods: number;
  goodsToBeDelivered: number;
  latestBatchEnd: string;
  showBatchesForm: boolean;
  activeBatch: number;
}

interface Event {
  title: string;
  status: string;
  start: Date;
  end: Date;
  id: number;
}

const localizer = momentLocalizer(moment)

const defaultState = {
  events: [],
  batches: [],
  calendarDate: new Date(Date.now()),
  calendarView: 'month',
  start: '',
  end: '',
  outputForView: 0,
  inputForView: 0,
  outputForFuture: 0,
  unplannedEntryGoods: 0,
  goodsToBeDelivered: 0,
  latestBatchEnd: '',
  showBatchesForm: false,
  activeBatch: -1,
}

const eventStyleGetter = function(event: Event) {
    var backgroundColor = '';
    if (event.status === 'active') backgroundColor = 'orange'
    if (event.status === 'finished') backgroundColor = 'green'
    var style = {
        backgroundColor: backgroundColor,
        fontSize: '12px',
        textAlign: 'center'
    };
    return {
        style: style
    };
}

class ProductionPlan extends React.Component<ProductionPlanProps, ProductionPlanState> {
  state: ProductionPlanState = {
    ...defaultState
  }

  componentDidMount() {
    resetNotifications()
    this.calcCalendar()
  }

  fetchData = (): void => {
    NotiStore.setState({loading: true});

    let {start, end} = this.state;

    if (start && end) {
      let batchesForView = fetchBatches(`filters[isDraft]=false&filters[start][$lt]=${moment(new Date(end)).add(1, "days").format('YYYY-MM-DD')}&filters[end][$gt]=${start}`);
      let allFutureBatches = fetchBatches(`filters[isDraft]=false&filters[end][$gt]=${moment(new Date()).subtract(1, "days").format('YYYY-MM-DD')}&sort[0]=end`);
      let goods = fetchInvetory(`filters[category]=entry`);
      let orders = fetchOrders('filters[isFulfilled]=false&filters[isDraft]=false');

      Promise.all([batchesForView, allFutureBatches, goods, orders])
      .then(res => {
        try {
          let events: Event[] = [];
          let outputForView: number = 0;
          let inputForView: number = 0;
          let outputForFuture: number = 0;
          let unplannedEntryGoods: number = 0;
          let goodsToBeDelivered: number = 0;

          if (res[0].data.length >= 1) res[0].data.forEach((batch: Batch) => {
            if ((batch.id || batch.id === 0) && batch.attributes.customId && batch.attributes.start && batch.attributes.end) {
              let event: Event = {
                title: batch.attributes.customId,
                status: batch.attributes.status,
                start: new Date(batch.attributes.start),
                end: new Date(batch.attributes.end),
                id: batch.id
              }

              events.push(event);
            }

            if (batch.attributes.output) outputForView += batch.attributes.output;
            if (batch.attributes.goodsToUseData && batch.attributes.goodsToUseData.length >= 1) batch.attributes.goodsToUseData.forEach(good => {
              if (good.inputQuantity) inputForView += good.inputQuantity
            })
          })

          if (res[1].data.length >= 1) res[1].data.forEach((batch: Batch) => {
            if (batch.attributes.output) outputForFuture += batch.attributes.output;
          })
          let latestBatchEnd: string = res[1].data.length >= 1 ? moment(res[1].data[res[1].data.length - 1].attributes.end).format('DD.MM.YYYY') : '';

          if (res[2].data.length >= 1) res[2].data.forEach((good: Good) => {
            if ((!good.attributes.batches || !good.attributes.batches.data) && good.attributes.origQuantity) unplannedEntryGoods += good.attributes.quantity
            else if (good.attributes.origQuantity) {
              let unplannedQuantity = good.attributes.origQuantity;

              good.attributes.batches.data.forEach((batch: Batch) => {
                if (batch.attributes.goodsToUseData && batch.attributes.goodsToUseData.length >= 1) {
                  let match = batch.attributes.goodsToUseData.filter(data => data.id === good.id);

                  if (match.length >= 1) match.forEach(m => {
                    unplannedQuantity = unplannedQuantity - m.inputQuantity;
                  })
                }
              })

              if (unplannedQuantity < 0) unplannedQuantity = 0;

              unplannedEntryGoods += unplannedQuantity;
            }
          })

          if (res[3].data && res[3].data.length >= 1) res[3].data.forEach((order: {attributes: OrderData}) => {
            if (order.attributes.positions && order.attributes.positions.length >= 1) order.attributes.positions.forEach(pos => {
              if (pos.quantity) goodsToBeDelivered += Number(pos.quantity)

              if (pos.truckData && pos.truckData.length >= 1) pos.truckData.forEach(truck => {
                if (truck.fulfilled && truck.truckAmount) goodsToBeDelivered = goodsToBeDelivered - Number(truck.truckAmount)
              })
            })
          })

          this.setState({events, batches: res[0].data, outputForView, inputForView, latestBatchEnd, outputForFuture, unplannedEntryGoods, goodsToBeDelivered})
        } catch {}
      })
      .catch(err => {
        NotiStore.setState({error: true, errorMessage: 'Fehler beim Abrufen der Daten aufgetreten.'})
      })
      .finally(() => {
        NotiStore.setState({loading: false})
      })
    }
  }

  handleSelectEvent = (e: any): void => {
    let {id} = e;
    let {batches} = this.state;

    let match = batches.length >= 1 ?  batches.filter(b => b.id === id) : undefined;

    if (match && match.length === 1) {
      this.startEdit(match[0].id)
    }
  }

  onCalendarNavigate = (date: Date, view: string): void => {
    this.setState({calendarDate: date, calendarView: view}, () => this.calcCalendar());
  }

  onCalendarView = (view: string): void => {
    this.setState({calendarView: view}, () => this.calcCalendar());
  }

  calcCalendar = () => {
    let view = this.state.calendarView
    let date = this.state.calendarDate

    if (view === 'week') {
      this.setState({start: moment(date).startOf('isoWeek').format('YYYY-MM-DD'), end: moment(date).endOf('isoWeek').format('YYYY-MM-DD'), inputForView: defaultState.inputForView, outputForView: defaultState.outputForView}, () => this.fetchData())
    } else if (view === 'month') {
      this.setState({start: moment(date).startOf('month').format('YYYY-MM-DD'), end: moment(date).endOf('month').format('YYYY-MM-DD'), inputForView: defaultState.inputForView, outputForView: defaultState.outputForView}, () => this.fetchData())
    }
  }

  onSelectSlot =(slotInfo: {
    start: Date,
    action: 'select' | 'click' | 'doubleClick',
  }): void => {
    if (slotInfo.action === 'doubleClick') {
      this.startEdit()
    }
  }

  startEdit = (id: number = -1): void => {
    this.setState({
      showBatchesForm: true,
      activeBatch: id
    })
  }

  render() {
    return (
      <div className="ProductionPlan content" data-testid="ProductionPlan">
        <div className="header-bar">
          <h1>Production plan</h1>
          <Button variant="contained" type='submit'>Produktionsplan drucken</Button>
        </div>
        
        {/* TODO: Berechnungen */}
        <div className='flex of-2 space-between overview'>
          <div className="fourty">
            <div className="header-bar mb-10">
              <h2>Current view</h2>
              <Tooltip title='Produktionsübersicht für den im Kalender sichtbaren Abschnitt.'>
                <InfoOutlined className='primary' />
              </Tooltip>
            </div>
            <div className="flex of-2 spacing-1">
              <div>
                <TextField
                  disabled
                  value={localNumber(this.state.outputForView) + ' kg'}
                  helperText='planned production amount'
                  className='text-center'
                  // inputProps={{className: 'disabled-secondary'}}
                />
              </div>
              <div>
                <TextField
                  disabled
                  value={localNumber(this.state.inputForView) + ' kg'}
                  helperText='needed incoming'
                  className='text-center'
                  // inputProps={{className: 'disabled-secondary'}}
                />
              </div>
            </div>
          </div>
          <div className="fifty">
            <div className="header-bar mb-10">
              <h2>Geplante Produktion bis {this.state.latestBatchEnd ? this.state.latestBatchEnd : '???'}</h2>
              <Tooltip title='Gesamte Produktionsübersicht bis zum Ende der letzten geplanten Charge.'>
                <InfoOutlined className='primary' />
              </Tooltip>
            </div>
            <div className="flex of-3 spacing-1">
              <div>
                <TextField
                  disabled
                  value={localNumber(this.state.outputForFuture) + ' kg'}
                  helperText='planned production amount'
                  className='text-center'
                  // inputProps={{className: 'disabled-secondary'}}
                />
              </div>
              <div>
                <TextField
                  disabled
                  value={localNumber(this.state.unplannedEntryGoods) + ' kg'}
                  helperText='stored raw material, not planned'
                  className='text-center'
                  // inputProps={{className: 'disabled-secondary'}}
                />
              </div>
              <div>
                <TextField
                  disabled
                  value={localNumber(this.state.goodsToBeDelivered) + ' kg'}
                  helperText='Will be delivered in this period'
                  className='text-center'
                  // inputProps={{className: 'disabled-secondary'}}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="calendar-container">
          {/* TODO Kalenderwoche anzeigen */}
          <Calendar
            localizer={localizer}
            events={this.state.events}
            onSelectEvent={this.handleSelectEvent}
            onSelectSlot={this.onSelectSlot}
            onNavigate={this.onCalendarNavigate}
            onView={this.onCalendarView}
            startAccessor="start"
            endAccessor="end"
            selectable
            messages={{
              next: "forward",
              previous: "back",
              today: "Today",
              month: "Month",
              week: "Week",
              day: "Tag"
            }}
            // @ts-ignore
            eventPropGetter={(eventStyleGetter)}
          />
        </div>

        <ProductionBatchesForm 
          open={this.state.showBatchesForm}
          activeBatch={this.state.activeBatch}
          closeModal={() => this.setState({showBatchesForm: false, activeBatch: defaultState.activeBatch}, this.fetchData)}
        />
      </div>
    )
  }
}

export default ProductionPlan;
