import React from 'react';
import './Sidebar.sass';
import { NavLink } from "react-router-dom";
// import systemIcon from '../../assets/icons/system_w.png';
// import produktionIcon from '../../assets/icons/produktion_w.png';
// import balkenIcon from '../../assets/icons/balkendiagramm_w.png';
// import laborIcon from '../../assets/icons/labor_w.png';
// import lagerIcon from '../../assets/icons/lager_w.png';
// import eingangIcon from '../../assets/icons/eingang.png';
// import ausgangIcon from '../../assets/icons/ausgang.png';
import { withRouter } from '../utils/withRouter/withRouter';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import BiotechIcon from '@mui/icons-material/Biotech';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import logo from '../../assets/images/ksm.png'
interface SidebarProps {
  rights: string[]
}

interface SidebarState {
  path: string;
  showProcessDD: boolean;
}

class Sidebar extends React.Component<SidebarProps, SidebarState> {
  state: SidebarState = {
    path: '',
    showProcessDD: false,
  }

  componentDidMount() {
    let path = window.location.pathname;
    this.setState({path: path});
  }

  render() {
    return (
      <div className="Sidebar" data-testid="Sidebar">
        <div className='header'>
          {/* KSM Recycling Production */}
          <img src={logo} alt="KSM Recycling Logo" style={{borderRadius:"10px",height:"111px"}}/>
        </div>
        <div className='nav'>
          <NavLink to="/dashboard" className='item' onClick={() => this.setState({path: '/dashboard'})}><DashboardIcon />&nbsp;&nbsp;Dashboard</NavLink>
          {(this.props.rights.includes('inventory') || this.props.rights.includes('warehouse')) && <NavLink to="/lager" className='item' onClick={() => this.setState({path: '/lager'})}><WarehouseIcon />&nbsp;&nbsp;Warehouse</NavLink>}
          {this.state.path.includes('lager') && (
            <div className='sub-nav'>
              {this.props.rights.includes('inventory') && <NavLink to="/lager/inventar" className='item' onClick={() => this.setState({path: '/lager/inventar'})}>Inventar</NavLink>}
              {this.props.rights.includes('warehouse') && <NavLink to="/lager/lagerplatz" className='item' onClick={() => this.setState({path: '/lager/lagerplatz'})}>Lagerplatz</NavLink>}
            </div>
          )}
          {this.props.rights.includes('entry') && <NavLink to="/wareneingang" className='item' onClick={() => this.setState({path: '/lager/wareneingang'})}><ContentPasteGoIcon />&nbsp;&nbsp;Incoming</NavLink>}
          {this.props.rights.includes('exit') && <NavLink to="/warenausgang" className='item' onClick={() => this.setState({path: '/lager/warenausgang'})}><ContentPasteIcon />&nbsp;&nbsp;Outgoing</NavLink>}
          {this.props.rights.includes('batches') && <NavLink to="/produktion" className='item' onClick={() => this.setState({path: '/produktion'})}><CorporateFareIcon />&nbsp;&nbsp;Production</NavLink>}
          {this.props.rights.includes('batches') && this.state.path.includes('produktion') && !this.state.path.includes('prozess') && (
            <div className='sub-nav'>
              <NavLink to="/produktion/produktionsplan" className='item' onClick={() => this.setState({path: '/produktion/produktionsplan'})}>Produktionsplan</NavLink>
              <NavLink to="/produktion/chargen" className='item' onClick={() => this.setState({path: '/produktion/chargen'})}>Chargen</NavLink>
            </div>
          )}
          {this.props.rights.includes('lab') && <NavLink to="/labor" className='item' onClick={() => this.setState({path: '/labor'})}><BiotechIcon />&nbsp;&nbsp;Laboratory</NavLink>}
          {this.props.rights.includes('lab') && this.state.path.includes('labor') && (
            <div className='sub-nav'>
              <NavLink to="/labor" className='item' onClick={() => this.setState({path: '/labor'})}>Analysen</NavLink>
            </div>
          )}
          {(this.props.rights.includes('system::users') || this.props.rights.includes('system::definitions') || this.props.rights.includes('system::emails') || this.props.rights.includes('system::warehouse') || this.props.rights.includes('system::lab') || this.props.rights.includes('system::production')) && <NavLink to="/system" className='item' onClick={() => this.setState({path: '/system'})}><SettingsSuggestIcon />&nbsp;&nbsp;System</NavLink>}
          {this.state.path.includes('system') && (
            <div className='sub-nav'>
              {(this.props.rights.includes('system::emails') || this.props.rights.includes('system::warehouse') || this.props.rights.includes('system::lab') || this.props.rights.includes('system::production')) && <NavLink to="/system/einstellungen" className='item' onClick={() => this.setState({path: '/system/einstellungen'})}>Einstellungen</NavLink>}
              {this.props.rights.includes('system::users') && <NavLink to="/system/benutzer" className='item' onClick={() => this.setState({path: '/system/benutzer'})}>Benutzer</NavLink>}
              {this.props.rights.includes('system::definitions') && <NavLink to="/system/definitionen" className='item' onClick={() => this.setState({path: '/system/definitionen'})}>Definitionen</NavLink>}
            </div>
          )}
        </div>
        {(this.props.rights.includes('process::production') || this.props.rights.includes('process::entry') || this.props.rights.includes('process::exit')) && <div className="process-btn-wrapper">
          <NavLink to="/prozess" className='process-btn'><div style={{borderRadius:"20px"}}>Prozesse</div></NavLink>
          <div className={'dropdown'}>
            {this.props.rights.includes('process::production') && <NavLink to="/prozess/produktion" className='item' onClick={() => this.setState({path: '/prozess/produktion'})}>Produktion</NavLink>}
            {this.props.rights.includes('process::entry') && <NavLink to="/prozess/wareneingang" className='item' onClick={() => this.setState({path: '/prozess/wareneingang'})}>Wareneingang</NavLink>}
            {this.props.rights.includes('process::exit') && <NavLink to="/prozess/warenausgang" className='item' onClick={() => this.setState({path: '/prozess/warenausgang'})}>Warenausgang</NavLink>}
          </div>
        </div>}
      </div>
    )
  }
};

export default withRouter(Sidebar);
