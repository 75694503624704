import React from 'react';
import './App.sass';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from './routes/Dashboard';
import System from './routes/System';
import Login from './routes/Login';
import {isAuthenticated} from './services/userService';
import {UserStore} from './stores/user';
import SystemStandardValues from './routes/System_StandardValues';
import SystemUsers from './routes/System_Users';
import SystemDefinitions from './routes/System_Definitions';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import Laboratory from './routes/Laboratory';
import ErrorSnackbar from './components/utils/ErrorSnackbar/ErrorSnackbar';
import SuccessSnackbar from './components/utils/SuccessSnackbar/SuccessSnackbar';
import InfoSnackbar from './components/utils/InfoSnackbar/InfoSnackbar';
import LoadingIndicator from './components/utils/LoadingIndicator/LoadingIndicator';
import Warehouse from './routes/Warehouse';
import WarehouseCalendar from './routes/Warehouse_Calendar';
import WarehouseEntry from './routes/Warehouse_Entry';
import ProcessEntryRoute from './routes/Process_Entry';
import WarehouseInventoryRoute from './routes/Warehouse_Inventory';
import WarehouseStoresRoute from './routes/Warehouse_Stores';
import ProductionPlanRoute from './routes/Production_Plan';
import ProductionBatchesRoute from './routes/Production_Batches';
import WarehouseExitRoute from './routes/Warehouse_Exit';
import ProcessOverviewRoute from './routes/Processes_Overview';
import ProcessProductionRoute from './routes/Process_Production';
import ProcessExitRoute from './routes/Process_Exit';

interface AppState {
  rights: string[];
}

interface AppProps {}

const darkGreen = '#024111';

const theme = createTheme({
  palette: {
    primary: {
      main: darkGreen
    }
  },
  components: {
    // Name of the component
    MuiButton: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          input: {
            padding: '8.5px 14px',
          }
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '8.5px 14px',
        },
      }
    }
  },
});

const defaultState = {
  rights: [''],
}

class App extends React.Component<AppProps, AppState> {
  state = {...defaultState}
  
  componentDidMount() {
    let path = window.location.pathname;

    isAuthenticated()
    .then(res => {
      if (res.username && res.email && res.confirmed) {
        UserStore.setState({
          isAuthenticated: true, 
          user: {
            blocked: res.blocked, 
            confirmed: res.confirmed, 
            email: res.email, 
            username: res.username,
            rights: res.rights
          }
        })
        this.setState({rights: res.rights || []});
      } else if (path !== '/' && path !== '/passwort-zuruecksetzen') {
        window.location.replace('/');
      }
    })
    .catch(() => {
      if (path !== '/' && path !== '/passwort-zuruecksetzen') window.location.replace('/');
    })
  }

  render() {
    return (
      // TODO: role
      <div className={"App role-"}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/passwort-zuruecksetzen" element={<Login mode="pass-reset" />} />

              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="/prozess" element={<ProcessOverviewRoute />} />
              <Route path="/prozess/warenausgang" element={<ProcessExitRoute />} />
              <Route path="/prozess/wareneingang" element={<ProcessEntryRoute />} />
              <Route path="/prozess/produktion" element={<ProcessProductionRoute />} />

              <Route path="/produktion" element={<Navigate to={'/produktion/produktionsplan'} />} />
              <Route path="/produktion/produktionsplan" element={<ProductionPlanRoute />} />
              <Route path="/produktion/chargen" element={<ProductionBatchesRoute />} />

              <Route path="/lager/inventar" element={<WarehouseInventoryRoute />} />
              <Route path="/lager/lagerplatz" element={<WarehouseStoresRoute />} />
              <Route path="/wareneingang" element={<WarehouseEntry />} />
              <Route path="/warenausgang" element={<WarehouseExitRoute />} />
              <Route path="/lager" element={<Warehouse />} />

              <Route path="/wareneingang/kalender" element={<WarehouseCalendar type='entry' />} />
              <Route path="/warenausgang/kalender" element={<WarehouseCalendar type='exit' />} />

              <Route path="/labor" element={<Laboratory />} />

              <Route path="/system/einstellungen" element={<SystemStandardValues />} />
              <Route path="/system/benutzer" element={<SystemUsers />} />
              <Route path="/system/definitionen" element={<SystemDefinitions />} />
              <Route path="/system" element={<System />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>

        <ErrorSnackbar />
        <SuccessSnackbar />
        <InfoSnackbar />
        <LoadingIndicator />
      </div>
    );
  }
}

export default App;
