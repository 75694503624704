import { ArrowRightAltOutlined } from '@mui/icons-material';
import React, { FC } from 'react';
import './HeadingArrow.sass';

interface HeadingArrowProps {
  text: string;
}

const HeadingArrow: FC<HeadingArrowProps> = (props) => (
  <div className='flex align-center green' style={{marginBottom: 10}}>
    <ArrowRightAltOutlined /> {props.text}
  </div>
);

export default HeadingArrow;
