import React, { FC } from 'react';
import './TopNav.sass';
import {UserStore} from '../../stores/user';
import LogoutButton from '../utils/LogoutButton/LogoutButton';

interface TopNavProps {}

const TopNav: FC<TopNavProps> = () => {
  const user = UserStore((state: any) => state.user);

  const [time, setTime] = React.useState(new Date().toLocaleString('de-DE'));

  React.useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date().toLocaleString('de-DE'));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="TopNav" data-testid="TopNav">
      <div style={{color:"green"}}>
        {time}
      </div>
      <div className='flex justify-end'>
        {user.username.toUpperCase()}
        <LogoutButton />
      </div>
    </div>
  )
};

export default TopNav;
