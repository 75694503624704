import React from 'react';
import './LoginForm.sass';
import {Button, TextField} from '@mui/material';
import {login, isAuthenticated} from '../../services/userService';
import {UserStore} from '../../stores/user';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import {NotiStore} from '../../stores/notifications';
import { api } from '../../services/apiService';

interface LoginFormProps {
  mode?: string;
  location?: any;
}

interface LoginFormState {
  emailFP: string;
  email: string;
  password: string;
  newPassword: string;
  newPasswordConfirm: string;
  passwordResetCode: string;
  success: string;
  error: string;
  errorType: string;
  redirectTo: string;
  mode: string;
  disableButtons: boolean;
  passwordChange:boolean;
}

const defaultState: LoginFormState = {
  emailFP: '',
  email: '',
  password: '',
  newPassword: '',
  newPasswordConfirm: '',
  passwordResetCode: '',
  success: '',
  error: '',
  errorType: '',
  redirectTo: '',
  mode: 'login',
  disableButtons: false,
  passwordChange: false,
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  constructor(props:LoginFormProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (window.location.search === '?mode=reset') this.setState({mode: 'forgotpw'})
    else if (this.props.mode) this.setState({mode: this.props.mode});

    let passwordResetCode = queryString.parse(window.location.search).code?.toString();
    if (passwordResetCode) this.setState({passwordResetCode});
  }

  state: LoginFormState = {...defaultState}

  handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let key: string = e.target.name;
    if (Object.keys(this.state).includes(key)) this.setState({[key]: e.target.value} as any);
  }

  handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (this.state.mode === 'login') this.handleLogin()
      else if (this.state.mode === 'forgotpw') this.handleForgotPassword()
      else if (this.state.mode === 'reset-pass') this.handlePassReset()
    }
  }

  handleLogin = (): void => {
    this.setState({error: '', errorType: ''});

    let {email, password} = this.state;

    if (!email) return this.setState({error: 'Bitte gib eine gültige Emailadresse oder einen gültigen Benutzernamen ein.', errorType: 'emailInvalid'})
    else if (!password) return this.setState({error: 'Bitte gib eine gültiges Passwort ein.', errorType: 'passwordInvalid'});

    login(email, password)
    .then(res => {
      window.localStorage.setItem('ksm_erp_jwt', res.jwt);
      UserStore.setState({isAuthenticated: true, user: res.user});

      isAuthenticated()
      .then(res => {
        if (res.username && res.email && res.confirmed) {
          UserStore.setState({
            isAuthenticated: true, 
            user: {
              blocked: res.blocked, 
              confirmed: res.confirmed, 
              email: res.email, 
              username: res.username,
              rights: res.rights
            }
          })
          this.setState({redirectTo: '/dashboard'});
        }
      })
    })
    .catch(err => {
      this.setState({error: 'Login fehlgeschlagen. Bitte überprüfe deine Angaben und versuche es erneut.'});
    });
  }

  handleForgotPassword = () => {
    NotiStore.setState({loading: true});
    this.setState({error: '', errorType: '', disableButtons: true});

    let {emailFP} = this.state;

    if (!emailFP) return this.setState({error: 'Bitte gib eine gültige Emailadresse oder einen gültigen Benutzernamen ein.', errorType: 'emailInvalid'});

    axios.post(api + '/api/auth/forgot-password', {email: emailFP})
    .then(res => {
      this.setState({success: 'Wir haben dir eine Email mit dem Link zum Zurücksetzen des Passworts gesendet.', emailFP: ''});
    })
    .catch(err => {
      if (err.response.status === 429) return this.setState({error: 'Zu viele Versuche. Bitte versuche es später erneut.'});
      this.setState({error: 'Es gab einen Fehler. Bitte überprüfe deine Angaben und versuche es erneut.'});
    })
    .finally(() => {
      NotiStore.setState({loading: false});
      this.setState({disableButtons: false})
    });
  }

  handlePassReset = () => {
    this.setState({error: '', errorType: ''});

    let {newPassword, newPasswordConfirm, passwordResetCode} = this.state;

    if (!passwordResetCode) return this.setState({error: 'Es gab einen Fehler. Bitte überprüfe deine Angaben und versuche es erneut.'})
    else if (!newPassword) return this.setState({error: 'Bitte gib eine gültige Emailadresse oder einen gültigen Benutzernamen ein.', errorType: 'newPasswordInvalid'})
    else if (newPassword !== newPasswordConfirm) return this.setState({error: 'Die Passwörter stimmen nicht überein.', errorType: 'newPasswordConfirmInvalid', newPasswordConfirm: ''});

    axios.post(api + '/api/auth/reset-password', {code: passwordResetCode, password: newPassword, passwordConfirmation: newPasswordConfirm})
    .then(res => {
      this.setState({success: 'Dein Passwort wurde geändert. Du kannst dich jetzt einloggen.', newPassword: '', newPasswordConfirm: ''});
    })
    .catch(err => {
      console.log(err)
      if (err.response.status === 429) return this.setState({error: 'Zu viele Versuche. Bitte versuche es später erneut.'});
      this.setState({error: 'Es gab einen Fehler. Bitte überprüfe deine Angaben und versuche es erneut.'});
    });
  }

  render() {
    if (this.state.redirectTo) return <Navigate to={this.state.redirectTo} />
    return (
      <div className="LoginForm" data-testid="LoginForm">
        {this.state.mode === 'login' && (
          <>
            <TextField name="email" size='small' label="Email oder Benutzername" variant="outlined" type="text" sx={{width: '100%'}} onChange={this.handleChange} onKeyDown={this.handleKeyDown} error={this.state.errorType === 'emailInvalid'} />
            <TextField name="password" size='small' label="Passwort" variant="outlined" type="password" sx={{width: '100%'}} onChange={this.handleChange} onKeyDown={this.handleKeyDown} error={this.state.errorType === 'passwordInvalid'} />
            <Button variant="contained" sx={{width: '100%'}} onClick={this.handleLogin}>Anmelden</Button>
          </>
        )}
        {this.state.mode === 'forgotpw' && !this.state.success && (
          <>
            <TextField name="emailFP" value={this.state.emailFP} label="Email" variant="outlined" type="text" sx={{width: '100%'}} onChange={this.handleChange} onKeyDown={this.handleKeyDown} error={this.state.errorType === 'emailInvalid'} />
            <Button disabled={this.state.disableButtons} variant="contained" sx={{width: '100%'}} onClick={this.handleForgotPassword}>{this.state.passwordChange ? "Passwort zurücksetzen":"Erstelle ein neues Passwort" }</Button>
          </>
        )}
        {this.state.mode === 'pass-reset' && !this.state.success && (
          <>
            <TextField name="newPassword" value={this.state.newPassword} label="Neues Passwort" variant="outlined" type="password" sx={{width: '100%'}} onChange={this.handleChange} onKeyDown={this.handleKeyDown} error={this.state.errorType === 'newPasswordInvalid'} />
            <TextField name="newPasswordConfirm" value={this.state.newPasswordConfirm} label="Neues Passwort wiederholen" variant="outlined" type="password" sx={{width: '100%'}} onChange={this.handleChange} onKeyDown={this.handleKeyDown} error={this.state.errorType === 'newPasswordConfirmInvalid'} />
            <Button variant="contained" sx={{width: '100%'}} onClick={this.handlePassReset}>Passwort ändern</Button>
          </>
        )}
        {this.state.success && !this.state.error && (
          <div>
            <p className='text-center success' style={{marginTop: 15, display: 'block'}}>{this.state.success}</p>
            {this.state.mode === 'forgotpw' && (
              <>
                <Button variant='contained' fullWidth onClick={() => this.setState({...defaultState})}>Zum Login</Button>
                <Button variant='outlined' sx={{marginTop: '15px'}} fullWidth onClick={() => this.setState({success: defaultState.success})}>Zurück</Button>
              </>
            )}
            {this.state.mode === 'pass-reset' && (
              <>
                <Button variant='contained' fullWidth onClick={() => this.setState({...defaultState})}>Zum Login</Button>
              </>
            )}
          </div>
        )}
        {this.state.error && (
          <p className='text-center error' style={{marginTop: 15, display: 'block'}}>{this.state.error}</p>
        )}
        {this.state.mode === 'login' && <div className='switcher' onClick={() => this.setState({mode: 'forgotpw', error: '', errorType: '',passwordChange:true})}>Passwort vergessen?</div>}
        {this.state.mode === 'forgotpw' && !this.state.success && <div className='switcher' onClick={() => this.setState({mode: 'login', error: '', errorType: ''})}>Zum Login</div>}
        {this.state.mode === 'pass-reset' && !this.state.success && <div className='switcher' onClick={() => {this.setState({mode: 'login', error: '', errorType: ''});window.history.replaceState(null, "KSM Recycling ERP", "/")}}>Zum Login</div>}
      </div>
    )
  }
};

export default LoginForm;
