import React, { FC } from 'react';
import './ErrorSnackbar.sass';
import {NotiStore} from '../../../stores/notifications';
import { Alert, Snackbar } from '@mui/material';

interface ErrorSnackbarProps {}

const ErrorSnackbar: FC<ErrorSnackbarProps> = () => {
  const error = NotiStore((state:any) => state.error);
  const errorMessage = NotiStore((state:any) => state.errorMessage);
  
  if (error || errorMessage) return (
    <Snackbar
      open={error || errorMessage}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={5000}
      onClose={() => NotiStore.setState({error: false, errorMessage: ''})}
    >
        <Alert severity='error' onClose={() => NotiStore.setState({error: false, errorMessage: ''})}>{errorMessage || 'Ein Fehler ist aufgetreten.'}</Alert>
    </Snackbar>
  );

  return null;
};

export default ErrorSnackbar;
