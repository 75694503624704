// TODO: possibility to make trucks without dedicated goods

import { Table, TableHead, TableBody, TableRow, TableCell, Modal, Box, TextField, Button, Tabs, Tab } from '@mui/material';
import React from 'react';
import { fetchInvetory, Good } from '../../../services/inventoryService';
import { resetNotifications } from '../../../services/notiStoreService';
import { isValidInventory } from '../../../services/productionService';
import { convertDateString, convertOrderAttributes, localNumber } from '../../../services/stringConverter';
import { TruckData } from '../../../services/warehouseEntriesService';
import { ExitOrder, fetchExitOrders, OnTruckData, submitExitTruck, SubmitExitTruckData } from '../../../services/warehouseExitService';
import { NotiStore } from '../../../stores/notifications';
import './ProcessExit.sass';
import ExitIcon from '../../../assets/icons/ausgang.png';
import { fetchWarehouseData, WarehouseSpot } from '../../../services/configService';
import { BorderColorOutlined, CheckCircleOutline, Close } from '@mui/icons-material';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'scroll',
  overflowX: 'hidden'
};

interface ProcessExitProps {}
interface ProcessExitState {
  orders: ExitOrder[];
  trucks: TableData[];
  stores: WarehouseSpot[];
  goods: Good[];
  showExitPop: boolean;
  exitPopMode: string;
  activeTruck: TableData;
  onTruckData: OnTruckData[];
  scanVal: string;
  scanValManual: string;
  showScanInput: boolean;
  tab: number;
  plateFilter: string;
  edit:boolean,
  dispatchDate:string
}

interface TableData extends TruckData {
  orderId: number;
  goods?: string[];
  plate?: string;
  orderCustomId?: string;
  orderCustomer?: string;
  orderCategory?: string;
  posId: number;
  posName: string;
  posColor: string;
  posFoodSafe: string;
  posMaterial: string;
  posPackaging: string;
  truckId: number;
  truckQuantity: string | null;
  dispatchDate: Date | null;
  storageTotal: number;
  creator: string;
  fulfillData?: {id: string, amount: number}[];
  orderDocuments: {attributes: {url: string; name: string;}}[];
}

const defaultState: ProcessExitState = {
  orders: [],
  trucks: [],
  stores: [],
  goods: [],
  showExitPop: false,
  exitPopMode: '',
  activeTruck: {
    truckPlate: '',
    orderId: -1,
    orderCustomId: '',
    orderCustomer: '',
    orderCategory: '',
    dispatchDate: new Date(Date.now()),
    posId: -1,
    posName: '',
    posColor: '',
    posFoodSafe: '',
    posMaterial: '',
    posPackaging: '',
    truckId: -1,
    truckQuantity: null,
    goods: [],
    storageTotal: 0,
    notes: '',
    creator: '',
    orderDocuments: [],
  },
  onTruckData: [],
  scanVal: '',
  scanValManual: '',
  showScanInput: false,
  tab: 0,
  plateFilter: '',
  edit:false,
  dispatchDate:''
}

class ProcessExit extends React.Component<ProcessExitProps, ProcessExitState> {
  state = {...defaultState}

  componentDidMount() {
    resetNotifications();
    this.fetchData();

    window.addEventListener("keydown", this.onScan, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onScan, false);
  }

  handleTruckDatepdate = (id:number) =>{
    console.log(id,"eventevent")
    // saveUpdateDateOrder({dispatchDate:this.state.dispatchDate},id)
  }
  fetchData = (): void => {
    NotiStore.setState({loading: true});

    let orders = fetchExitOrders('filters[isCancelled][$eq]=false');
    let stores = fetchWarehouseData();
    let goods = fetchInvetory('filters[status][0]=stored&filters[status][1]=sold');

    Promise.all([orders, stores, goods])
    .then(res => {
      try {
        let storageSpots = res[1].spots

        let trucksArr: TableData[] = []

        let orders = res[0].data;

        if (orders && orders?.length >= 1) {
          orders.forEach(order => {
            let {positions} = order.attributes;

            if (positions && positions.length >= 1) {
              positions.forEach((pos, i) => {
                let {trucks} = pos;

                if (trucks && trucks.length >= 1) {
                  trucks.forEach((truck, j) => {
                    if (truck.plate && truck.dispatchDate) trucksArr.push({
                      ...truck,
                      posId: i,
                      posName: pos.name,
                      posColor: pos.color,
                      posFoodSafe: pos.foodSafe,
                      posMaterial: pos.material,
                      posPackaging: pos.packaging,
                      truckId: j,
                      orderId: order.id || -1,
                      orderCustomId: order.attributes.customId || '',
                      truckQuantity: null,
                      orderCustomer: order.attributes.customer,
                      orderCategory: order.attributes.category || '',
                      creator: order?.attributes?.creator?.data?.attributes?.name || '',
                      orderDocuments: order.attributes.documents.data && order.attributes.documents.data.length >= 1 ? order.attributes.documents.data : [],
                    })
                  })
                }
              })
            }
          })
        }

        trucksArr = trucksArr.sort((a, b) => {
          let aDate = a.dispatchDate!;
          let bDate = b.dispatchDate!;
          if (aDate < bDate) return -1
          else return 1
        })

        this.setState({orders, trucks: trucksArr, goods: res[2].data, stores: storageSpots});  
      } catch(e) {
        console.log(e)
      }
    })
    .catch(err => {
      NotiStore.setState({error: true, errorMessage: 'Fehler beim Abrufen der Daten aufgetreten.'})
    })
    .finally(() => {
      NotiStore.setState({loading: false})
    })
  }

  getTruckSum = (t: TableData) => {
    let amount = 0;

    if (t.storageTotal) {
      amount += t.storageTotal
    } else {
      t.goods && t.goods.length >= 1 && t.goods.forEach(good => {
        let match = this.state.goods.filter(g => g.attributes.customId === good);
        if (match.length === 1) amount += match[0].attributes.quantity
      })
    }

    return localNumber(amount)
  }

  openExit = (t: TableData): void => {
    if (t.fulfilled) {
      this.setState({exitPopMode: 'view'})
    } else {
      this.setState({exitPopMode: 'fulfill'})
    }

    this.setState({activeTruck: t, showExitPop: true});
  }

  getGoodData = (customId: string): {type: string, quantity: number, storage: string} => {
    let {goods} = this.state;

    let data = {
      type: '???',
      quantity: 0,
      storage: '???'
    }

    let match = goods.filter(g => g.attributes.customId.toLowerCase() === customId.toLowerCase());

    if (match.length === 1) {
      data.type = match[0].attributes.typeIntern ? match[0].attributes.typeIntern : '???';
      data.storage = match[0].attributes.warehouse_spot.data && match[0].attributes.warehouse_spot.data.attributes.name ? match[0].attributes.warehouse_spot.data.attributes.name : '???';
      data.quantity = match[0].attributes.quantity ? match[0].attributes.quantity : 0;
    }

    return data
  }

  onScan = (e: any): void => {
    if (this.state.activeTruck.orderId) {
      let {scanVal} = this.state;

      if (e.key && e.key.toLowerCase() !== 'enter' && e.key.length === 1) {
        scanVal += e.key;
        this.setState({scanVal})
      } else if (e.key && e.key.toLowerCase() === 'enter') {
        NotiStore.setState({loading: true})

        if (this.state.showScanInput) scanVal = this.state.scanValManual

        if (scanVal.includes('#')) scanVal = scanVal.replace('#', '')

        isValidInventory(scanVal)
        .then((res) => {
          if (!res.success || !res.data.id) {
            this.setState({scanVal: defaultState.scanVal});
            NotiStore.setState({loading: false, error: true, errorMessage: 'Fehler bei der Produktabfrage aufgetreten.'})
          } else if (this.state.onTruckData.filter((g) => g.id === res.data.attributes.customId).length >= 1) {
            this.setState({scanVal: defaultState.scanVal});
            NotiStore.setState({loading: false, error: true, errorMessage: 'Das Produkt befindet sich bereits in der Liste.'})
          } else {
            this.setState(prevState => ({scanVal: defaultState.scanVal, scanValManual: defaultState.scanValManual, onTruckData: [...prevState.onTruckData, {id: res.data.attributes.customId, amount: res.data.attributes.quantity}], showScanInput: false}));
            NotiStore.setState({loading: false});
          }
        })
        .catch(err => {
          this.setState({scanVal: defaultState.scanVal});
          NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Fehler bei der Produktabfrage aufgetreten.'})
        })
      }
    }
  }

  removeScannedGood = (i: number): void => {
    let {onTruckData} = this.state;

    if (typeof onTruckData[i] !== 'undefined') {
      onTruckData.splice(i, 1)
      this.setState({onTruckData})
    }
  }

  onCloseExitPop = (): void => {
    this.setState({
      showExitPop: false,
      showScanInput: false,
      onTruckData: [],
      scanVal: '',
    })
  }

  onTruckSubmit = (): void => {
    resetNotifications()
    NotiStore.setState({loading: true})

    let {onTruckData, activeTruck} = this.state;
    let orderId = activeTruck.orderId;
    let {posId, truckId} = activeTruck;

    let data: SubmitExitTruckData = {
      orderId,
      posId,
      truckId,
      onTruckData,
    }

    if (window.confirm('Bist du sicher, dass der LKW richtig und vollständig beladen ist?')) {
      submitExitTruck(data)
      .then(res => {
        NotiStore.setState({loading: false, success: true, successMessage: 'LKW erfolgreich abgefertigt.'});
        this.setState({showExitPop: false, showScanInput: false, scanVal: '', exitPopMode: defaultState.exitPopMode, activeTruck: defaultState.activeTruck, onTruckData: defaultState.onTruckData});
        this.fetchData();
      })
      .catch(err => {
        NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Fehler beim Aktualisieren der Daten aufgetreten.'})
      })
    } else {
      NotiStore.setState({loading: false})
    }
  }

  getStoreName = (id: number | string): string => {
    let {stores} = this.state
    let matches = stores.filter(store => store.id.toString() === id.toString())
    let result = ''

    if (matches.length === 1) result = matches[0].attributes.name

    return result
  }

  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({tab: newValue});
  };
  
  render() {
    return (
      <div className="ProcessExit content" data-testid="ProcessExit">
        <div className="header-bar">
          <h1>Warenausgang</h1>
          <img src={ExitIcon} alt="icon" />
        </div>

        <div style={{marginBottom: 30}}>
          <Box>
            <Tabs
              value={this.state.tab}
              onChange={this.handleTabChange}
              variant="scrollable"
              scrollButtons={false}
              allowScrollButtonsMobile={false}
            >
              <Tab label="Offene Aufträge" sx={{width: '400px'}} />
              <Tab label="Abgeschlossene Aufträge" sx={{width: '400px'}} />
            </Tabs>
          </Box>
        </div>

        <div style={{marginBottom: 15}}>
          <TextField
            fullWidth
            onChange={(e) => this.setState({plateFilter: e.target.value})}
            value={this.state.plateFilter || ''}
            placeholder='Kennzeichen filtern'
          />
        </div>

        {this.state.tab === 0 ? <div className='trucks-window open-trucks'>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><span className='strong'>Kennzeichen</span></TableCell>
                <TableCell><span className='strong'>Abholdatum</span></TableCell>
                <TableCell><span className='strong'>ID Auftrag</span></TableCell>
                <TableCell><span className='strong'>Bezeichnung</span></TableCell>
                <TableCell><span className='strong'>Farbe</span></TableCell>
                <TableCell><span className='strong'>Versandmenge</span></TableCell>
                <TableCell><span className='strong'>Lagerplatz</span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>  
              {this.state.trucks.length >= 1 && this.state.trucks.filter((truck: TableData) => {
                if (!this.state.plateFilter) return true
                else if (truck.plate && truck.plate.toLowerCase().includes(this.state.plateFilter.toLowerCase())) return true
                else return false
              }).map((t: TableData, i: number) => (
                !t.fulfilled ? (
                  <TableRow onClick={() => this.openExit(t)} sx={{cursor: 'pointer'}} key={`unfulfilled-${i}`}>
                    <TableCell>{t.plate || ''}</TableCell>
                    <TableCell>{convertDateString((t.dispatchDate || '').toString() || '')}</TableCell>
                    <TableCell>{t.orderCustomId || ''}</TableCell>
                    <TableCell>{t.posName || ''}</TableCell>
                    <TableCell>{convertOrderAttributes(t.posColor || '')}</TableCell>
                    <TableCell>{this.getTruckSum(t) + ' kg'}</TableCell>
                    <TableCell>{t.storagePlace ? this.getStoreName(t.storagePlace) : ''}</TableCell>
                  </TableRow>
                ) : null
              ))}
            </TableBody>
          </Table>
        </div> : null}

        {this.state.tab === 1 ? <div className='trucks-window fulfilled-trucks'>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><span className='strong'>Kennzeichen</span></TableCell>
                <TableCell><span className='strong'>Abholdatum</span></TableCell>
                <TableCell><span className='strong'>ID Auftrag</span></TableCell>
                <TableCell><span className='strong'>Bezeichnung</span></TableCell>
                <TableCell><span className='strong'>Farbe</span></TableCell>
                <TableCell><span className='strong'>Versandmenge</span></TableCell>
                <TableCell><span className='strong'>&nbsp;</span></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>  
              {this.state.trucks.length >= 1 && this.state.trucks.filter((truck: TableData) => {
                if (!this.state.plateFilter) return true
                else if (truck.plate && truck.plate.toLowerCase().includes(this.state.plateFilter.toLowerCase())) return true
                else return false
              }).map((t: TableData, i: number) => (
                t.fulfilled ? (
                  <TableRow sx={{cursor: 'pointer'}} key={`unfulfilled-${i}`}>
                    <TableCell onClick={() => this.openExit(t)}>{t.plate || ''}</TableCell>
                    <TableCell onClick={() => this.openExit(t)}>{convertDateString((t.dispatchDate || '').toString() || '')}</TableCell>
                    <TableCell onClick={() => this.openExit(t)}>{t.orderCustomId || ''}</TableCell><TableCell>{t.posName || ''}</TableCell>
                    <TableCell onClick={() => this.openExit(t)}>{convertOrderAttributes(t.posColor || '')}</TableCell>
                    <TableCell onClick={() => this.openExit(t)}>{this.getTruckSum(t) + ' kg'}</TableCell>
                    <TableCell align='right'>
                      {t.orderDocuments.length >= 1 && t.orderDocuments.map((doc, i) => (
                        <React.Fragment key={`${doc.attributes.name}-${i}`}>
                          {t.plate && doc.attributes.name.includes(t.plate.replace(' ', '-')) && (
                            <>
                              {doc.attributes.name.includes('lieferschein') && <a className='letter-icon' style={{marginRight: 10}} href={doc.attributes.url} target='_blank' rel='noreferrer'>LS</a>}
                              {doc.attributes.name.includes('packliste') && <a className='letter-icon' href={doc.attributes.url} target='_blank' rel='noreferrer'>PL</a>}
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </TableCell>
                  </TableRow>
                ) : null
              ))}
            </TableBody>
          </Table>
        </div> : null}

        <Modal
          open={this.state.showExitPop}
          onClose={this.onCloseExitPop}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className='active-exit-modal'
        >
          <>
            <span className='modal-close pointer' onClick={this.onCloseExitPop}><Close /></span>
            <Box sx={modalStyle}>
              <div className='modal-close-btn' onClick={this.onCloseExitPop}><Close /></div>
              <h2>LKW {this.state.activeTruck ? this.state.activeTruck.plate : '???'} im Überblick</h2>
              <h3>Allgemein</h3>
              <div className="flex of-5 spacing-1">
                <TextField
                  helperText={'ID - Auftrag'}
                  value={this.state.activeTruck.orderCustomId || 'nicht gefunden'}
                  disabled
                />
                <TextField
                  helperText={'Erstellt von'}
                  value={this.state.activeTruck.creator}
                  disabled
                />
                <TextField
                  helperText={'Kunde'}
                  value={this.state.activeTruck.orderCustomer || 'nicht gefunden'}
                  disabled
                />
                <TextField
                  helperText={'Kategorie'}
                  value={convertOrderAttributes(this.state.activeTruck.orderCategory || 'n/a')}
                  disabled
                />
                
              </div>
              <div style={{paddingTop:"40px"}}>
                <TextField
                  helperText={'Abholdatum'}
                  defaultValue={convertDateString((this.state.activeTruck.dispatchDate || '').toString() || 'nicht gefunden')}
                  disabled={!this.state.edit}
                  onChange={(e)=>this.setState({dispatchDate:e.target.value})}
                />&nbsp;
                {this.state.edit ? <Button onClick={()=>this.handleTruckDatepdate(this.state.activeTruck.orderId)} style={{height:"40px"}} variant='contained'><CheckCircleOutline /></Button>:<Button style={{height:"40px"}} onClick={()=>this.setState({edit:true})} variant='contained'><BorderColorOutlined/></Button>}
              </div>
              <hr />
              <h3>Auftrag</h3>
              <div className="flex of-3 spacing-1">
                <TextField
                  helperText={'Bezeichnung'}
                  value={this.state.activeTruck.posName}
                  disabled
                />
                <TextField
                  helperText={'Farbe'}
                  value={convertOrderAttributes(this.state.activeTruck.posColor || '')}
                  disabled
                />
                <TextField
                  helperText={'Lebensmittelecht?'}
                  value={convertOrderAttributes(this.state.activeTruck.posFoodSafe || '')}
                  disabled
                />
                <TextField
                  helperText={'Lebensmittelecht?'}
                  value={convertOrderAttributes(this.state.activeTruck.posMaterial || '')}
                  disabled
                />
                <TextField
                  helperText={'Menge'}
                  value={this.state.activeTruck.storageTotal ? this.state.activeTruck.storageTotal + ' kg' : this.getTruckSum(this.state.activeTruck) + ' kg'}
                  disabled
                />
                <TextField
                  helperText={'Verpackung'}
                  value={convertOrderAttributes(this.state.activeTruck.posPackaging || 'n/a')}
                  disabled
                />
              </div>
              <hr />
              <h3 style={{marginBottom: 0}}>Ware zu verladen</h3>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Bezeichnung</TableCell>
                    <TableCell>Menge</TableCell>
                    <TableCell>Lagerplatz</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.activeTruck.goods && this.state.activeTruck.goods.length >= 1 && this.state.activeTruck.goods.map((good, i) => (
                    <TableRow key={'goodtruck-'+good+i}>
                      <TableCell>{good}</TableCell>
                      <TableCell>{this.getGoodData(good).type}</TableCell>
                      <TableCell>{localNumber(this.getGoodData(good).quantity) + ' kg'}</TableCell>
                      <TableCell>{this.getGoodData(good).storage}</TableCell>
                    </TableRow>
                  ))}
                  {this.state.activeTruck.goods?.length === 0 && this.state.activeTruck.storagePlace && (
                    <TableRow>
                      <TableCell>-</TableCell>
                      <TableCell>{this.state.activeTruck.posName}</TableCell>
                      <TableCell>{this.state.activeTruck.storageTotal} kg</TableCell>
                      <TableCell>{this.getStoreName(this.state.activeTruck.storagePlace)}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {this.state.activeTruck.goods && this.state.activeTruck.goods.length < 1 && !this.state.activeTruck.storagePlace && (
                <p className='text-center'>Keine Waren zugewiesen.</p>
              )}
              <hr />
              <div className="header-bar" style={{margin: '30px 0 0'}}>
                <h3 style={{marginBottom: 0}}>Verladene Ware</h3>
                {this.state.exitPopMode === 'view' ? null : <span style={{textDecoration: 'underline'}} className='pointer' onClick={() => this.setState({showScanInput: !this.state.showScanInput})}>{this.state.showScanInput ? 'manuelle Eingabe ausblenden' : 'manuelle Eingabe'}</span>}
              </div>
              {this.state.exitPopMode === 'view' ? null : <p>Bitte zuerst die Ware verladen, QR Code erst auf dem LKW scannen.</p>}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Bezeichnung</TableCell>
                    <TableCell>Menge</TableCell>
                    {this.state.exitPopMode === 'view' ? null : <TableCell>Lagerplatz</TableCell>}
                    {this.state.exitPopMode === 'view' ? null : <TableCell>&nbsp;</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.exitPopMode === 'view' ? (
                    <>
                      {this.state.activeTruck.fulfillData && this.state.activeTruck.fulfillData.length >= 1 && this.state.activeTruck.fulfillData.map((data, i) => (
                        <TableRow key={'goodtruck-'+i}>
                          <TableCell>{data.id}</TableCell>
                          {/* TODO: store type in fulfillData and do not filter goods on sold status (too much results one day) */}
                          <TableCell>{this.getGoodData(data.id).type}</TableCell>
                          <TableCell>{data.amount} kg</TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <>
                      {this.state.onTruckData && this.state.onTruckData.length >= 1 && this.state.onTruckData.map((good, i) => (
                        <TableRow key={'goodtruck-'+good.id+i}>
                          <TableCell>{good.id}</TableCell>
                          <TableCell>{this.getGoodData(good.id).type}</TableCell>
                          <TableCell>{localNumber(this.getGoodData(good.id).quantity) + ' kg'}</TableCell>
                          <TableCell>{this.getGoodData(good.id).storage}</TableCell>
                          <TableCell><span className='pointer red' onClick={() => this.removeScannedGood(i)}>stornieren</span></TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
              {this.state.exitPopMode === 'view' ? null : (
                <>
                  {this.state.onTruckData.length < 1 && (
                    <div className='text-center' style={{marginTop: 20}}>Bitte scanne ein Produkt.</div>
                  )}
                  <div style={{visibility: 'hidden', height: '0px', marginTop: 20}}>
                    <TextField
                      name="scan"
                      value={this.state.scanVal}
                      fullWidth
                    />
                  </div>
                  <div style={{visibility: this.state.showScanInput ? 'initial' : 'hidden', height: this.state.showScanInput ? 'auto' : '0px', marginTop: 20, marginBottom: 20}}>
                    <TextField
                      name="scan"
                      value={this.state.scanValManual}
                      helperText="ID - Produkt (Bestätigen mit 'Enter')"
                      fullWidth
                      onChange={e => this.setState({scanValManual: e.target.value})}
                    />
                  </div>
                </>
              )}
              {this.state.exitPopMode === 'view' ? (
                <div className='flex of-2 spacing-1' style={{marginTop: 50}}>
                  {this.state.activeTruck.orderDocuments.length >= 1 && this.state.activeTruck.orderDocuments.map((doc, i) => (
                    <React.Fragment key={`${doc.attributes.name}-${i}`}>
                      {this.state.activeTruck.plate && doc.attributes.name.includes(this.state.activeTruck.plate.replace(' ', '-')) && (
                        <>
                          {doc.attributes.name.includes('lieferschein') && <div><a href={doc.attributes.url} target='_blank' rel='noreferrer'><Button variant='contained' fullWidth>Lieferschein</Button></a></div>}
                          {doc.attributes.name.includes('packliste') && <div><a href={doc.attributes.url} target='_blank' rel='noreferrer'><Button variant='contained' fullWidth>Packliste</Button></a></div>}
                        </>
                      )}
                    </React.Fragment >
                  ))}
                </div>
              ) : (
                <div className="flex space-between">
                  <Button variant='outlined' onClick={this.onCloseExitPop}>Abbrechen</Button>
                  <Button variant='contained' onClick={this.onTruckSubmit}>LKW bestätigen</Button>
                </div>
              )}
            </Box>
          </>
        </Modal>
      </div>
    )
  }
}

export default ProcessExit;
