import React from 'react';
import './SystemUsers.sass';
import { Box, Modal, Button, Table, TableHead, TableRow, TableContainer, TableCell, TableBody, Paper,
  FormControl, InputLabel, OutlinedInput, FormControlLabel, Checkbox } from '@mui/material';
import { createUser, deleteUser, fetchUsers, updateUser, UserData, UserDataUpdate } from '../../../services/userService';
import { Close, DeleteForever, Edit } from '@mui/icons-material';
import { NotiStore } from '../../../stores/notifications';
import { resetNotifications } from '../../../services/notiStoreService';
import { userRights } from '../../../utils/defaults';

interface SystemUsersProps {}

interface SystemUsersState {
  users: UserData[];
  error: boolean;
  success: boolean;
  errorMessage: string;
  successMessage: string;
  activeEdit: boolean;
  activeEditUser: UserData;
  mode: string;
}

const tableHeaders = ['Name', 'Kürzel', 'Email'];

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
  maxHeight: '90vh'
};

class SystemUsers extends React.Component<SystemUsersProps, SystemUsersState> {
  state = {
    users: [], 
    error: false,
    success: false,
    errorMessage: '',
    successMessage: '',
    activeEdit: false,
    activeEditUser: {
      blocked: false,
      confirmed: false,
      email: '',
      username: '',
      name: '',
      id: 0,
      rights: [],
    },
    mode: 'edit'
  }

  componentDidMount() {
    this.getData();
  }

  getData = (): void => {
    NotiStore.setState({loading: true})

    fetchUsers()
    .then((res: {users: UserData[]}) => {
      this.setState({users: res.users});
    })
    .catch(() => {
      NotiStore.setState({error: true, errorMessage: 'Es konnten leider keine Benutzer gefunden werden.'});
    })
    .finally(() => {
      NotiStore.setState({loading: false})
    })
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let user = this.state.activeEditUser;
    let key: string = e.target.name;
    if (Object.keys(user).includes(key) && key !== 'id') {
      this.setState(prevState => ({
        activeEditUser: {
          ...prevState.activeEditUser,
          [key]: e.target.value
        }
      }));
    }
  }

  handleChangeRights = (val: string): void => {
    let {activeEditUser} = this.state

    // @ts-ignore
    if (!activeEditUser.rights.includes(val)) {
      // @ts-ignore
      activeEditUser.rights.push(val)
    } else {
      // @ts-ignore
      let index = activeEditUser.rights.indexOf(val)
      if (index > -1) activeEditUser.rights.splice(index, 1)
    }

    this.setState({activeEditUser})
  }

  activateEditMode = (id: number):void => {
    let user = this.state.users.find((u: UserData) => u.id === id);
    if (user) {
      // @ts-ignore
      if (!user.rights) user.rights = []
      this.setState({activeEdit: true, activeEditUser: user, mode: 'edit'});
    }
  }

  handleEdit = (e: React.FormEvent):void => {
    e.preventDefault();

    resetNotifications();

    let user = this.state.activeEditUser;

    if (user.name && user.username && user.id) {

      let data: UserDataUpdate = {
        name: user.name,
        username: user.username,
        email: user.email || null,
        rights: user.rights || [],
      };

      updateUser(user.id, data)
      .then(() => {
        this.setState({activeEdit: false});
        NotiStore.setState({success: true, successMessage: 'Benutzer erfolgreich aktualisiert.'});
        this.getData();
      })
      .catch(() => {
        NotiStore.setState({error: true, errorMessage: 'Es gab einen Fehler beim Aktualisieren des Nutzers.'});
      })
    } else {
      NotiStore.setState({error: true, errorMessage: 'Bitte fülle alle Felder aus.'});
    }
  }

  handleCreateUser = (e: React.FormEvent):void => {
    e.preventDefault();

    resetNotifications();

    let user = this.state.activeEditUser;

    if (user.name && user.username && user.email) {

      let data: UserDataUpdate = {
        name: user.name,
        username: user.username,
        email: user.email,
        rights: user.rights || [],
      };

      createUser(data)
      .then(() => {
        this.setState({activeEdit: false});
        NotiStore.setState({success: true, successMessage: 'Benutzer erfolgreich erstellt.', loading: false});
        this.getData();
      })
      .catch((err) => {
        let msg: string | null = null;

        if (err === 'Email already taken') {
          msg = 'Email wird bereits verwendet.'
        } else if (err === 'This attribute must be unique') {
          msg = 'Kürzel wird bereits verwendet.'
        }

        NotiStore.setState({error: true, errorMessage: msg ? msg : 'Es gab einen Fehler beim Erstellen des Nutzers.'});
      })
    } else {
      NotiStore.setState({error: true, errorMessage: 'Bitte fülle alle Felder aus.'});
    }
  }

  handleDelete = (id: number):void => {
    if (id > -1) {
      deleteUser(id)
      .then(() => {
        NotiStore.setState({success: true, successMessage: 'Benutzer erfolgreich gelöscht.'});
        this.getData();
      })
      .catch(() => {
        NotiStore.setState({error: true, errorMessage: 'Es gab einen Fehler beim Löschen des Nutzers.'});
      }) 
    } else {
      NotiStore.setState({error: true, errorMessage: 'Es gab einen Fehler beim Löschen des Nutzers.'});
    }
  }

  render() {
    return (
      <div className="SystemUsers content" data-testid="SystemUsers">
        <div className='header-bar'>
          <h1>Benutzer</h1>
          <Button 
            variant="contained" 
            onClick={() => this.setState({
              mode: 'create', 
              activeEdit: true,
              activeEditUser: {
                blocked: false,
                confirmed: false,
                email: '',
                username: '',
                name: '',
                id: 0,
                rights: [],
              }
            })}>
            Benutzer anlegen
          </Button>
        </div>
        <div className='table-container'>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((h, i) => (
                    <TableCell key={h+i}>{h}</TableCell>
                  ))}
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.users.length >= 1 && this.state.users.map((user: UserData) => (
                  <TableRow
                    key={user.username+user.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell><strong>{user.name}</strong></TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell align="right">
                      <Edit className='warning action' onClick={() => this.activateEditMode(user.id || -1)} style={{marginRight: 10}} />
                      <DeleteForever className='error action' onClick={() => {if (window.confirm("Benutzer sicher löschen?")) this.handleDelete(user.id || -1)}} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <Modal
          open={this.state.activeEdit}
          onClose={() => this.setState({activeEdit: false})}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
            <span className='modal-close pointer' onClick={() => this.setState({activeEdit: false})}><Close /></span>
            <Box sx={modalStyle}>
              <span className='modal-close pointer' onClick={() => this.setState({activeEdit: false})}><Close /></span>
              <div className='modal-close-btn' onClick={() => this.setState({activeEdit: false})}><Close /></div>
              <h2>{this.state.mode === 'create' ? 'Benutzer erstellen' : 'Benutzer bearbeiten'}</h2>
              <form onSubmit={this.state.mode === 'create' ? this.handleCreateUser : this.handleEdit}>
                <FormControl className='input' error={false} style={{display: 'none'}}>
                  <InputLabel htmlFor="id">User ID</InputLabel>
                  <OutlinedInput  required id='id' value={this.state.activeEditUser.id} name="id" label="User ID" type="number" onChange={this.handleChange}></OutlinedInput>
                </FormControl>
                <FormControl className='input' error={false} style={{marginBottom: 20}}>
                  <InputLabel htmlFor="name">Name</InputLabel>
                  <OutlinedInput disabled={this.state.mode === 'edit'} required id='name' error={this.state.activeEditUser.name ? false :true} value={this.state.activeEditUser.name} name="name" label="Name" type="text" onChange={this.handleChange}></OutlinedInput>
                </FormControl>
                <FormControl className='input' error={false} style={{marginBottom: 20}}>
                  <InputLabel htmlFor="username">Kürzel</InputLabel>
                  <OutlinedInput disabled={this.state.mode === 'edit'} required id='username' error={this.state.activeEditUser.username ? false :true} value={this.state.activeEditUser.username} name="username" label="Kürzel" type="text" onChange={this.handleChange}></OutlinedInput>
                </FormControl>
                <FormControl className='input' error={false} style={{marginBottom: 20}}>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <OutlinedInput disabled={this.state.mode === 'edit'} id='email' value={this.state.activeEditUser.email} error={this.state.activeEditUser.email ? false :true} name="email" label="Email" type="email" onChange={this.handleChange}></OutlinedInput>
                </FormControl>
                <p><strong>Berechtigungen</strong></p>
                <div style={{marginBottom: 30}} className='flex of-2 spacing-1'>
                  {/* @ts-ignore */}
                  {userRights.map(el => <div key={el.value}><FormControlLabel control={<Checkbox checked={this.state.activeEditUser.rights && this.state.activeEditUser.rights.includes(el.value)} onChange={() => this.handleChangeRights(el.value)} />} label={el.label} /></div>)}
                </div>
                <Button variant='contained' type="submit">Benutzer aktualisieren</Button>
              </form>
            </Box>
          </>
        </Modal>
      </div>
    )
  }
};

export default SystemUsers;
