import ProcessExit from '../components/Processes/ProcessExit/ProcessExit';
import Sidebar from '../components/Sidebar/Sidebar';
import TopNav from '../components/TopNav/TopNav';
import { UserStore } from '../stores/user';

export default function ProcessExitRoute() {
  const user = UserStore((state: any) => state.user);

  return (
    <main className='Process container'>
      <Sidebar user={user} />
      <TopNav />
      <ProcessExit />
    </main>
  )
}