import Sidebar from '../components/Sidebar/Sidebar';
import TopNav from '../components/TopNav/TopNav';
import WarehouseEntry from '../components/Warehouse/WarehouseEntry/WarehouseEntry';

export default function WarehouseEntryRoute() {
  return (
    <main className='Warehouse container'>
      <Sidebar />
      <TopNav />
      <WarehouseEntry />
    </main>
  )
}