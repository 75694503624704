import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { resetNotifications } from '../../../services/notiStoreService';
import { NotiStore } from '../../../stores/notifications';
import { withRouter } from '../../utils/withRouter/withRouter';
import OrgEmails from '../OrgEmails/OrgEmails';
import ProductionSettings from '../ProductionSettings/ProductionSettings';
import ToleranceValues from '../ToleranceValues/ToleranceValues';
import WarehouseSettings from '../WarehouseSettings/WarehouseSettings';
import './SystemStandardValues.sass';

interface SystemStandardValuesProps {
  rights: string[]
}

interface SystemStandardValuesState {
  value: number;
  loading: boolean;
  success: boolean;
  successMessage: string;
  error: string;
  errorType: string;
  setLocation: boolean;
}

interface tabStylesIF {
  width: string;
}

const tabStyles: tabStylesIF = {
  width: '25%',
}

class SystemStandardValues extends React.Component<SystemStandardValuesProps, SystemStandardValuesState> {
  state: SystemStandardValuesState = {
    value: 0,
    loading: false,
    success: false,
    successMessage: '',
    error: '',
    errorType: '',
    setLocation: false,
  }

  constructor(props:SystemStandardValuesProps) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
    this.clearNotifications = this.clearNotifications.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  componentDidMount() {
    if (window.location.search.includes('seite=emails')) {
      this.setState({value: 0})
    } else if (window.location.search.includes('seite=lager')) {
      this.setState({value: 1})
    } else if (window.location.search.includes('seite=labor')) {
      this.setState({value: 2})
    } else if (window.location.search.includes('seite=produktion')) {
      this.setState({value: 3})
    }
  }

  clearNotifications = (): void => {
    resetNotifications()
  }

  handleError = (errorMessage: string, errorType: string): void => {
    NotiStore.setState({error: true, errorMessage: errorMessage || 'Fehler', success: false, successMessage: ''})
  }

  handleSuccess = (status: boolean): void => {
    NotiStore.setState({success: status, successMessage: 'Erfolgreich.', error: false, errorMessage: ''})
  }

  handleLoading = (status: boolean): void => {
    NotiStore.setState({loading: status});
  }

  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({value: newValue, setLocation: true});
  };

  render() {
    return (
      <div className="SystemStandardValues content" data-testid="SystemStandardValues">
        <h1>Einstellungen</h1>
        <Box>
          <Tabs
            value={this.state.value}
            onChange={this.handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            allowScrollButtonsMobile={false}
          >
            {this.props.rights.includes('system::emails') && <Tab value={0} label="Emails" sx={tabStyles} />}
            {this.props.rights.includes('system::warehouse') && <Tab value={1} label="Lager" sx={tabStyles} />}
            {this.props.rights.includes('system::lab') && <Tab value={2} label="Labor" sx={tabStyles} />}
            {this.props.rights.includes('system::production') && <Tab value={3} label="Produktion" sx={tabStyles} />}
          </Tabs>
        </Box>
        <div style={{marginTop: '40px'}}>

          {this.state.value === 0 && this.props.rights.includes('system::emails') && (
            <OrgEmails setLocation={this.state.setLocation} />
          )}

          {this.state.value === 1 && this.props.rights.includes('system::warehouse') && (
            <WarehouseSettings 
              handleError={this.handleError} 
              handleLoading={this.handleLoading} 
              clearNotifications={this.clearNotifications} 
              handleSuccess={this.handleSuccess} 
            />
          )}

          {this.state.value === 2 && this.props.rights.includes('system::lab') && (
            <ToleranceValues />
          )}

          {this.state.value === 3 && this.props.rights.includes('system::production') && (
            <div>
              <ProductionSettings />
            </div>
          )}
        </div>
      </div>
    )
  }
};

export default withRouter(SystemStandardValues);
