import { Start } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import React from 'react';
import { convertGoodsCategory, convertOrderAttributes } from '../../../services/stringConverter';
import { foodSafeOptions, materials, productCategories, rpetColors } from '../../../utils/defaults';
import './FilterPop.sass';

interface FilterPopProps {
  submitFilters: () => void;
  filters: {[key: string]: string[]};
  type: string;
  active: boolean;
  closePop: () => void;
  updateFilter: (e: React.ChangeEvent<HTMLInputElement>, key: string, multiple?: string) => void;
}

interface FilterPopState {
  customId: string[];
}

const defaultState: FilterPopState = {
  customId: [],
}

class FilterPop extends React.Component<FilterPopProps, FilterPopState> {
  state = {...defaultState}

  submitFilter = (): void => {
    this.props.submitFilters()
    this.props.closePop()
  }

  render() {
    if (this.props.active) {
      if (this.props.type === 'customId') return (
        <div className="FilterPop" data-testid="FilterPop">
          <div className="flex nowrap">
            <TextField
              size='small'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'customId')}
              sx={{minWidth: '200px'}}
              label='Suchbegriff'
              value={this.props.filters.customId && this.props.filters.customId[0] ? this.props.filters.customId[0] : ''}
            />
            <Button sx={{marginLeft: '10px'}} variant='contained' onClick={this.submitFilter}>
              <Start />
            </Button>
          </div>
        </div>
      )

      if (this.props.type === 'batchId') return (
        <div className="FilterPop" data-testid="FilterPop">
          <div className="flex nowrap">
            <TextField
              size='small'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'batchId')}
              sx={{minWidth: '200px'}}
              label='Suchbegriff'
              value={this.props.filters.batchId && this.props.filters.batchId[0] ? this.props.filters.batchId[0] : ''}
            />
            <Button sx={{marginLeft: '10px'}} variant='contained' onClick={this.submitFilter}>
              <Start />
            </Button>
          </div>
        </div>
      )

      else if (this.props.type === 'tolerance') return (
        <div className="FilterPop" data-testid="FilterPop">
          <div className="flex nowrap">
            <div>
              <FormGroup>
                <FormControlLabel control={<Checkbox value='high' checked={this.props.filters.tolerance && this.props.filters.tolerance.indexOf('high') > -1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'tolerance', 'multiple')} />} label="Rot" />
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={<Checkbox value='medium' checked={this.props.filters.tolerance && this.props.filters.tolerance.indexOf('medium') > -1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'tolerance', 'multiple')} />} label="Orange" />
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={<Checkbox value='low' checked={this.props.filters.tolerance && this.props.filters.tolerance.indexOf('low') > -1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'tolerance', 'multiple')} />} label="Grün" />
              </FormGroup>
            </div>

            <Button sx={{marginLeft: '10px'}} variant='contained' onClick={this.submitFilter}>
              <Start />
            </Button>
          </div>
        </div>
      )

      else if (this.props.type === 'orderStatus') return (
        <div className="FilterPop" data-testid="FilterPop">
          <div className="flex nowrap">
            <div>
              <FormGroup>
                <FormControlLabel control={<Checkbox value='green' checked={this.props.filters.orderStatus && this.props.filters.orderStatus.indexOf('green') > -1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'orderStatus', 'multiple')} />} label="abgeschlossen" />
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={<Checkbox value='orange' checked={this.props.filters.orderStatus && this.props.filters.orderStatus.indexOf('orange') > -1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'orderStatus', 'multiple')} />} label="teilw. angeliefert" />
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={<Checkbox value='red' checked={this.props.filters.orderStatus && this.props.filters.orderStatus.indexOf('red') > -1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'orderStatus', 'multiple')} />} label="storniert" />
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={<Checkbox value='gray' checked={this.props.filters.orderStatus && this.props.filters.orderStatus.indexOf('gray') > -1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'orderStatus', 'multiple')} />} label="offen" />
              </FormGroup>
            </div>

            <Button sx={{marginLeft: '10px'}} variant='contained' onClick={this.submitFilter}>
              <Start />
            </Button>
          </div>
        </div>
      )

      else if (this.props.type === 'typeIntern') return (
        <div className="FilterPop" data-testid="FilterPop">
          <div className="flex nowrap">
            <TextField
              size='small'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'typeIntern')}
              sx={{minWidth: '200px'}}
              label='Suchbegriff'
              value={this.props.filters.typeIntern && this.props.filters.typeIntern[0] ? this.props.filters.typeIntern[0] : ''}
            />
            <Button sx={{marginLeft: '10px'}} variant='contained' onClick={this.submitFilter}>
              <Start />
            </Button>
          </div>
        </div>
      )

      else if (this.props.type === 'category') return (
        <div className="FilterPop" data-testid="FilterPop">
          <div className="flex nowrap">
            <TextField
              size='small'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'category')}
              sx={{minWidth: '200px'}}
              label='Suchbegriff'
              value={this.props.filters.category && this.props.filters.category[0] ? this.props.filters.category[0] : ''}
            />
            <Button sx={{marginLeft: '10px'}} variant='contained' onClick={this.submitFilter}>
              <Start />
            </Button>
          </div>
        </div>
      )

      else if (this.props.type === 'order-category') return (
        <div className="FilterPop" data-testid="FilterPop">
          <div className="flex nowrap">
            <div>
              {productCategories.map(cat => cat === 'cw' || cat === 'nm' || cat === 'rpet' ? null : (
                <FormGroup>
                  <FormControlLabel control={<Checkbox value={cat} checked={this.props.filters.category && this.props.filters.category.indexOf(cat) > -1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'category', 'multiple')} />} label={convertGoodsCategory(cat)} />
                </FormGroup>
              ))}
            </div>

            <Button sx={{marginLeft: '10px'}} variant='contained' onClick={this.submitFilter}>
              <Start />
            </Button>
          </div>
        </div>
      )

      else if (this.props.type === 'lab-category') return (
        <div className="FilterPop" data-testid="FilterPop">
          <div className="flex nowrap">
            <div>
              {productCategories.map(cat => (
                <FormGroup>
                  <FormControlLabel control={<Checkbox value={cat} checked={this.props.filters.category && this.props.filters.category.indexOf(cat) > -1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'category', 'multiple')} />} label={convertGoodsCategory(cat)} />
                </FormGroup>
              ))}
            </div>

            <Button sx={{marginLeft: '10px'}} variant='contained' onClick={this.submitFilter}>
              <Start />
            </Button>
          </div>
        </div>
      )

      else if (this.props.type === 'order-color') return (
        <div className="FilterPop" data-testid="FilterPop">
          <div className="flex nowrap">
            <div>
              {rpetColors.map(color => (
                <FormGroup>
                  <FormControlLabel control={<Checkbox value={color} checked={this.props.filters.color && this.props.filters.color.indexOf(color) > -1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'color', 'multiple')} />} label={convertOrderAttributes(color)} />
                </FormGroup>
              ))}
            </div>

            <Button sx={{marginLeft: '10px'}} variant='contained' onClick={this.submitFilter}>
              <Start />
            </Button>
          </div>
        </div>
      )

      else if (this.props.type === 'order-foodSafe') return (
        <div className="FilterPop" data-testid="FilterPop">
          <div className="flex nowrap">
            <div>
              {foodSafeOptions.map(option => (
                <FormGroup>
                  <FormControlLabel control={<Checkbox value={option} checked={this.props.filters.foodSafe && this.props.filters.foodSafe.indexOf(option) > -1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'foodSafe', 'multiple')} />} label={convertOrderAttributes(option)} />
                </FormGroup>
              ))}
            </div>

            <Button sx={{marginLeft: '10px'}} variant='contained' onClick={this.submitFilter}>
              <Start />
            </Button>
          </div>
        </div>
      )

      else if (this.props.type === 'order-material') return (
        <div className="FilterPop" data-testid="FilterPop">
          <div className="flex nowrap">
            <div>
              {materials.map(option => (
                <FormGroup>
                  <FormControlLabel control={<Checkbox value={option} checked={this.props.filters.material && this.props.filters.material.indexOf(option) > -1} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, 'material', 'multiple')} />} label={convertOrderAttributes(option)} />
                </FormGroup>
              ))}
            </div>

            <Button sx={{marginLeft: '10px'}} variant='contained' onClick={this.submitFilter}>
              <Start />
            </Button>
          </div>
        </div>
      )

      else if (this.props.type === 'supplier' || this.props.type === 'customer') return (
        <div className="FilterPop" data-testid="FilterPop">
          <div className="flex nowrap">
            <TextField
              size='small'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, this.props.type)}
              sx={{minWidth: '200px'}}
              label='Suchbegriff'
              value={this.props.filters[this.props.type] && this.props.filters[this.props.type][0] ? this.props.filters[this.props.type][0] : ''}
            />
            <Button sx={{marginLeft: '10px'}} variant='contained' onClick={this.submitFilter}>
              <Start />
            </Button>
          </div>
        </div>
      )

      else return (
        <div className="FilterPop" data-testid="FilterPop">
          <div className="flex nowrap">
            <TextField
              size='small'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.updateFilter(e, this.props.type)}
              sx={{minWidth: '200px'}}
              label='Suchbegriff'
              value={this.props.filters[this.props.type] && this.props.filters[this.props.type][0] ? this.props.filters[this.props.type][0] : ''}
            />
            <Button sx={{marginLeft: '10px'}} variant='contained' onClick={this.submitFilter}>
              <Start />
            </Button>
          </div>
        </div>
      )
    }
  }
};

export default FilterPop;
