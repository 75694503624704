import React from 'react';
import './ProcessEntry.sass';
import { NotiStore } from '../../../stores/notifications';
import { resetNotifications } from '../../../services/notiStoreService';
import { DeliveredPos, EntryPos, fetchOrders, finishTruck, onTruckUnload, OrderData, TruckData, updateDeliveredPositions, updateTruckNotes } from '../../../services/warehouseEntriesService';
import { Box, Button, InputAdornment, MenuItem, Modal, Paper, Tab, Tabs, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, TablePagination } from '@mui/material';
import { convertDateString, convertGoodsCategory, convertOrderAttributes, localNumber } from '../../../services/stringConverter';
import { Close, Calculate, InfoOutlined, QrCode2, ArrowDownward } from '@mui/icons-material';
import { fetchWarehouseData, WarehouseSpot } from '../../../services/configService';
import printJS from 'print-js';
import EntryIcon from '../../../assets/icons/eingang.png';
import { Good } from '../../../services/inventoryService';
import { PDFDocument } from 'pdf-lib';
import { rpetColors } from '../../../utils/defaults';
import { Link } from 'react-router-dom';

interface ProcessEntryProps { }
interface ProcessEntryState {
  orders: { id: number, attributes: OrderData }[];
  trucks: TableData[];
  activeTruck: TableData;
  showEntryPop: boolean;
  showDivisionPop: boolean;
  entryPopMode: string;
  divisionData: { pos: number, quantity: number, reports: number }[];
  positions: EntryPos[];
  spots: WarehouseSpot[];
  showLabelsPop: boolean;
  addLabelsCount: number;
  tab: number;
  weightWasCalculated: boolean;
  enablePosUpdate: boolean;
  showPosUpdatePassword: boolean;
  enablePosUpdatePassword: string;
  disableButtons: boolean;
  plateFilter: string;
  page:number,
  rowsPerPage:number,
  pagination:{ page: number, pageCount : number ,pageSize : number ,total :  number},
  sortBy:string
}

const emptyPos: EntryPos = {
  type: '',
  color: '',
  foodSafe: '',
  material: '',
  quantity: -1,
  unit: '',
  packaging: '',
  category: '',
  storagePlace: -1,
}

interface TableData extends TruckData {
  orderId?: number;
  ordercustomId?: string;
  orderSupplier?: string;
  posTypeIntern?: string;
  posColor?: string;
  posQuantity?: number | null;
  posUnit?: string;
  posCategory?: string;
  posFoodSafe?: string;
  posMaterial?: string;
  posPackaging?: string;
  posId: number;
  truckId: number;
  truckQuantity: string | null;
  truckLoadType?: string;
  truckStart?: string;
  truckEnd?: string;
  truckDeliveredPositions: DeliveredPos[];
  truckNotes: string;
  orderCreator: string;
}

const defaultState = {
  orders: [],
  trucks: [],
  activeTruck: {
    truckPlate: '',
    orderId: -1,
    ordercustomId: '',
    orderSupplier: '',
    truckDeliveryDate: '',
    posCategory: '',
    posTypeIntern: '',
    posColor: '',
    posFoodSafe: '',
    posMaterial: '',
    posPackaging: '',
    posQuantity: 0,
    posUnit: '',
    posId: -1,
    truckId: -1,
    truckQuantity: null,
    truckLoadType: '',
    start: '',
    end: '',
    truckDeliveredPositions: [],
    truckNotes: '',
    orderCreator: '',
  },
  showEntryPop: false,
  showDivisionPop: false,
  entryPopMode: '',
  divisionData: [{ pos: -1, quantity: 0, reports: 0 }],
  positions: [],
  spots: [],
  showLabelsPop: false,
  addLabelsCount: 1,
  tab: 0,
  weightWasCalculated: false,
  enablePosUpdate: false,
  showPosUpdatePassword: false,
  enablePosUpdatePassword: '',
  disableButtons: false,
  plateFilter: '',
  page:0  ,
  rowsPerPage:10,
  pagination:{ page: 1, pageCount : 8 ,pageSize : 10 ,total :  100},
  sortBy:''
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'scroll',
  overflowX: 'hidden'
};

class ProcessEntry extends React.Component<ProcessEntryProps, ProcessEntryState> {
  state = { ...defaultState };

  componentDidMount() {
    resetNotifications();

    this.fetchData();
    
  }

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
    ) => {
      this.setState({page: this.state.page === 0 ? newPage + 1 : newPage },this.fetchData)
      
    };
    
    handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        this.setState({rowsPerPage: parseInt(event.target.value)});
        this.setState({page: 1})
     
      };


      handleSort = (value: string): void => {
        this.setState({sortBy:value})
      }
      fetchData = (): void => {
    NotiStore.setState({ loading: true });
    fetchOrders(`pagination[page]=${this.state.page}&pagination[pageSize]=${this.state.rowsPerPage}&filters[isDraft][$eq]=false&filters[isCancelled][$eq]=false`) //&filters[isFulfilled][$eq]=false
      .then(res => {
        let trucksArr: TableData[] = []

        let { data, meta } = res;
        this.setState({ pagination: meta.pagination})

        if (data && data?.length >= 1) {
          data.forEach(order => {
            let { positions } = order.attributes;

            if (positions && positions.length >= 1) {
              positions.forEach((pos, i) => {
                let { truckData } = pos;

                if (truckData && truckData.length >= 1) {
                  truckData.forEach((truck, j) => {
                    if (truck.truckPlate && truck.truckDeliveryDate) {
                      let truckData = {
                        ...truck,
                        posId: i,
                        truckId: j,
                        posColor: pos.color || 'n/a',
                        posFoodSafe: pos.foodSafe || 'n/a',
                        posMaterial: pos.material || 'n/a',
                        posPackaging: pos.packaging || '',
                        posQuantity: pos.quantity || null,
                        posUnit: pos.unit || '',
                        posCategory: pos.category ? pos.category : '',
                        posTypeIntern: pos.typeIntern || '',
                        orderId: order.id || -1,
                        ordercustomId: order.attributes.customId || '',
                        orderSupplier: order.attributes.supplier || '',
                        orderCreator: order.attributes.creator && order.attributes.creator.data && order.attributes.creator.data.attributes.name ? order.attributes.creator.data.attributes.name : '',
                        truckQuantity: truck.truckAmount || null,
                        truckLoadType: truck.loadType || '',
                        truckStart: truck.start || '',
                        truckEnd: truck.end || '',
                        truckDeliveredPositions: truck.deliveredPositions || [],
                        truckNotes: truck.notes || '',
                      }

                      trucksArr.push(truckData)

                      let { activeTruck } = this.state

                      if (activeTruck.orderId && activeTruck.posId >= 0 && activeTruck.truckId >= 0) {
                        if (order.id === activeTruck.orderId && activeTruck.posId === i && activeTruck.truckId === j) {
                          this.openEntry(truckData)
                        }
                      }
                    }
                  })
                }
              })
            }
          })
        }

        trucksArr = trucksArr.sort((a, b) => {
          let aDate = a.actualDeliveryDate ? a.actualDeliveryDate : a.truckDeliveryDate!;
          let bDate = b.actualDeliveryDate ? b.actualDeliveryDate : b.truckDeliveryDate!;
          if (aDate < bDate) return -1
          else if (aDate > bDate) return 1
          else {
            let aStart = a.start || '23:59'
            let bStart = b.start || '23:59'

            if (aStart < bStart) return -1
            else return 1
          }
        })

        fetchWarehouseData()
          .then(async (res2) => {
            this.setState({ orders: res.data || [], trucks: trucksArr, spots: res2.spots || [] })
          })
          .catch(err => {
            NotiStore.setState({ error: true, errorMessage: 'Beim Abrufen der Lagerdaten ist ein Fehler aufgetreten.' })
          })
          .finally(() => {
            NotiStore.setState({ loading: false })
          })
      })
      .catch(() => {
        NotiStore.setState({ error: true, errorMessage: 'Beim Abrufen der Bestellungen ist ein Fehler aufgetreten.' })
      })
      .finally(() => {
        NotiStore.setState({ loading: false })
      })
  }

  openEntry = (t: TableData): void => {
    let positions = t.deliveredPositions && t.deliveredPositions.length >= 1 ? t.deliveredPositions : [{
      type: t.posTypeIntern ? t.posTypeIntern : '',
      color: t.posColor ? t.posColor : '',
      foodSafe: t.posFoodSafe ? t.posFoodSafe : '',
      material: t.posMaterial ? t.posMaterial : '',
      quantity: t.truckQuantity ? Number(t.truckQuantity) : -1,
      unit: t.posUnit ? t.posUnit : '',
      packaging: t.posPackaging ? t.posPackaging : '',
      category: t.posCategory ? t.posCategory : '',
      storagePlace: t.storagePlace ? t.storagePlace : -1,
    }]

    this.setState({
      entryPopMode: t.fulfilled ? 'view' : 'fulfill',
      positions,
      activeTruck: t,
      showEntryPop: true,
    })
  }

  onCloseEntryPop = (): void => {
    this.setState({
      activeTruck: defaultState.activeTruck,
      showEntryPop: false,
      positions: defaultState.positions,
      enablePosUpdate: defaultState.enablePosUpdate,
      enablePosUpdatePassword: defaultState.enablePosUpdatePassword,
      showPosUpdatePassword: defaultState.showPosUpdatePassword,
    });
  }

  onShowDivisionPop = (): void => {
    let { positions } = this.state;

    let err: string = ''

    if (positions.length < 1) {
      err = 'Keine Positionsdaten eingegeben.'
    } else positions.forEach((pos, i) => {
      let { category, color, foodSafe, material, packaging, quantity, storagePlace, type, unit } = pos

      if (!type) err = `Bitte gib bei Position ${i + 1} eine Bezeichnung an.`
      else if (!category) err = `Bitte gib bei Position ${i + 1} eine Kategorie an.`
      else if (category !== 'bm' && !color) err = `Bitte gib bei Position ${i + 1} die Farbe an.`
      else if (category !== 'bm' && !foodSafe) err = `Bitte gib bei Position ${i + 1} an, ob sie lebensmittelecht ist.`
      else if (category !== 'bm' && !material) err = `Bitte gib bei Position ${i + 1} das Material an.`
      else if (!packaging) err = `Bitte gib bei Position ${i + 1} die Verpackungsart an.`
      else if (!quantity || quantity <= 0) err = `Bitte gib bei Position ${i + 1} die Menge an.`
      else if (!unit) err = `Bitte gib bei Position ${i + 1} die Einheit an.`
      else if (!storagePlace || storagePlace < 0) err = `Bitte gib bei Position ${i + 1} den Lagerplatz an.`
    })

    if (err) {
      NotiStore.setState({ error: true, errorMessage: err })
    } else {
      let { addLabelsCount } = this.state
      let divisionData = []

      if (addLabelsCount) {
        for (let i = 0; i < addLabelsCount; i++) {
          divisionData.push({ pos: positions.length === 1 ? 0 : -1, quantity: 0, reports: 0 })
        }

        this.setState({ showDivisionPop: true, divisionData, addLabelsCount: defaultState.addLabelsCount })
      }
    }
  }

  onCloseDivisionPop = (): void => {
    this.setState({ showDivisionPop: false, divisionData: [...defaultState.divisionData], weightWasCalculated: false });
  }

  handleEntry = (orderId: number, posId: number, truckId: number, weightWasCalculated: boolean = false): void => {
    resetNotifications();
    NotiStore.setState({ loading: true });

    let { divisionData } = this.state

    let total: number = 0;
    let err: string = '';

    divisionData.forEach(val => {
      if (val.quantity === 0) err = 'Keiner der Mengenwerte darf 0 sein.'
      else if (val.pos < 0) err = 'Bitte gib an, für welche Positionen die Etiketten sind.'
      total += val.quantity;
    })

    // if (total.toString() !== this.state.activeTruck.truckQuantity) err = 'Die Summe der Mengenwerte muss ' + this.state.activeTruck.truckQuantity + ' kg ergeben.';

    if (!orderId || orderId < 0 || posId < 0 || truckId < 0) err = 'Beim Anlegen der Waren ist ein Fehler aufgetreten.';

    if (err) {
      NotiStore.setState({ loading: false, error: true, errorMessage: err });
    } else {

      if (window.confirm(`Die Summe der Mengen der angelegten Produkte sollte ${this.state.activeTruck.truckQuantity} ergeben. Aktuell beträgt sie ${total}. Bist du sicher, dass der LKW vollständig und richtig entladen ist und du fortfahren möchtest?`)) {
        onTruckUnload(orderId, posId, truckId, this.state.divisionData, this.state.positions, weightWasCalculated)
          .then(async (res) => {
            let files: string[] = [];

            if (res.data && res.data.length >= 1) res.data.forEach(file => {
              files.push(file.url)
            })

            if (files.length >= 2) {
              const mergedPdf = await PDFDocument.create();

              for (const file of files) {
                let arrayBuffer = await fetch(file).then(res => res.arrayBuffer())
                let pdfDoc = await PDFDocument.load(arrayBuffer)

                let copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
                copiedPages.forEach((page: any) => mergedPdf.addPage(page));
              }

              const mergedPdfFile = await mergedPdf.save();
              const blob = new Blob([mergedPdfFile], { type: "application/pdf" });

              const url = URL.createObjectURL(blob);

              this.printFile([url])
            } else if (files.length === 1) {
              this.printFile(files)
            }

            this.setState({
              showDivisionPop: false,
              divisionData: defaultState.divisionData,
              weightWasCalculated: false,
            })

            NotiStore.setState({ loading: false, success: true, successMessage: 'Update erfolgreich' });

            this.fetchData();

            this.onCloseDivisionPop();
            this.setState({ entryPopMode: 'view' })
          })
          .catch(err => {
            console.log(err)
            let message: string = '';
            if (err.message === 'Truck is already fulfilled') message = 'Der Truck wurde bereits entladen.'
            else if (err.message) message = err.message;

            NotiStore.setState({ error: true, errorMessage: message ? message : 'Beim Anlegen der Waren ist ein Fehler aufgetreten.' });
          })
          .finally(() => {
            NotiStore.setState({ loading: false });
          })
      }
    }
  }

  handleDivisionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let fieldName = e.target.name.split('-')[0]
    let value: number = Number(e.target.value) || 0;
    let index: number = Number(e.target.name.split('-')[1]);
    let { divisionData } = this.state;

    if ((index || index === 0) && divisionData.length - 1 >= index && (value || value === 0)) {
      //@ts-ignore
      divisionData[index][fieldName] = value;
    } else {
      //@ts-ignore
      divisionData[index][fieldName] = 0;
    }

    this.setState({ divisionData })
    if (fieldName === 'quantity') this.setState({ weightWasCalculated: false })
  }

  deleteDivision = (index: number): void => {
    if (index > -1 && typeof this.state.divisionData[index] !== 'undefined' && this.state.divisionData.length > 1) {
      let arr = this.state.divisionData;
      arr.splice(index, 1);
      this.setState({ divisionData: arr });
    }
  }

  addPosition = (): void => {
    this.setState(prevState => ({
      positions: [...prevState.positions, { ...emptyPos }]
    }))
  }

  handlePosChange = (e: React.ChangeEvent<HTMLInputElement>, isNumber: boolean = false): void => {
    let { name, value } = e.target;
    let posId: number = Number(name.split('-')[1]);
    let fieldName: string = name.split('-')[0];

    if (posId <= this.state.positions.length - 1) {
      let { positions } = this.state;

      // @ts-ignore
      positions[posId][fieldName] = isNumber ? Number(value) : value;

      this.setState({
        positions
      })
    }
  }

  printFile = (printable: string[]) => {
    printJS({
      printable: printable[0],
      type: 'pdf',
      onPrintDialogClose: () => {
        printable.splice(0, 1);
        if (printable.length >= 1) this.printFile(printable)
      }
    });
  }

  openMultiple = async (pos: EntryPos) => {
    let files: string[] = [];
    let url = ''

    if (pos.goods && pos.goods.length >= 1) pos.goods.forEach(good => {
      if (good.attributes.label && good.attributes.label.data && good.attributes.label.data.attributes.url) files.push(good.attributes.label.data.attributes.url)
    })

    if (pos.reports && pos.reports.length >= 1) pos.reports.forEach(report => {
      if (report.attributes.label && report.attributes.label.url) files.push(report.attributes.label.url)
    })

    if (files.length >= 2) {
      const mergedPdf = await PDFDocument.create();

      for (const file of files) {
        let arrayBuffer = await fetch(file).then(res => res.arrayBuffer())
        let pdfDoc = await PDFDocument.load(arrayBuffer)

        let copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
        copiedPages.forEach((page: any) => mergedPdf.addPage(page));
      }

      const mergedPdfFile = await mergedPdf.save();
      const blob = new Blob([mergedPdfFile], { type: "application/pdf" });

      url = URL.createObjectURL(blob);
    } else if (files.length === 1) {
      url = files[0]
    }

    window.open(url, '_blank', 'noopener,noreferrer');
  }

  getSpotData = (id: number): string => {
    let match: WarehouseSpot[] = this.state.spots.filter((spot: WarehouseSpot) => spot.id === id)

    if (match.length === 1) {
      return `${match[0].attributes.name}${match[0].attributes.description ? ' - ' + match[0].attributes.description : ''}`
    } else {
      return 'FEHLER'
    }
  }

  getStoreName = (id: number | string): string => {
    let { spots } = this.state
    let matches: WarehouseSpot[] = spots.filter((store: WarehouseSpot) => store.id.toString() === id.toString())
    let result = ''

    if (matches.length === 1) result = matches[0].attributes.name

    return result
  }

  updateTruckNotes = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let { activeTruck } = this.state;

    if (activeTruck) {
      activeTruck.truckNotes = e.target.value ? e.target.value : ''

      this.setState({ activeTruck })
    }
  }

  onCloseEntryPopFinish = (): void => {
    NotiStore.setState({ loading: true })

    let { activeTruck } = this.state;

    updateTruckNotes(activeTruck.orderId, activeTruck.posId, activeTruck.truckId, activeTruck.truckNotes)
      .then(() => {
        NotiStore.setState({ loading: false })
        this.onCloseEntryPop()
        this.fetchData()
      })
      .catch((err) => {
        NotiStore.setState({ loading: false, error: true, errorMessage: err.message ? err.message : 'Anmerkungen wurden nicht gespeichert.' });
        this.onCloseEntryPop()
      })
  }

  handleDeletePos = (id: number): void => {
    if (id > 0 && typeof this.state.positions[id] !== 'undefined') {
      let { positions } = this.state

      positions.splice(id, 1)

      this.setState({ positions })
    }
  }

  addLabels = (): void => {
    let { addLabelsCount } = this.state

    if (addLabelsCount) {
      let newArr = [...this.state.divisionData]

      Array.from(Array(addLabelsCount).keys()).forEach(item => {
        newArr.push({ pos: this.state.positions.length === 1 ? 0 : -1, quantity: 0, reports: 0 })
      })

      this.setState({ divisionData: [...newArr], showLabelsPop: false }, this.onShowDivisionPop)
    }
  }

  calculateWeight = (): void => {
    let { divisionData } = this.state

    if (divisionData.length >= 1) divisionData.forEach((data, i) => {
      let { pos } = divisionData[i]

      if ((pos || pos === 0) && typeof this.state.positions[pos] !== 'undefined') {
        let position: EntryPos = this.state.positions[pos]

        if (position.quantity) {
          let matches = divisionData.filter(data2 => data2.pos === data.pos)

          if (matches.length >= 1) {
            let doneCount = 0
            divisionData.forEach((data2, j) => {
              if (data2.pos === data.pos) {
                data2.quantity = doneCount === 0 ? Math.floor(Math.floor(position.quantity / matches.length) + position.quantity % matches.length) : Math.floor(position.quantity / matches.length)
                doneCount++
              }
            })

            this.setState({ divisionData })
          }
        }
      }
    })

    this.setState({ weightWasCalculated: true })
  }

  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({ tab: newValue });
  };

  validatePosUpdatePass = (): void => {
    let pass = this.state.enablePosUpdatePassword

    if (pass && process.env.REACT_APP_POS_UPDATE_PASS && pass === process.env.REACT_APP_POS_UPDATE_PASS) {
      this.setState({ enablePosUpdate: true, enablePosUpdatePassword: defaultState.enablePosUpdatePassword });
    } else {
      NotiStore.setState({ error: true, errorMessage: 'Passwort falsch.' });
      this.setState({ enablePosUpdatePassword: '' })
    }
  }

  updateDeliveredPositions = () => {
    resetNotifications()
    this.setState({ disableButtons: true })
    NotiStore.setState({ loading: true })

    let { orderId, posId, truckId } = this.state.activeTruck
    let { positions } = this.state

    if (!((orderId || orderId === 0) && (posId || posId === 0) && (truckId || truckId === 0) && positions && positions.length >= 1)) {
      this.setState({ disableButtons: false })
      NotiStore.setState({ loading: false, error: true, errorMessage: 'Fehlerhafte Daten übermittelt.' })
    } else {
      updateDeliveredPositions(orderId, posId, truckId, positions)
        .then(res => {
          this.setState({ enablePosUpdate: false, showPosUpdatePassword: false }, this.fetchData)
          NotiStore.setState({ success: true, successMessage: 'Positionen erfolgreich aktualisiert.' })
        })
        .catch(err => {
          NotiStore.setState({ error: true, errorMessage: err.message || 'Fehler während dem Aktualisieren der Positionen.' })
        })
        .finally(() => {
          this.setState({ disableButtons: false })
          NotiStore.setState({ loading: false })
        })
    }
  }

  finishTruck = () => {
    if (window.confirm('Bist du sicher, dass der LKW richtig und vollständig entladen ist? Dieser Schritt kann nicht rückgängig gemacht werden.')) {
      resetNotifications()
      this.setState({ disableButtons: true })
      NotiStore.setState({ loading: true })

      let { orderId, posId, truckId } = this.state.activeTruck

      if (!((orderId || orderId === 0) && (posId || posId === 0) && (truckId || truckId === 0))) {
        this.setState({ disableButtons: false })
        NotiStore.setState({ loading: false, error: true, errorMessage: 'Fehlerhafte Daten übermittelt.' })
      } else {
        finishTruck(orderId, posId, truckId)
          .then(res => {
            this.setState({ entryPopMode: 'view' }, this.fetchData)
            NotiStore.setState({ success: true, successMessage: 'LKW abgeschlossen.' })
          })
          .catch(err => {
            NotiStore.setState({ error: true, errorMessage: err.message || 'Fehler während dem Aktualisieren der LKW-Daten.' })
          })
          .finally(() => {
            this.setState({ disableButtons: false })
            NotiStore.setState({ loading: false })
          })
      }
    }
  }

  handleShortChange = (
    // event: React.SyntheticEvent, newValue: number
    ) => {
      console.log(this.state.trucks)
      // this.setState({ tab: newValue });
    };
    


  render() {
    return (
      <div className="ProcessEntry content" data-testid="ProcessEntry">
        <div className="header-bar">
          <h1>Wareneingang</h1>
          <img src={EntryIcon} alt="icon" />
        </div>

        <div style={{ marginBottom: 30 }}>
          <Box>
            <Tabs
              value={this.state.tab}
              onChange={this.handleTabChange}
              variant="scrollable"
              scrollButtons={false}
              allowScrollButtonsMobile={false}
            >
              <Tab label="Offene Lieferungen" sx={{ width: '400px' }} />
              <Tab label="Abgeschlossene Lieferungen" sx={{ width: '400px' }} />
            </Tabs>
          </Box>
        </div>

        <div style={{ marginBottom: 15 }}>
          <TextField
            fullWidth
            onChange={(e) => this.setState({ plateFilter: e.target.value })}
            value={this.state.plateFilter || ''}
            placeholder='Kennzeichen filtern'
          />
        </div>

        {this.state.tab === 0 ? <div className='trucks-window open-trucks'>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Kennzeichen</span>
                    <div>
                      <ArrowDownward className='pointer icon'
                       onClick={() => this.handleSort('truckPlate')} 
                       />
                    </div>
                  </div>
                  </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Lieferdatum</span>
                   
                  </div>
                 </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>ID Bestellung</span>
                    <div>
                      <ArrowDownward className='pointer icon'
                       onClick={() => this.handleSort('ordercustomId')} 
                       />
                    </div>
                  </div>
                  </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Bezeichnung</span>
                    <div>
                      <ArrowDownward className='pointer icon'
                       onClick={() => this.handleSort('posTypeIntern')} 
                       />
                    </div>
                  </div>
                 </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Farbe</span>
                    <div>
                      <ArrowDownward className='pointer icon'
                       onClick={() => this.handleSort('posColor')} 
                       />
                    </div>
                  </div>
                 </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>LME</span>
                    <Tooltip title='lebensmittelecht'>
                      <InfoOutlined sx={{ width: '17px', marginLeft: '5px' }} />
                    </Tooltip>
                    <div>
                      <ArrowDownward className='pointer icon'
                       onClick={() => this.handleSort('posFoodSafe')} 
                       />
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Material</span>
                    <div>
                      <ArrowDownward className='pointer icon'
                       onClick={() => this.handleSort('posMaterial')} 
                       />
                    </div>
                  </div>
                  </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Menge</span>
                  </div>
                  </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Lagerplatz</span>   
                  </div>
                  </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.trucks.length >= 1 && this.state.trucks.filter((truck: TableData) => {
                if (!this.state.plateFilter) return true
                else if (truck.truckPlate && truck.truckPlate.toLowerCase().includes(this.state.plateFilter.toLowerCase())) return true
                else return false
              })
              .sort((a:any, b:any) =>{
                let response
                if(this.state.sortBy === 'truckPlate'){
                  response = a.truckPlate.localeCompare(b.truckPlate)
                }else if(this.state.sortBy === 'ordercustomId'){
                  response = a.ordercustomId.localeCompare(b.ordercustomId)
                }else if( this.state.sortBy=== 'posTypeIntern'){
                  response = a.posTypeIntern.localeCompare(b.posTypeIntern)
                }else if( this.state.sortBy=== 'posColor'){
                  response = a.posColor.localeCompare(b.posColor)
                }else if( this.state.sortBy=== 'posFoodSafe'){
                  response = a.posFoodSafe.localeCompare(b.posFoodSafe)
                }else if( this.state.sortBy=== 'posMaterial'){
                  response = a.posMaterial.localeCompare(b.posMaterial)
                }
                return response
              })
              .map((t: TableData, i: number) => (
                !t.fulfilled ? (
                  <TableRow onClick={() => this.openEntry(t)} sx={{ cursor: 'pointer' }} key={`unfulfilled-${i}`}>
                    <TableCell>{t.truckPlate || ''}</TableCell>
                    {t.actualDeliveryDate ? (
                      <TableCell>{convertDateString(t.actualDeliveryDate)}</TableCell>
                    ) : (
                      <TableCell>{t.truckDeliveryDate?.split('-')[2] + '.' + t.truckDeliveryDate?.split('-')[1] + '.' + t.truckDeliveryDate?.split('-')[0]}</TableCell>
                    )}
                    <TableCell>{t.start || 'n/a'} - {t.end || 'n/a'}</TableCell>
                    <TableCell>{t.ordercustomId || ''}</TableCell>
                    <TableCell>{t.posTypeIntern || ''}</TableCell>
                    <TableCell>{convertOrderAttributes(t.posColor || '')}</TableCell>
                    <TableCell>{convertOrderAttributes(t.posFoodSafe || '')}</TableCell>
                    <TableCell>{convertOrderAttributes(t.posMaterial || '')}</TableCell>
                    <TableCell>{t.truckQuantity || ''} {t.posUnit || ''}</TableCell>
                    <TableCell>{t.storagePlace ? this.getStoreName(t.storagePlace) : ''}</TableCell>
                  </TableRow>
                ) : null
              ))}
            </TableBody>
          </Table>
        </div> : null}
                      
        {this.state.tab === 1 ? <div className='trucks-window fulfilled-trucks'>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Kennzeichen</span>
                    <div>
                      <ArrowDownward className='pointer icon'
                       onClick={() => this.handleSort('truckPlate')} 
                       />
                    </div>
                  </div>
                  </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Lieferdatum</span>
                   
                  </div>
                 </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>ID Bestellung</span>
                    <div>
                      <ArrowDownward className='pointer icon'
                       onClick={() => this.handleSort('ordercustomId')} 
                       />
                    </div>
                  </div>
                  </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Bezeichnung</span>
                    <div>
                      <ArrowDownward className='pointer icon'
                       onClick={() => this.handleSort('posTypeIntern')} 
                       />
                    </div>
                  </div>
                 </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Farbe</span>
                    <div>
                      <ArrowDownward className='pointer icon'
                       onClick={() => this.handleSort('posColor')} 
                       />
                    </div>
                  </div>
                 </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>LME</span>
                    <Tooltip title='lebensmittelecht'>
                      <InfoOutlined sx={{ width: '17px', marginLeft: '5px' }} />
                    </Tooltip>
                    <div>
                      <ArrowDownward className='pointer icon'
                       onClick={() => this.handleSort('posFoodSafe')} 
                       />
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Material</span>
                    <div>
                      <ArrowDownward className='pointer icon'
                       onClick={() => this.handleSort('posMaterial')} 
                       />
                    </div>
                  </div>
                  </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Menge</span>
                  </div>
                  </TableCell>
                <TableCell>
                <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Lagerplatz</span>   
                  </div>
                  </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.trucks.length >= 1 && this.state.trucks.filter((truck: TableData) => {
                if (!this.state.plateFilter) return true
                else if (truck.truckPlate && truck.truckPlate.toLowerCase().includes(this.state.plateFilter.toLowerCase())) return true
                else return false
              })
              .sort((a:any, b:any) =>{
                let response 
                if(this.state.sortBy === 'truckPlate'){
                  response =  a.truckPlate.localeCompare(b.truckPlate)
                }else if(this.state.sortBy === 'ordercustomId'){
                  response =  a.ordercustomId.localeCompare(b.ordercustomId)
                }else if( this.state.sortBy=== 'posTypeIntern'){
                  response =  a.posTypeIntern.localeCompare(b.posTypeIntern)
                }else if( this.state.sortBy=== 'posColor'){
                  response =  a.posColor.localeCompare(b.posColor)
                }else if( this.state.sortBy=== 'posFoodSafe'){
                  response =  a.posFoodSafe.localeCompare(b.posFoodSafe)
                }else if( this.state.sortBy=== 'posMaterial'){
                  response =  a.posMaterial.localeCompare(b.posMaterial)
                }
                return response
              })
              .map((t: TableData, i: number) => (
                t.fulfilled ? (
                  <TableRow onClick={() => this.openEntry(t)} sx={{ cursor: 'pointer' }} key={`fulfilled-${i}`}>
                    <TableCell>{t.truckPlate || ''}</TableCell>
                    {t.actualDeliveryDate ? (
                      <TableCell>{convertDateString(t.actualDeliveryDate)}</TableCell>
                    ) : (
                      <TableCell>{t.truckDeliveryDate?.split('-')[2] + '.' + t.truckDeliveryDate?.split('-')[1] + '.' + t.truckDeliveryDate?.split('-')[0]}</TableCell>
                    )}
                    <TableCell>{t.ordercustomId || ''}</TableCell>
                    <TableCell>{t.posTypeIntern || ''}</TableCell>
                    <TableCell>{convertOrderAttributes(t.posColor || '')}</TableCell>
                    <TableCell>{convertOrderAttributes(t.posFoodSafe || '')}</TableCell>
                    <TableCell>{convertOrderAttributes(t.posMaterial || '')}</TableCell>
                    <TableCell>{t.truckQuantity || ''} {t.posUnit || ''}</TableCell>
                    <TableCell>{t.storagePlace ? this.getStoreName(t.storagePlace) : ''}</TableCell>
                  </TableRow>
                ) : null
              ))}
            </TableBody>
          </Table>
        </div> : null}
        
        <TablePagination
           component={"div"}
           count={this.state.pagination.total}
           page={this.state.page}
           onPageChange={this.handleChangePage}
           rowsPerPage={this.state.rowsPerPage}
           onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
        

        <Modal
          open={this.state.showEntryPop}
          onClose={this.onCloseEntryPop}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="NewOrderForm"
        >
          <>
            <span className='modal-close pointer' onClick={this.onCloseEntryPop}><Close /></span>
            <Box sx={modalStyle}>
              <div className='modal-close-btn' onClick={this.onCloseEntryPop}><Close /></div>
              <div className="header-bar">
                <h2>LKW {this.state.activeTruck ? this.state.activeTruck.truckPlate : '???'} im Überblick</h2>
                {this.state.entryPopMode === 'fulfill' && <Button disabled={this.state.disableButtons} variant='contained' onClick={this.finishTruck}>LKW abschließen</Button>}
              </div>
              <h3>Allgemein</h3>
              <div className='flex of-3 spacing-1'>
                <TextField
                  helperText={'ID Bestellung'}
                  value={this.state.activeTruck.ordercustomId}
                  fullWidth
                  disabled
                />
                <TextField
                  helperText={'Bestellt von'}
                  //TODO
                  value={this.state.activeTruck.orderCreator}
                  fullWidth
                  disabled
                />
                <TextField
                  helperText={'Lieferant'}
                  value={this.state.activeTruck.orderSupplier}
                  fullWidth
                  disabled
                />
                <TextField
                  helperText={'Liefertermin'}
                  value={this.state.activeTruck.truckDeliveryDate?.split('-')[2] + '.' + this.state.activeTruck.truckDeliveryDate?.split('-')[1] + '.' + this.state.activeTruck.truckDeliveryDate?.split('-')[0]}
                  fullWidth
                  disabled
                  sx={{ marginTop: '15px' }}
                />
                <TextField
                  helperText={'Ladungstyp'}
                  value={convertOrderAttributes(this.state.activeTruck.truckLoadType || 'n/a')}
                  fullWidth
                  disabled
                  sx={{ marginTop: '15px' }}
                />
                <TextField
                  helperText={'Kategorie'}
                  value={convertGoodsCategory(this.state.activeTruck.posCategory)}
                  fullWidth
                  disabled
                  sx={{ marginTop: '15px' }}
                />
              </div>
              <hr />
              <h3>Inhalt SOLL</h3>
              <div className={`flex spacing-1 ${this.state.activeTruck.posCategory === 'bm' ? 'of-3' : 'of-3'}`}>
                <TextField
                  helperText={'Bezeichnung'}
                  value={this.state.activeTruck.posTypeIntern}
                  fullWidth
                  disabled
                />
                {this.state.activeTruck.posCategory === 'bm' ? null : (
                  <TextField
                    helperText={'Farbe'}
                    value={convertOrderAttributes(this.state.activeTruck.posColor)}
                    fullWidth
                    disabled
                  />
                )}
                {this.state.activeTruck.posCategory === 'bm' ? null : (
                  <TextField
                    helperText={'Lebensmittelecht'}
                    value={convertOrderAttributes(this.state.activeTruck.posFoodSafe)}
                    fullWidth
                    disabled
                  />
                )}
                {this.state.activeTruck.posCategory === 'bm' ? null : (
                  <TextField
                    helperText={'Material'}
                    value={convertOrderAttributes(this.state.activeTruck.posMaterial)}
                    fullWidth
                    disabled
                  />
                )}
                <TextField
                  helperText={'Menge'}
                  value={localNumber(this.state.activeTruck.truckQuantity ? this.state.activeTruck.truckQuantity : '') + ' ' + this.state.activeTruck.posUnit}
                  fullWidth
                  disabled
                />
                <TextField
                  helperText={'Verpackung'}
                  value={convertOrderAttributes(this.state.activeTruck.posPackaging)}
                  fullWidth
                  disabled
                />
              </div>
              <hr />
              <h2>Inhalt IST</h2>

              <>
                {true ? this.state.positions.map((pos: EntryPos, i: number) => (
                  <div key={`pos-ist-${i}`} style={{ marginTop: 40 }}>
                    <h3>Position {i + 1}</h3> {this.state.entryPopMode === 'fulfill' && this.state.enablePosUpdate && (!pos.goods || pos.goods.length < 1) && i > 0 ? <span onClick={() => this.handleDeletePos(i)} className='pointer underline'>löschen</span> : null}
                    <div className={`flex spacing-1 ${pos.category === 'bm' ? 'of-3' : 'of-3'}`} style={{ marginTop: 10 }}>
                      <TextField
                        name={`type-${i}`}
                        helperText={'Bezeichnung'}
                        value={pos.type}
                        fullWidth
                        sx={{ marginBottom: '15px' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePosChange(e)}
                        disabled={this.state.entryPopMode === 'view' || !this.state.enablePosUpdate || (pos.goods && pos.goods.length >= 1)}
                      />
                      {pos.category === 'bm' ? null : (
                        <>
                          {this.state.entryPopMode === 'view' || !this.state.enablePosUpdate || (pos.goods && pos.goods.length >= 1) ? (
                            <TextField
                              helperText={'Farbe'}
                              value={convertOrderAttributes(pos.color)}
                              fullWidth
                              sx={{ marginBottom: '15px' }}
                              disabled
                            />
                          ) : (
                            <TextField
                              name={`color-${i}`}
                              helperText={'Farbe'}
                              value={pos.color}
                              fullWidth
                              select
                              sx={{ marginBottom: '15px' }}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePosChange(e)}
                            >
                              {rpetColors.map(color => <MenuItem key={color} value={color}>{convertOrderAttributes(color)}</MenuItem>)}
                            </TextField>
                          )}
                          {this.state.entryPopMode === 'view' || !this.state.enablePosUpdate || (pos.goods && pos.goods.length >= 1) ? (
                            <TextField
                              helperText={'Lebensmittelecht'}
                              value={convertOrderAttributes(pos.foodSafe)}
                              fullWidth
                              sx={{ marginBottom: '15px' }}
                              disabled
                            />
                          ) : (
                            <TextField
                              name={`foodSafe-${i}`}
                              helperText={'Lebensmittelecht'}
                              value={pos.foodSafe}
                              fullWidth
                              select
                              sx={{ marginBottom: '15px' }}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePosChange(e)}
                              disabled={this.state.entryPopMode === 'view' || !this.state.enablePosUpdate}
                            >
                              <MenuItem value='yes'>Ja</MenuItem>
                              <MenuItem value='no'>Nein</MenuItem>
                            </TextField>
                          )}
                          {this.state.entryPopMode === 'view' || !this.state.enablePosUpdate || (pos.goods && pos.goods.length >= 1) ? (
                            <TextField
                              helperText={'Material'}
                              value={convertOrderAttributes(pos.material)}
                              fullWidth
                              sx={{ marginBottom: '15px' }}
                              disabled
                            />
                          ) : (
                            <TextField
                              name={`material-${i}`}
                              helperText={'Material'}
                              value={pos.material}
                              fullWidth
                              select
                              sx={{ marginBottom: '15px' }}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePosChange(e)}
                              disabled={this.state.entryPopMode === 'view' || !this.state.enablePosUpdate}
                            >
                              <MenuItem value='tray'>{convertOrderAttributes('tray')}</MenuItem>
                              <MenuItem value='bottle'>{convertOrderAttributes('bottle')}</MenuItem>
                              <MenuItem value='bottleAndTray'>{convertOrderAttributes('bottleAndTray')}</MenuItem>
                            </TextField>
                          )}
                        </>
                      )}
                      {this.state.entryPopMode === 'view' || !this.state.enablePosUpdate ? (
                        <TextField
                          helperText={'Menge'}
                          value={localNumber(pos.quantity) + ' ' + pos.unit}
                          fullWidth
                          sx={{ marginBottom: '15px' }}
                          disabled
                        />
                      ) : (
                        <div className='input-with-unit'>
                          <TextField
                            name={`quantity-${i}`}
                            helperText={'Menge'}
                            value={pos.quantity >= 0 ? pos.quantity : ''}
                            fullWidth
                            sx={{ marginBottom: '15px' }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePosChange(e, true)}
                          />
                          <TextField
                            name={`unit-${i}`}
                            value={pos.unit}
                            fullWidth
                            select
                            sx={{ marginBottom: '15px' }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePosChange(e)}
                          >
                            <MenuItem value='kg'>kg</MenuItem>
                            <MenuItem value='t'>t</MenuItem>
                            <MenuItem value='stk'>stk</MenuItem>
                            <MenuItem value='l'>l</MenuItem>
                          </TextField>
                        </div>
                      )}
                      {this.state.entryPopMode === 'view' || !this.state.enablePosUpdate || (pos.goods && pos.goods.length >= 1) ? (
                        <TextField
                          helperText={'Verpackung'}
                          value={convertOrderAttributes(pos.packaging)}
                          fullWidth
                          sx={{ marginBottom: '15px' }}
                          disabled
                        />
                      ) : (
                        <TextField
                          name={`packaging-${i}`}
                          helperText={'Verpackung'}
                          value={pos.packaging}
                          fullWidth
                          select
                          sx={{ marginBottom: '15px' }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePosChange(e)}
                        >
                          <MenuItem value='bigbag'>Bigbag</MenuItem>
                          <MenuItem value='bale'>Ballen</MenuItem>
                          <MenuItem value='divers'>Divers</MenuItem>
                        </TextField>
                      )}
                    </div>
                    <div className={`flex spacing-1 of-5`}>
                      {this.state.entryPopMode === 'view' || !this.state.enablePosUpdate || (pos.goods && pos.goods.length >= 1) ? (
                        <TextField
                          helperText={'Kategorie'}
                          value={convertGoodsCategory(pos.category)}
                          fullWidth
                          sx={{ marginBottom: '15px' }}
                          disabled
                        />
                      ) : (
                        <TextField
                          name={`category-${i}`}
                          helperText={'Kategorie'}
                          value={pos.category}
                          fullWidth
                          select
                          sx={{ marginBottom: '15px' }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePosChange(e)}
                        >
                          <MenuItem value='bm'>Betriebsmittel</MenuItem>
                          <MenuItem value='entry'>Rohstoff</MenuItem>
                          <MenuItem value='zl'>Handel (ZL)</MenuItem>
                          <MenuItem value='hw'>Handel</MenuItem>
                        </TextField>
                      )}
                      <div></div>
                      <div></div>
                      <div></div>
                      {this.state.entryPopMode === 'view' || !this.state.enablePosUpdate || (pos.goods && pos.goods.length >= 1) ? (
                        <TextField
                          helperText={'Lagerplatz'}
                          value={`${this.getSpotData(pos.storagePlace)}`}
                          fullWidth
                          sx={{ marginBottom: '15px' }}
                          disabled={this.state.entryPopMode === 'view' || !this.state.enablePosUpdate || (pos.goods && pos.goods.length >= 1)}
                        />
                      ) : (
                        <TextField
                          name={`storagePlace-${i}`}
                          helperText={'Lagerplatz'}
                          value={pos.storagePlace}
                          fullWidth
                          select
                          sx={{ marginBottom: '15px' }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handlePosChange(e, true)}
                        >
                          {this.state.spots.length >= 1 && this.state.spots.map((spot: WarehouseSpot, j) => (
                            <MenuItem key={`storagePlace-${i}-${j}`} value={spot.id}>{spot.attributes.name}</MenuItem>
                          ))}
                        </TextField>
                      )}
                    </div>

                    {pos.goods && pos.goods.length >= 1 && (
                      <Paper>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableCell>ID</TableCell>
                              <TableCell>Bezeichnung</TableCell>
                              <TableCell>Menge</TableCell>
                              <TableCell></TableCell>
                            </TableHead>
                            {pos.goods.map((good: Good, y: number) => (
                              <TableRow key={`pos-${i}-good-${y}`}>
                                <TableCell><strong>{good.attributes.customId ? <Link to={`/lager/inventar?id=${good.id}`}>{good.attributes.customId}</Link> : '???'}</strong></TableCell>
                                <TableCell>{good.attributes.typeIntern || '???'}</TableCell>
                                <TableCell>{localNumber(good.attributes.origQuantity)} {good.attributes.unit}</TableCell>
                                <TableCell align='right'><a href={good.attributes.label && good.attributes.label.data ? good.attributes.label.data.attributes.url : '/'} target='_blank' rel='noreferrer'><Tooltip title='Label ansehen'><QrCode2 className='action primary' /></Tooltip></a></TableCell>
                              </TableRow>
                            ))}
                            {pos.reports && pos.reports.length >= 1 && pos.reports.map((report, y) => (
                              <TableRow key={`pos-${i}-rep-${y}`}>
                                <TableCell>{report.attributes.customId ? <strong>{report.attributes.customId}</strong> : '???'}</TableCell>
                                <TableCell>{pos.type || '???'}</TableCell>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell align='right'>{report.attributes.label && report.attributes.label.url ? <a href={report.attributes.label.url} target='_blank' rel='noreferrer'><Tooltip title='Label ansehen'><QrCode2 className='action primary' /></Tooltip></a> : '???'}</TableCell>
                              </TableRow>
                            ))}
                            {(pos.reports && pos.reports.length >= 1) || (pos.goods && pos.goods.length >= 1) ? (
                              <TableRow>
                                <TableCell><strong>Alle Labels</strong></TableCell>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell align='right'><span className='pointer primary' onClick={() => this.openMultiple(pos)}><QrCode2 /></span></TableCell>
                              </TableRow>
                            ) : null}
                          </Table>
                        </TableContainer>
                      </Paper>
                    )}
                  </div>
                )) : null}
                {this.state.enablePosUpdate || this.state.entryPopMode === 'view' ? null : (
                  <div style={{ marginTop: 30 }}>
                    {this.state.showPosUpdatePassword ? (
                      <div style={{ marginTop: 30 }}>
                        <p className='strong'>Bitte kontaktiere den Supervisor, damit er die Daten überarbeiten kann.</p>
                        <TextField
                          fullWidth
                          helperText='Passwort (bestätigen mit "Enter")'
                          onChange={e => this.setState({ enablePosUpdatePassword: e.target.value })}
                          value={this.state.enablePosUpdatePassword}
                          type='password'
                          onKeyDown={e => { if (e.key.toLowerCase() === 'enter') this.validatePosUpdatePass() }}
                        />
                      </div>
                    ) : (
                      <React.Fragment>
                        <p className='strong'>Ist die Lieferung korrekt?</p>
                        <div style={{ marginTop: 10 }} className='flex of-2 spacing-1'>
                          <div><Button disabled={this.state.disableButtons} variant='contained' fullWidth onClick={() => this.setState({ showLabelsPop: true })}>Ja - Etiketten drucken</Button></div>
                          <div><Button disabled={this.state.disableButtons} variant='outlined' fullWidth onClick={() => this.setState({ showPosUpdatePassword: true })}>Nein - Daten überarbeiten</Button></div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </>

              {this.state.entryPopMode !== 'view' && this.state.enablePosUpdate && (
                <div style={{ marginTop: 30 }}>
                  <strong className='pointer' onClick={this.addPosition}>+ Position hinzufügen</strong>
                </div>
              )}
              {this.state.entryPopMode !== 'view' && this.state.enablePosUpdate && (
                <div style={{ marginTop: 30 }} className='text-right'>
                  <Button disabled={this.state.disableButtons} variant='contained' onClick={this.updateDeliveredPositions}>Positionen speichern</Button>
                  {/* <Button variant='contained' disabled onClick={() => this.setState({showLabelsPop: true})}>Etiketten drucken</Button> */}
                </div>
              )}
              {this.state.entryPopMode === 'view' && (
                <>
                  <hr />
                  <h3>Anmerkungen</h3>
                  <TextField
                    fullWidth
                    value={this.state.activeTruck.truckNotes || ''}
                    onChange={this.updateTruckNotes}
                  />
                  <div style={{ marginTop: 30 }} className='text-right'>
                    <Button disabled={this.state.disableButtons} variant='contained' onClick={this.onCloseEntryPopFinish}>Speichern &amp; schließen</Button>
                  </div>
                </>
              )}
            </Box>
          </>
        </Modal>

        <Modal
          open={this.state.showDivisionPop}
          onClose={this.onCloseDivisionPop}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="NewOrderForm"
        >
          <>
            <span className='modal-close pointer' onClick={this.onCloseDivisionPop}><Close /></span>
            <Box sx={{ ...modalStyle, maxWidth: 900 }}>
              <div className='modal-close-btn' onClick={this.onCloseDivisionPop}><Close /></div>
              <h2>QR Code erstellen</h2>
              <div>Bitte trage das richtige <strong>Gewicht in kg</strong> ein.</div>
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>
                        <span className='flex align-center'>
                          <span>Position</span>
                          <Tooltip title='Sind im LKW unterschiedliche Produkte verladen, wähle bitte das Produkt aus, für welches du ein Etikett erstellen möchtest.'>
                            <InfoOutlined sx={{ width: '17px', marginLeft: '5px' }} />
                          </Tooltip>
                        </span>
                      </TableCell>
                      <TableCell>Menge</TableCell>
                      {/* <TableCell>Laborproben</TableCell> */}
                      <TableCell align='center'>
                        <Tooltip title='Menge aus Gesamtmenge automatisch berechnen'>
                          <Button disabled={this.state.disableButtons} variant='contained' onClick={() => this.calculateWeight()}><Calculate className='action white' /></Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.divisionData.map((data, i) => (
                      <TableRow key={`division${i}`}>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>automatisch</TableCell>
                        <TableCell>
                          <TextField
                            name={`pos-${i}`}
                            type="number"
                            value={data.pos}
                            fullWidth
                            onChange={this.handleDivisionChange}
                            select
                          >
                            {this.state.positions.length >= 1 && this.state.positions.map((pos: EntryPos, j) => (
                              <MenuItem key={`pos-eti-${i}-${j}`} value={j}>Position {j + 1}: {pos.type}</MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            name={`quantity-${i}`}
                            type="number"
                            value={Number(data.quantity).toString() || '0'}
                            fullWidth
                            onChange={this.handleDivisionChange}
                            InputProps={{
                              // @ts-ignore
                              endAdornment: <InputAdornment position="end">{(data.pos || data.pos === 0) && typeof this.state.positions[data.pos] !== 'undefined' && this.state.positions[data.pos].unit ? this.state.positions[data.pos].unit : ''}</InputAdornment>
                            }}
                          />
                        </TableCell>
                        {/* <TableCell>
                          <TextField
                            name={`reports-${i}`}
                            type="number"
                            value={data.reports}
                            fullWidth
                            onChange={this.handleDivisionChange}
                            select
                          >
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val: number) => (
                              <MenuItem key={`report-${val}`} value={val}>{val}</MenuItem>
                            ))}
                          </TextField>
                        </TableCell> */}
                        <TableCell align='center'>
                          <Close className='action error' onClick={() => this.deleteDivision(i)} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div className='pointer strong' style={{ margin: '20px 0' }} onClick={() => this.setState({ showLabelsPop: true })}>+ Etikett hinzufügen</div>
              </div>
              <div className="text-right">
                <Button disabled={this.state.disableButtons} variant='contained' onClick={() => this.handleEntry(this.state.activeTruck.orderId, this.state.activeTruck.posId, this.state.activeTruck.truckId, this.state.weightWasCalculated)}>Etikette{this.state.divisionData.length > 1 && 'n'} erstellen &amp; drucken</Button>
              </div>
            </Box>
          </>
        </Modal>

        <Modal
          open={this.state.showLabelsPop}
          onClose={() => this.setState({ showLabelsPop: false, addLabelsCount: defaultState.addLabelsCount })}
        >
          <>
            <span className='modal-close pointer' onClick={() => this.setState({ showLabelsPop: false, addLabelsCount: defaultState.addLabelsCount })}><Close /></span>
            <Box sx={{ ...modalStyle, maxWidth: 600 }}>
              <div className='modal-close-btn' onClick={() => this.setState({ showLabelsPop: false, addLabelsCount: defaultState.addLabelsCount })}><Close /></div>
              <h2>Wie viele Etiketten benötigst du?</h2>
              <TextField
                select
                onChange={e => this.setState({ addLabelsCount: Number(e.target.value) })}
                fullWidth
                value={this.state.addLabelsCount}
              >
                {Array.from(Array(60).keys()).map((i,index) => (
                  <MenuItem key={index} value={i + 1}>{i + 1}</MenuItem>
                ))}
              </TextField>
              <div className="text-right">
                <Button disabled={this.state.disableButtons} variant='contained' sx={{ marginTop: '15px' }} onClick={this.addLabels}>Etiketten hinzufügen</Button>
              </div>
            </Box>
          </>
        </Modal>
      </div>
    )
  }
};

export default ProcessEntry;
