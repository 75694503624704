import { Badge, Button, FormControl, InputAdornment, InputLabel, OutlinedInput, Paper } from '@mui/material';
import React from 'react';
import { addToleranceValuesManual, deleteToleranceValuesManual, fetchToleranceValues, fetchToleranceValuesResponse, updateToleranceValues, updateToleranceValuesResponse } from '../../../services/configService';
import './ToleranceValues.sass';
import {NotiStore} from '../../../stores/notifications';
import { resetNotifications } from '../../../services/notiStoreService';
import { api } from '../../../services/apiService';

interface ToleranceValuesProps {}
interface ToleranceValuesState {
  flakesMin: number;
  flakesMax: number;
  yellowFlakesMin: number;
  yellowFlakesMax: number;
  poMin: number;
  poMax: number;
  labelsMin: number;
  labelsMax: number;
  paMin: number;
  paMax: number;
  glueMin: number;
  glueMax: number;
  rubberMin: number;
  rubberMax: number;
  multilayerFlakesMin: number;
  multilayerFlakesMax: number;
  otherMin: number;
  otherMax: number;
  pvcMin: number;
  pvcMax: number;
  metalMin: number;
  metalMax: number;
  phValueMin: number;
  phValueMax: number;
  bulkDensityMin: number;
  bulkDensityMax: number;
  moistureMin: number;
  moistureMax: number;
  flakesSubOneMin: number;
  flakesSubOneMax: number;
  flakesOneMin: number;
  flakesOneMax: number;
  flakesTwoMin: number;
  flakesTwoMax: number;
  flakesFourMin: number;
  flakesFourMax: number;
  flakesSixMin: number;
  flakesSixMax: number;
  flakesEightMin: number;
  flakesEightMax: number;
  flakesTenMin: number;
  flakesTenMax: number;
  flakesTwelveMin: number;
  flakesTwelveMax: number;
  flakesFourteenMin: number;
  flakesFourteenMax: number;
  flakesSubOneMinPercent: number;
  flakesSubOneMaxPercent: number;
  flakesOneMinPercent: number;
  flakesOneMaxPercent: number;
  flakesTwoMinPercent: number;
  flakesTwoMaxPercent: number;
  flakesFourMinPercent: number;
  flakesFourMaxPercent: number;
  flakesSixMinPercent: number;
  flakesSixMaxPercent: number;
  flakesEightMinPercent: number;
  flakesEightMaxPercent: number;
  flakesTenMinPercent: number;
  flakesTenMaxPercent: number;
  flakesTwelveMinPercent: number;
  flakesTwelveMaxPercent: number;
  flakesFourteenMinPercent: number;
  flakesFourteenMaxPercent: number;
  toleranceMedium: number;
  manualUrl: string;
}

class ToleranceValues extends React.Component<ToleranceValuesProps, ToleranceValuesState> {
  state = {
    flakesMin: 0,
    flakesMax: 0,
    yellowFlakesMin: 0,
    yellowFlakesMax: 0,
    poMin: 0,
    poMax: 0,
    labelsMin: 0,
    labelsMax: 0,
    paMin: 0,
    paMax: 0,
    glueMin: 0,
    glueMax: 0,
    rubberMin: 0,
    rubberMax: 0,
    multilayerFlakesMin: 0,
    multilayerFlakesMax: 0,
    otherMin: 0,
    otherMax: 0,
    pvcMin: 0,
    pvcMax: 0,
    metalMin: 0,
    metalMax: 0,
    phValueMin: 0,
    phValueMax: 0,
    bulkDensityMin: 0,
    bulkDensityMax: 0,
    moistureMin: 0,
    moistureMax: 0,
    flakesTwoMin: 0,
    flakesTwoMax: 0,
    flakesFourMin: 0,
    flakesFourMax: 0,
    flakesSixMin: 0,
    flakesSixMax: 0,
    flakesEightMin: 0,
    flakesEightMax: 0,
    flakesTenMin: 0,
    flakesTenMax: 0,
    flakesTwelveMin: 0,
    flakesTwelveMax: 0,
    flakesFourteenMin: 0,
    flakesFourteenMax: 0,
    flakesOneMin: 0,
    flakesOneMax: 0,
    flakesSubOneMin: 0,
    flakesSubOneMax: 0,
    flakesSubOneMinPercent: 0,
    flakesSubOneMaxPercent: 0,
    flakesOneMinPercent: 0,
    flakesOneMaxPercent: 0,
    flakesTwoMinPercent: 0,
    flakesTwoMaxPercent: 0,
    flakesFourMinPercent: 0,
    flakesFourMaxPercent: 0,
    flakesSixMinPercent: 0,
    flakesSixMaxPercent: 0,
    flakesEightMinPercent: 0,
    flakesEightMaxPercent: 0,
    flakesTenMinPercent: 0,
    flakesTenMaxPercent: 0,
    flakesTwelveMinPercent: 0,
    flakesTwelveMaxPercent: 0,
    flakesFourteenMinPercent: 0,
    flakesFourteenMaxPercent: 0,
    toleranceMedium: 0,
    manualUrl: '',
  }

  fileInput: HTMLInputElement | null = null;

  componentDidMount() {
    window.history.replaceState(null, "KSM Recycling ERP", "/system/einstellungen?seite=labor");
    NotiStore.setState({loading: true});
    resetNotifications()

    fetchToleranceValues()
    .then((res: fetchToleranceValuesResponse) => {
      if (!res.success) return NotiStore.setState({error: true, errorMessage: 'Beim Abrufen der Toleranzwerte ist ein Fehler aufgetreten.'});

      Object.keys(res.data).forEach((key: string): void => {
        // @ts-ignore: Unreachable code error
        if (Object.keys(this.state).includes(key)) this.setState({[key]: res.data[key]} as any);
      })

      if (res.data.manual && res.data.manual.data && res.data.manual.data.attributes.url) this.setState({manualUrl: res.data.manual.data.attributes.url})
    })
    .catch((e) => {
      console.log(e)
      NotiStore.setState({error: true, errorMessage: 'Beim Abrufen der Toleranzwerte ist ein Fehler aufgetreten.'});
    })
    .finally(() => {
      NotiStore.setState({loading: false});
    })
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let key: string = e.target.name;
    if (Object.keys(this.state).includes(key)) this.setState({[key]: e.target.value} as any);
  }

  handleUpdateToleranceValues = (): void => {
    NotiStore.setState({loading: true});
    resetNotifications()

    let {manualUrl, ...obj} = this.state;

    // @ts-ignore: Unreachable code error
    updateToleranceValues(obj)
    .then((res: updateToleranceValuesResponse) => {
      if (!res.success) return NotiStore.setState({error: true, errorMessage: 'Beim Aktualisieren der Toleranzwerte ist ein Fehler aufgetreten.'});

      Object.keys(res.data).forEach((key: string): void => {
        if (Object.keys(this.state).includes(key)) this.setState({[key]: res.data[key]} as any);
      })

      NotiStore.setState({success: true, successMessage: 'Werte erfolgreich aktualisiert.'});
    })
    .catch((err) => {
      NotiStore.setState({error: true, errorMessage: err.message || 'Beim Aktualisieren der Toleranzwerte ist ein Fehler aufgetreten.'});
    })
    .finally(() => {
      NotiStore.setState({loading: false});
    })
  }

  handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    NotiStore.setState({loading: true});
    resetNotifications();

    if (e.target?.files && e.target.files[0]) {
      addToleranceValuesManual(e.target.files[0])
      .then((res) => {
        this.setState({manualUrl: res.data.url});
        NotiStore.setState({success: true, successMessage: 'PDF erfolgreich hochgeladen.'});;
      })
      .catch(() => NotiStore.setState({error: true, errorMessage: 'Beim Hochladen ist ein Fehler aufgetreten.'}))
      .finally(() => NotiStore.setState({loading: false}));
    } else {
      NotiStore.setState({error: true, errorMessage: 'Beim Hochladen ist ein Fehler aufgetreten.', loading: false});
    }
  }

  handleDeleteManual = (): void => {
    NotiStore.setState({loading: true});
    resetNotifications();

    deleteToleranceValuesManual()
    .then(() => {
      this.setState({manualUrl: ''});
      NotiStore.setState({success: true, successMessage: 'PDF erfolgreich gelöscht.'})
    })
    .catch(() => NotiStore.setState({error: true, errorMessage: 'Beim Löschen der Datei ist ein Fehler aufgetreten.'}))
    .finally(() => NotiStore.setState({loading: false}));
  }

  render() {
    return (
      <div className="ToleranceValues" data-testid="ToleranceValues">
        <div className="header-bar">
          <h2>Toleranz - Standardprüfung</h2>
          <Button variant='contained' onClick={this.handleUpdateToleranceValues}>Werte aktualisieren</Button>
        </div>
        
        <Paper className='tolerance-table'>
          <div className="inner">
            <div className="column">
              <div className="header">farbige Flakes</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesMin">Min</InputLabel>
                <OutlinedInput id='flakesMin' name="flakesMin" label="Min" type="number" value={this.state.flakesMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesMax">Max</InputLabel>
                <OutlinedInput id='flakesMax' name="flakesMax" label="Max" type="number" value={this.state.flakesMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">Gelbe Flakes</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="yellowFlakesMin">Min</InputLabel>
                <OutlinedInput id='yellowFlakesMin' name="yellowFlakesMin" label="Min" type="number" value={this.state.yellowFlakesMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="yellowFlakesMax">Max</InputLabel>
                <OutlinedInput id='yellowFlakesMax' name="yellowFlakesMax" label="Max" type="number" value={this.state.yellowFlakesMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">PO</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="poMin">Min</InputLabel>
                <OutlinedInput id='poMin' name="poMin" label="Min" type="number" value={this.state.poMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="poMax">Max</InputLabel>
                <OutlinedInput id='poMax' name="poMax" label="Max" type="number" value={this.state.poMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">Labels</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="labelsMin">Min</InputLabel>
                <OutlinedInput id='labelsMin' name="labelsMin" label="Min" type="number" value={this.state.labelsMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="labelsMax">Max</InputLabel>
                <OutlinedInput id='labelsMax' name="labelsMax" label="Max" type="number" value={this.state.labelsMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">PA, PS</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="paMin">Min</InputLabel>
                <OutlinedInput id='paMin' name="paMin" label="Min" type="number" value={this.state.paMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="paMax">Max</InputLabel>
                <OutlinedInput id='paMax' name="paMax" label="Max" type="number" value={this.state.paMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">Kleber (Flakes inkl.)</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="glueMin">Min</InputLabel>
                <OutlinedInput id='glueMin' name="glueMin" label="Min" type="number" value={this.state.glueMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="glueMax">Max</InputLabel>
                <OutlinedInput id='glueMax' name="glueMax" label="Max" type="number" value={this.state.glueMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">Gummi</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="rubberMin">Min</InputLabel>
                <OutlinedInput id='rubberMin' name="rubberMin" label="Min" type="number" value={this.state.rubberMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="rubberMax">Max</InputLabel>
                <OutlinedInput id='rubberMax' name="rubberMax" label="Max" type="number" value={this.state.rubberMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">Multilayer Flakes</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="multilayerFlakesMin">Min</InputLabel>
                <OutlinedInput id='multilayerFlakesMin' name="multilayerFlakesMin" label="Min" type="number" value={this.state.multilayerFlakesMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="multilayerFlakesMax">Max</InputLabel>
                <OutlinedInput id='multilayerFlakesMax' name="multilayerFlakesMax" label="Max" type="number" value={this.state.multilayerFlakesMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">Metall</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="metalMin">Min</InputLabel>
                <OutlinedInput id='metalMin' name="metalMin" label="Min" type="number" value={this.state.metalMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="metalMax">Max</InputLabel>
                <OutlinedInput id='metalMax' name="metalMax" label="Max" type="number" value={this.state.metalMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">PVC</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="pvcMin">Min</InputLabel>
                <OutlinedInput id='pvcMin' name="pvcMin" label="Min" type="number" value={this.state.pvcMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="pvcMax">Max</InputLabel>
                <OutlinedInput id='pvcMax' name="pvcMax" label="Max" type="number" value={this.state.pvcMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">sonstige</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="otherMin">Min</InputLabel>
                <OutlinedInput id='otherMin' name="otherMin" label="Min" type="number" value={this.state.otherMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="otherMax">Max</InputLabel>
                <OutlinedInput id='otherMax' name="otherMax" label="Max" type="number" value={this.state.otherMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column scroll-helper">&nbsp;</div>
          </div>
        </Paper>

        <div className="header-bar">
          <h2>Toleranz - erweiterte Prüfung</h2>
          <Button variant='contained' onClick={this.handleUpdateToleranceValues}>Werte aktualisieren</Button>
        </div>

        <Paper className='tolerance-table'>
          <div className="inner">
            <div className="column">
              <div className="header">PH-Wert</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="phValueMin">Min</InputLabel>
                <OutlinedInput id='phValueMin' name="phValueMin" label="Min" type="number" value={this.state.phValueMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="phValueMax">Max</InputLabel>
                <OutlinedInput id='phValueMax' name="phValueMax" label="Max" type="number" value={this.state.phValueMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">Feuchte [%]</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="moistureMin">Min</InputLabel>
                <OutlinedInput id='moistureMin' name="moistureMin" label="Min" type="number" value={this.state.moistureMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="moistureMax">Max</InputLabel>
                <OutlinedInput id='moistureMax' name="moistureMax" label="Max" type="number" value={this.state.moistureMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">Dichte [g/l]</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="bulkDensityMin">Min</InputLabel>
                <OutlinedInput id='bulkDensityMin' name="bulkDensityMin" label="Min" type="number" value={this.state.bulkDensityMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="bulkDensityMax">Max</InputLabel>
                <OutlinedInput id='bulkDensityMax' name="bulkDensityMax" label="Max" type="number" value={this.state.bulkDensityMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">&lt; 1mm</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesSubOneMin">Min g</InputLabel>
                <OutlinedInput id='flakesSubOneMin' name="flakesSubOneMin" label="Min g" type="number" value={this.state.flakesSubOneMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesSubOneMax">Max g</InputLabel>
                <OutlinedInput id='flakesSubOneMax' name="flakesSubOneMax" label="Max g" type="number" value={this.state.flakesSubOneMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesSubOneMinPercent">Min %</InputLabel>
                <OutlinedInput id='flakesSubOneMinPercent' name="flakesSubOneMinPercent" label="Min %" type="number" value={this.state.flakesSubOneMinPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesSubOneMaxPercent">Max %</InputLabel>
                <OutlinedInput id='flakesSubOneMaxPercent' name="flakesSubOneMaxPercent" label="Max %" type="number" value={this.state.flakesSubOneMaxPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">&gt; 1mm</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesOneMin">Min g</InputLabel>
                <OutlinedInput id='flakesOneMin' name="flakesOneMin" label="Min g" type="number" value={this.state.flakesOneMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesOneMax">Max g</InputLabel>
                <OutlinedInput id='flakesOneMax' name="flakesOneMax" label="Max g" type="number" value={this.state.flakesOneMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesOneMinPercent">Min %</InputLabel>
                <OutlinedInput id='flakesOneMinPercent' name="flakesOneMinPercent" label="Min %" type="number" value={this.state.flakesOneMinPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesOneMaxPercent">Max %</InputLabel>
                <OutlinedInput id='flakesOneMaxPercent' name="flakesOneMaxPercent" label="Max %" type="number" value={this.state.flakesOneMaxPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">&gt; 2mm</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesTwoMin">Min g</InputLabel>
                <OutlinedInput id='flakesTwoMin' name="flakesTwoMin" label="Min g" type="number" value={this.state.flakesTwoMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesTwoMax">Max g</InputLabel>
                <OutlinedInput id='flakesTwoMax' name="flakesTwoMax" label="Max g" type="number" value={this.state.flakesTwoMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesTwoMinPercent">Min %</InputLabel>
                <OutlinedInput id='flakesTwoMinPercent' name="flakesTwoMinPercent" label="Min %" type="number" value={this.state.flakesTwoMinPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesTwoMaxPercent">Max %</InputLabel>
                <OutlinedInput id='flakesTwoMaxPercent' name="flakesTwoMaxPercent" label="Max %" type="number" value={this.state.flakesTwoMaxPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">&gt; 4mm</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesFourMin">Min g</InputLabel>
                <OutlinedInput id='flakesFourMin' name="flakesFourMin" label="Min g" type="number" value={this.state.flakesFourMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesFourMax">Max g</InputLabel>
                <OutlinedInput id='flakesFourMax' name="flakesFourMax" label="Max g" type="number" value={this.state.flakesFourMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesFourMinPercent">Min %</InputLabel>
                <OutlinedInput id='flakesFourMinPercent' name="flakesFourMinPercent" label="Min %" type="number" value={this.state.flakesFourMinPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesFourMaxPercent">Max %</InputLabel>
                <OutlinedInput id='flakesFourMaxPercent' name="flakesFourMaxPercent" label="Max %" type="number" value={this.state.flakesFourMaxPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">&gt; 6,3mm</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesSixMin">Min g</InputLabel>
                <OutlinedInput id='flakesSixMin' name="flakesSixMin" label="Min g" type="number" value={this.state.flakesSixMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesSixMax">Max g</InputLabel>
                <OutlinedInput id='flakesSixMax' name="flakesSixMax" label="Max g" type="number" value={this.state.flakesSixMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesSixMinPercent">Min %</InputLabel>
                <OutlinedInput id='flakesSixMinPercent' name="flakesSixMinPercent" label="Min %" type="number" value={this.state.flakesSixMinPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesSixMaxPercent">Max %</InputLabel>
                <OutlinedInput id='flakesSixMaxPercent' name="flakesSixMaxPercent" label="Max %" type="number" value={this.state.flakesSixMaxPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">&gt; 8mm</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesEightMin">Min g</InputLabel>
                <OutlinedInput id='flakesEightMin' name="flakesEightMin" label="Min g" type="number" value={this.state.flakesEightMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesEightMax">Max g</InputLabel>
                <OutlinedInput id='flakesEightMax' name="flakesEightMax" label="Max g" type="number" value={this.state.flakesEightMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesEightMinPercent">Min %</InputLabel>
                <OutlinedInput id='flakesEightMinPercent' name="flakesEightMinPercent" label="Min %" type="number" value={this.state.flakesEightMinPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesEightMaxPercent">Max %</InputLabel>
                <OutlinedInput id='flakesEightMaxPercent' name="flakesEightMaxPercent" label="Max %" type="number" value={this.state.flakesEightMaxPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">&gt; 10mm</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesTenMin">Min g</InputLabel>
                <OutlinedInput id='flakesTenMin' name="flakesTenMin" label="Min g" type="number" value={this.state.flakesTenMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesTenMax">Max g</InputLabel>
                <OutlinedInput id='flakesTenMax' name="flakesTenMax" label="Max g" type="number" value={this.state.flakesTenMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesTenMin">Min %</InputLabel>
                <OutlinedInput id='flakesTenMinPercent' name="flakesTenMinPercent" label="Min %" type="number" value={this.state.flakesTenMinPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesTenMaxPercent">Max %</InputLabel>
                <OutlinedInput id='flakesTenMaxPercent' name="flakesTenMaxPercent" label="Max %" type="number" value={this.state.flakesTenMaxPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">&gt; 12,5mm</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesTwelveMin">Min g</InputLabel>
                <OutlinedInput id='flakesTwelveMin' name="flakesTwelveMin" label="Min g" type="number" value={this.state.flakesTwelveMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesTwelveMax">Max g</InputLabel>
                <OutlinedInput id='flakesTwelveMax' name="flakesTwelveMax" label="Max g" type="number" value={this.state.flakesTwelveMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesTwelveMinPercent">Min %</InputLabel>
                <OutlinedInput id='flakesTwelveMinPercent' name="flakesTwelveMinPercent" label="Min %" type="number" value={this.state.flakesTwelveMinPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesTwelveMaxPercent">Max %</InputLabel>
                <OutlinedInput id='flakesTwelveMaxPercent' name="flakesTwelveMaxPercent" label="Max %" type="number" value={this.state.flakesTwelveMaxPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column">
              <div className="header">14mm</div>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesFourteenMin">Min g</InputLabel>
                <OutlinedInput id='flakesFourteenMin' name="flakesFourteenMin" label="Min g" type="number" value={this.state.flakesFourteenMin} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesFourteenMax">Max g</InputLabel>
                <OutlinedInput id='flakesFourteenMax' name="flakesFourteenMax" label="Max g" type="number" value={this.state.flakesFourteenMax} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesFourteenMinPercent">Min %</InputLabel>
                <OutlinedInput id='flakesFourteenMinPercent' name="flakesFourteenMinPercent" label="Min %" type="number" value={this.state.flakesFourteenMinPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
              <FormControl className='input' error={false}>
                <InputLabel htmlFor="flakesFourteenMaxPercent">Max %</InputLabel>
                <OutlinedInput id='flakesFourteenMaxPercent' name="flakesFourteenMaxPercent" label="Max %" type="number" value={this.state.flakesFourteenMaxPercent} inputProps={{step: .001}} onChange={this.handleChange}></OutlinedInput>
              </FormControl>
            </div>
            <div className="column scroll-helper">&nbsp;</div>
          </div>
        </Paper>

        <div className="flex of-2">
          <div>
            <div className="header-bar">
              <h2>Toleranz - Farben</h2>
            </div>
            <div className="colors-table">
              <div className="table-row strong">
                <div className="table-column">Bereich</div>
                <div className="table-column">Farbe</div>
              </div>
              <div className="table-row">
                <div className="table-column">Toleranz</div>
                <div className="table-column"><Badge color='success' badgeContent=" " /></div>
              </div>
              <div className="table-row">
                <div className="table-column"><OutlinedInput id='toleranceMedium' value={this.state.toleranceMedium} name="toleranceMedium" type="number" startAdornment={<InputAdornment position="start" className='preAdornment'>+<br />-</InputAdornment>} endAdornment={<InputAdornment position="end">%</InputAdornment>} onChange={this.handleChange}></OutlinedInput></div>
                <div className="table-column"><Badge color='warning' badgeContent=" " /></div>
              </div>
              <div className="table-row">
                <div className="table-column">Rest</div>
                <div className="table-column"><Badge color='error' badgeContent=" " /></div>
              </div>
            </div>
          </div>
          <div>
            <div className="header-bar">
              <h2>Analyseverfahren im Detail</h2>
            </div>
            <div className='show-pdf'>
              {this.state.manualUrl ? 
                // TODO: dynamic URL for anchor tag
                <a href={api + this.state.manualUrl} target="_blank" rel='noreferrer' style={{width: '100%'}}>
                  <Button variant="contained" sx={{width: '100%'}}>hinterlegtes PDF ansehen</Button>
                </a>
              :
                <Button disabled variant="contained" sx={{width: '100%'}}>hinterlegtes PDF ansehen</Button>
              }
              <div className='delete' onClick={this.handleDeleteManual}>löschen</div>
            </div>
            <label htmlFor="manual"><strong style={{cursor: 'pointer'}}><span className='plus-btn'>+</span>neue Anleitung hinterlegen</strong></label>
            <input accept='.pdf' style={{display: 'none'}} type="file" name="manual" id="manual" onChange={this.handleFileUpload} ref={(input) => { this.fileInput = input }}  />
          </div>
        </div>
      </div>
    )
  }
};

export default ToleranceValues;
