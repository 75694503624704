import ContentContainer from '../components/ContentContainer/ContentContainer';
import Sidebar from '../components/Sidebar/Sidebar';

export default function SystemUsers() {
  return (
    <main className='System container'>
      <Sidebar />
      <ContentContainer page='users' />
    </main>
  )
}