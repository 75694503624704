import { DeleteForever, Edit, ExpandMore, LockOutlined } from '@mui/icons-material';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Tooltip, TablePagination, Box, CircularProgress, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import React from 'react';
import { Batch, deleteBatch, fetchBatches, StoreToStore, StoreToUse } from '../../../services/batchesService';
import { resetNotifications } from '../../../services/notiStoreService';
import { NotiStore } from '../../../stores/notifications';
import { withRouter } from '../../utils/withRouter/withRouter';
import ProductionBatchesForm from '../ProductionBatchesForm/ProductionBatchesForm';
import './ProductionBatches.sass';

interface ProductionBatchesProps {
  searchParams: { [key: string]: string };
}
interface ProductionBatchesState {
  batches: Batch[];
  storesToStore: StoreToStore[];
  storesToUse: StoreToUse[];
  showPop: boolean;
  activeBatch: number;
  activeBatchIsDraft: boolean;
  showStoragePop: boolean;
  storagePopMode: string;
  batchesFormMode: string;
  showBatchesForm: boolean;
  page: number;
  rowsPerPage: number;
  pagination: { page: number, pageCount: number, pageSize: number, total: number }
}

const defaultState = {
  batches: [],
  storesToStore: [],
  storesToUse: [],
  showPop: false,
  showStoragePop: false,
  storagePopMode: '',
  batchesFormMode: '',
  activeBatch: -1,
  activeBatchIsDraft: false,
  showBatchesForm: false,
  page: 0,
  rowsPerPage: 10,
  pagination: { page: 1, pageCount: 2, pageSize: 100, total: 100 }
}

class ProductionBatches extends React.Component<ProductionBatchesProps, ProductionBatchesState> {
  state: ProductionBatchesState = { ...defaultState }

  componentDidMount() {
    resetNotifications()
    this.fetchData(true)
  }

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    this.setState({ page: this.state.page === 0 ? newPage + 1 : newPage }, this.fetchData)

  };

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
    this.setState({ page: 0 }, this.fetchData)

  };

  fetchData = (checkSearchParams: boolean = false): void => {
    NotiStore.setState({ loading: true });

    let batches = fetchBatches(`pagination[page]=${this.state.page}&pagination[pageSize]=${this.state.rowsPerPage}`);

    Promise.all([batches])
      .then(res => {

        this.setState({
          batches: res[0].data
        }, checkSearchParams ? () => {
          this.setState({ pagination: res[0].meta.pagination })
          // open batch on load
          let { id } = this.props.searchParams;
          if (id) {
            this.startEdit(Number(id), 'view')
          }
          // open batch on load end
        } : undefined)
      })
      .catch(err => {
        NotiStore.setState({ error: true, errorMessage: 'Fehler beim Abrufen der Daten aufgetreten.' })
      })
      .finally(() => {
        NotiStore.setState({ loading: false })
      })
  }

  
  startEdit = (id: number, mode: string = ''): void => {
    this.setState({
      showBatchesForm: true,
      activeBatch: id
    })
  }

  handleOnClick = async (id:number) =>{
    if (window.confirm('Möchten Sie diesen Stapel löschen?')) {
      const response  =  await deleteBatch(id)
      if(response.success){
        NotiStore.setState({loading: false, success: true, successMessage: 'Erfolgreich.'})
  
        this.fetchData()
      }


    }
  }

  render() {
    return (
      <div className="ProductionBatches content" data-testid="ProductionBatches">
        <div className="header-bar">
          <h1>Chargen</h1>
          <Button variant="contained" type='submit' onClick={() => this.setState({ showBatchesForm: true })}>Charge erstellen</Button>
        </div>

        {this.state.batches.length >= 1 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Bezeichnung</TableCell>
                  <TableCell>Produktlager</TableCell>
                  {/* <TableCell>Warenlager</TableCell> */}
                  <TableCell>Verarbeitete Ware</TableCell>
                  <TableCell>Start</TableCell>
                  <TableCell>Ende</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.batches.map((batch: Batch, i: number) => (
                  <TableRow key={`batch-${i}`}>
                    <TableCell>
                      {batch.attributes.status === 'planned' && <Tooltip title="geplant"><div className='swatch yellow'></div></Tooltip>}
                      {batch.attributes.status === 'active' && <Tooltip title="aktiv"><div className='swatch orange'></div></Tooltip>}
                      {batch.attributes.status === 'finished' && <Tooltip title="abgeschlossen"><div className='swatch green'></div></Tooltip>}
                    </TableCell>
                    <TableCell>
                      {batch.attributes.isDraft ? <span className='pointer' onClick={() => this.startEdit(batch.id)}>Entwurf</span> : (
                        <>
                          <strong className='pointer' onClick={() => batch.attributes.status === 'planned' ? this.startEdit(batch.id) : this.startEdit(batch.id, 'view')}>{batch.attributes.customId}</strong>
                        </>
                      )}
                    </TableCell>
                    <TableCell>{batch.attributes.type || 'n/a'}</TableCell>
                    <TableCell>
                      {batch.attributes.storesToStore && batch.attributes.storesToStore.data && Array.isArray(batch.attributes.storesToStore.data) && batch.attributes.storesToStore.data.length >= 1 ? batch.attributes.storesToStore.data.map((store, j) => (
                        <React.Fragment key={`good-stores-${j}`}><span>{store.attributes.name ? store.attributes.name : 'FEHLER'}</span><br /></React.Fragment>
                      )) : 'n/a'}
                    </TableCell>
                    {/* <TableCell>
                      {Array.isArray(batch.attributes.goodsToUse.data) && batch.attributes.goodsToUse.data.length >= 1 ? (
                        <React.Fragment>
                          {batch.attributes.goodsToUse.data.map((good, j) => (batch.attributes.status !== 'finished' || (batch.attributes.status === 'finished' && batch.attributes.goodsToUseData.filter((g: {id: number}) => g.id === good.id)[0].inputDone)) ? (
                            <>{batch.attributes.goodsToUseData.filter((g: {id: number}) => g.id === good.id).length >= 1 && batch.attributes.goodsToUseData.filter((g: {id: number}) => g.id === good.id)[0].inputQuantity > 0 && <React.Fragment key={`good-store-${j}`}><span>{good.attributes.warehouse_spot.data ? good.attributes.warehouse_spot.data.attributes.name : 'nicht gelagert'}</span><br /></React.Fragment>}</>
                          ) : null)}
                        </React.Fragment>
                      ) : 'n/a'}
                    </TableCell> */}
                    <TableCell>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore/>}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                         Verarbeitete Ware View
                        </AccordionSummary>
                        <AccordionDetails>
                          
                      {Array.isArray(batch.attributes.goodsToUse.data) && batch.attributes.goodsToUse.data.length >= 1 ? (
                        <React.Fragment>
                          {batch.attributes.goodsToUse.data.map((good, j) => (batch.attributes.status !== 'finished' || (batch.attributes.status === 'finished' && batch.attributes.goodsToUseData.filter((g: {id: number}) => g.id === good.id)[0].inputDone)) ? (
                            <>{batch.attributes.goodsToUseData.filter((g: {id: number}) => g.id === good.id).length >= 1 && batch.attributes.goodsToUseData.filter((g: {id: number}) => g.id === good.id)[0].inputQuantity > 0 && <React.Fragment key={`good-type-${j}`}><span>{good.attributes.customId} - {good.attributes.typeIntern}{good.attributes.warehouse_spot.data && batch.attributes.status !== 'finished' ? ` (${good.attributes.warehouse_spot.data.attributes.name})` : ''}</span><br /></React.Fragment>}</>
                          ) : null)}
                        </React.Fragment>
                      ) : 'n/a'}
                        </AccordionDetails>
                      </Accordion>
                  
                    </TableCell>
                    <TableCell>{batch.attributes.start ? (`${new Date(batch.attributes.start).toLocaleDateString()} ${new Date(batch.attributes.start).toLocaleTimeString().replace(':00', '')}`) : 'n/a'}</TableCell>
                    <TableCell>{batch.attributes.end ? (`${new Date(batch.attributes.end).toLocaleDateString()} ${new Date(batch.attributes.end).toLocaleTimeString().replace(':00', '')}`) : 'n/a'}</TableCell>
                    <TableCell>
                      {batch.attributes.status === 'planned' ? <Edit className='warning action' onClick={() => this.startEdit(batch.id)} /> : <LockOutlined className='action' onClick={() => this.startEdit(batch.id, 'view')} />}
                    </TableCell>
                    <TableCell>
                      <div 
                      onClick={()=>this.handleOnClick(batch.id)}
                      >
                      <DeleteForever className='error action' />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Paper style={{ padding: '20px' }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="70vh"
              paddingLeft={0}
            >
              <CircularProgress />
            </Box>
            {/* Keine Chargen gefunden. */}
          </Paper>
        )}
        {
          this.state.batches.length >= 1 && 
        <TablePagination
          component={"div"}
          count={this.state.pagination.total}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          rowsPerPage={this.state.rowsPerPage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
        }
        {
         
            <ProductionBatchesForm
              open={this.state.showBatchesForm}
              activeBatch={this.state.activeBatch}
              closeModal={() => this.setState({ showBatchesForm: false, activeBatch: defaultState.activeBatch }, this.fetchData)}
            /> 
        }
      </div>
    )
  }
}

export default withRouter(ProductionBatches);
