import axios from "axios";
import { api } from "./apiService";
import { Batch } from "./batchesService";
import { Good } from "./inventoryService";
import { getJWT, jwtRes } from "./jwtService";

export interface isValidInventoryRes {
  success: boolean;
  data: ValidGood;
  message?: string;
}

interface ValidGood extends Good {
  inputQuantity?: number;
  inputDone?: number;
}

export interface submitFillDataRes {
  success: boolean;
  message?: string;
}

export interface finishBatchRes {
  success: boolean;
  message?: string;
}

export interface createProductFromProductionRes {
  success: boolean;
  data: Upload[];
  message?: string;
}

export interface Upload {
  url: string;
  name: string;
}

export interface ScannedGood extends Good {
  input: number;
}

export interface FillData {
  scannedGoods: ScannedGood[],
  batch: Batch
}

export interface CreateData {
  batch: Batch,
  scaleWeight: number,
  withPalette: boolean,
  qrCategory: string,
  qrType: string,
}

export let isValidInventory = (customId: string): Promise<isValidInventoryRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: []
      })
      
      //do stuff here
      axios.get(api + `/api/goods?filters[customId][$eq]=${customId.toUpperCase()}&filters[$or][0][status][$eq]=stored&filters[$or][1][status][$eq]=util_in_use&populate=*`, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        if (res.data.data.length !== 1) return reject({
          success: false,
          data: [],
          message: 'Kein gelagertes Produkt mit dieser ID gefunden.'
        })

        resolve({
          success: true,
          data: res.data.data[0]
        })
      })
      .catch(err => {
        reject({
          success: false,
          data: []
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: []
      })
    })
  });
}

export let submitFillData = (data: FillData): Promise<submitFillDataRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: []
      })

      
      //do stuff here
      axios.post(api + `/api/batches/fill`, {data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true
        })
      })
      .catch(err => {
        let message: string = '';

        try {
          message = err.response.data.error.message
        } catch {
          message = 'Fehler beim Übermitteln der Daten.'
        }

        reject({
          success: false,
          data: false,
          message
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: []
      })
    })
  });
}

export let createProductFromProduction = (data: CreateData): Promise<createProductFromProductionRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: []
      })

      
      //do stuff here
      axios.post(api + `/api/goods/create-from-production`, {data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          data: res.data
        })
      })
      .catch(err => {
        let message: string = '';

        try {
          message = err.response.data.error.message
        } catch {
          message = 'Fehler beim Übermitteln der Daten.'
        }

        reject({
          success: false,
          data: false,
          message
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: []
      })
    })
  });
}

export let finishBatch = (id: number): Promise<finishBatchRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: []
      })

      
      //do stuff here
      axios.post(api + `/api/batches/finish`, {data: {id}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true
        })
      })
      .catch(err => {
        console.log(err)

        let message: string = '';

        try {
          message = err.response.data.error.message
        } catch {
          message = 'Fehler beim Übermitteln der Daten.'
        }

        reject({
          success: false,
          data: false,
          message
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: []
      })
    })
  });
}

export interface fetchGoodsFromTruckRes {
  success: boolean; 
  message: string;
  data: ValidGood[];
}

export let fetchGoodsFromTruck = (string: string): Promise<fetchGoodsFromTruckRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: []
      })

      
      //do stuff here
      axios.post(api + `/api/order/goods-from-truck`, {data: {string}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          message: 'success',
          data: res.data || []
        })
      })
      .catch(err => {
        console.log(err)

        let message: string = '';

        try {
          message = err.response.data.error.message
        } catch {
          message = 'Fehler beim Abrufen der Daten.'
        }

        reject({
          success: false,
          data: false,
          message
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: []
      })
    })
  });
}