import axios from "axios";
import { api } from "./apiService";
import { getJWT, jwtRes } from "./jwtService";

export interface createPDFResponse {
  success: boolean;
  data?: {};
  message?: string;
}

export let createPDF = (pdfType: string, objectId: number): Promise<createPDFResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })

      if (pdfType.includes('lab_') && (pdfType === 'lab_ext' || pdfType === 'lab_int')) {
        let data = {
          type: pdfType,
          id: objectId
        }

        axios.post(api + '/api/laboratory-reports/create-pdf', data, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
        .then((res) => {
          resolve({
            success: true
          })
        })
        .catch(err => {
          console.log(err)
          reject({
            success: false
          })
        })
      } else {
        reject({
          success: false,
          message: 'type not valid'
        })
      }
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'jwt catch triggered'
      })
    })
  })
}