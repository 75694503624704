import { ArrowRightAlt, Close, ExpandMoreOutlined, QrCode2 } from '@mui/icons-material';
import { Modal, Box, TextField, Accordion, AccordionSummary, AccordionDetails, Button, MenuItem, InputAdornment } from '@mui/material';
import printJS from 'print-js';
import React from 'react';
import { Link } from 'react-router-dom';
import { fetchInvetory, Good } from '../../../services/inventoryService';
import { createLabLabel, newLabReportData, Report, saveNewLabReport, testOptionsExtended, testOptionsStandard } from '../../../services/laboratoryService';
import { resetNotifications } from '../../../services/notiStoreService';
import { convertGoodsCategory } from '../../../services/stringConverter';
import { NotiStore } from '../../../stores/notifications';
import './NewLabReportModal.sass';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'scroll'
};

interface NewLabReportModalProps {
  active: boolean;
  onClose: () => void;
  fetchData: () => void;
  modalEditDraftMode: boolean;
  modalEditDraftData: Report | null;
  isDraft: boolean;
  mode: string;
  isCreateLabel: boolean;
}

interface NewLabReportData {
  category: string;
  type: string;
  productId: string;
  tests: {
    [key: string]: string
  }[]
}

interface NewLabReportModalState {
  newReportData: NewLabReportData;
  expandedTest: number;
  goods: Good[];
}

const defaultState: NewLabReportModalState = {
  newReportData: {
    category: '',
    type: '',
    productId: '',
    tests: [{}]
  },
  expandedTest: 1,
  goods: [],
}

class NewLabReportModal extends React.Component<NewLabReportModalProps, NewLabReportModalState> {
  state: NewLabReportModalState = {...defaultState}

  componentDidMount() {
    resetNotifications()
    this.fetchData();
  }

  fetchData = (): void => {
    NotiStore.setState({loading: true});

    let goods = fetchInvetory('filters[status]=stored');

    Promise.all([goods])
    .then(res => {
      try {
        this.setState({goods: res[0].data});
      } catch {}
    })
    .catch(err => {
      NotiStore.setState({error: true, errorMessage: 'Fehler beim Abrufen der Daten aufgetreten.'})
    })
    .finally(() => {
      NotiStore.setState({loading: false})
    })
  }

  componentDidUpdate(prevProps: NewLabReportModalProps) {
    if (!prevProps.modalEditDraftMode && this.props.modalEditDraftMode) {
      this.setState({newReportData: {
          category: this.props.modalEditDraftData?.attributes.good.data ? this.props.modalEditDraftData?.attributes.good.data.attributes.category : '',
          type: this.props.modalEditDraftData?.attributes.good.data ? this.props.modalEditDraftData?.attributes.good.data.attributes.typeIntern : '',
          productId: this.props.modalEditDraftData?.attributes.good.data ? this.props.modalEditDraftData?.attributes.good.data.id.toString() : '',
          tests: this.props.modalEditDraftData?.attributes.testData || [{}]
        }
      })
    } else if (prevProps.modalEditDraftMode && !this.props.modalEditDraftMode) {
      this.setState({newReportData: {
          category: '',
          type: '',
          productId: '',
          tests: [{}]
        }
      })
    }
  }

  handleTestAccordion = (id: number): void => {
    this.state.newReportData.tests.length > 1 && this.setState({expandedTest: this.state.expandedTest === id ? 1 : id});
  }

  addNewTest = ():void => {
    let newObj: {[key: string]: string} = {};

    testOptionsStandard.forEach(o => {
      newObj[o.id] = '';
    })

    this.setState(prevState => (
      {newReportData: {
        ...prevState.newReportData,
        tests: [...prevState.newReportData.tests, newObj]
      }}
    ))
  }

  handleDeleteTest = (id: number): void => {
    this.state.newReportData.tests[id] && this.setState(prevState => ({
      newReportData: {
        ...prevState.newReportData,
        tests: prevState.newReportData.tests.filter((test, aId) => id !== aId)
      },
      expandedTest: prevState.newReportData.tests.length <= 2 ? 1 : prevState.expandedTest
    }));
  }

  handleReportDataChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let {value} = e.target;
    
    let match = this.state.goods.filter((g: Good) => g.id.toString() === value.toString());

    if (match.length === 1 && match[0].attributes) {
      this.setState({
        newReportData: {
          category: match[0].attributes.category ? match[0].attributes.category : '',
          type: match[0].attributes.typeIntern ? match[0].attributes.typeIntern : '',
          productId: value,
          tests: [{}]
        }
      })
    }
  }

  handleTestValChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      let {name, value} = e.target;
      let testId = Number(name.split('-')[1]);
      let fieldId = name.split('-')[0];

      if (testId <= this.state.newReportData.tests.length - 1) {
        let {tests} = this.state.newReportData;

        tests[testId][fieldId] = value || ''; 

        this.setState(prevState => ({
          newReportData: {
            ...prevState.newReportData,
            tests: tests
          }
        }))
      }
    } catch {}
  }

  handleSaveNewReport = (asDraft: boolean = false, isUpdate: boolean = false, reportId: number = -1):void => {
    resetNotifications();
    NotiStore.setState({loading: true});

    let error: string | undefined = undefined;

    this.state.newReportData.tests.forEach((test, i) => {
      testOptionsStandard.forEach((option, j) => {

        if (!asDraft && (!test[option.id])) error = 'Bitte fülle bei allen Proben alle Felder der Standardprüfung aus.';

        if (i === this.state.newReportData.tests.length - 1 && j === testOptionsStandard.length - 1) {
          if (!this.state.newReportData.category) error = 'Bitte wähle eine Kategorie.'
          else if (!this.state.newReportData.type) error = 'Bitte wähle eine Bezeichnung.'

          if (error) {
            NotiStore.setState({error: true, errorMessage: error, loading: false})
          } else {
            let data: newLabReportData = {
              category: this.state.newReportData.category,
              type: this.state.newReportData.type,
              testData: this.state.newReportData.tests,
              isDraft: asDraft,
              good: this.state.newReportData.productId
            }

            saveNewLabReport(data, isUpdate, reportId)
            .then(res => {
              NotiStore.setState({success: true, successMessage: 'Analysebericht erfolgreich gespeichert.'})
              this.setState(prevState => ({...defaultState, goods: prevState.goods}))

              this.props.onClose();
              this.props.fetchData();
            })
            .catch(err => {
              NotiStore.setState({loading: false, error: true, errorMessage: 'Beim Speichern des Berichts ist ein Fehler aufgetreten.'})
            })
          }
        }
      })
    })
  }

  createLabel = (): void => {
    resetNotifications()
    NotiStore.setState({loading: true})

    let {productId} = this.state.newReportData

    if (!productId) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte wähle ein Produkt.'})
    } else {
      createLabLabel(productId)
      .then(res => {
        NotiStore.setState({loading: false, success: true, successMessage: 'Label erfolgreich erstellt.'})
        this.props.onClose()
        this.props.fetchData()

        if (res.data) this.printFile(res.data)
      })
      .catch(err => {
        NotiStore.setState({loading: false, error: true, errorMessage: err.message || 'Bitte wähle ein Produkt.'})
      })
    }
  }

  printFile = (url: string): void => {
    if (url) printJS({
      printable: url,
      type: 'pdf'
    });
  }

  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={this.props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="NewLabReportModal"
      >
        <>
          <span className='modal-close pointer' onClick={this.props.onClose}><Close /></span>
          <Box sx={modalStyle}>
            <div className='modal-close-btn' onClick={this.props.onClose}><Close /></div>
            <div className="header-bar">
              <h2>{this.props.isDraft ? (this.props.isCreateLabel ? 'Neues Label erstellen' : 'Neue Analyse durchführen') : `Analyse für ${this.props.modalEditDraftData?.attributes.customId}`}</h2>
              {!this.props.isDraft && this.props.modalEditDraftData?.attributes.label && this.props.modalEditDraftData?.attributes.label?.data?.attributes.url && <div><a href={this.props.modalEditDraftData?.attributes.label?.data?.attributes.url} target='_blank' rel='noreferrer' style={{color: 'white'}}><Button variant='contained'><QrCode2 /></Button></a></div>}
            </div>
            <h3>Grunddaten</h3>
            <div className="basic-data flex of-3 spacing-1">
            <div style={{position: 'relative'}}>
                <TextField
                  name="productId"
                  error={this.state.newReportData.productId ? false :true}
                  value={this.state.newReportData.productId}
                  helperText="ID - Produkt/Ware"
                  select
                  fullWidth
                  onChange={this.handleReportDataChange}
                  disabled={!this.props.isDraft}
                  InputProps={this.props.isDraft ? {} : {sx: {fontWeight: 'bold'}}}
                >
                  {this.state.goods.length >= 1 ? this.state.goods.map((good: Good, i) => (good.attributes.customId && !good.attributes.customId.toLowerCase().startsWith('bm-') && !good.attributes.customId.toLowerCase().startsWith('d-')) ? (
                    <MenuItem key={'goodselect-'+i} value={good.id}>{good.attributes.customId}</MenuItem>
                  ) : null) : <MenuItem disabled>Keine Produkte gefunden</MenuItem>}
                </TextField>
                {!this.props.isDraft && this.props.modalEditDraftData && this.props.modalEditDraftData.attributes.good && this.props.modalEditDraftData.attributes.good.data && <Link className='overlay-link' to={`/lager/inventar?id=${this.props.modalEditDraftData.attributes.good.data.id || -1}`}></Link>}
              </div>
              <div>
                <TextField
                  disabled
                  fullWidth
                  error={convertGoodsCategory(this.state.newReportData.category) ? false :true}
                  value={convertGoodsCategory(this.state.newReportData.category)}
                  helperText='Kategorie'
                />
              </div>
              <div>
                <TextField
                  disabled
                  fullWidth
                  error={this.state.newReportData.type ? false :true}
                  value={this.state.newReportData.type}
                  helperText='Bezeichnung'
                />
              </div>
            </div>
            {!this.props.isCreateLabel ? (
              <div className="tests">
                <h3>Analysewerte eintragen</h3>
                {this.state.newReportData.tests.map((test, i) => (
                  <Accordion key={'test-'+i} className="test" expanded={this.state.expandedTest === i + 1}>
                    <AccordionSummary
                      expandIcon={this.state.newReportData.tests.length > 1 ? <ExpandMoreOutlined onClick={() => this.handleTestAccordion(i + 1)} style={{cursor: this.state.newReportData.tests.length < 2 || this.state.expandedTest === i+1 ? 'default' : 'pointer'}} /> : null}
                      style={{cursor: 'default'}}
                    >
                      <strong>Probe {i + 1}</strong>
                      {this.state.newReportData.tests.length > 1 && <u style={{marginLeft: 20, cursor: 'pointer'}} onClick={() => this.handleDeleteTest(i)}>löschen</u>}
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{marginBottom: 20}}>Standardprüfung [ppm]</div>
                      <div>
                        <div className="flex of-6 spacing-1">
                          {testOptionsStandard.map((o, j) => (
                            <div key={o.title+i+j} style={{marginBottom: 15}}>
                              <div className="label">{o.title}</div>
                              <div className="input">
                              <TextField
                                name={`${o.id}-${i}`}
                                type='text'
                                error={this.state.newReportData.tests[i][o.id] ? false :true}
                                onChange={this.handleTestValChange}
                                //@ts-ignore
                                value={this.state.newReportData.tests[i][o.id] ||  ''}
                              />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                          <ArrowRightAlt style={{position: 'relative', bottom: 1, opacity: .8}} /> erweiterte Prüfung [ppm]
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="flex of-6 spacing-1">
                            {testOptionsExtended.map((o, j) => (
                              <React.Fragment key={o.title+i+j}>
                                <div style={{marginBottom: 15}}>
                                  <div className="label">{o.title}</div>
                                  <div className="input">
                                  <TextField
                                    name={`${o.id}-${i}`}
                                    type='text'
                                    onChange={this.handleTestValChange}
                                    value={this.state.newReportData.tests[i][o.id] || ''}
                                    InputProps={o.id.includes('flakes') ? {
                                      endAdornment: <InputAdornment position="end">g</InputAdornment>
                                    } : {}}
                                  />
                                  </div>
                                  {o.id.includes('flakes') && (
                                    <div style={{marginTop: 15}}>
                                      <div className="input">
                                      <TextField
                                        name={`${o.id+'Percent'}-${i}`}
                                        type='text'
                                        onChange={this.handleTestValChange}
                                        value={this.state.newReportData.tests[i][o.id+'Percent'] || ''}
                                        InputProps={{
                                          endAdornment: <InputAdornment position="end">%</InputAdornment>
                                        }}
                                      />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </React.Fragment>
                            ))}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </AccordionDetails>
                  </Accordion>
                ))}
            
                {this.props.mode === 'view' ? null : (
                  <div onClick={this.addNewTest}>
                    <strong style={{cursor: 'pointer', marginTop: 20, display: 'block'}}><span className='plus-btn'>+</span>weitere Probe hinzufügen</strong>
                  </div>
                )}

                {this.props.mode === 'view' ? null : (
                  <div className='flex space-between' style={{marginTop: 30}}>
                    {this.props.isDraft && <Button variant='outlined' onClick={this.props.modalEditDraftMode ? () => this.handleSaveNewReport(true, this.props.modalEditDraftMode, this.props.modalEditDraftData?.id || -1) : () => this.handleSaveNewReport(true)}>Als Entwurf speichern</Button>}
                    <Button variant='contained' onClick={() => {if (window.confirm("Bist du dir sicher, dass die eingetragenen Werte keine Mess-/Tippfehler enthalten?\n\nNach dem Abschließen der Analyse ist es dir nicht mehr möglich die Daten zu überarbeiten.")) {this.props.modalEditDraftMode ? this.handleSaveNewReport(false, this.props.modalEditDraftMode, this.props.modalEditDraftData?.id || -1) : this.handleSaveNewReport()}}}>Analyse speichern</Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-right" style={{marginTop: 30}}>
                <Button variant='contained' onClick={this.createLabel}>Label erstellen</Button>
              </div>
            )}
          </Box>
        </>
      </Modal>
    )
  }
};

export default NewLabReportModal;
