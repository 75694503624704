import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import './WarehouseCalendar.sass';
import { NotiStore } from '../../../stores/notifications';
import { resetNotifications } from '../../../services/notiStoreService';
import { getSymbol } from '../../../services/stringConverter';
import { fetchOrdersWithFilters, OrderData } from '../../../services/warehouseEntriesService';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ExitOrder, fetchExitOrdersWithFilters } from '../../../services/warehouseExitService';
import { Link } from 'react-router-dom';
import { FormatListBulletedSharp } from '@mui/icons-material';

// calendar german language: require('moment/locale/de.js')

interface WarehouseCalendarProps {
  type: string;
  dashboard:boolean;
}
interface WarehouseCalendarState {
  events: Event[];
  eventsFilter: Event[];
  calendarDate: Date;
  calendarView: string;
  start: string;
  end: string;
  labelType: number;
  filterLabelType: any;
}

interface Event {
  title: string;
  status: string;
  start: Date;
  end: Date;
  id: number;
  titles: string[];
  type: string;
  isFulfilled: boolean;
}

const localizer = momentLocalizer(moment)

const defaultState = {
  events: [],
  eventsFilter:[],
  calendarDate: new Date(Date.now()),
  calendarView: 'month',
  start: '',
  end: '',
  labelType: 0,
  filterLabelType:'clear'
}

const eventStyleGetter = function(event: Event) {
    var backgroundColor = '';
    var border = '';
    var color = 'black';

    if (event.type === 'exit') backgroundColor = '#ddd'
    if (event.type === 'entry') backgroundColor = '#fff'

    if (event.isFulfilled) border = '2px solid green'
    else border = '2px solid red'

    var style = {
      color,
      border,
      backgroundColor: backgroundColor,
      fontSize: '12px',
      textAlign: 'center'
    };
    return {
      style: style
    };
}

class WarehouseCalendar extends React.Component<WarehouseCalendarProps, WarehouseCalendarState> {
  state: WarehouseCalendarState = {
    ...defaultState
  }

  componentDidMount() {
    resetNotifications()
    this.calcCalendar()
  }

  fetchData = (): void => {
    NotiStore.setState({loading: true});
    let {start, end} = this.state;
    
    if (start && end) {
      let ordersForView = fetchOrdersWithFilters({
        isDraft: ['false'],
        isCancelled: ['false'],
        end: [moment(new Date(end)).add(1, "days").format('YYYY-MM-DD')], 
        start: [start],
      });
      
      let exitOrdersForView = fetchExitOrdersWithFilters({
        isDraft: ['false'],
        isCancelled: ['false'],
        end: [moment(new Date(end)).add(1, "days").format('YYYY-MM-DD')], 
        start: [start],
      });

      Promise.all([ordersForView, exitOrdersForView])
      .then(res => {
        try {
          let events: Event[] = [];
          let eventsFilter: Event[] = [];

          if (res[0] && res[0].data && res[0].data.length >= 1) res[0].data?.forEach((order: {id: number, attributes: OrderData}) => {
            if (order.attributes.positions && order.attributes.positions.length >= 1) order.attributes.positions.forEach(pos => {
              if (pos.truckData && pos.truckData.length >= 1) pos.truckData.forEach(truck => {
                let date: Date | undefined;
                
                if (truck.actualDeliveryDate) {
                  date = new Date(truck.actualDeliveryDate)
                } else if (truck.truckDeliveryDate) {
                  date = new Date(truck.truckDeliveryDate)
                }
                
                if (date) {
                  let titles: string[] = [
                    `${order.attributes.customId || 'n/a'}`,
                    `${getSymbol(pos.category) || 'n/a'} / ${pos.typeIntern || pos.typeOffer || 'n/a'}`
                  ];
                  
                  let event: Event = {
                    title: titles[this.state.labelType <= titles.length ? this.state.labelType : 0],
                    status: '???',
                    start: new Date(date),
                    end: new Date(date),
                    id: order.id,
                    titles,
                    type: 'entry',
                    isFulfilled: truck.fulfilled || false,
                  }                  
                  events.push(event);
                  eventsFilter.push(event)
                }
              })
            })
          })
          
          if (res[1] && res[1].data && res[1].data.length >= 1) res[1].data?.forEach((order: ExitOrder) => {
            if (order.attributes.positions && order.attributes.positions.length >= 1) order.attributes.positions.forEach(pos => {
              if (pos.trucks && pos.trucks.length >= 1) pos.trucks.forEach(truck => {
                let date: Date | undefined = truck.dispatchDate ? new Date(truck.dispatchDate) : undefined;

                if (date) {
                  let titles: string[] = [
                    `${order.attributes.customId || 'n/a'}`,
                    `${getSymbol(pos.name) || 'n/a'}`
                  ];

                  let event: Event = {
                    title: titles[this.state.labelType <= titles.length ? this.state.labelType : 0],
                    status: '???',
                    start: new Date(date),
                    end: new Date(date),
                    id: order.id,
                    titles,
                    type: 'exit',
                    isFulfilled: truck.fulfilled || false,
                  }
    
                  events.push(event);
                  eventsFilter.push(event)
                }
              })
            })
          })

          this.setState({events})
          this.setState({eventsFilter})
        } catch {}
      })
      .catch(err => {
        NotiStore.setState({error: true, errorMessage: 'Fehler beim Abrufen der Daten aufgetreten.'})
      })
      .finally(() => {
        NotiStore.setState({loading: false})
      })
    }
    this.setState({filterLabelType:'clear'})
  }

  onCalendarNavigate = (date: Date, view: string): void => {
    this.setState({calendarDate: date, calendarView: view}, () => this.calcCalendar());
  }

  onCalendarView = (view: string): void => {
    this.setState({calendarView: view}, () => this.calcCalendar());
  }

  calcCalendar = () => {
    let view = this.state.calendarView
    let date = this.state.calendarDate

    if (view === 'week') {
      this.setState({start: moment(date).startOf('isoWeek').format('YYYY-MM-DD'), end: moment(date).endOf('isoWeek').format('YYYY-MM-DD')}, () => this.fetchData())
    } else if (view === 'month') {
      this.setState({start: moment(date).startOf('month').format('YYYY-MM-DD'), end: moment(date).endOf('month').format('YYYY-MM-DD')}, () => this.fetchData())
    }
  }

  handleLabels = (e: React.MouseEvent<HTMLElement>, val: string | undefined) => {
    if (val && !isNaN(Number(val))) {
      this.setState({labelType: Number(val)}, () => {
        let {events} = this.state

        if (events.length >= 1) events.forEach(event => {
          if (event.titles && this.state.labelType <= event.titles.length) event.title = event.titles[this.state.labelType]
        })

        this.setState({events})
      })
    }
  }

  handleSelectEvent = (e: any) => {
    let {id, type} = e;
    
    if (type === 'entry' && id) {
      window.open(`/wareneingang?id=${id}`, '_blank')
    } else if (type === 'exit' && id) {
      window.open(`/warenausgang?id=${id}`, '_blank')
    }
  }
  

  handleChangeType = (e: React.MouseEvent<HTMLElement>, val: string | undefined) =>{
    if(val === 'clear'){
      this.fetchData()
      this.setState({filterLabelType:val})
    }else{

      const data =  this.state.eventsFilter.filter((event:any)=>{
        return event.type === val
       })
       this.setState({events:data,filterLabelType:val})
    }
  }
  render() {
    return (
      <div className="WarehouseCalendar content" data-testid="WarehouseCalendar">
        <div className="header-bar">
          <h1>
            {this.props.type === 'entry' && (
              <React.Fragment>
                Incoming
                <Link to={'/wareneingang'} style={{marginLeft: 10}}><Button variant='contained' color='secondary'><FormatListBulletedSharp /></Button></Link>
              </React.Fragment>
            )}
            {this.props.type === 'exit' && (
              <React.Fragment>
                Outgoing
                <Link to={'/warenausgang'} style={{marginLeft: 10}}><Button variant='contained' color='secondary'><FormatListBulletedSharp /></Button></Link>
              </React.Fragment>
            )}
          </h1>
          <div>
          <ToggleButtonGroup
            value={this.state.filterLabelType}
            exclusive
            onChange={this.handleChangeType}
            aria-label="label type"
            size='small'
          >
            <ToggleButton value="entry" aria-label="ID">
              Incomming
            </ToggleButton>
            <ToggleButton value="exit" aria-label="type">
              Outgoing
            </ToggleButton>
            <ToggleButton color="error" value="clear" aria-label="type">
              Clear
            </ToggleButton>
          </ToggleButtonGroup>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <ToggleButtonGroup
            value={this.state.labelType.toString()}
            exclusive
            onChange={this.handleLabels}
            aria-label="label type"
            size='small'
          >
            <ToggleButton value="0" aria-label="ID">
              ID Bestellung
            </ToggleButton>
            <ToggleButton value="1" aria-label="type">
              Bezeichnung
            </ToggleButton>
          </ToggleButtonGroup>
          

          </div>
        </div>

        <div className={this.props.dashboard ? "dashboard-calendar" : "calendar-container"}>
          <Calendar
            localizer={localizer}
            events={this.state.events}
            onSelectEvent={this.handleSelectEvent}
            // onSelectSlot={this.onSelectSlot}
            onNavigate={this.onCalendarNavigate}
            onView={this.onCalendarView}
            startAccessor="start"
            endAccessor="end"
            selectable
            showAllEvents
            messages={{
              next: "forward",
              previous: "back",
              today: "Today",
              month: "Month",
              week: "Week",
              day: "Tag"
            }}
            // @ts-ignore
            eventPropGetter={(eventStyleGetter)}
          />
        </div>
      </div>
    )
  }
}

export default WarehouseCalendar;
