import { Close, DeleteForever, ExpandMoreOutlined, InfoOutlined, LockOpenOutlined, LockOutlined, QrCode2, ScaleOutlined } from '@mui/icons-material';
import { Button, Paper, TextField, Modal, Box, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, InputAdornment, MenuItem, Alert, Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { emptyBatch, Batch, fetchBatches } from '../../../services/batchesService';
import { fetchInvetory, Good } from '../../../services/inventoryService';
import { resetNotifications } from '../../../services/notiStoreService';
import { createProductFromProduction, fetchGoodsFromTruck, finishBatch, isValidInventory, ScannedGood, submitFillData, Upload } from '../../../services/productionService';
import { convertGoodsCategory, convertOrderAttributes, localNumber, localNumberInTons, minToTime } from '../../../services/stringConverter';
import { NotiStore } from '../../../stores/notifications';
import printJS from 'print-js'
import './ProcessProduction.sass';
import { addUtility, fetchUtilities, removeUtility, RemoveUtilityData } from '../../../services/utilitiesService';
import FactoryIcon from '../../../assets/icons/produktion.png';
import { WarehouseSpot } from '../../../services/configService';
import { PDFDocument } from 'pdf-lib';
// var QRCode = require("qrcode");

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'scroll',
  overflowX: 'hidden'
};

interface ProcessProductionProps {}
interface ProcessProductionState {
  activeBatch: Batch;
  selectedBatch: number;
  batches: Batch[];
  goods: Good[];
  scannedGoods: ScannedGood[];
  showBatchesPop: boolean;
  showDetailsPop: boolean;
  showFillPop: boolean;
  showQrPop: boolean;
  showScanInput: boolean;
  scanVal: string;
  scanValManual: string;
  scaleWeight: number;
  disableScaleInput: boolean;
  withPalette: boolean;
  qrCategory: string;
  qrType: string;
  qrData: Upload[];
  showUtilsPop: boolean;
  utilities: Good[];
  utilitiesStored: Good[];
  expandedUtil: number;
  utilReason: string;
  addUtilId: string;
  disableButtons: boolean;
  sort:boolean
}

const defaultState = {
  activeBatch: {...emptyBatch},
  selectedBatch: -1,
  batches: [],
  goods: [],
  scannedGoods: [],
  showBatchesPop: false,
  showDetailsPop: false,
  showFillPop: false,
  showQrPop: false,
  showScanInput: false,
  scanVal: '',
  scanValManual: '',
  scaleWeight: 0,
  disableScaleInput: true,
  withPalette: false,
  qrCategory: '',
  qrType: '',
  qrData: [],
  showUtilsPop: false,
  utilities: [],
  expandedUtil: -1,
  utilReason: '',
  utilitiesStored: [],
  addUtilId: '',
  disableButtons: false,
  sort:false,
}

class ProcessProduction extends React.Component<ProcessProductionProps, ProcessProductionState> {
  state = {...defaultState}

  componentDidMount() {
    resetNotifications()
    this.fetchData()

    window.addEventListener("keydown", this.onScan, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onScan, false);
  }

  fetchData = (): void => {
    NotiStore.setState({loading: true});

    let batches = fetchBatches('filters[isDraft]=false', 'start');
    let goods = fetchInvetory('filters[status]=stored');
    let utilities = fetchUtilities();
    Promise.all([batches, goods, utilities])
    .then(res => {
      try {
        let batches = res[0].data;
        console.log(batches,"batchesbatchesbatchesbatchesbatchesbatchesbatchesbatches")
        let activeBatch: Batch = {...emptyBatch};
        let filteredBatches: Batch[] = [];
        if (batches.length >= 1) {
          batches.forEach(batch => {
            if (batch.attributes.status === 'planned' || batch.attributes.status === 'active') {
              filteredBatches.push(batch)
            }

            if (batch.attributes.status === 'active') activeBatch = batch;
          })
        }

        let utilitiesStored: Good[] = [];

        res[1].data.forEach(good => {
          if (good.attributes.customId.toLowerCase().includes('bm-') && !res[2].data.includes(good)) utilitiesStored.push(good);
        })

        this.setState({batches: filteredBatches, goods: res[1].data, activeBatch, utilities: res[2].data, utilitiesStored});
      } catch {}
    })
    .catch(err => {
      NotiStore.setState({error: true, errorMessage: 'Fehler beim Abrufen der Daten aufgetreten.'})
    })
    .finally(() => {
      NotiStore.setState({loading: false})
    })
  }

  startBatch = () => {
    resetNotifications();
    NotiStore.setState({loading: true})

    let {selectedBatch} = this.state;

    if (selectedBatch < 0) {
      NotiStore.setState({error: true, errorMessage: 'Bitte wähle die Charge, die du starten möchtest.'})
    } else {
      let match = this.state.batches.filter((b: Batch) => b.id === selectedBatch);

      if (match.length !== 1) {
        NotiStore.setState({error: true, errorMessage: 'Fehler beim Abrufen der Daten.'})
      } else {
        this.setState({selectedBatch: defaultState.selectedBatch, showBatchesPop: false, activeBatch: match[0]})
      }
    }

    NotiStore.setState({loading: false})
  }

  onScan = (e: any): void => {
    if (this.state.showFillPop || this.state.showUtilsPop) {
      let {scanVal} = this.state;

      if (e.key && e.key.toLowerCase() !== 'enter' && e.key.length === 1) {
        scanVal += e.key;
        this.setState({scanVal})
      } else if (e.key && e.key.toLowerCase() === 'enter') {
        this.setState({scanVal: defaultState.scanVal})

        if (this.state.showScanInput) scanVal = this.state.scanValManual

        if (scanVal.includes('#')) scanVal = scanVal.replace('#', '')
        
        if (this.state.showUtilsPop && !scanVal.toLowerCase().includes('bm-')) {
          this.setState({scanVal: defaultState.scanVal, scanValManual: defaultState.scanValManual});
          NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte verwende eine gültige Betriebsmittel-ID.'})
        } else {
          NotiStore.setState({loading: true})

          if (scanVal.startsWith('truck__')) {
            fetchGoodsFromTruck(scanVal)
            .then(res => {
              let scannedGoods = this.state.scannedGoods || []

              if (res.data.length >= 1) res.data.forEach(good => {
                // @ts-ignore
                if (scannedGoods.length === 0 || scannedGoods.filter((sGood: ScannedGood) => sGood.id === good.id).length <= 0) scannedGoods.push({...good, input: this.getRequiredInput(good) || 0})
              })

              this.setState({scanVal: defaultState.scanVal, scanValManual: defaultState.scanValManual, scannedGoods, showScanInput: false})
              NotiStore.setState({loading: false});
            })
            .catch(err => {
              NotiStore.setState({error: true, errorMessage: err.message || 'Fehler', loading: false});
              this.setState({scanVal: defaultState.scanVal, scanValManual: defaultState.scanValManual});
            })
          } else {
            isValidInventory(scanVal)
            .then((res) => {
              if (!res.success || !res.data.id) {
                this.setState({scanVal: defaultState.scanVal, scanValManual: defaultState.scanValManual});
                NotiStore.setState({loading: false, error: true, errorMessage: 'Fehler bei der Produktabfrage aufgetreten.'})
              } else if (this.state.showFillPop && this.state.scannedGoods.filter((g: Good) => g.id === res.data.id).length >= 1) {
                this.setState({scanVal: defaultState.scanVal, scanValManual: defaultState.scanValManual});
                NotiStore.setState({loading: false, error: true, errorMessage: 'Das Produkt befindet sich bereits in der Liste.'})
              } else {
                if (this.state.showFillPop) {
                  this.setState(prevState => ({scanVal: defaultState.scanVal, scanValManual: defaultState.scanValManual, scannedGoods: [...prevState.scannedGoods, {...res.data, input: this.getRequiredInput(res.data) || 0}], showScanInput: false}))
                }
                else if (this.state.showUtilsPop) {
                  let match = this.state.utilities.filter((util: Good) => util.attributes.customId.toLowerCase() === scanVal.toLowerCase());

                  if (match.length === 1) {
                    this.setState({expandedUtil: this.state.utilities.indexOf(match[0])})
                  } else {
                    this.setState({scanVal: defaultState.scanVal, scanValManual: defaultState.scanValManual, addUtilId: scanVal, showScanInput: false, expandedUtil: 9999});
                  }
                }
                NotiStore.setState({loading: false});
              }
            })
            .catch(err => {
              this.setState({scanVal: defaultState.scanVal, scanValManual: defaultState.scanValManual});
              NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Fehler bei der Produktabfrage aufgetreten.'})
            })
          }
        }
      }
    }
  }

  removeScannedGood = (i: number): void => {
    let {scannedGoods} = this.state;

    if (typeof scannedGoods[i] !== 'undefined') {
      scannedGoods.splice(i, 1)
      this.setState({scannedGoods})
    }
  }

  updateGoodInput = (e: React.ChangeEvent<HTMLInputElement>, i: number): void => {
    let {scannedGoods} = this.state;

    try {
      if (typeof scannedGoods[i] !== 'undefined') {
        //@ts-ignore
        scannedGoods[i].input = Number(e.target.value);
  
        this.setState({scannedGoods});
      }
    } catch {}
  }

  submitFillPop = (): void => {
    NotiStore.setState({loading: true});
    resetNotifications();

    let {scannedGoods} = this.state;

    if (scannedGoods.length < 1) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte scanne die Produkte, die du verarbeiten möchtest.'});
    } else {
      let error = '';

      scannedGoods.forEach(good => {
        //@ts-ignore
        if (good.input > good.attributes.quantity) error = `Von Produkt ${good.attributes.customId} sind nur ${good.attributes.quantity} ${good.attributes.unit} vorhanden.`;
      })

      if (error) {NotiStore.setState({loading: false, error: true, errorMessage: error});}
      else {
        let data = {
          scannedGoods,
          batch: this.state.activeBatch
        }

        submitFillData(data)
        .then(res => {
          this.setState({scanVal: defaultState.scanVal, showFillPop: false, showScanInput: false, scannedGoods: defaultState.scannedGoods});
          NotiStore.setState({loading: false, success: true, successMessage: 'Daten erfolgreich übermittelt.'});
          this.fetchData();
        })
        .catch(err => {
          NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Fehler beim Übermitteln der Daten.'})
        })
      }
    }
  }

  updateScaleWeight = (e: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      let {value} = e.target;
      this.setState({scaleWeight: Number(value)})
    } catch {}
  }

  fetchScale = (): void => {
    this.setState(prevState => ({scaleWeight: prevState.scaleWeight + 100, disableScaleInput: true}))
  }

  updateQrCategory = (e: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      let {value} = e.target;
      this.setState({qrCategory: value, qrType: ''})
    } catch {}
  }

  updateQrType = (e: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      let {value} = e.target;
      this.setState({qrType: value})
    } catch {}
  }

  closeQrPop = (): void => {
    this.setState({
      showQrPop: false,
      qrCategory: '',
      qrType: '',
      scaleWeight: 0,
      withPalette: false,
    })
  }

  submitQrPop = (): void => {
    this.setState({disableButtons: true});

    resetNotifications();
    NotiStore.setState({loading: true});

    let {scaleWeight, withPalette, qrCategory, qrType} = this.state;

    if (!scaleWeight || isNaN(scaleWeight)) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib das Gewicht an.'});
      this.setState({disableButtons: false});
    } else if (!qrCategory) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib die Produktkategorie an.'});
      this.setState({disableButtons: false});
    } else if (qrCategory === 'nm' && !qrType) {
      NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib die Produktbezeichnung an.'});
      this.setState({disableButtons: false});
    } else {
      let data = {
        batch: this.state.activeBatch,
        scaleWeight,
        qrCategory,
        qrType,
        withPalette,
        withLab: qrCategory === 'rpet' && (this.state.activeBatch.attributes.rpetCount + 1) % this.state.activeBatch.attributes.labInterval === 0
      }

      createProductFromProduction(data)
      .then((res) => {
        if (res.data) {
          this.setState({qrData: res.data, scaleWeight: defaultState.scaleWeight, disableButtons: false, withPalette: false});
          if (res.data.length >= 1) this.printMultipleFiles(res.data)
        }
        NotiStore.setState({loading: false, success: true, successMessage: 'Produkt erfolgreich erstellt.'});
        this.fetchData()
      })
      .catch(err => {
        NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Fehler beim Erstellen des Produkts aufgetreten.'})
        this.setState({disableButtons: false});
      })
    }
  }

  printMultipleFiles = async (data: Upload[]) => {
    let files: string[] = [];

    if (data && data.length >= 1) data.forEach(file => {
      files.push(file.url)
    })

    if (files.length >= 2) {
      const mergedPdf = await PDFDocument.create();

      for (const file of files) {
        let arrayBuffer = await fetch(file).then(res => res.arrayBuffer())
        let pdfDoc = await PDFDocument.load(arrayBuffer)

        let copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
        copiedPages.forEach((page: any) => mergedPdf.addPage(page));
      }

      const mergedPdfFile = await mergedPdf.save();
      const blob   = new Blob( [ mergedPdfFile ], { type: "application/pdf" } );

      const url = URL.createObjectURL(blob);

      this.printFile(url)
    } else if (files.length === 1) {
      this.printFile(files[0])
    }
  }

  printFile = (url: string) => {
    printJS(url);
  }

  onBatchFinish = () => {
    if (window.confirm(`Bist du sicher, dass du die Charge abgeschlossen hast?\n\n rPET produziert: ${localNumberInTons(this.getTotalProducedActiveBatch())} t\n rPET vorgeschrieben: ${localNumberInTons(this.state.activeBatch.attributes.output)} t`)) {
      NotiStore.setState({loading: true})

      finishBatch(this.state.activeBatch.id)
      .then(() => {
        NotiStore.setState({loading: false, success: true, successMessage: 'Charge erfolgreich abgeschlossen.'})
        this.fetchData();
      })
      .catch(err => {
        NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Fehler beim Abschließen der Charge.'})
      })
    }
  }

  getTotalProducedActiveBatch = (): number => {
    let {activeBatch} = this.state
    let total = 0

    if (activeBatch) {
      if (activeBatch.attributes.finishedProducts.data && activeBatch.attributes.finishedProducts.data.length >= 1) activeBatch.attributes.finishedProducts.data.forEach((good: Good) => {
        if (good.attributes.customId.toLowerCase().startsWith('rpet')) total += good.attributes.origQuantity
      })
    }

    return total
  }

  removeUtil = (i: number): void => {
    if (window.confirm('Bist du sicher, dass du das Betriebsmittel entfernen möchtest?')) {
      NotiStore.setState({loading: true});

      let {utilities, utilReason} = this.state;

      if (typeof utilities[i] !== 'undefined' && utilReason) {
        let data: RemoveUtilityData = {good: utilities[i], reason: utilReason};
        
        removeUtility(data)
        .then(() => {
          NotiStore.setState({loading: false, success: true, successMessage: 'Betriebsmittels entfernt.'});
          this.setState({expandedUtil: defaultState.expandedUtil, utilReason: defaultState.utilReason});
          this.fetchData();
        })
        .catch(err => {
          NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Fehler beim Entfernen des Betriebsmittels.'})
        })
      } else if (!utilReason) {
        NotiStore.setState({loading: false, error: true, errorMessage: 'Bitte gib an, warum du das Betriebsmittel entfernen möchtest.'})
      } else {
        NotiStore.setState({loading: false, error: true, errorMessage: 'Fehler beim Entfernen des Betriebsmittels.'})
      }
    }
  }

  addNewUtil = (): void => {
    resetNotifications();
    NotiStore.setState({loading: true});

    addUtility(this.state.addUtilId)
    .then(() => {
      NotiStore.setState({loading: false, success: true, successMessage: 'Betriebsmittel hinzugefügt.'});
      this.setState({addUtilId: defaultState.addUtilId});
      this.fetchData();
    })
    .catch(err => {
      NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Fehler beim Hinzufügen des Betriebsmittels.'});
    })
  }

  getGoodData = (id: string): Good | undefined => {
    let match = this.state.utilitiesStored.filter((util: Good) => util.attributes.customId.toLowerCase() === id.toLowerCase());

    if (match.length === 1) {
      return match[0]
    } 
  }

  getGoodsToUseTotal = (): string => {
    let {activeBatch} = this.state
    let remaining = 0
    let total = 0

    if (activeBatch && activeBatch.id) {
      let {goodsToUseData} = activeBatch.attributes

      if (goodsToUseData.length >= 1) goodsToUseData.forEach((g: {inputQuantity: number; inputDone: number}) => {
        remaining +=(g.inputDone || 0)
        total += g.inputQuantity
      })
    }

    return `${localNumber(remaining)} / ${localNumber(total)} kg`
  }

  getGoodsToUseSingleData = (id: number): string => {
    let {activeBatch} = this.state
    let remaining = 0
    let total = 0

    if (activeBatch && activeBatch.id) {
      let {goodsToUseData} = activeBatch.attributes

      if (goodsToUseData.length >= 1) {
        let matches = goodsToUseData.filter(data => data.id === id)

        if (matches.length >= 1) {
          // remaining = remaining + matches[0].inputQuantity - (matches[0].inputDone || 0)
          // total += matches[0].inputQuantity

          remaining = (matches[0].inputDone || 0)
          total = matches[0].inputQuantity
        }
      }
    }

    return `${localNumber(remaining)} / ${localNumber(total)} kg`
  }

  getProducedSum = (): string => {
    let {activeBatch} = this.state
    let total = 0

    if (activeBatch && activeBatch.id) {
      let {finishedProducts} = activeBatch.attributes

      if (finishedProducts.data.length >= 1) finishedProducts.data.forEach((good: Good) => {
        if (good.attributes.origQuantity && good.attributes.customId.toLowerCase().startsWith('rpet')) total += good.attributes.origQuantity
      })
    }

    return `${localNumber(total)}`
  }

  getRequiredInput = (good: {id: number}): number => {
    let val = 0

    let matches = this.state.activeBatch.attributes.goodsToUseData.filter((g: {id: number, inputQuantity: number}) => g.id === good.id)

    if (matches.length === 1) {
      let match = matches[0]

      val = (match.inputQuantity - match.inputDone || 0) < 0 ? 0 : (match.inputQuantity || 0) - (match.inputDone || 0)
      if (val < 0) val = 0
    }

    return val
  }

  handleSort = () => {
      this.setState({sort:this.state.sort ? false :true})
      this.fetchData()
  }
  
 
  render() {
    let batch: Batch | undefined = this.state.activeBatch.id >= 0 ? this.state.activeBatch : undefined;
    let storesToStore: string = '';
    let scannedGoodsSum: number = 0;

    if (batch) {
      //@ts-ignore
      batch['attributes']['storesToStore']['data'] && batch['attributes']['storesToStore']['data'].forEach((store: WarehouseSpot) => {
        storesToStore += storesToStore ? `, ${store.attributes.name}` : store.attributes.name
      })
    }

    if (this.state.scannedGoods.length >= 1) this.state.scannedGoods.forEach(g => {
      //@ts-ignore
      if (g.input && g.input > 0) scannedGoodsSum += g.input;
    })

   
    return (
      <div className="ProcessProduction content" data-testid="ProcessProduction" tabIndex={0}>
        <div className="header-bar">
          <h1>Produktion</h1>
          <img src={FactoryIcon} alt="icon" />
        </div>
        <Paper className="box">
          {this.state.activeBatch.id < 0 && <div className="overlay strong green">aktuell wird nichts produziert</div>}
          <div className="header-bar">
            <h2>Aktuelle Charge</h2>
            <small>Geplanter Start: {this.state.activeBatch && 'attributes' in this.state.activeBatch && `${new Date(this.state.activeBatch['attributes']['start']).toLocaleDateString()}, ${new Date(this.state.activeBatch['attributes']['start']).toLocaleTimeString().replace(':00', '')}`}</small>
          </div>
          
          <div className="flex of-6 spacing-1">
            <TextField
              helperText='ID - Charge'
              value={this.state.activeBatch && 'attributes' in this.state.activeBatch && this.state.activeBatch['attributes']['customId']}
              disabled
            />
            <TextField
              helperText='Bezeichnung'
              value={this.state.activeBatch.attributes.type}
              disabled
            />
            <TextField
              helperText='Farbe'
              value={this.state.activeBatch && this.state.activeBatch.attributes.color && convertOrderAttributes(this.state.activeBatch.attributes.color)}
              disabled
            />
            <TextField
              helperText='Lebensmittelecht?'
              value={this.state.activeBatch && this.state.activeBatch.attributes.foodSafe && convertOrderAttributes(this.state.activeBatch.attributes.foodSafe)}
              disabled
            />
            <TextField
              helperText='Material'
              value={this.state.activeBatch && this.state.activeBatch.attributes.material && convertOrderAttributes(this.state.activeBatch.attributes.material)}
              disabled
            />
            <TextField
              helperText='Menge'
              value={this.state.activeBatch && 'attributes' in this.state.activeBatch && (this.getProducedSum() + ' / ' + localNumber(this.state.activeBatch['attributes']['output']) + ' kg')}
              disabled
            />
            <TextField
              helperText='Produktlager'
              value={storesToStore}
              disabled
            />
          </div>
          <div className="flex buttons">
            <div>
              <Button variant='contained' onClick={() => this.setState({showDetailsPop: true})}>Details anzeigen</Button>
            </div>
            <div>
              <Button variant='outlined' onClick={this.onBatchFinish}>abschließen</Button>
            </div>
          </div>
        </Paper>
        <div className="actions flex of-2 spacing-1">
          <div><div className={`big-btn text-center ${!(this.state.activeBatch.id >= 0) && 'disabled'}`} onClick={() => this.state.activeBatch.id >= 0 ? this.setState({showFillPop: true}) : {}}>Anlage befüllen</div></div>
          <div><div className={`big-btn text-center ${!((this.state.activeBatch.id >= 0) && this.state.activeBatch.attributes.status === 'active') && 'disabled'}`} onClick={() => this.state.activeBatch.id >= 0 && this.state.activeBatch.attributes.status === 'active' ? this.setState({showQrPop: true}) : {}}>QR Code<br />erstellen</div></div>
          <div><div className={`big-btn text-center`} onClick={() => this.setState({showUtilsPop: true})}>Betriebsmittel<br />verwalten</div></div>
          <div><div className={`big-btn text-center yellow ${this.state.activeBatch.id >= 0 && 'disabled'}`} onClick={() => !(this.state.activeBatch.id >= 0) ? this.setState({showBatchesPop: true}) : {}}>Neue Charge<br />beginnen</div></div>
        </div>

        <Modal
          open={this.state.showBatchesPop}
          onClose={() => this.setState({showBatchesPop: false})}
        >
          <>
            <span className='modal-close pointer' onClick={() => this.setState({showBatchesPop: false})}><Close /></span>
            <Box sx={{...modalStyle}}>
              <div className='modal-close-btn' onClick={() => this.setState({showBatchesPop: false})}><Close /></div>
              <h2>Geplante Chargen</h2>
              <p>Bitte wähle die Charge, die produziert werden soll.</p>

              <div className="windowed">
                <Table>
                  <TableHead>
                    <TableCell>Geplanter Start</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Bezeichnung</TableCell>
                    <TableCell>Farbe</TableCell>
                    <TableCell>
                      <div className='flex align-center nowrap'>
                        <span className='strong'>LME</span>
                        <Tooltip title='lebensmittelecht'>
                          <InfoOutlined sx={{width: '17px', marginLeft: '5px'}} />
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell>Material</TableCell>
                    <TableCell>Menge</TableCell>
                    <TableCell>Dauer</TableCell>
                  </TableHead>
                  <TableBody>
                    {this.state.batches.length >= 1 ? this.state.batches.map((batch: Batch, i) => (
                      <TableRow key={`batches-${i}`} className={`pointer ${this.state.selectedBatch === batch.id && 'active'}`} onClick={() => this.setState({selectedBatch: batch.id})}>
                        <TableCell>{batch.attributes.start ? `${new Date(batch.attributes.start).toLocaleDateString()} ${new Date(batch.attributes.start).toLocaleTimeString().replace(':00', '')}` : '???'}</TableCell>
                        <TableCell>{batch.attributes.customId}</TableCell>
                        <TableCell>{batch.attributes.type}</TableCell>
                        <TableCell>
                          {batch.attributes.color && convertOrderAttributes(batch.attributes.color)}
                        </TableCell>
                        <TableCell>
                          {batch.attributes.foodSafe && convertOrderAttributes(batch.attributes.foodSafe)}
                        </TableCell>
                        <TableCell>
                          {batch.attributes.material && convertOrderAttributes(batch.attributes.material)}
                        </TableCell>
                        <TableCell>{localNumber(batch.attributes.output)} kg</TableCell>
                        <TableCell>{`${Math.floor(batch.attributes.duration / 60)} h ${batch.attributes.duration % 60} min`}</TableCell>
                      </TableRow>
                    )) : <TableRow><TableCell>Keine Chargen geplant.</TableCell><TableCell></TableCell><TableCell></TableCell><TableCell></TableCell><TableCell></TableCell><TableCell></TableCell><TableCell></TableCell></TableRow>}
                  </TableBody>
                </Table>
              </div>

              <div className="flex space-between" style={{marginTop: 20}}>
                <Button variant='outlined' onClick={() => this.setState({showBatchesPop: false, selectedBatch: defaultState.selectedBatch})}>abbrechen</Button>
                <Button variant='contained' onClick={this.startBatch}>Charge starten</Button>
              </div>
            </Box>
          </>
        </Modal>

        <Modal
          open={this.state.showDetailsPop}
          onClose={() => this.setState({showDetailsPop: false})}
        >
          <>
            <span className='modal-close pointer' onClick={() => this.setState({showDetailsPop: false})}><Close /></span>
            <Box sx={{...modalStyle}}>
              <span className='modal-close pointer' onClick={() => this.setState({showDetailsPop: false})}><Close /></span>
              <h2>Aktuelle Charge</h2>
              <div className="flex of-6 spacing-1">
                <TextField
                  helperText='ID - Charge'
                  value={this.state.activeBatch.id >= 0 && this.state.activeBatch['attributes']['customId']}
                  disabled
                />
                <TextField
                  helperText='Bezeichnung'
                  value={'rPET'}
                  disabled
                />
                <TextField
                  helperText='Farbe'
                  value={this.state.activeBatch && this.state.activeBatch.attributes.color && convertOrderAttributes(this.state.activeBatch.attributes.color)}
                  disabled
                />
                <TextField
                  helperText='Lebensmittelecht?'
                  value={this.state.activeBatch && this.state.activeBatch.attributes.foodSafe && convertOrderAttributes(this.state.activeBatch.attributes.foodSafe)}
                  disabled
                />
                <TextField
                  helperText='Material'
                  value={this.state.activeBatch && this.state.activeBatch.attributes.material && convertOrderAttributes(this.state.activeBatch.attributes.material)}
                  disabled
                />
                <TextField
                  helperText='Menge'
                  value={this.state.activeBatch.id >= 0 && 'attributes' in this.state.activeBatch && localNumber(this.state.activeBatch['attributes']['output']) + ' kg'}
                  disabled
                />
                <TextField
                  helperText='Produktlager'
                  value={storesToStore}
                  disabled
                />
              </div>
              <hr />
              <h3>Geplante Dauer</h3>
              <div className="flex of-3 spacing-1">
                <div><strong>Dauer: </strong>{this.state.activeBatch.id >= 0 && 'attributes' in this.state.activeBatch && minToTime(this.state.activeBatch['attributes']['duration'])}</div>
                <div><strong>Start: </strong>{this.state.activeBatch.id >= 0 && 'attributes' in this.state.activeBatch && `${new Date(this.state.activeBatch['attributes']['start']).toLocaleDateString()}, ${new Date(this.state.activeBatch['attributes']['start']).toLocaleTimeString()}`}</div>
                <div><strong>Ende: </strong>{this.state.activeBatch.id >= 0 && 'attributes' in this.state.activeBatch && `${new Date(this.state.activeBatch['attributes']['end']).toLocaleDateString()}, ${new Date(this.state.activeBatch['attributes']['end']).toLocaleTimeString()}`}</div>
              </div>
              <hr />
              <h3>Ware zu verarbeiten</h3>
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Kategorie</TableCell>
                      <TableCell>Bezeichnung</TableCell>
                      <TableCell>Menge</TableCell>
                      <TableCell>Warenlager</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* @ts-ignore */}
                  <TableBody>
                    {this.state.activeBatch.id >= 0 && 'attributes' in this.state.activeBatch && this.state.activeBatch['attributes']['goodsToUse']['data'].length && this.state.activeBatch['attributes']['goodsToUse']['data'].map((good: Good, i: number) => this.state.activeBatch.attributes.goodsToUseData.filter((g: {id: number, inputQuantity: number}) => g.id === good.id)[0].inputQuantity ? (
                      <TableRow key={`good-${i}`}>
                        <TableCell>{good.attributes.customId}</TableCell>
                        <TableCell>{convertGoodsCategory(good.attributes.category)}</TableCell>
                        <TableCell>{good.attributes.typeIntern}</TableCell>
                        <TableCell>{this.getGoodsToUseSingleData(good.id)}</TableCell>
                        <TableCell>{good.attributes.warehouse_spot.data ? good.attributes.warehouse_spot.data.attributes.name : 'verbraucht'}</TableCell>
                      </TableRow>
                    ) : null)}
                    <TableRow>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell align='right'>Summe</TableCell>
                      <TableCell>{this.getGoodsToUseTotal()}</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div className='text-right' style={{marginTop: 30}}>
                  <Button variant='contained' disabled={this.state.activeBatch.id < 0} onClick={() => this.state.activeBatch.id >= 0 ? this.setState({showFillPop: true, showDetailsPop: false}) : {}}>Anlage befüllen</Button>
                </div>
              </div>
              <hr />
              <h3>Produzierte Produkte</h3>
              {this.state.activeBatch.attributes.finishedProducts.data.length >= 1 ? (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Kategorie<span onClick={()=>this.handleSort()} style={{fontSize:"30px",paddingLeft:"5px",cursor:"pointer"}}><b>&darr;</b></span></TableCell>
                        <TableCell>Bezeichnung</TableCell>
                        <TableCell>Menge</TableCell>
                        <TableCell>Lagerplatz</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        this.state.sort ?
                         this.state.activeBatch.attributes.finishedProducts.data.sort((a, b) => a.attributes.customId.localeCompare(b.attributes.customId)).map((good: Good, i: number) => (
                          <TableRow key={'activeProduced-' + i}>
                            <TableCell>{good.attributes.customId}</TableCell>
                            <TableCell>{convertGoodsCategory(good.attributes.category)}</TableCell>
                            <TableCell>{good.attributes.typeIntern}</TableCell>
                            <TableCell>{good.attributes.origQuantity} {good.attributes.unit}</TableCell>
                            <TableCell>{good.attributes.warehouse_spot.data ? good.attributes.warehouse_spot.data.attributes.name : 'keiner'}</TableCell>
                            <TableCell><span style={{cursor:"pointer"}}><QrCode2 ></QrCode2></span></TableCell>
                          </TableRow>
                        )):
                        this.state.activeBatch.attributes.finishedProducts.data.map((good: Good, i: number) => (
                          <TableRow key={'activeProduced-' + i}>
                            <TableCell>{good.attributes.customId}</TableCell>
                            <TableCell>{convertGoodsCategory(good.attributes.category)}</TableCell>
                            <TableCell>{good.attributes.typeIntern}</TableCell>
                            <TableCell>{good.attributes.origQuantity} {good.attributes.unit}</TableCell>
                            <TableCell>{good.attributes.warehouse_spot.data ? good.attributes.warehouse_spot.data.attributes.name : 'keiner'}</TableCell>
                            <TableCell><a target='_blank' href={good.attributes.label.data.attributes.url} ><QrCode2 ></QrCode2></a></TableCell>
                          </TableRow>
                        ))

                     
                      }
                      <TableRow>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell align='right'><strong>Summe rPET</strong></TableCell>
                        <TableCell><strong>{this.getProducedSum()} kg</strong></TableCell>
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              ) : <p>Keine Produkte produziert.</p>}
              <div className='text-right' style={{marginTop: 30}}>
                <Button variant='contained' disabled={!((this.state.activeBatch.id >= 0) && this.state.activeBatch.attributes.status === 'active')} onClick={() => this.state.activeBatch.id >= 0 && this.state.activeBatch.attributes.status === 'active' ? this.setState({showQrPop: true, showDetailsPop: false}) : {}}>QR Code erstellen</Button>
              </div>
            </Box>
          </>
        </Modal>

        <Modal
          open={this.state.showFillPop}
          onClose={() => this.setState({showFillPop: false})}
        >
          <>
            <span className='modal-close pointer' onClick={() => this.setState({showFillPop: false})}><Close /></span>
            <Box sx={{...modalStyle}}>
              <span className='modal-close pointer' onClick={() => this.setState({showFillPop: false})}><Close /></span>
              <h2>Aktuelle Charge</h2>
              <div className="flex of-6 spacing-1">
                <TextField
                  helperText='ID - Charge'
                  value={this.state.activeBatch && 'attributes' in this.state.activeBatch && this.state.activeBatch['attributes']['customId']}
                  disabled
                />
                <TextField
                  helperText='Bezeichnung'
                  value={'rPET'}
                  disabled
                />
                <TextField
                  helperText='Farbe'
                  value={this.state.activeBatch && this.state.activeBatch.attributes.color && convertOrderAttributes(this.state.activeBatch.attributes.color)}
                  disabled
                />
                <TextField
                  helperText='Lebensmittelecht?'
                  value={this.state.activeBatch && this.state.activeBatch.attributes.foodSafe && convertOrderAttributes(this.state.activeBatch.attributes.foodSafe)}
                  disabled
                />
                <TextField
                  helperText='Material'
                  value={this.state.activeBatch && this.state.activeBatch.attributes.material && convertOrderAttributes(this.state.activeBatch.attributes.material)}
                  disabled
                />
                <TextField
                  helperText='Menge'
                  value={this.state.activeBatch && 'attributes' in this.state.activeBatch && localNumber(this.state.activeBatch['attributes']['output']) + ' kg'}
                  disabled
                />
                <TextField
                  helperText='Produktlager'
                  value={storesToStore}
                  disabled
                />
              </div>
              <hr />
              <h3>Ware zu verarbeiten</h3>
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Kategorie</TableCell>
                      <TableCell>Bezeichnung</TableCell>
                      <TableCell>Menge</TableCell>
                      <TableCell>Warenlager</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* @ts-ignore */}
                  <TableBody>
                    {this.state.activeBatch && 'attributes' in this.state.activeBatch && this.state.activeBatch['attributes']['goodsToUse']['data'].length && this.state.activeBatch['attributes']['goodsToUse']['data'].map((good: Good, i: number) => this.state.activeBatch.attributes.goodsToUseData.filter((g: {id: number, inputQuantity: number}) => g.id === good.id)[0].inputQuantity ? (
                      <TableRow key={`good-${i}`}>
                        <TableCell>{good.attributes.customId}</TableCell>
                        <TableCell>{convertGoodsCategory(good.attributes.category)}</TableCell>
                        <TableCell>{good.attributes.typeIntern}</TableCell>
                        <TableCell>{this.getGoodsToUseSingleData(good.id)}</TableCell>
                        <TableCell>{good.attributes.warehouse_spot.data ? good.attributes.warehouse_spot.data.attributes.name : 'verbraucht'}</TableCell>
                        <TableCell><DeleteForever className='error action' /></TableCell>
                      </TableRow>
                    ) : null)}
                    <TableRow>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell align='right'>Summe</TableCell>
                      <TableCell>{this.getGoodsToUseTotal()}</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <hr />
              <div className="header-bar">
                <h3>ID scannen</h3>
              </div>
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Kategorie</TableCell>
                      <TableCell>Bezeichnung</TableCell>
                      <TableCell>Menge</TableCell>
                      <TableCell>Lagerplatz</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.scannedGoods.length >= 1 && this.state.scannedGoods.map((good: ScannedGood, i: number) => (
                      <TableRow key={`scannedgood-${i}`}>
                        {/* @ts-ignore */}
                        <TableCell>
                          {
                            this.state.activeBatch.id >= 0 && this.state.activeBatch.attributes.goodsToUse.data.filter((g: Good) => g.id === good.id).length < 1 
                            ? 
                            <Tooltip title='Produkt ID nicht in Charge vorhanden, bitte überprüfen!'><span className='error'>{good.attributes.customId}</span></Tooltip> 
                            : 
                            good.attributes.customId
                          }
                        </TableCell>
                        <TableCell>{convertGoodsCategory(good.attributes.category || '')}</TableCell>
                        <TableCell>{good.attributes.typeIntern}</TableCell>
                        <TableCell>
                          <TextField
                            value={good.input ? good.input : ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateGoodInput(e, i)}
                            type={'number'}
                            fullWidth
                            InputProps={{
                              endAdornment: <InputAdornment position="end">von {localNumber(this.getRequiredInput(good))} kg</InputAdornment>
                            }}
                          />
                        </TableCell>
                        <TableCell>{good.attributes.warehouse_spot.data ? good.attributes.warehouse_spot.data.attributes.name : '???'}</TableCell>
                        <TableCell className='pointer'><span className='red' onClick={() => this.removeScannedGood(i)}>stornieren</span></TableCell>
                      </TableRow>
                    ))}
                    {this.state.scannedGoods.length >= 1 && (
                      <TableRow>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell align='right'>Summe</TableCell>
                        <TableCell>{localNumber(scannedGoodsSum)} kg</TableCell>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {this.state.scannedGoods.length < 1 && (
                  <div className='text-center' style={{marginTop: 20}}>Bitte scanne ein Produkt.</div>
                )}
                <div style={{visibility: 'hidden', height: '0px', marginTop: 20}}>
                  <TextField
                    name="scan"
                    value={this.state.scanVal}
                    fullWidth
                  />
                </div>
                <div style={{visibility: this.state.showScanInput ? 'initial' : 'hidden', height: this.state.showScanInput ? 'auto' : '0px', marginTop: 20}}>
                  <TextField
                    name="scan"
                    value={this.state.scanValManual}
                    helperText="ID - Produkt (Bestätigen mit 'Enter')"
                    fullWidth
                    onChange={e => this.setState({scanValManual: e.target.value})}
                    sx={{marginBottom: '20px'}}
                  />
                </div>
              </div>
              <div className="flex space-between">
                <div>
                  <span style={{textDecoration: 'underline'}} className='pointer' onClick={() => this.setState({showScanInput: !this.state.showScanInput})}>{this.state.showScanInput ? 'manuelle Eingabe ausblenden' : 'manuelle Eingabe'}</span>
                </div>
                <div>
                  <Button sx={{marginRight: '15px'}} variant='outlined' onClick={() => this.setState({showFillPop: false, scannedGoods: []})}>Abbrechen</Button>
                  <Button variant='contained' onClick={this.submitFillPop}>Bestätigen</Button>
                </div>
              </div>
            </Box>
          </>
        </Modal>

        <Modal
          open={this.state.showQrPop}
          onClose={() => this.setState({showQrPop: false, scaleWeight: defaultState.scaleWeight, withPalette: false, disableScaleInput: defaultState.disableScaleInput, qrData: defaultState.qrData})}
        >
          <>
            <span className='modal-close pointer' onClick={() => this.setState({showQrPop: false, scaleWeight: defaultState.scaleWeight, withPalette: false, disableScaleInput: defaultState.disableScaleInput, qrData: defaultState.qrData})}><Close /></span>
            <Box sx={{...modalStyle, maxWidth: '600px'}}>
              <span className='modal-close pointer' onClick={() => this.setState({showQrPop: false, scaleWeight: defaultState.scaleWeight, withPalette: false, disableScaleInput: defaultState.disableScaleInput, qrData: defaultState.qrData})}><Close /></span>
              <h2>QR Code erstellen</h2>
              {this.state.qrData.length < 1 ? (
                <React.Fragment>
                  <h3>Gewicht</h3>
                    <div className='flex of-3 spacing-1'>
                      <div className='sixty'>
                        <TextField
                          helperText={'Bitte stelle das Produkt auf die Waage.'}
                          value={this.state.scaleWeight ? this.state.scaleWeight : ''}
                          fullWidth
                          type='number'
                          onChange={this.updateScaleWeight}
                          disabled={this.state.disableScaleInput}
                        />
                      </div>
                      <div className='twenty'>
                        <Button sx={{height: '58px'}} fullWidth variant={this.state.disableScaleInput ? 'contained' : 'outlined'} onClick={this.fetchScale}><ScaleOutlined /></Button>
                      </div>
                      <div className='twenty'>
                        <Button sx={{height: '58px'}} fullWidth variant={!this.state.disableScaleInput ? 'contained' : 'outlined'} onClick={() => this.setState({disableScaleInput: !this.state.disableScaleInput})}>{this.state.disableScaleInput ? <LockOutlined /> : <LockOpenOutlined />}</Button>
                      </div>
                    </div>
                    <div style={{marginTop: 20}}>
                      <FormControlLabel control={<Checkbox checked={this.state.withPalette} onChange={() => this.setState({withPalette: !this.state.withPalette})} />} label="Gewicht inkl. Palette" />
                    </div>
                    <hr />
                    <h3>Produktkategorie</h3>
                    <p>Um welche Kategorie handelt es sich?</p>
                    <div className='flex of-2 spacing-1' style={{marginBottom: 10}}>
                      <div><Button variant={this.state.qrCategory === 'rpet' ? 'contained' : 'outlined'} fullWidth onClick={() => this.setState({qrCategory: 'rpet'})}>Produkt</Button></div>
                      <div><Button variant={this.state.qrCategory === 'nm' ? 'contained' : 'outlined'} fullWidth onClick={() => this.setState({qrCategory: 'nm'})}>Nebenstrom</Button></div>
                    </div>
                    {this.state.qrCategory === 'nm' && (
                      <React.Fragment>
                        <TextField
                          sx={{marginTop: '15px'}}
                          select
                          fullWidth
                          onChange={this.updateQrType}
                          helperText='Bezeichnung'
                          value={this.state.qrType || 'default'}
                        >
                          <MenuItem selected disabled value='default'>Kategorie wählen</MenuItem>
                          <MenuItem value='Metall'>Metall</MenuItem>
                          <MenuItem value='PO'>PO</MenuItem>
                          <MenuItem value='Kleinteile'>Kleinteile</MenuItem>
                          <MenuItem value='Chargenwechsel'>Chargenwechsel</MenuItem>
                        </TextField>
                      </React.Fragment>
                    )}
                    {this.state.scaleWeight && this.state.qrCategory === 'rpet' && (this.state.activeBatch.attributes.rpetCount + 1) % this.state.activeBatch.attributes.labInterval === 0 ? (
                      <Alert severity='error' sx={{marginTop: '15px'}}><strong>ACHTUNG:</strong> Für dieses Produkt musst du ebenfalls eine Laborprobe entnehmen!</Alert>
                    ) : null}
                </React.Fragment>
              ) : null}
              {this.state.qrData.length < 1 ? (
                <div style={{marginTop: 30}} className='flex space-between'>
                  <Button variant='outlined' onClick={this.closeQrPop}>Abbrechen</Button>
                  <Button disabled={this.state.disableButtons} variant='contained' onClick={this.submitQrPop}>QR Code erstellen</Button>
                </div>
              ) : (
                <div style={{marginTop: 15}}>
                  {this.state.qrData.map((data: Upload, i: number) => (
                    <Button key={`qrdata-${i}`} sx={{marginTop: '15px'}} variant='contained' fullWidth onClick={() => this.printFile(data.url)}>{data.name}</Button>
                  ))}
                  <Button variant='outlined' fullWidth sx={{marginTop: '15px'}} onClick={() => this.setState({
                    qrCategory: '',
                    qrType: '',
                    scaleWeight: 0,
                    withPalette: false,
                    qrData: defaultState.qrData
                  })}>Neustart</Button>
                </div>
              )}
            </Box>
          </>
        </Modal>

        <Modal
          open={this.state.showUtilsPop}
          onClose={() => this.setState({showScanInput: false, scanVal: '', scanValManual: '', addUtilId: defaultState.addUtilId, showUtilsPop: false, expandedUtil: defaultState.expandedUtil, utilReason: defaultState.utilReason})}
        >
          <>
            <span className='modal-close pointer' onClick={() => this.setState({showScanInput: false, scanVal: '', scanValManual: '', addUtilId: defaultState.addUtilId, showUtilsPop: false, expandedUtil: defaultState.expandedUtil, utilReason: defaultState.utilReason})}><Close /></span>
            <Box sx={{...modalStyle}}>
              <span className='modal-close pointer' onClick={() => this.setState({showScanInput: false, scanVal: '', scanValManual: '', addUtilId: defaultState.addUtilId, showUtilsPop: false, expandedUtil: defaultState.expandedUtil, utilReason: defaultState.utilReason})}><Close /></span>
              <div className="header-bar">
                <h2>Betriebsmittel</h2>
                <span style={{textDecoration: 'underline', marginBottom: 15, display: 'inline-block'}} className='pointer' onClick={() => this.setState({showScanInput: !this.state.showScanInput})}>{this.state.showScanInput ? 'manuelle Eingabe ausblenden' : 'manuelle Eingabe'}</span>
              </div>
              <p>Bitte scanne das Betriebsmittel, das du neu hinzufügen oder entfernen möchtest.</p>
              <div style={{visibility: 'hidden', height: '0px'}}>
                <TextField
                  name="scan"
                  value={this.state.scanVal}
                  fullWidth
                />
              </div>
              <div style={{visibility: this.state.showScanInput ? 'initial' : 'hidden', height: this.state.showScanInput ? 'auto' : '0px', marginTop: 20}}>
                <TextField
                  name="scan"
                  value={this.state.scanValManual}
                  helperText="ID - Produkt (Bestätigen mit 'Enter')"
                  fullWidth
                  onChange={e => this.setState({scanValManual: e.target.value})}
                  sx={{marginBottom: '15px'}}
                />
              </div>
              <h3>Aktuell in Verwendung</h3>
              {this.state.utilities.map((util: Good, i) => (
                <Accordion key={'pos-'+i} expanded={this.state.expandedUtil === i} sx={{marginTop: i === 0 ? 0 :'15px'}}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined onClick={() => this.setState({utilReason: defaultState.utilReason, expandedUtil: this.state.expandedUtil === i ? -1 : i})} style={{cursor: 'pointer'}} />}
                    style={{cursor: 'default'}}
                  >
                    <strong>BM {i + 1}: {util.attributes.typeIntern} ({util.attributes.customId})</strong>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex of-2 spacing-1">
                      <TextField
                        select
                        name='util-reason'
                        onChange={(e) => this.setState({utilReason: e.target.value})}
                        value={this.state.utilReason}
                        helperText='Grund der Entfernung'
                        fullWidth
                      >
                        <MenuItem value='empty'>leer</MenuItem>
                        <MenuItem value='wrong'>falsch</MenuItem>
                        <MenuItem value='broken'>defekt</MenuItem>
                      </TextField>
                      <div>
                        <Button sx={{height: '100%'}} fullWidth variant='contained' onClick={() => this.removeUtil(i)}>Betriebsmittel entfernen</Button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
              <hr />
              <h3>Neues Betriebsmittel hinzufügen</h3>
              <Accordion expanded={this.state.expandedUtil === 9999}>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined onClick={() => this.setState({utilReason: defaultState.utilReason, expandedUtil: this.state.expandedUtil === 9999 ? -1 : 9999})} style={{cursor: 'pointer'}} />}
                  style={{cursor: 'default'}}
                >
                  <strong>Neues Betriebsmittel</strong>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex of-3 spacing-1">
                    <TextField
                      value={this.getGoodData(this.state.addUtilId) ? this.getGoodData(this.state.addUtilId)?.attributes.customId : ''}
                      helperText='ID - Produkt'
                      fullWidth
                      disabled
                    />
                    <TextField
                      value={this.getGoodData(this.state.addUtilId) ? this.getGoodData(this.state.addUtilId)?.attributes.typeIntern : ''}
                      helperText='Bezeichnung'
                      fullWidth
                      disabled
                    />
                    <div>
                      <Button sx={{height: '100%'}} fullWidth variant='contained' onClick={this.addNewUtil}>Betriebsmittel hinzufügen</Button>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Box>
          </>
        </Modal>
      </div>
    )
  }
}

export default ProcessProduction;
