import React, { FC } from 'react';
import Laboratory from '../Laboratory/Laboratory';
import SystemDefinitions from '../System/SystemDefinitions/SystemDefinitions';
import SystemStandardValues from '../System/SystemStandardValues/SystemStandardValues';
import SystemUsers from '../System/SystemUsers/SystemUsers';
import TopNav from '../TopNav/TopNav';
import './ContentContainer.sass';

interface ContentContainerProps {
  page?: string;
}

const ContentContainer: FC<ContentContainerProps> = (props) => (
  <div className="ContentContainer" data-testid="ContentContainer">
    <TopNav />
    {props.page === 'settings' && <SystemStandardValues />}
    {props.page === 'users' && <SystemUsers />}
    {props.page === 'definitions' && <SystemDefinitions />}
    {props.page === 'laboratory' && <Laboratory />}
  </div>
);

export default ContentContainer;
