import React from 'react';
import './ProductionSettings.sass';
import {Button, FormControl, InputAdornment, InputLabel, OutlinedInput, TextField} from '@mui/material';
import {NotiStore} from '../../../stores/notifications';
import { resetNotifications } from '../../../services/notiStoreService';
import { fetchProductionSettings, fetchProductionSettingsResponse, updateProductionSettings, updateProductionSettingsResponse } from '../../../services/configService';

interface ProductionSettingsProps {}

interface ProductionSettingsState {
  shiftsFirstStart: string;
  shiftsFirstEnd: string;
  shiftsSecondStart: string;
  shiftsSecondEnd: string;
  shiftsThirdStart: string;
  shiftsThirdEnd: string;
  productionTimeHours: number;
  productionTimeMinutes: number;
  productionTime: number;
  rpetInput: number;
}

class ProductionSettings extends React.Component<ProductionSettingsProps, ProductionSettingsState> {
  state = {
    shiftsFirstStart: '',
    shiftsFirstEnd: '',
    shiftsSecondStart: '',
    shiftsSecondEnd: '',
    shiftsThirdStart: '',
    shiftsThirdEnd: '',
    productionTimeHours: 0,
    productionTimeMinutes: 0,
    productionTime: 0,
    rpetInput: 0,
  }
  
  componentDidMount() {
    window.history.replaceState(null, "KSM Recycling ERP", "/system/einstellungen?seite=produktion");
    resetNotifications()

    this.fetchData()
  }

  fetchData = (): void => {
    fetchProductionSettings()
    .then((res: fetchProductionSettingsResponse) => {
      if (!res.success) return NotiStore.setState({error: true, errorMessage: 'Beim Abrufen der Produktionseinstellungen ist ein Fehler aufgetreten.'});

      Object.keys(res.data).forEach((key: string): void => {
        if (Object.keys(this.state).includes(key)) this.setState({[key]: res.data[key]} as any);
      })

      this.setState({productionTimeHours: Math.floor(res.data.productionTime / 60), productionTimeMinutes: res.data.productionTime % 60})
    })
    .catch(() => {
      NotiStore.setState({error: true, errorMessage: 'Beim Abrufen der Produktionseinstellungen ist ein Fehler aufgetreten.'});
    })
    .finally(() => {
      NotiStore.setState({loading: false});
    })
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let key: string = e.target.name;
    if (Object.keys(this.state).includes(key)) this.setState({[key]: e.target.value} as any);
  }

  handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let key: string = e.target.name;
    if (Object.keys(this.state).includes(key)) this.setState({[key]: Number(e.target.value)} as any);
  }

  handleUpdate = (): void => {
    NotiStore.setState({loading: true});
    resetNotifications();

    if (this.state.productionTimeMinutes > 59) return NotiStore.setState({error: true, errorMessage: 'Der Wert im Feld "Minuten" darf nicht größer als 59 sein.', loading: false})
    else if (!Number.isInteger(this.state.rpetInput) || !Number.isInteger(this.state.productionTimeHours) || !Number.isInteger(this.state.productionTimeMinutes)) return NotiStore.setState({error: true, errorMessage: 'Fehler! Alle Werte müssen ganzzahlig sein.', loading: false})

    let productionTime: number = this.state.productionTimeHours * 60 + this.state.productionTimeMinutes;

    let data = {...this.state, productionTime}

    updateProductionSettings(data)
    .then((res: updateProductionSettingsResponse) => {
      if (!res.success) return NotiStore.setState({error: true, errorMessage: 'Beim Aktualisieren der Produktionseinstellungen ist ein Fehler aufgetreten.'});

      this.fetchData()

      NotiStore.setState({success: true, successMessage: 'Produktionseinstellungen erfolgreich aktualisiert.'});
    })
    .catch((err) => {
      NotiStore.setState({error: true, errorMessage: err.message ? err.message : 'Beim Aktualisieren der Produktionseinstellungen ist ein Fehler aufgetreten.'});
    })
    .finally(() => {
      NotiStore.setState({loading: false});
    })
  }

  render() {
    return (
      <div className="ProductionSettings" data-testid="ProductionSettings">
        <div className="header-bar">
          <h2>Schichtzeiten</h2>
          <Button variant="contained" type='submit' onClick={this.handleUpdate}>Aktualisieren</Button>
        </div>

        <div className="input-column">
          <TextField
            name="shiftsFirstStart"
            value={this.state.shiftsFirstStart}
            label="Frühschicht Start"
            type="time"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={this.handleChange}
          />
          <TextField
            name="shiftsFirstEnd"
            value={this.state.shiftsFirstEnd}
            label="Frühschicht Ende"
            type="time"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={this.handleChange}
          />
        </div>
        <div className="input-column">
          <TextField
            name="shiftsSecondStart"
            value={this.state.shiftsSecondStart}
            label="Spätschicht Start"
            type="time"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={this.handleChange}
          />
          <TextField
            name="shiftsSecondEnd"
            value={this.state.shiftsSecondEnd}
            label="Spätschicht Ende"
            type="time"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={this.handleChange}
          />
        </div>
        <div className="input-column">
          <TextField
            name="shiftsThirdStart"
            value={this.state.shiftsThirdStart}
            label="Nachtschicht Start"
            type="time"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={this.handleChange}
          />
          <TextField
            name="shiftsThirdEnd"
            value={this.state.shiftsThirdEnd}
            label="Nachtschicht Ende"
            type="time"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={this.handleChange}
          />
        </div>

        <div className="header-bar" style={{marginTop: 50}}>
          <h2>Systemvorgaben</h2>
          <Button variant="contained" type='submit' onClick={this.handleUpdate}>Aktualisieren</Button>
        </div>
        <div className="input-column combined">
          {/* TODO: system is only working with minutes */}
          <FormControl>
            <InputLabel htmlFor="productionTimeHours">1.000 kg rPET produzieren dauert</InputLabel>
            <OutlinedInput
              id="productionTimeHours"
              name="productionTimeHours"
              value={this.state.productionTimeHours}
              label="1.000 kg rPET produzieren dauert"
              type="number"
              onChange={this.handleNumberChange}
              endAdornment={<InputAdornment position="end">Stunden</InputAdornment>}
            />
          </FormControl>
          <OutlinedInput
            name="productionTimeMinutes"
            value={this.state.productionTimeMinutes}
            type="number"
            onChange={this.handleNumberChange}
            endAdornment={<InputAdornment position="end">Minuten</InputAdornment>}
          />
        </div>
        <FormControl className='input' sx={{maxWidth: '600px'}}>
          <InputLabel htmlFor="rpetInput">Wareneingang für 1.000 kg rPET</InputLabel>
          <OutlinedInput value={this.state.rpetInput} name="rpetInput" label="Wareneingang für 1.000 kg rPET" type="number" endAdornment={<InputAdornment position="end">kg</InputAdornment>} onChange={this.handleNumberChange}></OutlinedInput>
        </FormControl>
      </div>
    )
  }
};

export default ProductionSettings;
