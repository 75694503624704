import { Navigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import TopNav from '../components/TopNav/TopNav';
import { UserStore } from '../stores/user';

export default function Warehouse() {
  const user = UserStore((state: any) => state.user);

  return (
    <main className='Warehouse container'>
      <Sidebar />
      <TopNav />
      {user.rights.includes('warehouse') && <Navigate to='/lager/lagerplatz' />}
      {user.rights.includes('inventory') && <Navigate to='/lager/inventar' />}
    </main>
  )
}