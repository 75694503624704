import React from 'react';
import './Laboratory.sass';
import { Button, Table, TableHead, TableRow, TableContainer, TableCell, TableBody, Paper, Chip, TablePagination, Box, CircularProgress } from '@mui/material';
import { ArrowDownward, Close, DeleteForever, Edit, FilterList, PictureAsPdf } from '@mui/icons-material';
import { deleteReport, fetchLaboratoryReports, fetchLaboratoryReportsResponse, fetchLabReport, fetchReportsWithFilters, Report } from '../../services/laboratoryService';
import {NotiStore} from '../../stores/notifications';
import { resetNotifications } from '../../services/notiStoreService';
import { convertDateString, convertGoodsCategory, convertOrderAttributes } from '../../services/stringConverter';
import NewLabReportModal from './NewLabReportModal/NewLabReportModal';
import { createPDF } from '../../services/pdfService';
import { withRouter } from '../utils/withRouter/withRouter';
import FilterPop from '../utils/FilterPop/FilterPop';

interface LaboratoryProps {
  searchParams: {[key: string]: string};
}

interface LaboratoryState {
  reports: Report[];
  loading: boolean;
  showCreateModal: boolean;
  modalEditDraftMode: boolean;
  modalEditDraftData: Report | null;
  activeIsDraft: boolean;
  isCreateLabel: boolean;
  showFilterPop: number;
  filters: {
    [key: string]: string[];
  };
  activeFilters: boolean;
  sortBy: {
    [key: string]: string;
  };
  page:number,
  rowsPerPage:number,
  pagination:{ page: number, pageCount : number ,pageSize : number ,total :  number}
}

const defaultState = {
  reports: [],
  loading: true,
  showCreateModal: false,
  modalEditDraftMode: false,
  modalEditDraftData: null,
  activeIsDraft: false,
  isCreateLabel: false,
  showFilterPop: -1,
  filters: {},
  activeFilters: false,
  sortBy: {},
  page:0  ,
  rowsPerPage:10,
  pagination:{ page: 1, pageCount : 8 ,pageSize : 8 ,total :  100}
}

class Laboratory extends React.Component<LaboratoryProps, LaboratoryState> {
  state: LaboratoryState = {...defaultState}

  componentDidMount() {
    resetNotifications();
    this.fetchData(true);
  }

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
    ) => {
      this.setState({page: this.state.page === 0 ? newPage + 1 : newPage },this.fetchData)
      
    };
    
    handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        this.setState({rowsPerPage: parseInt(event.target.value)});
        this.setState({page: 0}, this.fetchData)
     
      };

  fetchData = (checkSearchParams: boolean = false): void => {
    NotiStore.setState({loading: true});

    fetchLaboratoryReports(`pagination[page]=${this.state.page}&pagination[pageSize]=${this.state.rowsPerPage}`)
    .then((res: fetchLaboratoryReportsResponse) => {
      this.setState({reports: res.reports || []}, checkSearchParams ? () => {
        // open order on load
        this.setState({ pagination: res.meta.pagination})
        
        let {id} = this.props.searchParams;
        if (id) {
          this.editDraft(Number(id), false)
        }
        // open order on load end
      } : undefined)
    })
    .catch(() => {
      NotiStore.setState({error: true, errorMessage: 'Beim Abrufen der Analyseberichte ist ein Fehler aufgetreten.'});
    })
    .finally(() => {
      NotiStore.setState({loading: false});
    })
  }

  closeCreateModal = (): void => {
    this.setState({showCreateModal: false, modalEditDraftData: null, modalEditDraftMode: false, activeIsDraft: false, isCreateLabel: false});
  }

  uploadPDF = (type: string, id: number): void => {
    resetNotifications();
    NotiStore.setState({loading: true});

    createPDF(type, id)
    .then(() => {
      NotiStore.setState({success: true, successMessage: 'PDF erfolgreich erstellt.'});
    })
    .catch(() => {
      NotiStore.setState({error: true, errorMessage: 'Beim Erstellen der PDF ist ein Fehler aufgetreten.'});
    })
    .finally(() => {
      this.fetchData()
    })
  }

  editDraft = (id: number, isDraft: boolean): void => {
    fetchLabReport(id)
    .then((res) => {
      if (!res.report?.attributes) {
        NotiStore.setState({error: true, errorMessage: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.'})
      } else {
        this.setState({
          modalEditDraftMode: true,
          modalEditDraftData: res.report,
          activeIsDraft: isDraft,
          showCreateModal: true,
        })
      }
    })
    .catch(() => NotiStore.setState({error: true, errorMessage: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.'}))
  }

  getBatchId = (r: Report): string => {
    let id = ''

    console.log(r)

    if (r.attributes.good?.data && r.attributes.good?.data.attributes.fromBatch && r.attributes.good?.data.attributes.fromBatch.data && r.attributes.good?.data.attributes?.fromBatch?.data.attributes.customId) id =  r.attributes.good.data.attributes.fromBatch.data.attributes.customId
    // @ts-ignore
    else if (r.attributes.batch && r.attributes.batch.customId) id = r.attributes.batch.customId

    return id
  }

  showFilterPop = (id: number): void => {
    if ((id && id > 0) || id === 0) {
      let {showFilterPop} = this.state;

      if (showFilterPop !== id) showFilterPop = id
      else {
        showFilterPop = defaultState.showFilterPop
      }

      this.setState({showFilterPop})
    }
  }

  submitFilters = (): void => {
    NotiStore.setState({loading: true})

    let {filters, sortBy} = this.state

    this.setState({activeFilters: true})

    fetchReportsWithFilters(filters, sortBy)
    .then(res => {
      this.setState({reports: res.reports || []});
      NotiStore.setState({loading: false})
    })
    .catch(err => {
      NotiStore.setState({error: true, errorMessage: err.message || 'Fehler beim Abrufen der Bestellungen aufgetreten.', loading: false})
    })
  }

  clearFilters = (): void => {
    this.setState({activeFilters: false, filters: {}})

    this.fetchData();
  }

  updateFilter = (e: React.ChangeEvent<HTMLInputElement>, key: string, multiple: string = ''): void => {
    let {filters} = this.state;

    if (multiple === 'multiple') {
      if (!filters[key]) filters[key] = [e.target.value]
      else {
        if (!filters[key].includes(e.target.value)) filters[key].push(e.target.value)
        else {
          let index = filters[key].indexOf(e.target.value)
          if (index > -1) filters[key].splice(index, 1)
        }
      }
    } else {
      if (e.target.value) filters[key] = [e.target.value]
      else delete filters[key]
    }

    this.setState({filters})
  }

  handleSort = (value: string): void => {
    if (value) {
      NotiStore.setState({loading: true})

      let {sortBy} = this.state
      if (sortBy.id === value) {
        if (sortBy.direction === 'desc') this.setState({sortBy: {id: value, direction: 'asc'}}, this.submitFilters)
        else if (sortBy.direction === 'asc') this.setState({sortBy: {id: value, direction: 'desc'}}, this.submitFilters)
      } else {
        this.setState({sortBy: {id: value, direction: 'asc'}}, this.submitFilters)
      }

      NotiStore.setState({loading: false})
    }
  }

  deleteReport = (id: number) => {
    deleteReport(id)
    .then(res => {
      NotiStore.setState({loading: false, success: true, successMessage: res.message || 'Erfolgreich.'})

      let reports = this.state.reports
      if (!reports) reports = []
      reports = reports.filter(rep => rep.id !== id)
      this.setState({reports})
    })
    .catch(err => {
      NotiStore.setState({error: true, errorMessage: err.message || 'Fehler beim Abrufen der Bestellungen aufgetreten.', loading: false})
    })
  }

  //TODO: implement pagination and filter (if possible by backend)
  render() {
    return (
      <div className="Laboratory content" data-testid="Laboratory">
        <div className='header-bar'>
          <h1>Analysen</h1>
          <div>
            <Button variant="contained" sx={{marginRight: '15px'}} onClick={() => this.setState({showCreateModal: true, activeIsDraft: true, isCreateLabel: true})}>
              Neues Label
            </Button>
            <Button variant="contained" onClick={() => this.setState({showCreateModal: true, activeIsDraft: true})}>
              Neue Analyse
            </Button>
          </div>
        </div>

        {this.state.activeFilters ? (
          <Chip color='error' className='pointer' sx={{marginBottom: '15px'}} label={<span className='flex align-center'><Close sx={{fontSize: '14px', marginRight: '10px'}} />Filter zurücksetzen</span>} onClick={this.clearFilters} />
        ) : null}

        {this.state.reports.length >= 1 ? <TableContainer className='table-container' component={Paper}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <span>ID</span>
                      <div>
                        <ArrowDownward className='pointer icon' onClick={() => this.handleSort('customId')} />
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 0 ? 'active' : ''}`} onClick={() => this.showFilterPop(0)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='customId'
                        active={this.state.showFilterPop === 0}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>
                  <TableCell><span className='strong'>ID Probe</span></TableCell>
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <span>Charge</span>
                      <div>
                        <ArrowDownward className='pointer icon' onClick={() => this.handleSort('batchId')} />
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 1 ? 'active' : ''}`} onClick={() => this.showFilterPop(1)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='batchId'
                        active={this.state.showFilterPop === 1}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <span>Bezeichnung</span>
                      <div>
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 2 ? 'active' : ''}`} onClick={() => this.showFilterPop(2)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='typeIntern'
                        active={this.state.showFilterPop === 2}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <span>Material</span>
                      <div>
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 3 ? 'active' : ''}`} onClick={() => this.showFilterPop(3)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='order-material'
                        active={this.state.showFilterPop === 3}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <span>Kategorie</span>
                      <div>
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 4 ? 'active' : ''}`} onClick={() => this.showFilterPop(4)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='lab-category'
                        active={this.state.showFilterPop === 4}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>
                  <TableCell><span className='strong'>Datum</span></TableCell>
                  <TableCell align='center'><span className='strong'>Toleranz</span></TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.reports.map((r) => (
                  <TableRow
                    key={'report-'+r.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {r.attributes.isDraft ? <span className='pointer' onClick={() => this.editDraft(r.id, r.attributes.isDraft)}>Entwurf</span> : null}
                      {r.attributes.needsData ? <strong className='pointer' onClick={() => this.editDraft(r.id, r.attributes.isDraft)}>{r.attributes.customId.replace('LP', 'A')}</strong> : null}
                      {!r.attributes.needsData && !r.attributes.isDraft ? <strong className='pointer' onClick={() => this.editDraft(r.id, r.attributes.isDraft)}>{r.attributes.customId.replace('LP', 'A')}</strong> : null}
                    </TableCell>
                    <TableCell>{r.attributes.customId}</TableCell>
                    <TableCell>{this.getBatchId(r)}</TableCell>
                    <TableCell>{r.attributes.good?.data && r.attributes.good?.data.attributes.typeIntern}</TableCell>
                    <TableCell>{r.attributes.good?.data && convertOrderAttributes(r.attributes.good?.data.attributes.material)}</TableCell>
                    <TableCell>{convertGoodsCategory(r.attributes.good?.data ? r.attributes.good?.data.attributes.category : '')}</TableCell>
                    <TableCell>{convertDateString(r.attributes.createdAt)}</TableCell>
                    <TableCell align='center'>
                      {r.attributes.tolerance === 'low' && <div className='swatch green'></div>}
                      {r.attributes.tolerance === 'medium' && <div className='swatch orange'></div>}
                      {r.attributes.tolerance === 'high' && <div className='swatch red'></div>}
                    </TableCell>
                    <TableCell align="right">
                      {r.attributes.isDraft || r.attributes.needsData ? <Edit className='warning action' onClick={() => this.editDraft(r.id, r.attributes.isDraft)} /> : 
                        <>
                          {/* <LockOutlined className='action' onClick={() => this.editDraft(r.id, r.attributes.isDraft)} /> */}
                          <div className='dropdown-btn-container'>
                            <a style={!r.attributes.report_int?.data ? {pointerEvents: 'none'} : {}} href={(r.attributes.report_int?.data?.attributes?.url || '#')} target="_blank" rel='noreferrer'><PictureAsPdf className={'action error ' + (!r.attributes.report_int?.data && 'disabled')} /></a>
                            {!r.attributes.report_int?.data && <div className='dropdown'><div className='dropdown-btn' onClick={() => {if (window.confirm("Bist du sicher?")) this.uploadPDF('lab_int', r.id)}}>PDF (int.) erneut generieren</div></div>}
                          </div>
                          <div className='dropdown-btn-container'>
                            <a style={!r.attributes.report_ext?.data ? {pointerEvents: 'none'} : {}} href={(r.attributes.report_ext?.data?.attributes?.url || '#')} target="_blank" rel='noreferrer'><PictureAsPdf className={'action success ' + (!r.attributes.report_ext?.data && 'disabled')} /></a>
                            {!r.attributes.report_ext?.data && <div className='dropdown'><div className='dropdown-btn' onClick={() => {if (window.confirm("Bist du sicher?")) this.uploadPDF('lab_ext', r.id)}}>PDF (ext.) erneut generieren</div></div>}
                          </div>
                        </>
                      }
                    </TableCell>
                    <TableCell>
                      <DeleteForever className={'action-icon pointer'} color="error" onClick={() => {if (window.confirm("Laborprobe sicher löschen?")) this.deleteReport(r.id)}} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>  : <Paper style={{padding: '20px'}}>
          <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="70vh"
                    paddingLeft={0}
                  >
                  <CircularProgress />
                  </Box>
            {/* Keine Analyseberichte gefunden. */}
            </Paper>
        }
      
      {
        this.state.reports.length  >= 1 &&
        <TablePagination
       component={"div"}
       count={this.state.pagination.total}
       page={this.state.page}
       onPageChange={this.handleChangePage}
       rowsPerPage={this.state.rowsPerPage}
       onRowsPerPageChange={this.handleChangeRowsPerPage}
    />
      }

        <NewLabReportModal 
          active={this.state.showCreateModal} 
          onClose={this.closeCreateModal}
          fetchData={this.fetchData}
          modalEditDraftMode={this.state.modalEditDraftMode}
          modalEditDraftData={this.state.modalEditDraftData}
          isDraft={this.state.activeIsDraft}
          mode={this.state.modalEditDraftData && !this.state.modalEditDraftData?.attributes.isDraft && !this.state.modalEditDraftData?.attributes.needsData ? 'view' : ''}
          isCreateLabel={this.state.isCreateLabel}
        />
      </div>
    )
  }
};

export default withRouter(Laboratory);
