import React, { FC } from 'react';
import './InfoSnackbar.sass';
import {NotiStore} from '../../../stores/notifications';
import { Alert, Snackbar } from '@mui/material';

interface InfoSnackbarProps {}

const InfoSnackbar: FC<InfoSnackbarProps> = () => {
  const info = NotiStore((state:any) => state.info);
  
  if (info) return (
    <Snackbar
      open={info}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={5000}
      onClose={() => NotiStore.setState({info: ''})}
    >
        <Alert severity='info' onClose={() => NotiStore.setState({info: ''})}>{info}</Alert>
    </Snackbar>
  );

  return null;
};

export default InfoSnackbar;
