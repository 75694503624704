import React, { FC } from 'react';
import './SuccessSnackbar.sass';
import {NotiStore} from '../../../stores/notifications';
import { Alert, Snackbar } from '@mui/material';

interface SuccessSnackbarProps {}

const SuccessSnackbar: FC<SuccessSnackbarProps> = () => {
  const success = NotiStore((state:any) => state.success);
  const successMessage = NotiStore((state:any) => state.successMessage);
  
  if (success) return (
    <Snackbar
      open={success}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={5000}
      onClose={() => NotiStore.setState({success: false, successMessage: ''})}
    >
        <Alert severity='success' onClose={() => NotiStore.setState({success: false, successMessage: ''})}>{successMessage || 'Erfolgreich.'}</Alert>
    </Snackbar>
  );

  return null;
};

export default SuccessSnackbar;
