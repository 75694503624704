import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import './ProcessesOverview.sass';
import FactoryIcon from '../../../assets/icons/produktion.png';
import EntryIcon from '../../../assets/icons/eingang.png';
import ExitIcon from '../../../assets/icons/ausgang.png';
import { withRouter } from '../../utils/withRouter/withRouter';

interface ProcessesOverviewProps {
  rights: string[]
}

const ProcessesOverview: FC<ProcessesOverviewProps> = (props) => (
  <div className="ProcessesOverview content" data-testid="ProcessesOverview">
    <div className="flex of-3 spacing-1 inner">
      {props.rights.includes('process::production') && <Link to={'/prozess/produktion'} className="big-btn">
        <div className="icon"><img src={FactoryIcon} alt='icon' /></div>
        <div>Produktion</div>
      </Link>}
      {props.rights.includes('process::entry') && <Link to={'/prozess/wareneingang'} className="big-btn">
        <div className="icon"><img src={EntryIcon} alt='icon' /></div>
        <div>Wareneingang</div>
      </Link>}
      {props.rights.includes('process::exit') && <Link to={'/prozess/warenausgang'} className="big-btn">
        <div className="icon"><img src={ExitIcon} alt='icon' /></div>
        <div>Warenausgang</div>
      </Link>}
    </div>
  </div>
);

export default withRouter(ProcessesOverview);
