import ContentContainer from '../components/ContentContainer/ContentContainer';
import Sidebar from '../components/Sidebar/Sidebar';

export default function SystemStandardValues() {
  return (
    <main className='System container'>
      <Sidebar />
      <ContentContainer page="settings" />
    </main>
  )
}