import React from 'react';
import './OrgEmails.sass';import { Button, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { resetNotifications } from '../../../services/notiStoreService';
import { NotiStore } from '../../../stores/notifications';
import { FetchEmailsResponse, fetchOrgEmails, updateOrgEmails } from '../../../services/configService';

interface OrgEmailsProps {
  setLocation: boolean;
}
interface OrgEmailsState {
  emailBooks: string;
  emailPurchasing: string;
  emailSales: string;
  emailPackingLists: string;
  emailDeliveryNotes: string;
}

const defaultState = {
  emailBooks: '',
  emailPurchasing: '',
  emailSales: '',
  emailPackingLists: '',
  emailDeliveryNotes: '',
}

class OrgEmails extends React.Component<OrgEmailsProps, OrgEmailsState> {
  state = {...defaultState}

  componentDidMount() {
    if (this.props.setLocation) window.history.replaceState(null, "KSM Recycling ERP", "/system/einstellungen?seite=emails");

    NotiStore.setState({loading: true});

    fetchOrgEmails()
    .then((res: FetchEmailsResponse) => {
      if (!res.success) return NotiStore.setState({error: true, errorMessage: 'Es gab einen Fehler beim Abrufen der Emailadressen.', loading: false});

      if (res.emails?.emailBooks) this.setState({emailBooks: res.emails?.emailBooks});
      if (res.emails?.emailPurchasing) this.setState({emailPurchasing: res.emails?.emailPurchasing});
      if (res.emails?.emailDeliveryNotes) this.setState({emailDeliveryNotes: res.emails?.emailDeliveryNotes});
      if (res.emails?.emailPackingLists) this.setState({emailPackingLists: res.emails?.emailPackingLists});
      if (res.emails?.emailSales) this.setState({emailSales: res.emails?.emailSales});

      NotiStore.setState({loading: false})
    })
    .catch(() => {
      NotiStore.setState({error: true, errorMessage: 'Es gab einen Fehler beim Abrufen der Emailadressen.', loading: false});
    })
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let key: string = e.target.name;
    if (Object.keys(this.state).includes(key)) this.setState({[key]: e.target.value} as any);
  }

  handleUpdate = (e: React.FormEvent): void => {
    e.preventDefault(); 
    resetNotifications();
    NotiStore.setState({loading: true})

    let {emailBooks, emailPurchasing, emailSales, emailPackingLists, emailDeliveryNotes} = this.state;

    let data = {
      emailBooks: emailBooks || null, 
      emailPurchasing: emailPurchasing || null, 
      emailSales: emailSales || null, 
      emailPackingLists: emailPackingLists || null, 
      emailDeliveryNotes: emailDeliveryNotes || null,
    };

    updateOrgEmails(data)
    .then((res: FetchEmailsResponse) => {
      if (!res.success) return NotiStore.setState({error: true, errorMessage: 'Es gab einen Fehler beim Aktualisieren der Emailadressen.', loading: false});

      if (res.emails?.emailBooks) this.setState({emailBooks: res.emails?.emailBooks});
      if (res.emails?.emailPurchasing) this.setState({emailPurchasing: res.emails?.emailPurchasing});
      if (res.emails?.emailDeliveryNotes) this.setState({emailDeliveryNotes: res.emails?.emailDeliveryNotes});
      if (res.emails?.emailPackingLists) this.setState({emailPackingLists: res.emails?.emailPackingLists});
      if (res.emails?.emailSales) this.setState({emailSales: res.emails?.emailSales});

      NotiStore.setState({success: true, successMessage: 'Update erfolgreich.', loading: false});
    })
    .catch((e) => {
      NotiStore.setState({loading: false, error: true, errorMessage: e.message || 'Es gab einen Fehler beim Aktualisieren der Emailadressen.'});
    })
  }

  render() {
    return (
      <div className="OrgEmails" data-testid="OrgEmails">
        <div>
          <h2>Hinterlegte Emailadressen</h2>
          <form onSubmit={this.handleUpdate}>
            <FormControl className='input'>
              <InputLabel htmlFor="emailBooks">Buchhaltung</InputLabel>
              <OutlinedInput id='emailBooks' value={this.state.emailBooks} name="emailBooks" label="Buchhaltung" type="email" onChange={this.handleChange}></OutlinedInput>
            </FormControl>
            <FormControl className='input'>
              <InputLabel htmlFor="emailPurchasing">Einkauf</InputLabel>
              <OutlinedInput id='emailPurchasing' value={this.state.emailPurchasing} name="emailPurchasing" label="Einkauf" type="email" onChange={this.handleChange}></OutlinedInput>
            </FormControl>
            <FormControl className='input'>
              <InputLabel htmlFor="emailDeliveryNotes">Lieferscheine</InputLabel>
              <OutlinedInput id='emailDeliveryNotes' value={this.state.emailDeliveryNotes} name="emailDeliveryNotes" label="Lieferscheine" type="email" onChange={this.handleChange}></OutlinedInput>
            </FormControl>
            <FormControl className='input'>
              <InputLabel htmlFor="emailPackingLists">Packlisten</InputLabel>
              <OutlinedInput id='emailPackingLists' value={this.state.emailPackingLists} name="emailPackingLists" label="Packlisten" type="email" onChange={this.handleChange}></OutlinedInput>
            </FormControl>
            <FormControl className='input'>
              <InputLabel htmlFor="emailSales">Verkauf</InputLabel>
              <OutlinedInput id='emailSales' value={this.state.emailSales} name="emailSales" label="Verkauf" type="email" onChange={this.handleChange}></OutlinedInput>
            </FormControl>
            <Button variant="contained" type='submit'>Emails Aktualisieren</Button>
          </form>
        </div>
      </div>
    )
  }
};

export default OrgEmails;
