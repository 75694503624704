import Sidebar from '../components/Sidebar/Sidebar';
import TopNav from '../components/TopNav/TopNav';
import WarehouseExit from '../components/Warehouse/WarehouseExit/WarehouseExit';

export default function WarehouseExitRoute() {
  return (
    <main className='Warehouse container'>
      <Sidebar />
      <TopNav />
      <WarehouseExit />
    </main>
  )
}