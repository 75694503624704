import axios from "axios";
import { api } from "./apiService";
import { Good } from "./inventoryService";
import { getJWT, jwtRes } from "./jwtService";
import { OrderData, OrderPosition } from "./warehouseEntriesService";
import { ExitOrderAttributes } from "./warehouseExitService";

export interface fetchUtilitiesRes {
  success: true;
  data: Good[];
  message: string;
}

export interface RemoveUtilityRes {
  success: true;
  message: string;
}

export interface RemoveUtilityData {
  good: Good;
  reason: string;
}

export let fetchUtilities = (): Promise<fetchUtilitiesRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      
      //do stuff here
      axios.get(api + '/api/utilities-log?populate=*', {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
          data: res.data.data.attributes.goods_in_use.data || [],
          message: ''
        })
      })
      .catch(err => {
        reject({
          success: false,
          message: err.message ? err.message : 'Fehler beim Abrufen der Betriebsmittel',
          data: []
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let removeUtility = (data: RemoveUtilityData): Promise<RemoveUtilityRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })

      if (!data.good || !data.reason) return reject({
        success: false,
        message: 'Bitte gib an, warum du das Betriebsmittel entfernen möchtest.'
      })
      
      //do stuff here
      axios.post(api + '/api/utilities-log/remove', {data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
          message: ''
        })
      })
      .catch(err => {
        reject({
          success: false,
          message: err.message ? err.message : 'Fehler beim Entfernen des Betriebsmittels.',
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let addUtility = (id: string): Promise<RemoveUtilityRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })

      if (!id || !id.toLowerCase().includes('bm-')) return reject({
        success: false,
        message: 'Bitte gib eine gültige Betriebsmittel-ID an.'
      })
      
      //do stuff here
      axios.post(api + '/api/utilities-log/add', {data: {id}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
          message: ''
        })
      })
      .catch(err => {
        console.log(err)
        reject({
          success: false,
          message: err.message ? err.message : 'Fehler beim Hinzufügen des Betriebsmittels.',
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let checkRightsPrice = (pos: OrderPosition, rights: string[], mode: string): boolean => {
  if (!pos || !pos.category || !rights || rights.length < 1) return false

  if ((pos.category === 'entry' || pos.category === 'r') && rights.includes(`price::${mode}.r`)) return true
  if (pos.category === 'zl' && rights.includes(`price::${mode}.zl`)) return true
  if ((pos.category === 'production' || pos.category === 'rpet') && rights.includes(`price::${mode}.rpet`)) return true
  if (pos.category === 'hw' && rights.includes(`price::${mode}.hw`)) return true
  if (pos.category === 'd' && rights.includes(`price::${mode}.d`)) return true
  if (pos.category === 'bm' && rights.includes(`price::${mode}.bm`)) return true
  if ((pos.category === 'nm' || pos.category === 'cw') && rights.includes(`price::${mode}.nm`)) return true

  return false
}

export let checkRightsPriceOrder = (order: OrderData | ExitOrderAttributes | undefined, rights: string[], mode: string): boolean => {
  let result = true
  
  if (mode === 'entry') {
    if (!order || !order.positions || order.positions.length < 1 || !rights || rights.length < 1) return false

    order.positions.forEach(pos => {
      // @ts-ignore
      if (result) result = checkRightsPrice(pos, rights, mode)
    })
  } else if (mode === 'exit') {
    // console.log(order)
  } else {
    result = false
  }

  return result
}