export const convertLabCategory = (input: string): string => {
  if (input === 'trading') return 'Handel'
  if (input === 'product') return 'Produkt'
  if (input === 'entry') return 'Wareneingang'

  return input
}

function isIsoDate(str: string): boolean {
  try {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str); 
    return d.toISOString()===str;
  } catch {
    return false
  }
}

export const convertDateString = (input: string): string => {
  if (isIsoDate(input)) {
    return new Date(input).toLocaleDateString(undefined, {day: '2-digit', month: '2-digit', year: 'numeric'})
  } else if (input.split('-').length === 3) {
    return input.split('-')[2] + '.' + input.split('-')[1] + '.' + input.split('-')[0]
  } else {
    return input
  }
}

export const toLocalISOTime = (input: any) => {
  try {
    var tzoffset = (new Date(input)).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(input - tzoffset)).toISOString().slice(0, -1).split('.')[0];

    return localISOTime
  } catch {
    return input
  }
}

export const toLocalISODate = (input: any) => {
  try {
    var tzoffset = (new Date(input)).getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = (new Date(input - tzoffset)).toISOString().slice(0, -1);
    return localISOTime.split('T')[0]
  } catch {
    return input
  }
}

export const convertOrderAttributes = (input: string): string => {
  if (input === 'yes') return 'Ja'
  if (input === 'no') return 'Nein'
    
  if (input === 'tray') return 'Tray'
  if (input === 'bottle') return 'Bottle'
  if (input === 'bottleAndTray') return 'Bottle + Tray'

  if (input === 'colored') return 'Mixed'
  if (input === 'transparent') return 'Clear'
  if (input === "blue") return 'Blue'
  if (input === "brown") return 'Brown'
  if (input === "clear-and-light-blue") return 'Clear & light blue'
  if (input === "green") return 'Green'
  if (input === "light-blue") return 'Light blue'

  if (input === 'bale') return 'Ballen'
  if (input === 'bigbag') return 'Bigbag'
  if (input === 'divers') return 'Divers'

  // exit order categories
  if (input === 'product') return 'Produkt'
  if (input === 'trading') return 'Handel'
  if (input === 'nm') return 'Nebenstrom'

  if (input === 'lkw') return 'LKW Ladung'
  if (input === 'container') return 'LKW mit Container'

  return input
}

export const convertGoodsCategory = (input: string): string => {
  if (input.toLowerCase() === 'rpet') return 'rPET'
  if (input.toLowerCase() === 'bm') return 'Betriebsmittel'
  if (input.toLowerCase() === 'd') return 'Divers'
  if (input.toLowerCase() === 'hw') return 'Handelsware'
  if (input.toLowerCase() === 'zl') return 'Handelsware (ZL)'
  if (input.toLowerCase() === 'nm') return 'Nebenstrom'
  if (input.toLowerCase() === 'r') return 'Rohstoff'
  if (input.toLowerCase() === 'cw') return 'Chargenwechsel'

  if (input === 'entry') return 'Rohstoff'
  if (input === 'production') return 'Produktion'

  return input
}

export const getSymbol = (input: string): string => {
  input = input.toLowerCase()

  if (input === 'r' || input === 'entry') return 'R'
  if (input === 'production' || input === 'rpet') return 'rPET'
  if (input.toLowerCase() === 'bm' || input.toLowerCase() === 'd' || input.toLowerCase() === 'hw' || input.toLowerCase() === 'zl' || input.toLowerCase() === 'nm' || input.toLowerCase() === 'r' || input.toLowerCase() === 'cw') return input.toUpperCase()

  return input
}

export const localNumber = (input: number | string): string => {
  if (typeof input === 'string') {
    if (input.includes('.')) input = input.replaceAll('.', '')
    if (input.includes(',')) input = input.replaceAll(',', '.')

    try {
      return ((Number(input)/100)*100).toLocaleString('de-DE')
    } catch {
      return input
    }
  } else if (typeof input === 'number') {
    try {
      return input.toLocaleString('de-DE')
    } catch {
      return input.toString()
    }
  }

  return input
}

export const localNumberInTons = (input: number | string): string => {
  if (typeof input === 'string') {
    if (input.includes('.')) input = input.replaceAll('.', '')
    if (input.includes(',')) input = input.replaceAll(',', '.')
    
    try {
      return (Number(input) / 1000).toLocaleString('de-DE')
    } catch {
      return input
    }
  } else if (typeof input === 'number') {
    try {
      return (input / 1000).toLocaleString('de-DE')
    } catch {
      return input.toString()
    }
  }

  return input
}

export const localPriceInTons = (input: number | string): string => {
  if (typeof input === 'string') {
    try {
      return (Number(input) * 1000).toLocaleString('de-DE')
    } catch {
      return input
    }
  } else if (typeof input === 'number') {
    try {
      return (input * 1000).toLocaleString('de-DE')
    } catch {
      return input.toString()
    }
  }

  return input
}

export const localCurrency = (input: number | string): string => {
  const formatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,      
    maximumFractionDigits: 2,
  });

  if (typeof input === 'string') {
    try {
      return formatter.format(Number(input))
    } catch {
      return input
    }
  } else if (typeof input === 'number') {
    try {
      return formatter.format((input))
    } catch {
      return input.toString()
    }
  }

  return input
}

export const minToTime = (input: number): string => {
  if (isNaN(input)) return input.toString()

  return `${Math.floor(input / 60)} h  ${Math.ceil(input % 60)} min`
}

export const translate = (input: string): string => {
  if (input === 'stk') return 'Stk.'
  if (input === 'planned') return 'geplant'
  if (input === 'active') return 'aktiv'
  if (input === 'finished') return 'abgeschlossen'

  if (input === 'stored') return 'auf Lager'
  if (input === 'sold') return 'verkauft'
  if (input === 'used') return 'verbraucht'
  if (input === 'util_in_use') return 'BM in Verwendung'
  if (input === 'util_used') return 'BM verbraucht'
  if (input === 'util_broken') return 'BM kaputt'

  return input
}

export const translateEn = (input: string): string => {
  if (input === 'stored') return 'On stock'
  if (input === 'sold') return 'sold'
  if (input === 'used') return 'used'
  if (input === 'util_in_use') return 'resource in use'
  if (input === 'util_used') return 'resource used'
  if (input === 'util_broken') return 'wrong resource'

  return input
}
