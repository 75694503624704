import ProductionPlan from '../components/Production/ProductionPlan/ProductionPlan';
import Sidebar from '../components/Sidebar/Sidebar';
import TopNav from '../components/TopNav/TopNav';

export default function ProductionPlanRoute() {
  return (
    <main className='Warehouse container'>
      <Sidebar />
      <TopNav />
      <ProductionPlan />
    </main>
  )
}