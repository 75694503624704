import create from 'zustand'
import {logout} from './../services/userService';

export const UserStore: any = create((set: any) => ({
  isAuthenticated: false,
  user: {
    blocked: false,
    confirmed: false,
    email: '',
    username: '',
    rights: [],
  },
  navigateTo: '',
  logout: () => {
    set(() => ({ isAuthenticated: false }));
    set(() => ({ user: {
      blocked: false,
      confirmed: false,
      email: '',
      username: '',
      rights: [],
      navigateTo: '/'
    }}));
    logout();
  },
  login: () => {
    set(() => ({ isAuthenticated: true }));
  }
}))