import axios from "axios";
import { api } from "./apiService";
import { Batch } from "./batchesService";
import { Good } from "./inventoryService";
import { getJWT, jwtRes } from "./jwtService";

export interface Report {
  id: number,
  attributes: {
    category: string;
    batch: {data: Batch};
    createdAt: string;
    idSample: string;
    tolerance: string;
    type: string;
    updatedAt: string;
    isDraft: boolean;
    needsData: boolean;
    report_int: {data: {attributes: {url: string}}};
    report_ext: {data: {attributes: {url: string}}};
    testData: {
      [key: string]: string
    }[];
    good: {data: Good};
    customId: string;
    label?: {
      id: number;
      name: string;
      url: string;
      data?: {attributes: {url: string}}
    }
  }
}
export interface metaResponse {
  pagination: { page: number; pageCount: number; pageSize: number; total: number; };
   page: number, pageCount : number ,pageSize : number ,total :  number
}
export interface fetchLaboratoryReportsResponse {
  success: boolean;
  reports?: Report[];
  reportsCount?: number;
  message?: string;
  meta:metaResponse
}

export interface fetchLabReportResponse {
  success: boolean;
  report?: Report;
  message?: string;
}

export interface newLabReportData {
  category: string;
  type: string;
  testData: {
    [key: string]: string;
  }[],
  isDraft: boolean;
  good: string;
}

export interface saveNewLabReportResponse {
  success: boolean;
  message?: string;
}

export const testOptionsStandard: {id: string, title: string}[] = [
  {id: "flakes", title: "farbige Flakes"},
  {id: "yellowFlakes", title: "gelbe Flakes"},
  {id: "po", title: "PO"},
  {id: "labels", title: "Labels"},
  {id: "pa", title: "PA, PS"},
  {id: "glue", title: "Kleber (Flakes inkl.)"},
  {id: "rubber", title: "Gummi"},
  {id: "multilayerFlakes", title: "Multilayer Flakes"},
  {id: "metal", title: "Metall"},
  {id: "pvc", title: "PVC"},
  {id: "other", title: "Sonstige"},
]

export const testOptionsExtended: {id: string, title: string}[] = [
  {id: "phValue", title: "PH-Wert"},
  {id: "bulkDensity", title: "Dichte g/l"},
  {id: "moisture", title: "Feuchte %"},
  {id: "flakesSubOne", title: "Flakes <1mm"},
  {id: "flakesOne", title: "Flakes >1mm"},
  {id: "flakesTwo", title: "Flakes >2mm"},
  {id: "flakesFour", title: "Flakes >4mm"},
  {id: "flakesSix", title: "Flakes >6,3mm"},
  {id: "flakesEight", title: "Flakes >8mm"},
  {id: "flakesTen", title: "Flakes >10mm"},
  {id: "flakesTwelve", title: "Flakes >12,5mm"},
  {id: "flakesFourteen", title: "Flakes 14mm"},
]

export interface createLabLabelRes {
  success: boolean;
  message: string;
  data: string;
}

export let fetchLaboratoryReports = (filter:string): Promise<fetchLaboratoryReportsResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })

      axios.get(api + `/api/laboratory-reports?${filter}&sort[0]=createdAt%3Adesc&populate[0]=good.fromBatch&populate[1]=report_int&populate[2]=report_ext`, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          reports: res.data.data,
          reportsCount: res.data.data.count,
          meta:res.data.meta
        })
      })
      .catch(err => {
        reject({
          success: false
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'catch triggered'
      })
    })
  })
}

export let fetchLabReport = (id: number): Promise<fetchLabReportResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })

      axios.get(api + '/api/laboratory-reports/' + id + '?populate=*', {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          report: res.data.data
        })
      })
      .catch(err => {
        reject({
          success: false
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'catch triggered'
      })
    })
  })
}

export let saveNewLabReport = (data: newLabReportData, isUpdate: boolean = false, reportId: number = -1): Promise<saveNewLabReportResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })

      if (isUpdate && reportId >= 0) {
        axios.put(api + '/api/laboratory-reports/' + reportId, {data: data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
        .then((res) => {
          resolve({
            success: true
          })
        })
        .catch(err => {
          console.log(err)
          reject({
            success: false
          })
        })
      } else {
        axios.post(api + '/api/laboratory-reports', {data: data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
        .then((res) => {
          resolve({
            success: true
          })
        })
        .catch(err => {
          console.log(err)
          reject({
            success: false
          })
        })
      }
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let createLabLabel = (goodId: string): Promise<createLabLabelRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })

      axios.post(api + '/api/laboratory-reports/create-label', {data: {goodId}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          message: '',
          data: res.data || ''
        })
      })
      .catch(err => {
        reject({
          success: false,
          message: 'Fehler beim Erstellen des Labels.',
          data: ''
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let fetchReportsWithFilters = (filters: {[key: string]: string[]}, sortBy: {[key: string]: string} = {}): Promise<fetchLaboratoryReportsResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        reports: []
      })
      
      //do stuff here
      axios.post(api + `/api/laboratory-reports/custom-filter?populate=*`, {data: {filters, sortBy}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          reports: res.data || [],
          message: '',
          meta:res.data.meta
        })
      })
      .catch(err => {
        reject({
          success: false,
          reports: [],
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message :'Fehler beim Abrufen der Daten.'
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        reports: []
      })
    })
  });
}

export let deleteReport = (id: number): Promise<fetchLabReportResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })

      axios.delete(api + '/api/laboratory-reports/' + id, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          message: 'Laborprobe erfolgreich gelöscht.'
        })
      })
      .catch(err => {
        reject({
          success: false,
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message : 'Fehler aufgetreten.'
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'catch triggered'
      })
    })
  })
}