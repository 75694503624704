import ContentContainer from '../components/ContentContainer/ContentContainer';
import Sidebar from '../components/Sidebar/Sidebar';

export default function SystemDefinitions() {
  return (
    <main className='System container'>
      <Sidebar />
      <ContentContainer page="definitions" />
    </main>
  )
}