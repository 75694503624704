import Sidebar from '../components/Sidebar/Sidebar';
import LaboratoryComponent from '../components/Laboratory/Laboratory';
import TopNav from '../components/TopNav/TopNav';

export default function Laboratory() {
  return (
    <main className='Laboratory container'>
      <Sidebar />
      <TopNav />
      <LaboratoryComponent />
    </main>
  )
}