import { ExpandMore } from '@mui/icons-material';
import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import React from 'react';
// import { Link } from 'react-router-dom';
import { fetchWarehouseData, WarehouseSpot } from '../../../services/configService';
import { Good } from '../../../services/inventoryService';
import { resetNotifications } from '../../../services/notiStoreService';
import { convertDateString, convertGoodsCategory, localNumber, localNumberInTons } from '../../../services/stringConverter';
import { NotiStore } from '../../../stores/notifications';
import './WarehouseStores.sass';

interface WarehouseStoresProps { }
interface WarehouseStoresState {
  spots: WarehouseSpot[];
  tableExpandedPos: number[];
  page: number;
  rowsPerPage: number;
  pagination: { page: number, pageCount: number, pageSize: number, total: number },
  // sort:string | null,
  search:string 
}

const defaultState = {
  spots: [],
  tableExpandedPos: [-1],
  page: 0,
  rowsPerPage: 10,
  pagination: { page: 1, pageCount: 8, pageSize: 100, total: 100 },
  // sort:"name:asc",
  search:''
};

class WarehouseStores extends React.Component<WarehouseStoresProps, WarehouseStoresState> {
  state = { ...defaultState };

  componentDidMount() {
    resetNotifications()
    this.fetchData()
  }

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    this.setState({ page: this.state.page === 0 ? newPage + 1 : newPage }, this.fetchData)

  };

  handleFilter = (type:string,value:string): void => {
    // NotiStore.setState({ loading: true })
    if(type==='search'){
      this.setState({search:value})
      this.fetchData(value)
      
    }else{
      this.fetchData() 
    }

  }

  clearFilters = (): void => {
    // this.setState({ sort:'name:desc',search:'' })
    this.fetchData();
  }

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
    this.setState({ page: 0 }, this.fetchData)

  };

  fetchData = (search=''): void => {
    NotiStore.setState({ loading: true });
    fetchWarehouseData(`_q=${search}`)
      .then(res => {
        let tableExpandedPos: number[] = [];
        if (res.spots && res.spots.length >= 1) res.spots.forEach(store => {
          let storeGoods: Good[] = []
          this.setState({ pagination: res.meta.pagination })
          if (store.attributes.goods && store.attributes.goods.data) store.attributes.goods.data.forEach(good => {
            if (good.attributes.status === 'stored') storeGoods.push(good)
          })
          store.attributes.goods = { data: storeGoods }
        })

        // if (res.spots && res.spots.length >= 1) res.spots.forEach((o, i) => tableExpandedPos.push(i))

        if (res.spots && res.spots.length >= 1) this.setState({ spots: res.spots, tableExpandedPos });
      })
      .catch(err => {
        NotiStore.setState({ error: true, errorMessage: 'Fehler beim Abrufen der Lagerdaten aufgetreten.' })
      })
      .finally(() => {
        NotiStore.setState({ loading: false })
      })
  }

  expand = (i: number): void => {
    if (i >= 0) {
      let { tableExpandedPos } = this.state

      if (tableExpandedPos.includes(i)) {
        let index = tableExpandedPos.indexOf(i)

        if (index > -1) {
          tableExpandedPos.splice(index, 1)
        }
      } else {
        tableExpandedPos.push(i)
      }

      this.setState({ tableExpandedPos })
    }
  }

  render() {
    return (
      <div className="WarehouseStores content" data-testid="WarehouseStores">
        <div className="header-bar">
          <h1>Lagerplätze</h1>
        </div>
        <div style={{ marginTop: 10,marginBottom:10 }}>
            <TextField value={this.state.search} placeholder='Search...' onChange={(e)=>this.handleFilter('search',e.target.value)} />
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Select
            value={this.state.sort}
            input={<OutlinedInput />}
            onChange={(e) => this.setState({sort:e.target.value})}
            onClose={()=>this.handleFilter('sort','')}
            sx={{ width: '300px' }}
          >
           
            {InvetorySort.map((status,i) => (
              <MenuItem key={i} value={status.value}>
                {status.Label}
              </MenuItem>
            ))}

          </Select>&nbsp;&nbsp;
          <Button onClick={()=>this.handleFilter('sort','')} variant='contained'>Sort</Button>&nbsp;&nbsp;
          <Button onClick={()=>this.clearFilters()} variant='contained' color="error">Clear</Button> */}
          </div>
        {this.state.spots.length >= 1 ? (
          <TableContainer component={Paper} className='spots-table'>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>Lager</TableCell>
                  <TableCell>Bezeichnung</TableCell>
                  <TableCell>Positionen</TableCell>
                  <TableCell>Gesamtkapazität</TableCell>
                  <TableCell>Belegt</TableCell>
                  <TableCell>Freie Kapazität</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.spots
                .sort((a:any, b:any) => a.attributes.name.localeCompare(b.attributes.name))
                .map((spot: WarehouseSpot, i: number) => (
                  <React.Fragment key={spot.attributes.name}>
                    <TableRow>
                      <TableCell sx={{ width: '60px' }}>
                        {spot.attributes.goods && spot.attributes.goods.data && spot.attributes.goods.data.length >= 1 ? <ExpandMore className={`pointer`} style={this.state.tableExpandedPos.includes(i) ? { transform: 'rotate(270deg)' } : {}} onClick={() => this.expand(i)} /> : ''}
                      </TableCell>
                      <TableCell><strong>{spot.attributes.name}</strong></TableCell>
                      <TableCell>{spot.attributes.description || ''}</TableCell>
                      <TableCell><i>{spot.attributes.goods && spot.attributes.goods.data && spot.attributes.goods.data.length >= 1 ? (spot.attributes.goods.data.length + ' Positionen') : 'leer'}</i></TableCell>
                      <TableCell>{localNumberInTons(spot.attributes.totalCapacity || 0)} t</TableCell>
                      <TableCell>{localNumberInTons(spot.attributes.totalCapacity - spot.attributes.capacity)} t</TableCell>
                      <TableCell>{localNumberInTons(spot.attributes.capacity || 0)} t</TableCell>
                    </TableRow>
                    {this.state.tableExpandedPos.includes(i) && spot.attributes.goods && spot.attributes.goods.data && spot.attributes.goods.data.length >= 1 && (
                      <TableRow sx={{ background: '#F4F4F4' }}>
                        <TableCell style={{ padding: '20px 20px 20px 60px' }} colSpan={8}>
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ background: '#f4f4f4' }}><strong>ID</strong></TableCell>
                                <TableCell sx={{ background: '#f4f4f4' }}><strong>Bezeichnung</strong></TableCell>
                                <TableCell sx={{ background: '#f4f4f4' }}><strong>Kategorie</strong></TableCell>
                                <TableCell sx={{ background: '#f4f4f4' }}><strong>Menge</strong></TableCell>
                                <TableCell sx={{ background: '#f4f4f4' }}><strong>Eingang</strong></TableCell>
                                <TableCell sx={{ background: '#f4f4f4' }}><strong>Verarbeitung</strong></TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {spot.attributes.goods && spot.attributes.goods.data ? spot.attributes.goods.data.map((good: Good, j: number) => good.attributes.status === 'stored' && (
                                <TableRow key={`stored-${good.id}`}>
                                  <TableCell>
                                    {/* <Link to={`/lager/inventar?id=${good.id}`}> */}
                                    <span className='pointer'>{good.attributes.customId}</span>
                                    {/* </Link> */}
                                  </TableCell>
                                  <TableCell>{good.attributes.typeIntern}</TableCell>
                                  <TableCell>{convertGoodsCategory(good.attributes.category)}</TableCell>
                                  <TableCell>{good.attributes.unit === 'kg' ? localNumberInTons(good.attributes.quantity) : localNumber(good.attributes.quantity)} {good.attributes.unit === 'kg' ? 't' : good.attributes.unit}</TableCell>
                                  <TableCell>{convertDateString(good.attributes.createdAt)}</TableCell>
                                  <TableCell>
                                    {good.attributes.batches && good.attributes.batches.data && good.attributes.batches.data.length >= 1 ? good.attributes.batches.data.map((batch) => (
                                      <React.Fragment key={good.id + batch.id}><span>{batch.attributes.start ? `${new Date(batch.attributes.start).toLocaleDateString()} ${new Date(batch.attributes.start).toLocaleTimeString().replace(':00', '')}` : 'n/a'}</span><br /></React.Fragment>
                                    )) : 'nicht verplant'}
                                  </TableCell>
                                </TableRow>
                              )) : null}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableCell>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="70vh"
              paddingLeft={80}
            >
              <CircularProgress />
            </Box>


          </TableCell>
          // <Paper style={{padding: '20px'}}>Keine Lagerplätze gefunden.</Paper>
        )}
        {/* {
          this.state.spots.length >= 1 && 
        <TablePagination
          component={"div"}
          count={this.state.pagination.total}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          rowsPerPage={this.state.rowsPerPage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
        } */}
      </div>
    )
  }
}

export default WarehouseStores;
