import { OrderData, OrderPosition } from "./warehouseEntriesService";
import { ExitOrderData } from "./warehouseExitService";

// Create our number formatter.
export var formatter = new Intl.NumberFormat('de-DE', {
  // style: 'currency',
  // currency: 'EUR',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

// formatter.format(2500); /* $2,500.00 */

export const calcTotal = (q: number, u: string, p: number, pU: string) => { //input quantity, unit, price, priceUnit
  if (!((u === 't' || u === 'kg' || u === 'stk' || u === 'l') && (q > 0 && p > 0) && (pU === 't' || pU === 'kg' || pU === 'stk' || pU === 'l'))) {
    return 0
  } 

  if (u === 't') q = q * 1000;

  if (pU === 't') p = p / 1000;

  return p * q
}

export const calcTotalOfPos = (pos: OrderPosition) => { //input quantity, unit, price, priceUnit
  let u = pos.unit;
  let q = pos.quantity || 0;
  let p = pos.price || 0;
  let pU = pos.priceUnit;
  
  if (!((u === 't' || u === 'kg' || u === 'stk' || u === 'l') && (q > 0 && p > 0) && (pU === 't' || pU === 'kg' || pU === 'stk' || pU === 'l'))) {
    return 0
  } else {
    if (u === 't') q = q * 1000;

    if (pU === 't') p = p / 1000;

    return p * q
  }
}

export const add = (vals: number[]) => {
  return vals.reduce((partialSum, a) => partialSum + a, 0)
}

export const multiply = (a: number, b: number) => {
  if (a && b) {
    return a * b
  } 

  return 'n/a'
}

export let getTotalOfOrder = (order: OrderData | ExitOrderData) => {
  let total = 0;

  order.positions.forEach((pos) => {
    let {quantity, unit, price, priceUnit} = pos;
    if (quantity && unit && price && priceUnit) total += calcTotal(quantity, unit, price, priceUnit)
  })

  return formatter.format(total);
}

export const getWeightOfOrder = (order: OrderData) => {
  let total = 0;

  try {
    order.positions.forEach((pos) => {
      let {quantity, unit} = pos;

      if (quantity && unit) {
        quantity = Number(quantity)

        if (unit === 't') {
          quantity = quantity * 1000;
          unit = 'kg'
        }

        total = total + quantity
      }
    })
  } catch { return 0 }

  return formatter.format(total);
}