import ProcessProduction from '../components/Processes/ProcessProduction/ProcessProduction';
import Sidebar from '../components/Sidebar/Sidebar';
import TopNav from '../components/TopNav/TopNav';
import { UserStore } from '../stores/user';

export default function ProcessProductionRoute() {
  const user = UserStore((state: any) => state.user);

  return (
    <main className='Process container'>
      <Sidebar user={user} />
      <TopNav />
      <ProcessProduction />
    </main>
  )
}