import React from 'react';
import logo from '../assets/images/ksm-recycling-logo-bgremove.png';
import LoginForm from '../components/LoginForm/LoginForm';

interface LoginProps {
  mode?: string;
}

let Login = (props: LoginProps) => {
  return (
    <main className='Login'>
      <img className='logo' src={logo} alt="KSM Recycling Logo" />
      <LoginForm mode={props.mode || 'login'} />
    </main>
  )
}

export default Login;