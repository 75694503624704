import axios from 'axios';
import { api } from './apiService';
import { Good } from './inventoryService';
import { getJWT, jwtRes } from './jwtService';

export interface EmailData {
  emailBooks: string | null;
  emailPurchasing: string | null;
  emailPackingLists: string | null;
  emailDeliveryNotes: string | null;
  emailSales: string | null;
}

export interface FetchEmailsResponse {
  success: boolean;
  emails?: EmailData;
}

export interface WarehouseSpot {
  id: number;
  attributes: {
    name: string;
    description: string;
    capacity: number;
    totalCapacity: number;
    goods?: {data: Good[]};
    bypasses?: string[];
  };
  isUpdated?: boolean;
}

interface UpdateSpotData {
  name: string;
  description: string;
  totalCapacity: number;
}

export interface FetchWarehouseDataResponse {
  success: boolean;
  spots: WarehouseSpot[];
  message: string;
  meta:metaResponse

}
export interface metaResponse {
  pagination: { page: number; pageCount: number; pageSize: number; total: number; };
   page: number, pageCount : number ,pageSize : number ,total :  number
}
export interface FetchWarehousePlanResponse {
  success: boolean;
  url?: string;
}

export interface fetchToleranceValuesResponse {
  success: boolean;
  message?: string;
  data: {
    flakesMin: number;
    flakesMax: number;
    yellowFlakesMin: number;
    yellowFlakesMax: number;
    poMin: number;
    poMax: number;
    labelsMin: number;
    labelsMax: number;
    paMin: number;
    paMax: number;
    glueMin: number;
    glueMax: number;
    rubberMin: number;
    rubberMax: number;
    multilayerFlakesMin: number;
    multilayerFlakesMax: number;
    otherMin: number;
    otherMax: number;
    pvcMin: number;
    pvcMax: number;
    metalMin: number;
    metalMax: number;
    phValueMin: number;
    phValueMax: number;
    bulkDensityMin: number;
    bulkDensityMax: number;
    moistureMin: number;
    moistureMax: number;
    flakesSubOneMin: number;
    flakesSubOneMax: number;
    flakesOneMin: number;
    flakesOneMax: number;
    flakesTwoMin: number;
    flakesTwoMax: number;
    flakesFourMin: number;
    flakesFourMax: number;
    flakesSixMin: number;
    flakesSixMax: number;
    flakesEightMin: number;
    flakesEightMax: number;
    flakesTenMin: number;
    flakesTenMax: number;
    flakesTwelveMin: number;
    flakesTwelveMax: number;
    flakesFourteenMin: number;
    flakesFourteenMax: number;
    flakesSubOneMinPercent: number;
    flakesSubOneMaxPercent: number;
    flakesOneMinPercent: number;
    flakesOneMaxPercent: number;
    flakesTwoMinPercent: number;
    flakesTwoMaxPercent: number;
    flakesFourMinPercent: number;
    flakesFourMaxPercent: number;
    flakesSixMinPercent: number;
    flakesSixMaxPercent: number;
    flakesEightMinPercent: number;
    flakesEightMaxPercent: number;
    flakesTenMinPercent: number;
    flakesTenMaxPercent: number;
    flakesTwelveMinPercent: number;
    flakesTwelveMaxPercent: number;
    flakesFourteenMinPercent: number;
    flakesFourteenMaxPercent: number;
    toleranceMedium: number;
    manual: {
      data: {
        attributes: {
          url: string;
        }
      }
    };
  }
}

export interface updateToleranceValuesResponse {
  success: boolean;
  message?: string;
  data: {
    [key: string]: number;
  }
}

export interface uploadToleranceValuesManualResponse {
  success: boolean;
  message?: string;
  data: {
    url: string;
  }
}

export interface fetchProductionSettingsResponse {
  success: boolean;
  message?: string;
  data: {
    [key: string]: string | number;
    productionTime: number;
    rpetInput: number;
  }
}

export interface updateProductionSettingsResponse {
  success: boolean;
  message?: string;
  data: {
    [key: string]: number;
  }
}

export let fetchOrgEmails = (): Promise<FetchEmailsResponse> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt) {
      reject({});
    } else {
      axios.get(api + '/api/organization-email', {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        resolve({
          success: true,
          emails: res.data.data.attributes
        });
      })
      .catch(err => {
        reject({});
      })
    }
  })
}

export let updateOrgEmails = (data: EmailData): Promise<FetchEmailsResponse> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');


    if (!jwt) {
      reject({});
    } else {
      axios.put(api + '/api/organization-email', {data: data}, {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        resolve({
          success: true,
          emails: res.data.data.attributes
        });
      })
      .catch(err => {
        if (err.response.data.error.message && err.response.data.error.message.includes('must be a valid email')) err.response.data.error.message = 'Bitte gib nur gültige Email-Adressen an.'
        reject({
          success: false,
          message: err.response.data.error.message || 'Fehler beim Aktualisieren der Emails',
        });
      })
    }
  })
}

export let fetchWarehouseData = (filter:string = ''): Promise<FetchWarehouseDataResponse> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt) {
      reject({});
    } else {
      axios.get(api + `/api/warehouse-spots?${filter}&populate[0]=goods&populate[1]=batchChangeBags&populate[2]=batches&populate[3]=goods.batches`, {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        resolve({
          success: true,
          spots: res.data.data.sort((a: WarehouseSpot, b: WarehouseSpot) => {return a.id - b.id}),
          message: '',
          meta:res.data.meta,
        });
      })
      .catch(err => {
        reject({});
      })
    }
  })
}

export let addWarehouseSpot = (data: {attributes: { name: string; description: string; totalCapacity: number; }}): Promise<FetchWarehouseDataResponse> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt) {
      reject({});
    } else {
      axios.post(api + '/api/warehouse-spots', {data: data.attributes}, {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        resolve({
          success: true,
          spots: [],
          message: '',
          meta:res.data.meta,
        });
      })
      .catch(err => {
        reject({
          success: false,
          message: err.response.data.error.message ? err.response.data.error.message : '',
        });
      })
    }
  })
}

export let updateWarehouseSpot = (id: number, data: UpdateSpotData): Promise<FetchWarehouseDataResponse> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt) {
      reject({});
    } else {
      axios.put(api + '/api/warehouse-spots/' + id, {data: data}, {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        resolve({
          success: true,
          spots: [],
          message: '',
          meta:res.data.meta,
        });
      })
      .catch(err => {
        reject({
          success: false,
          message: err.response.data.error.message ? err.response.data.error.message : '',
        });
      })
    }
  })
}

export let deleteWarehouseSpot = (id: number): Promise<{success: boolean}> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt) {
      reject({});
    } else {
      axios.delete(api + '/api/warehouse-spots/' + id, {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        resolve({
          success: true
        });
      })
      .catch(err => {
        reject({});
      })
    }
  })
}

export let fetchWarehousePlan = (): Promise<FetchWarehousePlanResponse> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt) {
      reject({});
    } else {
      axios.get(api + '/api/warehouse-plan?populate=*', {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        resolve({
          success: true,
          url: res.data.data.attributes.plan.data.attributes.url
        });
      })
      .catch(err => {
        reject({});
      })
    }
  })
}

export let addWarehousePlan = (file: File): Promise<FetchWarehouseDataResponse> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt) {
      reject({});
    } else {
      axios.put(api + '/api/warehouse-plan', {data: {plan: null}}, {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        let formData = new FormData();
        formData.append('files', file);
        formData.append('ref', 'api::warehouse-plan.warehouse-plan')
        formData.append('refId', '1')
        formData.append('field', 'plan')

        axios.post(api + '/api/upload', formData, {headers: {'Authorization': `Bearer ${jwt}`}})
        .then(res => {
          resolve({
            success: true,
            spots: [],
            message: '',
            meta:res.data.meta,
          });
        })
        .catch(err => {
          reject({});
        })
      })
      .catch(err => {
        reject({});
      })
    }
  })
}

export let deleteWarehousePlan = (): Promise<FetchWarehouseDataResponse> => {
  return new Promise((resolve, reject) => {
    let jwt = window.localStorage.getItem('ksm_erp_jwt');

    if (!jwt) {
      reject({});
    } else {
      axios.put(api + '/api/warehouse-plan', {data: {plan: null}}, {headers: {'Authorization': `Bearer ${jwt}`}})
      .then(res => {
        resolve({
          success: true,
          spots: [],
          message: '',
          meta:res.data.meta,
        });
      })
      .catch(err => {
        reject({});
      })
    }
  })
}

export let fetchToleranceValues = (): Promise<fetchToleranceValuesResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: {}
      })

      axios.get(api + '/api/tolerance-value?populate=manual', {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          data: res.data.data.attributes,
        })
      })
      .catch((err) => {
        reject({
          success: false,
          message: 'catch 2 triggered',
          data: {}
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'catch triggered',
        data: {}
      })
    })
  })
}

export let updateToleranceValues = (data: {[key: string]: number}): Promise<updateToleranceValuesResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: {}
      })

      axios.put(api + '/api/tolerance-value', {data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
          data: res.data.data.attributes
        })
      })
      .catch(err => {
        reject({
          success: false,
          message: err.response.data.error.message,
          data: {}
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'catch triggered',
        data: {}
      })
    })
  })
}

export let addToleranceValuesManual = (file: File): Promise<uploadToleranceValuesManualResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: {}
      })

      axios.put(api + '/api/tolerance-value', {data: {manual: null}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        let formData = new FormData();
        formData.append('files', file);
        formData.append('ref', 'api::tolerance-value.tolerance-value')
        formData.append('refId', '1')
        formData.append('field', 'manual')

        axios.post(api + '/api/upload', formData, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
        .then(res => {
          resolve({
            success: true,
            data: {url: res.data[0].url}
          });
        })
        .catch(err => {
          reject({
            success: false,
            message: err.response.data.error.message,
            data: {}
          })
        })
      })
      .catch(err => {
        reject({
          success: false,
          message: err.response.data.error.message,
          data: {}
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'err.response.data.error.message',
        data: {}
      })
    })
  })
}

export let deleteToleranceValuesManual = (): Promise<updateToleranceValuesResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: {}
      })

      axios.put(api + '/api/tolerance-value', {data: {manual: null}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
          data: res.data.data.attributes
        });
      })
      .catch(err => {
        reject({
          success: false,
          message: err.response.data.error.message,
          data: {}
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: err.response.data.error.message,
        data: {}
      })
    })
  })
}

export let fetchProductionSettings = (): Promise<fetchProductionSettingsResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: {}
      })

      axios.get(api + '/api/production-setting', {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          data: res.data.data.attributes,
        })
      })
      .catch((err) => {
        reject({
          success: false,
          message: 'catch 2 triggered',
          data: {}
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'catch triggered',
        data: {}
      })
    })
  })
}

export let updateProductionSettings = (data: {[key: string]: string | number}): Promise<updateProductionSettingsResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: {}
      })

      axios.put(api + '/api/production-setting', {data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
          data: res.data.data.attributes
        })
      })
      .catch(err => {
        reject({
          success: false,
          message: err.response.data.error.message,
          data: {}
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'catch triggered',
        data: {}
      })
    })
  })
}