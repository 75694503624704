import ProductionBatches from '../components/Production/ProductionBatches/ProductionBatches';
import Sidebar from '../components/Sidebar/Sidebar';
import TopNav from '../components/TopNav/TopNav';

export default function ProductionBatchesRoute() {
  return (
    <main className='Warehouse container'>
      <Sidebar />
      <TopNav />
      <ProductionBatches />
    </main>
  )
}