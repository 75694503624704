import { Tooltip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Modal, Box, Button, Chip, TextField, MenuItem, Checkbox, ListItemText, Select, OutlinedInput, InputAdornment, TablePagination, CircularProgress } from '@mui/material';
import React from 'react';
import { deleteProduct, fetchInvetory, fetchInvetoryWithFilters, fetchSingleInventory, FilteredGood, Good, updateGood, updateWarehouseSpot } from '../../../services/inventoryService';
import { resetNotifications } from '../../../services/notiStoreService';
import { NotiStore } from '../../../stores/notifications';
import './Inventory.sass';
import { convertDateString, convertGoodsCategory, convertOrderAttributes, localCurrency, localNumber, localNumberInTons, toLocalISOTime, translate, translateEn } from '../../../services/stringConverter';
import { ExitOrderAttributes, fetchExitOrders } from '../../../services/warehouseExitService';
import { Close, FilterList, ArrowDownward, QrCode2, DeleteForever, InfoOutlined, DeleteForeverOutlined } from '@mui/icons-material';
import FilterPop from '../../utils/FilterPop/FilterPop';
import { Link } from 'react-router-dom';
import { withRouter } from '../../utils/withRouter/withRouter';
import ProductForm from './ProductForm/ProductForm';
import { InvetorySort, foodSafeOptions, materials, rpetColors, statuses } from '../../../utils/defaults';
import { CSVLink } from "react-csv";
import { fetchWarehouseData, WarehouseSpot } from '../../../services/configService';
import { Dayjs } from 'dayjs';

interface InventoryProps {
  searchParams: { [key: string]: string };
  rights: string[];
}
interface InventoryState {
  hasError: boolean;
  inventory: FilteredGood[];
  spots: WarehouseSpot[];
  activeGood: Good | undefined;
  showDetailPop: boolean;
  exitOrders: {
    id: number;
    attributes: ExitOrderAttributes;
  }[];
  showFilterPop: number;
  filters: {
    [key: string]: string[];
  };
  activeFilters: boolean;
  sortBy: {
    [key: string]: string;
  };
  showCreateModal: boolean;
  filterStatus: string[];
  enableSpotChange: boolean;
  newSpot: string;
  enableUpdate: string;
  updateData: { [key: string]: string | number };
  page: number;
  rowsPerPage: number;
  pagination: { page: number, pageCount: number, pageSize: number, total: number },
  startDate:Dayjs | null,
  endDate:Dayjs | null,
  sort:string | null,
  search:string,
  checks:number[],
  checkbox:boolean
}

const defaultState: InventoryState = {
  hasError: false,
  inventory: [],
  spots: [],
  activeGood: undefined,
  showDetailPop: false,
  exitOrders: [],
  showFilterPop: -1,
  filters: {},
  activeFilters: false,
  sortBy: {},
  showCreateModal: false,
  filterStatus: ['stored', 'util_in_use'],
  enableSpotChange: false,
  newSpot: '',
  enableUpdate: '',
  updateData: {},
  page: 0,
  rowsPerPage: 10,
  pagination: { page: 1, pageCount: 8, pageSize: 8, total: 100 },
  startDate:null,
  endDate:null,
  sort:"customId:desc",
  search:'',
  checks:[],
  checkbox:false
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'scroll',
  overflowX: 'hidden'
};

class Inventory extends React.Component<InventoryProps, InventoryState> {
  state = { ...defaultState };

  componentDidMount() {
    resetNotifications()
    this.fetchData(true)
  }

  componentDidCatch(err: any, info: any) {
    this.setState({ hasError: true })
  }
  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    this.setState({ page: this.state.page === 0 ? newPage + 1 : newPage }, this.fetchData)

  };

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
    this.setState({ page: 0 }, this.fetchData)

  };
  fetchData = (checkSearchParams: boolean = false): void => {
    NotiStore.setState({ loading: true });


    let statusString = `sort[0]=${this.state.sort}&_q=${this.state.search}&`
    this.state.filterStatus.forEach((status, i) => {
      statusString += `${i > 0 ? '&' : ''}filters[status][${i}]=${status}`
    })

    fetchInvetory(statusString)
      .then(res => {
        this.setState({ inventory: res.data });
        this.setState({ pagination: res.meta.pagination })

        fetchWarehouseData()
          .then(resWh => {
            if (resWh) this.setState({ spots: resWh.spots || [] })

            fetchExitOrders('filters[isFulfilled]=false')
              .then(res => {
                this.setState({ exitOrders: res.data }, checkSearchParams ? () => {
                  let { id } = this.props.searchParams;

                  if (id) {
                    this.showProductDetail(Number(id))
                  }
                } : undefined);

                NotiStore.setState({ loading: false })
              })
              .catch(err => {
                NotiStore.setState({ error: true, errorMessage: 'Fehler beim Abrufen des Inventars aufgetreten.' })
                NotiStore.setState({ loading: false })
              })
          })
          .catch(err => {
            NotiStore.setState({ error: true, errorMessage: 'Fehler beim Abrufen der Lagerdaten aufgetreten.' })
            NotiStore.setState({ loading: false })
          })
      })
      .catch(err => {
        NotiStore.setState({ error: true, errorMessage: 'Fehler beim Abrufen des Inventars aufgetreten.' })
        NotiStore.setState({ loading: false })
      })
  }

  // TODO: only stored products are shown -> implement fetchProduct
  showProductDetail = (id: number): void => {
    resetNotifications()
    NotiStore.setState({ loading: true })

    fetchSingleInventory(id)
      .then(res => {
        this.setState({
          activeGood: res.data,
          showDetailPop: true
        })
      })
      .catch(err => {
        NotiStore.setState({ error: true, errorMessage: err.message || 'Fehler beim Abrufen der Produktdaten.' })
      })
      .finally(() => NotiStore.setState({ loading: false }))
  }

  onCloseDetailPop = (): void => {
    this.setState({
      activeGood: defaultState.activeGood,
      showDetailPop: false,
      updateData: defaultState.updateData,
      enableUpdate: defaultState.enableUpdate,
    })
  }

  checkExitDate = (id: string): string => {
    let { exitOrders } = this.state;
    let date: string = ''

    if (exitOrders.length >= 1) {
      exitOrders.forEach(order => {
        let { positions } = order.attributes

        if (positions && positions.length >= 1) positions.forEach(pos => {
          if (pos.trucks && pos.trucks.length >= 1) pos.trucks.forEach(truck => {
            if (truck.goods.includes(id)) {
              if (truck.dispatchDate) date = truck.dispatchDate.toString()
              else date = 'n/a'
            }
          })
        })
      })
    }

    return date ? convertDateString(date) : 'nicht geplant'
  }

  getTableTotal = (): { quantity: string, value: string, valueWithDelivery: string } => {
    let data = {
      quantity: 'n/a',
      value: 'n/a',
      valueWithDelivery: 'n/a',
    }

    let quantity: number = 0
    let value: number = 0
    let valueWithDelivery: number = 0

    if (this.state.inventory.length >= 1) this.state.inventory.forEach((good: Good) => {
      if (good.attributes.status === 'stored') {
        if (good.attributes.quantity && good.attributes.unit === 'kg') quantity += good.attributes.quantity
        else if (good.attributes.quantity && good.attributes.unit === 't') quantity += (good.attributes.quantity * 1000)

        if (good.attributes.baseValue && good.attributes.quantity) value += good.attributes.baseValue * good.attributes.quantity

        if (good.attributes.baseValueWithDelivery && good.attributes.quantity) valueWithDelivery += good.attributes.baseValueWithDelivery * good.attributes.quantity
      }
    })

    data.quantity = `${localNumber(quantity / 1000)} t`
    data.value = `${localCurrency(value)} €`
    data.valueWithDelivery = `${localCurrency(valueWithDelivery)} €`

    return data
  }

  handleFilterPop = (id: number): void => {
    if ((id && id > 0) || id === 0) {
      let { showFilterPop } = this.state;

      if (showFilterPop !== id) showFilterPop = id
      else {
        showFilterPop = defaultState.showFilterPop
      }

      this.setState({ showFilterPop })
    }
  }

  submitFilters = (): void => {
    NotiStore.setState({ loading: true })

    let { filters, sortBy } = this.state

    filters.status = this.state.filterStatus || []

    this.setState({ activeFilters: true })

    fetchInvetoryWithFilters(filters, sortBy)
      .then(res => {
        this.setState({ inventory: res.data || [] });
        NotiStore.setState({ loading: false })
      })
      .catch(err => {
        NotiStore.setState({ error: true, errorMessage: err.message || 'Fehler beim Abrufen des Inventars aufgetreten.', loading: false })
      })
  }

  handleFilter = (type:string,value:string): void => {
    NotiStore.setState({ loading: true })
    if(type==='search'){
      this.setState({search:value})
      this.fetchData()
    }else{
        this.fetchData() 
    }

  }

  clearFilters = (): void => {
    this.setState({ activeFilters: false, filters: {} ,sort:'customId:desc',search:'' })
    this.fetchData();
  }

  updateFilter = (e: React.ChangeEvent<HTMLInputElement>, key: string, multiple: string = ''): void => {
    let { filters } = this.state;

    if (multiple === 'multiple') {
      if (!filters[key]) filters[key] = [e.target.value]
      else {
        if (!filters[key].includes(e.target.value)) filters[key].push(e.target.value)
        else {
          let index = filters[key].indexOf(e.target.value)
          if (index > -1) filters[key].splice(index, 1)
        }
      }
    } else {
      if (e.target.value) filters[key] = [e.target.value]
      else delete filters[key]
    }

    this.setState({ filters })
  }

  handleSort = (value: string): void => {
    if (value) {
      NotiStore.setState({ loading: true })

      let { sortBy } = this.state
      if (sortBy.id === value) {
        if (sortBy.direction === 'desc') this.setState({ sortBy: { id: value, direction: 'asc' } }, this.submitFilters)
        else if (sortBy.direction === 'asc') this.setState({ sortBy: { id: value, direction: 'desc' } }, this.submitFilters)
      } else {
        this.setState({ sortBy: { id: value, direction: 'asc' } }, this.submitFilters)
      }

      NotiStore.setState({ loading: false })
    }
  }

  deleteProduct = (id: number): void => {
    if (window.confirm('Bist du sicher, dass du das Produkt löschen möchtest? Dieser Schritt kann nicht mehr rückgängig gemacht werden.')) {
      resetNotifications()
      NotiStore.setState({ loading: true })

      deleteProduct(id)
        .then(res => {
          NotiStore.setState({ success: true, successMessage: 'Produkt erfolgreich gelöscht.' })
          this.fetchData()
        })
        .catch(err => {
          NotiStore.setState({ error: true, errorMessage: err.message || 'Fehler beim Löschen des Produktes.' })
        })
        .finally(() => NotiStore.setState({ loading: false }))
    }
  }

  handleSpotChange = (): void => {
    if (this.state.enableSpotChange) {
      resetNotifications()
      NotiStore.setState({ loading: true })

      updateWarehouseSpot(this.state.activeGood ? this.state.activeGood.id : 0, this.state.newSpot)
        .then(res => {
          NotiStore.setState({ loading: false, success: true, successMessage: res.message || 'Produkt erfolgreich aktualisiert' })

          this.fetchData()
          if (this.state.activeGood) this.showProductDetail(this.state.activeGood.id)
        })
        .catch(err => {
          NotiStore.setState({ loading: false, error: true, errorMessage: err.message || 'Fehler' })
        })
    }

    this.setState({ enableSpotChange: !this.state.enableSpotChange })
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let { name, value } = e.target
    let { activeGood } = this.state

    if (activeGood && name && value) {
      // @ts-ignore
      activeGood.attributes[name] = value
      this.setState({ activeGood, updateData: { id: activeGood.id, [name]: value } })
    }
  }

  handleUpdate = (key: string): void => {
    let { enableUpdate, updateData } = this.state

    if (!enableUpdate && key) {
      this.setState({ enableUpdate: key })
    } else {
      resetNotifications()
      NotiStore.setState({ loading: true })

      updateGood(updateData)
        .then(res => {
          NotiStore.setState({ loading: false, success: true, successMessage: res.message || 'Produkt erfolgreich aktualisiert' })

          this.fetchData()
          if (this.state.activeGood) this.showProductDetail(this.state.activeGood.id)
        })
        .catch(err => {
          NotiStore.setState({ loading: false, error: true, errorMessage: err.message || 'Fehler' })
        })
        .finally(() => {
          this.setState({ enableUpdate: defaultState.enableUpdate })
        })
    }
  }

  filterByDateRage= ()=>{
    console.log(this.state.startDate?.format())
    this.fetchData()
  }

  
  handleCheckBox = (id:number)=>{
    if(this.state.checks.includes(id)){
      const index = this.state.checks.indexOf(id);
      if (index > -1) { 
        this.state.checks.splice(index, 1); 
      }
      this.setState({checks:this.state.checks})
    }else{
      this.state.checks.push(id)
      this.setState({checks:this.state.checks})
    }
  }
  handleMultiDelete=()=>{

    for(let i = 0; i<this.state.checks.length; i++){
      deleteProduct(this.state.checks[i])
          
        }
        this.setState({checks:[]})
        NotiStore.setState({ success: true, successMessage: 'Produkt erfolgreich gelöscht.' })
        this.fetchData()
  }
  render() {
    if (this.state.hasError) return (
      <div className="Inventory content" data-testid="Inventory">Etwas ist schief gelaufen.</div>
    )

    return (
      <div className="Inventory content" data-testid="Inventory">
        <div className="header-bar">
          <h1>Inventory</h1>
          <div>
            {this.props.rights.includes('warehouse::create') && <Button variant='contained' sx={{ marginRight: '15px' }} onClick={() => this.setState({ showCreateModal: true })}>Produkt erstellen</Button>}
            {this.props.rights.includes('inventory::csv') && <CSVLink
              separator=';'
              filename={`inventar-${toLocalISOTime(new Date())}.csv`}
              data={[['ID', 'Kategorie', 'Bezeichnung', 'Menge', 'Wert', 'Material', 'Lager', 'Erstellt', 'Ausgang']].concat(this.state.inventory.map(good => {
                return [
                  good.attributes.customId,
                  convertGoodsCategory(good.attributes.category),
                  good.attributes.typeIntern,
                  `${good.attributes.unit === 'kg' ? localNumberInTons(good.attributes.quantity) : localNumber(good.attributes.quantity)} ${good.attributes.unit === 'kg' ? 't' : good.attributes.unit}`,
                  `${good.attributes.baseValue ? localCurrency((good.attributes.baseValue) * good.attributes.quantity) : 0} €`,
                  convertOrderAttributes(good.attributes.material || ''),
                  `${good.attributes.warehouse_spot && good.attributes.warehouse_spot.data && good.attributes.warehouse_spot.data.attributes ? good.attributes.warehouse_spot.data.attributes.name : '???'}`,
                  convertDateString(good.attributes.createdAt),
                  this.checkExitDate(good.attributes.customId),
                ]
              }))}
            ><Button variant='contained'>CSV-Export</Button></CSVLink>}&nbsp;&nbsp;
           {
              this.state.checks.length ?
              <Button  onClick={()=>this.handleMultiDelete()} variant='contained' color="error" ><DeleteForeverOutlined /></Button>:null
            }
            <div>
            </div>
          </div>
        </div>
        <div className="header-bar">
        <div style={{ marginBottom: 20 }}>
          <Select
            multiple
            value={this.state.filterStatus}
            input={<OutlinedInput />}
            renderValue={(selected) => selected.map(el => translateEn(el)).join(', ')}
            onChange={(e) => this.setState({ filterStatus: Array.isArray(e.target.value) ? e.target.value : [e.target.value] })}
            onClose={this.submitFilters}
            sx={{ width: '300px' }}
          >
            {statuses.map((status) => (
              <MenuItem key={status} value={status}>
                <Checkbox checked={this.state.filterStatus.includes(status)} />
                <ListItemText primary={translateEn(status)} />
              </MenuItem>
            ))}
          </Select>
          <div style={{ marginTop: 10 }}>
            <TextField value={this.state.search} placeholder='Search...' onChange={(e)=>this.handleFilter('search',e.target.value)} />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Select
            value={this.state.sort}
            input={<OutlinedInput />}
            onChange={(e) => this.setState({sort:e.target.value})}
            onClose={()=>this.handleFilter('sort','')}
            sx={{ width: '300px' }}
          >
           
            {InvetorySort.map((status,i) => (
              <MenuItem key={i} value={status.value}>
                {status.Label}
              </MenuItem>
            ))}

          </Select>&nbsp;&nbsp;
          <Button onClick={()=>this.handleFilter('sort','')} variant='contained'>Sort</Button>&nbsp;&nbsp;
          <Button onClick={()=>this.clearFilters()} variant='contained' color="error">Clear</Button>
          </div>
        </div>
          <div>
            
            {/* <DemoContainer components={['DatePicker']}>
              <DatePicker value={this.state.startDate} onChange={(newValue) => this.setState({startDate:newValue})} />
              <DatePicker value={this.state.endDate} onChange={(newValue) => this.setState({endDate:newValue})} />
            <Button onClick={()=>this.filterByDateRage()} variant='contained'>Filter</Button>
            </DemoContainer> */}
          </div>

        </div>


        {this.state.activeFilters ? (
          <Chip color='error' className='pointer' sx={{ marginBottom: '15px' }} label={<span className='flex align-center'><Close sx={{ fontSize: '14px', marginRight: '10px' }} />Filter zurücksetzen</span>} onClick={this.clearFilters} />
        ) : null}

        <TableContainer className='inventory-table' component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  Action
                </TableCell>
                <TableCell>
                  <div className="flex space-between align-center nowrap td-with-icon">
                    <span>ID</span>
                    <div>
                      <ArrowDownward className='pointer icon' onClick={() => this.handleSort('customId')} />
                      <FilterList className={`pointer icon ${this.state.showFilterPop === 0 ? 'active' : ''}`} onClick={() => this.handleFilterPop(0)} />
                    </div>
                    <FilterPop
                      submitFilters={this.submitFilters}
                      filters={this.state.filters}
                      type='customId'
                      active={this.state.showFilterPop === 0}
                      closePop={() => this.setState({ showFilterPop: defaultState.showFilterPop })}
                      updateFilter={this.updateFilter}
                    />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex space-between align-center nowrap td-with-icon">
                    <span>category</span>
                    <div>
                      <ArrowDownward className='pointer icon' onClick={() => this.handleSort('category')} />
                      <FilterList className={`pointer icon ${this.state.showFilterPop === 3 ? 'active' : ''}`} onClick={() => this.handleFilterPop(3)} />
                    </div>
                    <FilterPop
                      submitFilters={this.submitFilters}
                      filters={this.state.filters}
                      type='category'
                      active={this.state.showFilterPop === 3}
                      closePop={() => this.setState({ showFilterPop: defaultState.showFilterPop })}
                      updateFilter={this.updateFilter}
                    />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex space-between align-center nowrap td-with-icon">
                    <span>description</span>
                    <div>
                      <ArrowDownward className='pointer icon' onClick={() => this.handleSort('typeIntern')} />
                      <FilterList className={`pointer icon ${this.state.showFilterPop === 2 ? 'active' : ''}`} onClick={() => this.handleFilterPop(2)} />
                    </div>
                    <FilterPop
                      submitFilters={this.submitFilters}
                      filters={this.state.filters}
                      type='typeIntern'
                      active={this.state.showFilterPop === 2}
                      closePop={() => this.setState({ showFilterPop: defaultState.showFilterPop })}
                      updateFilter={this.updateFilter}
                    />
                  </div>
                </TableCell>
                <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      amount
                    <div>
                      <ArrowDownward className='pointer icon'  />
                      <FilterList className={`pointer icon ${this.state.showFilterPop === 3 ? 'active' : ''}`} onClick={() => this.handleFilterPop(3)} />
                    </div>
                    <FilterPop
                      submitFilters={this.submitFilters}
                      filters={this.state.filters}
                      type='category'
                      active={this.state.showFilterPop === 3}
                      closePop={() => this.setState({ showFilterPop: defaultState.showFilterPop })}
                      updateFilter={this.updateFilter}
                    />
                </div>
                </TableCell>
                <TableCell>value</TableCell>
                <TableCell>
                  <div className='flex align-center nowrap'>
                    <span className='strong'>value incl.</span>
                    <Tooltip title='product value incl. delivery costs, duty, …'>
                      <InfoOutlined sx={{ width: '17px', marginLeft: '5px' }} />
                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell>material</TableCell>
                <TableCell>storage</TableCell>
                <TableCell>
                  <div className="flex space-between align-center nowrap td-with-icon">
                    <span>income</span>
                    <div>
                      <ArrowDownward className='pointer icon' onClick={() => this.handleSort('createdAt')} />
                    </div>
                  </div>
                </TableCell>
                <TableCell>planned outcome</TableCell>
                <TableCell>
                  <div className="flex space-between align-center nowrap td-with-icon">
                    <span>Toleranz</span>
                    <div>
                      <FilterList className={`pointer icon ${this.state.showFilterPop === 1 ? 'active' : ''}`} onClick={() => this.handleFilterPop(1)} />
                    </div>
                    <FilterPop
                      submitFilters={this.submitFilters}
                      filters={this.state.filters}
                      type='tolerance'
                      active={this.state.showFilterPop === 1}
                      closePop={() => this.setState({ showFilterPop: defaultState.showFilterPop })}
                      updateFilter={this.updateFilter}
                    />
                  </div>
                </TableCell>
                {this.props.rights.includes('warehouse::delete') && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.inventory.length >= 1 ? this.state.inventory.map((item: Good, i: number) => (
                <TableRow key={`item-${i}`}>
                  <TableCell><Checkbox checked={this.state.checks.includes(item.id)} onClick={()=>this.handleCheckBox(item.id)}/></TableCell>
                  <TableCell onClick={() => this.showProductDetail(item.id)}>{item.attributes.customId}</TableCell>
                  <TableCell>{convertGoodsCategory(item.attributes.category)}</TableCell>
                  <TableCell>{item.attributes.typeIntern}</TableCell>
                  <TableCell>{item.attributes.unit === 'kg' ? localNumberInTons(item.attributes.quantity) : localNumber(item.attributes.quantity)} {item.attributes.unit === 'kg' ? 't' : item.attributes.unit}</TableCell>
                  <TableCell>{item.attributes.baseValue ? localCurrency((item.attributes.baseValue) * item.attributes.quantity) : 0} €</TableCell>
                  <TableCell>{item.attributes.baseValueWithDelivery ? localCurrency((item.attributes.baseValueWithDelivery) * item.attributes.quantity) : 0} €</TableCell>
                  <TableCell>{convertOrderAttributes(item.attributes.material || '')}</TableCell>
                  <TableCell>{item.attributes.warehouse_spot && item.attributes.warehouse_spot.data && item.attributes.warehouse_spot.data.attributes ? item.attributes.warehouse_spot.data.attributes.name : '???'}</TableCell>
                  <TableCell>{convertDateString(item.attributes.createdAt)}</TableCell>
                  <TableCell>{this.checkExitDate(item.attributes.customId)}</TableCell>
                  <TableCell align='center'>
                    {item.attributes.laboratory_reports && item.attributes.laboratory_reports.data && item.attributes.laboratory_reports.data.length >= 1 && item.attributes.laboratory_reports.data.sort((a, b) => { return (a.attributes.createdAt < b.attributes.createdAt ? 1 : -1) })[0].attributes.tolerance === 'low' && <div className='swatch green'></div>}
                    {item.attributes.laboratory_reports && item.attributes.laboratory_reports.data && item.attributes.laboratory_reports.data.length >= 1 && item.attributes.laboratory_reports.data.sort((a, b) => { return (a.attributes.createdAt < b.attributes.createdAt ? 1 : -1) })[0].attributes.tolerance === 'medium' && <div className='swatch orange'></div>}
                    {item.attributes.laboratory_reports && item.attributes.laboratory_reports.data && item.attributes.laboratory_reports.data.length >= 1 && item.attributes.laboratory_reports.data.sort((a, b) => { return (a.attributes.createdAt < b.attributes.createdAt ? 1 : -1) })[0].attributes.tolerance === 'high' && <div className='swatch red'></div>}
                  </TableCell>
                  {this.props.rights.includes('warehouse::delete') && (
                    <TableCell>
                      <DeleteForever className='red action' onClick={() => this.deleteProduct(item.id)}></DeleteForever>
                    </TableCell>
                  )}
                </TableRow>
              )) : (
                <TableRow>
                  <TableCell>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="50vh"
                    paddingLeft={80}
                  >
                  <CircularProgress />
                  </Box>
                   

                  </TableCell>
                  {/* <TableCell>Keine Waren gefunden.</TableCell> */}
                </TableRow>
              )}
              <TableRow className='footer-row'>
                <TableCell>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>{this.getTableTotal().quantity}</TableCell>
                <TableCell>{this.getTableTotal().value}</TableCell>
                <TableCell>{this.getTableTotal().valueWithDelivery}</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
                {this.props.rights.includes('warehouse::delete') && <TableCell></TableCell>}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      { 
      this.state.inventory.length >= 1 &&
          <TablePagination
          component={"div"}
          count={this.state.pagination.total}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          rowsPerPage={this.state.rowsPerPage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      }
        <Modal
          open={this.state.showDetailPop && this.state.activeGood !== undefined}
          onClose={this.onCloseDetailPop}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="detail-pop"
        >
          <>
            <span className='modal-close pointer' onClick={this.onCloseDetailPop}><Close /></span>
            <Box sx={modalStyle}>
              <span className='modal-close pointer' onClick={this.onCloseDetailPop}><Close /></span>
              <div className="header-bar">
                <h2>Detailansicht</h2>
                {this.state.activeGood && this.state.activeGood?.attributes.label && this.state.activeGood?.attributes.label.data ? (
                  <div><a href={this.state.activeGood.attributes.label.data.attributes.url} target='_blank' rel='noreferrer' style={{ color: 'white' }}><Button variant='contained'><QrCode2 /></Button></a></div>
                ) : null}
              </div>
              <h3>Grunddaten</h3>
              <div className="flex of-4 spacing-1">
                <TextField
                  helperText='ID'
                  value={this.state.activeGood?.attributes.customId || 'nicht gefunden'}
                  disabled
                />
                <TextField
                  helperText='Kategorie'
                  value={this.state.activeGood?.attributes.category ? convertGoodsCategory(this.state.activeGood?.attributes.category) : 'nicht gefunden'}
                  disabled
                />
                <div style={{ position: 'relative' }}>
                  <TextField
                    helperText='Bezeichnung'
                    value={this.state.activeGood?.attributes.typeIntern || 'nicht gefunden'}
                    name='typeIntern'
                    disabled={this.state.enableUpdate !== 'typeIntern'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                    fullWidth
                  />
                  <span className='update-field' onClick={() => this.handleUpdate('typeIntern')}>
                    {this.state.enableUpdate === 'typeIntern' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <div style={{ position: 'relative' }}>
                  {this.state.enableUpdate === 'color' ? (
                    <TextField
                      helperText='Farbe'
                      value={this.state.activeGood?.attributes.color || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                      fullWidth
                      select
                      name='color'
                    >
                      {rpetColors.map(value => (
                        <MenuItem key={`color-${value}`} value={value}>{convertOrderAttributes(value)}</MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='Farbe'
                      value={convertOrderAttributes(this.state.activeGood?.attributes.color || 'n/a')}
                      disabled
                      fullWidth
                    />
                  )}
                  <span className='update-field' onClick={() => this.handleUpdate('color')}>
                    {this.state.enableUpdate === 'color' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <div style={{ position: 'relative' }}>
                  {this.state.enableUpdate === 'foodSafe' ? (
                    <TextField
                      helperText='LME'
                      value={this.state.activeGood?.attributes.foodSafe || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                      fullWidth
                      select
                      sx={{ marginTop: '15px' }}
                      name='foodSafe'
                    >
                      {foodSafeOptions.map(value => (
                        <MenuItem key={`foodSafe-${value}`} value={value}>{convertOrderAttributes(value)}</MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='LME'
                      value={convertOrderAttributes(this.state.activeGood?.attributes.foodSafe || 'n/a')}
                      disabled
                      sx={{ marginTop: '15px' }}
                      fullWidth
                    />
                  )}
                  <span className='update-field' onClick={() => this.handleUpdate('foodSafe')}>
                    {this.state.enableUpdate === 'foodSafe' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <div style={{ position: 'relative' }}>
                  {this.state.enableUpdate === 'material' ? (
                    <TextField
                      helperText='Material'
                      value={this.state.activeGood?.attributes.material || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                      fullWidth
                      select
                      sx={{ marginTop: '15px' }}
                      name='material'
                    >
                      {materials.map(value => (
                        <MenuItem key={`material-${value}`} value={value}>{convertOrderAttributes(value)}</MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='Material'
                      value={convertOrderAttributes(this.state.activeGood?.attributes.material || 'n/a')}
                      disabled
                      sx={{ marginTop: '15px' }}
                      fullWidth
                    />
                  )}
                  <span className='update-field' onClick={() => this.handleUpdate('material')}>
                    {this.state.enableUpdate === 'material' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <TextField
                  helperText='integriert von'
                  value={this.state.activeGood?.attributes.creator.data?.attributes.name || '???'}
                  disabled
                  sx={{ marginTop: '15px' }}
                />
                <TextField
                  helperText='Status'
                  value={translate(this.state.activeGood?.attributes.status || '???')}
                  disabled
                  sx={{ marginTop: '15px' }}
                />
              </div>
              <h3>Lager</h3>
              <div className="flex of-4 spacing-1">
                <div style={{ position: 'relative' }}>
                  <TextField
                    type={this.state.enableUpdate === 'quantity' ? 'number' : 'text'}
                    helperText='Menge'
                    value={this.state.enableUpdate === 'quantity' ? Number(this.state.activeGood?.attributes.quantity || 0) : (localNumber(this.state.activeGood?.attributes.quantity || '') || 'nicht gefunden')}
                    fullWidth
                    name='quantity'
                    disabled={this.state.enableUpdate !== 'quantity'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{this.state.activeGood?.attributes.unit || ''}</InputAdornment>
                    }}
                  />
                  <span className='update-field' onClick={() => this.handleUpdate('quantity')}>
                    {this.state.enableUpdate === 'quantity' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <TextField
                  helperText='Wert'
                  value={(this.state.activeGood?.attributes.baseValue ? localCurrency((this.state.activeGood?.attributes.baseValue) * this.state.activeGood?.attributes.quantity) : 0)}
                  disabled
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                  }}
                />
                <div style={{ position: 'relative' }}>
                  {this.state.enableSpotChange ? (
                    <TextField
                      helperText='Lagerplatz'
                      value={this.state.newSpot || ''}
                      onChange={e => this.setState({ newSpot: e.target.value })}
                      fullWidth
                      select
                    >
                      {this.state.spots && this.state.spots.length >= 1 && this.state.spots.map(spot => (
                        <MenuItem key={`spot-${spot.id}`} value={spot.id}>{spot.attributes.name} - {localNumber(spot.attributes.capacity)} kg frei</MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='Lagerplatz'
                      value={this.state.activeGood?.attributes.warehouse_spot.data ? this.state.activeGood?.attributes.warehouse_spot.data.attributes.name : 'nicht gefunden'}
                      disabled
                      fullWidth
                    />
                  )}
                  <span className='update-field' onClick={this.handleSpotChange}>
                    {this.state.enableSpotChange ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <TextField
                  helperText='erstellt am'
                  value={this.state.activeGood?.attributes.createdAt ? convertDateString(this.state.activeGood?.attributes.createdAt) : 'nicht gefunden'}
                  disabled
                />
              </div>

              <h3>Dokumente</h3>
              <div className="flex of-4 spacing-1 documents">
                <Link to={this.state.activeGood?.attributes.fromBatch && this.state.activeGood?.attributes.fromBatch.data ? `/produktion/chargen?id=${this.state.activeGood.attributes.fromBatch.data.id}` : '#'}>
                  <TextField
                    helperText='ID Charge'
                    value={this.state.activeGood?.attributes.fromBatch && this.state.activeGood?.attributes.fromBatch.data ? this.state.activeGood?.attributes.fromBatch.data.attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={this.state.activeGood?.attributes.fromBatch && this.state.activeGood?.attributes.fromBatch.data ? { style: { fontWeight: 'bold' } } : {}}
                  />
                </Link>
                <Link to={this.state.activeGood?.attributes.order && this.state.activeGood?.attributes.order.data ? `/wareneingang?id=${this.state.activeGood.attributes.order.data.id}` : '#'}>
                  <TextField
                    helperText='ID Bestellung'
                    value={this.state.activeGood?.attributes.order && this.state.activeGood?.attributes.order.data ? this.state.activeGood?.attributes.order.data.attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={this.state.activeGood?.attributes.order && this.state.activeGood?.attributes.order.data ? { style: { fontWeight: 'bold' } } : {}}
                  />
                </Link>
                <Link to={this.state.activeGood?.attributes.exit_orders && this.state.activeGood?.attributes.exit_orders.data && this.state.activeGood?.attributes.exit_orders.data[0] && this.state.activeGood?.attributes.exit_orders.data[0].attributes ? `/warenausgang?id=${this.state.activeGood?.attributes.exit_orders.data[0].id}` : '#'}>
                  <TextField
                    helperText='ID Auftrag'
                    value={this.state.activeGood?.attributes.exit_orders && this.state.activeGood?.attributes.exit_orders.data && this.state.activeGood?.attributes.exit_orders.data[0] && this.state.activeGood?.attributes.exit_orders.data[0].attributes && this.state.activeGood?.attributes.exit_orders.data[0].attributes.customId ? this.state.activeGood?.attributes.exit_orders.data[0].attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={this.state.activeGood?.attributes.exit_orders && this.state.activeGood?.attributes.exit_orders.data && this.state.activeGood?.attributes.exit_orders.data[0] && this.state.activeGood?.attributes.exit_orders.data[0].attributes ? { style: { fontWeight: 'bold' } } : {}}
                  />
                </Link>
                  <TextField
                    helperText='Laborbericht'
                    value={this.state.activeGood?.attributes.laboratory_reports && this.state.activeGood?.attributes.laboratory_reports.data && this.state.activeGood?.attributes.laboratory_reports.data[0] && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId ? this.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={this.state.activeGood?.attributes.laboratory_reports && this.state.activeGood?.attributes.laboratory_reports.data && this.state.activeGood?.attributes.laboratory_reports.data[0] && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes ? { style: { fontWeight: 'bold' } } : {}}
                  />
              </div>
              {
                this.state.activeGood?.attributes.laboratory_reports && this.state.activeGood?.attributes.laboratory_reports.data && this.state.activeGood?.attributes.laboratory_reports.data[0] && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId&&
                <div className='flex of-4 spacing-1 documents'>
                  <Link to={this.state.activeGood?.attributes.laboratory_reports && this.state.activeGood?.attributes.laboratory_reports.data && this.state.activeGood?.attributes.laboratory_reports.data[0] && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes ? `/labor?id=${this.state.activeGood?.attributes.laboratory_reports.data[0].id}` : '#'}>
                    <Button  sx={{ marginTop: '15px' }}className="pt-5" variant='contained'>Go {this.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId}</Button>
                  </Link>
                </div>
              }

              <h3>Status</h3>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Aktueller Status</TableCell>
                      <TableCell align='right'>{translate(this.state.activeGood?.attributes.status || 'nicht bekannt')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Geplante Abholung (Warenausgang)</TableCell>
                      <TableCell align='right'>{this.state.activeGood?.id ? this.checkExitDate(this.state.activeGood?.attributes.customId) : '???'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <h3>Status</h3> */}
              {/* //TODO */}
            </Box>
          </>
        </Modal>
        {
          this.state.showCreateModal ?
            <ProductForm
              active={this.state.showCreateModal}
              onClose={(fetchData: boolean = false) => {
                this.setState({ showCreateModal: false })
              }}
              fetchData={this.fetchData}
            /> : null
        }
      </div>
    )
  }
}

export default withRouter(Inventory);
