import create from 'zustand'

export const NotiStore: any = create((set: any) => ({
  loading: false,
  success: false,
  error: false,
  successMessage: '',
  errorMessage: '',
  info: '',
  handleSuccess: (success: boolean, successMessage: string = '') => {
    set(() => ({ success, successMessage, error: false }));
  },
  handleError: (error: boolean, errorMessage: string = '') => {
    set(() => ({ error, errorMessage, success: false }));
  },
  handleLoading: (loading: boolean) => {
    set(() => ({loading}));
  },
  resetNotifications: () => {
    set(() => ({success: false, error: false, errorMessage: '', successMessage: '', info: ''}))
  }
}))